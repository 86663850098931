
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div *ngIf="type !== 'mail-email-template-avatar'" class="vnc-avatar avatar" [ngClass] = "type" [ngStyle]="{'background-color': getColor()}">
  <img draggable="false" *ngIf="avatarURL" [src]="avatarURL" #imgElement validate="never" (error)="imgLoadOnError($event)">
  <div *ngIf="user && user.firstLastCharacters && !avatarURL && !isLoginUser" class="profile-avtar">{{user.firstLastCharacters}}</div>
  <div *ngIf="pendingOperation" class="pending-operation-icon" matTooltip="{{'OFFLINE_ACTION_NOT_SYNC' | translate }}">
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/restore" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(1.000000, 3.000000)" fill="#ffffff">
              <path d="M12,0 C7.03,0 3,4.03 3,9 L0,9 L3.89,12.89 L3.96,13.03 L8,9 L5,9 C5,5.13 8.13,2 12,2 C15.87,2 19,5.13 19,9 C19,12.87 15.87,16 12,16 C10.07,16 8.32,15.21 7.06,13.94 L5.64,15.36 C7.27,16.99 9.51,18 12,18 C16.97,18 21,13.97 21,9 C21,4.03 16.97,0 12,0 L12,0 Z M11,5 L11,10 L15.28,12.54 L16,11.33 L12.5,9.25 L12.5,5 L11,5 L11,5 Z" id="Shape"></path>
          </g>
      </g>
    </svg>
  </div>
  <img draggable="false" *ngIf="isLoginUser && !avatarURL" [src]="'assets/images/profile.png'" validate="never">
</div>
<div *ngIf="type == 'mail-email-template-avatar'" class="vnc-avatar avatar" [ngClass] = "type" [ngStyle]="{'background-color': '#9d46ff'}">
  <mat-icon class="mdi-24px" fontSet="mdi" fontIcon="mdi-email-variant"></mat-icon>
</div>
<div class="right-bottom" *ngIf="rightIconDefaultAvatarTemplate">
  <ng-container [ngTemplateOutlet]="rightIconDefaultAvatarTemplate"></ng-container>
</div>


