
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <vnc-header
    class="message"
    title="{{ 'WARNING' | translate }}"
    type="title-close"
    size="l"
    (onCloseClick)="close('cancel')"
    [showCloseIcon]="true"
    closeIconToolTip="{{ 'CLOSE' | translate }}">
  </vnc-header>
    <div class="content">
        {{ 'CALENDARS.SAVE_CHANGE_CONTENT' | translate }}
    </div>
    <div class="action-btn" [ngClass]="{ 'dfb-theme': currentTheme === 'dfb' }">
        <vnc-button class="no-hover vnc-cancel-btn" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'primary'" label="{{'COMMON.YES' | translate }}" (click)="close('yes')"></vnc-button>
        <vnc-button class="no-hover vnc-cancel-btn" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{'COMMON.NO' | translate }}" (click)="close('no')"></vnc-button>
        <vnc-button class="no-hover vnc-cancel-btn" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{'CANCEL' | translate }}" (click)="close('cancel')"></vnc-button>
    </div>
</div>