
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="desktop-view" *ngIf="!isMobileScreen">
    <div class="mail__dialog-header desktop-header" style="padding: 0 16px;width: auto;">
        <div class="header-text">{{ 'CALENDARS.FIND_EQUIPMENT_LBL' | translate }}</div>
        <div class="header-icon" (click)="close()" matTooltip="{{ 'CLOSE' | translate }}">
            <ng-container [ngTemplateOutlet]="closeIcon"></ng-container>
        </div>
    </div>
    <div class="mail__dialog-body">
        <div class="content" [ngClass]="{ 'dfb-theme': currentTheme === 'dfb' }">
            <div class="left-side">
                <div class="find-location">
                    {{ 'CALENDARS.SEARCH_EQUIPMENT_LBL' | translate }}
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{'CALENDARS.NAME_LBL' | translate }}" [(ngModel)]="nameField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.DESCRIPTION_EQUPMENT_LBL' | translate }}"
                            [(ngModel)]="descriptionField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.CONTACT_EQUIPMENT_LBL' | translate }}"
                            [(ngModel)]="contactField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.SITE_LBL' | translate }}" [(ngModel)]="siteField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.BUILDING_LBL' | translate }}"
                            [(ngModel)]="buildingField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.FLOOR_LBL' | translate }}" [(ngModel)]="floorField">
                    </mat-form-field>
                </div>
                <div class="search-btn">
                    <vnc-button [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="primary" (click)="searchRequest()">
                        <span>{{'CALENDARS.SEARCH_LBL' | translate }}</span>
                    </vnc-button>
                </div>
            </div>
            <div class="right-side">
                <div class="search-equipment">
                    <div class="list-item">
                        <table>
                            <tr>
                                <th *ngFor="let column of columns" mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event, 'resource-' + column.title)" [resizeEdges]="{bottom: false, right: true, top: false, left: true}" class="disable-select resource-{{column.title}}">
                                    {{ column.title }}
                               </th>
                            </tr>
                            <tr *ngFor="let resouce of calResource; index as i;"
                                (click)="selectEvent(resouce, i, $event)"
                                [ngClass]="{'is-selected': isSelectedItem(resouce)}">
                                <td class="disable-select" matTooltipPosition="above" matTooltip="{{resouce?.fullName}}" [matTooltipClass]="'resource-tooltip'">{{resouce?.fullName}}</td>
                                <td class="disable-select" matTooltipPosition="above" matTooltip="{{resouce?.fullName}}" [matTooltipClass]="'resource-tooltip'">{{resouce?.fullName}}</td>
                                <td class="disable-select"></td>
                                <td class="disable-select"></td>
                                <td matTooltipPosition="above" class="{{itemsById[resouce.email]?.className}}" [matTooltipClass]="'resource-tooltip'" matTooltip="{{ (itemsById[resouce.email]?.txt || 'CALENDARS.FREE_LBL') | translate}}">{{ (itemsById[resouce.email]?.txt || 'CALENDARS.FREE_LBL') | translate }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="btns">
                    <vnc-button [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="{{calResource && calResource.length === 0 ? 'disabled' : 'primary'}}" (click)="selectAll()">
                        <span>{{'CALENDARS.ADD_ALL_LBL' | translate }}</span>
                    </vnc-button>
                    <vnc-button [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="{{selectedItems.length === 0 ? 'disabled' : 'primary'}}" (click)="addItem()">
                        <span>{{'CALENDARS.ADD_LBL' | translate }}</span>
                    </vnc-button>
                    <vnc-button [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="{{selectedEquipmentItem.length === 0? 'disabled' : 'primary'}}" (click)="removeEquipment()">
                        <span>{{'CALENDARS.REMOVE_LBL' | translate }}</span>
                    </vnc-button>
                    <vnc-button [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="{{selectedCalResouce.length === 0 ? 'disabled' : 'primary'}}" (click)="removeAll()">
                        <span>{{'CALENDARS.REMOVE_ALL_ALL' | translate }}</span>
                    </vnc-button>
                </div>
                <div class="location-for-equipment">
                    {{ 'CALENDARS.LOCATION_FOR_EQUIPMENT' | translate }}
                </div>
                <div class="selected-equipment">
                    <div class="list-item">
                        <table>
                            <tr>
                                <th *ngFor="let column of selectedColumns" class="disable-select"
                                    mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event, 'selected-' + column.title)" [resizeEdges]="{bottom: false, right: true, top: false, left: true}" class="disable-select selected-{{column.title}}"
                                >
                                    {{ column.title }}
                                </th>
                            </tr>
                            <tr *ngFor="let resouce of selectedCalResouce"
                                (click)="selectResouceEvent(resouce, i, $event)"
                                [ngClass]="{'is-selected': isSelectedEquipmentItem(resouce)}">
                                <td matTooltipPosition="above" [matTooltipClass]="'resource-tooltip'" matTooltip="{{resouce?.fullName}}">{{resouce?.fullName}}</td>
                                <td matTooltipPosition="above" [matTooltipClass]="'resource-tooltip'" matTooltip="{{resouce?.fullName}}">{{resouce?.fullName}}</td>
                                <td></td>
                                <td></td>
                                <td matTooltipPosition="above" class="{{itemsById[resouce.email]?.className}}" [matTooltipClass]="'resource-tooltip'" matTooltip="{{ (itemsById[resouce.email]?.txt || 'CALENDARS.FREE_LBL') | translate}}">{{ (itemsById[resouce.email]?.txt || 'CALENDARS.FREE_LBL') | translate }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <vnc-footer class="calendar-menu-action" [secondaryLabel]="'CANCEL' | translate | uppercase"
        [primaryLabel]="'CALENDARS.SELECT_EQUIPMENT_LBL' | translate | uppercase"
        [type]="'secondary-primary'"
        [color]="'white'"
        (onSecondaryClick)="close()"
        [ngClass]="{ 'dfb-theme': currentTheme === 'dfb' }"
        [isPrimaryDisabled]="selectedCalResouce.length === 0"
        (onPrimaryClick)="submit()"></vnc-footer>
    </div>
</div>

<div class="mobile-view" *ngIf="isMobileScreen">
    <div class="mail__dialog-header" layout="row center-center">
        <div class="header-title">
            <div class="icon" (click)="close()">
                <mat-icon>close</mat-icon>
            </div>
            <div class="title"><span>{{'CALENDARS.FIND_EQUIPMENT_LBL' | translate }}</span></div>
        </div>
    </div>
    <div class="mail__dialog-body">
        <div class="content-item">
            <div class="search-content">
                <div class="find-location">
                    {{ 'CALENDARS.SEARCH_EQUIPMENT_LBL' | translate }}
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{'CALENDARS.NAME_LBL' | translate }}" [(ngModel)]="nameField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.DESCRIPTION_EQUPMENT_LBL' | translate }}"
                            [(ngModel)]="descriptionField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.CONTACT_EQUIPMENT_LBL' | translate }}"
                            [(ngModel)]="contactField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.SITE_LBL' | translate }}" [(ngModel)]="siteField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.BUILDING_LBL' | translate }}"
                            [(ngModel)]="buildingField">
                    </mat-form-field>
                </div>
                <div class="form-filed-division">
                    <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{ 'CALENDARS.FLOOR_LBL' | translate }}" [(ngModel)]="floorField">
                    </mat-form-field>
                </div>
                <div class="search-btn">
                    <button mat-button (click)="searchRequest()">{{ 'CALENDARS.SEARCH_LBL' | translate }}</button>
                </div>
            </div>
            <div class="mobile-search-equipment">
                    <div class="find-location">
                            {{ 'CALENDARS.SUGGEST_LOCATIONS_LBL' | translate }}
                        </div>
                    <mat-list role="list">
                        <mat-list-item *ngFor="let resouce of calResource; index as i;" (click)="selectEvent(resouce, i, $event)" [ngClass]="{'is-selected': isSelectedItem(resouce)}">
                            <div class="main-search-div">
                                <div class="left-search-item">
                                    {{resouce?.fullName}} <span class="{{itemsById[resouce.email]?.className}}">
                                        {{ (itemsById[resouce.email]?.txt || 'CALENDARS.FREE_LBL') | translate }}
                                    </span>
                                </div>
                                <div class="righ-search-close">
                                    <!-- <mat-icon>close</mat-icon> -->
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
            </div>
            <mat-divider></mat-divider>
            <div class="btns">
                    <button mat-button (click)="selectAll()"
                        [disabled]="calResource && calResource.length === 0">{{ 'CALENDARS.ADD_ALL_LBL' | translate }}</button>
                    <button mat-button (click)="addItem()"
                        [disabled]="selectedItems.length === 0">{{ 'CALENDARS.ADD_LBL' | translate }}</button>
                    <button mat-button (click)="removeEquipment()"
                        [disabled]="selectedEquipmentItem.length === 0">{{ 'CALENDARS.REMOVE_LBL' | translate }}</button>
                    <button mat-button (click)="removeAll()"
                        [disabled]="selectedCalResouce.length === 0">{{ 'CALENDARS.REMOVE_ALL_ALL' | translate }}</button>
            </div>
            <mat-divider></mat-divider>
            <div class="mobile-selected-equipment">
                <div class="find-location">
                        {{ 'CALENDARS.LOCATION_FOR_EQUIPMENT' | translate }}
                    </div>
                <mat-list role="list">
                    <mat-list-item *ngFor="let resouce of selectedCalResouce" (click)="selectResouceEvent(resouce, i, $event)" [ngClass]="{'is-selected': isSelectedEquipmentItem(resouce)}">
                        <div class="main-search-div">
                            <div class="left-search-item">
                                {{resouce?.fullName}} <span class="{{itemsById[resouce.email]?.className}}">
                                    {{ (itemsById[resouce.email]?.txt || 'CALENDARS.FREE_LBL') | translate }}
                                </span>
                            </div>
                            <div class="righ-search-close">
                                <mat-icon (click)="removeFromClose(resouce)">close</mat-icon>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
</div>

<div class="mobile-footer" *ngIf="isMobileScreen && selectedCalResouce.length > 0">
    <span>{{ 'SAVE_CHANGES' | translate }}</span>
    <button mat-button (click)="submit()">
        <span>
            <mat-icon>check</mat-icon>
        </span>
    </button>
</div>

<ng-template  #closeIcon>
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
        </g>
    </svg>
  </ng-template>