
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
<div class="switch-holder" *ngIf="switchApp && !isMobileScreen">
  <ng-template ngFor let-route [ngForOf]="routes">
    <div class="switch-box app_switch--{{route.title}}" [class.active-switch]="route.active"
             (click)="openApp(route)">
          <div class="switch-icon"><img [src]="route.icon"/></div>
          <div class="switch-title">{{route.title}}</div>
        </div>
  </ng-template>
</div>
<div class="switch-holder" *ngIf="switchApp && isMobileScreen" [ngClass]="{'more-apps-expanded': isMoreAppsExpanded}">
  <ng-template ngFor let-route [ngForOf]="(isMoreAppsExpanded ? routes : routes?.slice(0, 5))">
    <div class="switch-box app_switch--{{route.title}}" [class.active-switch]="route.active"
             (click)="openApp(route)">
          <div class="switch-icon"><img [src]="route.icon"/></div>
          <div class="switch-title">{{route.title}}</div>
    </div>
  </ng-template>
  <div *ngIf="!isMoreAppsExpanded" class="switch-box more-icon"
      (click)="expandApp()">
      <div class="switch-icon">
        <div class="more-icon-block">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Icon/product/more-horiz" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Icon-24px" transform="translate(4.000000, 10.000000)" fill="#8B96A0">
                  <path d="M2,0 C0.9,0 0,0.9 0,2 C0,3.1 0.9,4 2,4 C3.1,4 4,3.1 4,2 C4,0.9 3.1,0 2,0 L2,0 Z M14,0 C12.9,0 12,0.9 12,2 C12,3.1 12.9,4 14,4 C15.1,4 16,3.1 16,2 C16,0.9 15.1,0 14,0 L14,0 Z M8,0 C6.9,0 6,0.9 6,2 C6,3.1 6.9,4 8,4 C9.1,4 10,3.1 10,2 C10,0.9 9.1,0 8,0 L8,0 Z" id="Shape"></path>
              </g>
          </g>
        </svg>
      </div>
    </div>
    <div class="switch-title">{{'MORE_LBL' | translate }}</div>
  </div>
</div>
