
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, ChangeDetectorRef, HostListener } from "@angular/core";
import { Subject, of as observableOf, Observable } from "rxjs";
import { FlatTreeControl } from "@angular/cdk/tree";
import { CalendarFolderFlatNode, CalendarFolder, CalendarComposeViewDefaultControl } from "src/app/common/models/calendar.model";
import { SelectionModel } from "@angular/cdk/collections";
import { ToastService } from "src/app/common/providers/toast.service";
import { Store } from "@ngrx/store";
import { take, takeUntil } from "rxjs/operators";
import { CalenderUtils } from "src/app/calendar/utils/calender-utils";
import { CalendarConstants } from "src/app/common/utils/calendar-constants";
import { TranslateService } from "@ngx-translate/core";
import { FormControl } from "@angular/forms";
import * as moment from "moment-timezone";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import jstimezonedetect from "jstimezonedetect";
import { ConfigService } from "src/app/config.service";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { CommonService } from "src/app/services/ common.service.";
import { getCalendarFolders } from "src/app/reducers";
import { environment } from "src/environments/environment";
import { MatTreeFlattener, MatTreeFlatDataSource } from "@angular/material/tree";
import { MatDialogRef } from "@angular/material/dialog";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

@Component({
    selector: "vp-calendar-print-dialog",
    templateUrl: "./calendar-print-dialog.component.html"
})
export class CalendarPrintDialogComponent implements OnInit, OnDestroy {
    currentTheme = localStorage.getItem("theme");
    IsSpecificHours: boolean = true;
    private isAlive$ = new Subject<boolean>();
    calendarTreeControl: FlatTreeControl<CalendarFolderFlatNode>;
    calendarTreeFlattener: MatTreeFlattener<CalendarFolder, CalendarFolderFlatNode>;
    calendarDataSource: MatTreeFlatDataSource<CalendarFolder, CalendarFolderFlatNode>;
    calendarFolders: CalendarFolder[];
    AllCalendarFolderBackup: CalendarFolder[] = [];
    AllCalendarFolderFlatten: CalendarFolder[] = [];
    searchControl = new FormControl("");
    searchFilterOptions = [{label: "is", value: "="}];
    searchPlaceholder: string = "Search";
    searchFilterControl = new FormControl(this.searchFilterOptions[0].value);
    checklistSelection = new SelectionModel<CalendarFolder>(true);
    selectedCalendarBackup = [];
    selectedRadio: string = "currentDate";
    todayDateFormControl: FormControl = new FormControl(new Date());
    startDateFormControl: FormControl = new FormControl(new Date());
    endDateFormControl: FormControl = new FormControl(new Date());
    printViewType: string = "day";
    selectedCalendarFolder: string = "";
    viewTypeOptions: any = [
        "day",
        "week",
        "month",
        "list"
    ];
    startTimeControl: Date;
    endTimeControl: Date;
    calendarComposeView: CalendarComposeViewDefaultControl;
    oneDayPage: boolean = false;
    oneWeekPage: boolean = false;
    minicalendar: boolean = false;
    isMobileScreen: boolean = false;
    CalendarConstants = CalendarConstants;
    @HostListener("document:click", ["$event.target"])
    onClick(e) {
        if (e?.classList?.contains("cdk-overlay-backdrop")) {
            this.cancel();
        }
    }
    constructor(
        public dialogRef: MatDialogRef<CalendarPrintDialogComponent>,
        public toastService: ToastService,
        private store: Store<CalendarState>,
        private commonService: CommonService,
        private translate: TranslateService,
        private calendarRepository: CalendarRepository,
        private configService: ConfigService,
        public mailService: MailService,
        private breakpointObserver: BreakpointObserver,
        private changeDetectionRef: ChangeDetectorRef,
    ) {
        this.calendarComposeView = this.calendarRepository.calendarComposeViewDefaultControl;
        this.calendarTreeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
            this._isExpandable, this._getChildren);
        this.calendarTreeControl = new FlatTreeControl<CalendarFolderFlatNode>(this._getLevel, this._isExpandable);
        this.calendarDataSource = new MatTreeFlatDataSource(this.calendarTreeControl, this.calendarTreeFlattener);
        this.store.select(getCalendarFolders).pipe(take(1)).subscribe(res => {
            if (!!res) {
                this.AllCalendarFolderBackup = res;
                this.calendarFolders = res;
                if (res) this.setFlatFolderList(this.AllCalendarFolderBackup);
                if (this.calendarDataSource) {
                    this.calendarDataSource.data = this.calendarFolders;
                }
                this.checkCalenderFolder(this.calendarFolders);
            }
        });
        this.printViewType = this.calendarRepository.selectingCalendarView;
        if (this.calendarRepository.selectingCalendarView === "workWeek") {
            this.printViewType = "week";
        }
        this.setRangeDate();
        this.changeViewType(this.printViewType);

        this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
        this.breakpointObserver
          .observe(["(max-width: 767px)"])
          .pipe(takeUntil(this.isAlive$))
          .subscribe((state: BreakpointState) => {
            if (state.matches) {
              this.isMobileScreen = true;
            } else {
              this.isMobileScreen = false;
            }
            this.changeDetectionRef.markForCheck();
          });
    }

    hasNestedChild = (_: number, nodeData: CalendarFolder) => (nodeData.folder && nodeData.folder.length > 0) || nodeData.link;
    transformer = (node: CalendarFolder, level: number) => {
        const flat = node as CalendarFolderFlatNode;
        flat.level = level;
        flat.expandable = !!node.folder;
        return flat;
    }
    private _getLevel = (node: CalendarFolderFlatNode) => node.level;

    private _isExpandable = (node: CalendarFolderFlatNode) => node.expandable;

    private _getChildren(node: CalendarFolder): Observable<CalendarFolder[]> {
        let folders = node.folder || [];
        if (node.link) {
            folders = [...folders, ...node.link.map(v => v as CalendarFolder)];
        }
        return observableOf(folders);
    }

    hasChild = (_data: number, _nodeData: CalendarFolderFlatNode) => _nodeData.expandable;

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    ngOnInit() {
        const todayDate = new Date();
        this.startTimeControl = moment(todayDate).toDate();
        this.endTimeControl = moment(todayDate).toDate();
        this.startTimeControl.setHours(8);
        this.startTimeControl.setMinutes(0);
        this.endTimeControl.setHours(17);
        this.endTimeControl.setMinutes(0);
        this.calendarRepository.initCalendarComposeViewForTimePoint(moment(todayDate).toDate());
        if (this.checklistSelection?.selected?.length) {
            this.getSelectedCalendars();
        } else {
            this.selectedCalendarFolder = "";
        }
        this.searchControl.valueChanges.subscribe(v => {
            if (!!v && v.length > 0) {
              this.calendarDataSource.data = this.AllCalendarFolderFlatten.filter((f: any) => f?.name.toLowerCase().indexOf(v.toLowerCase()) !== -1);
            } else {
              this.calendarDataSource.data = [...this.AllCalendarFolderBackup];
            }
        });
    }

    cancel() {
        this.checklistSelection.clear();
        this.selectedCalendarBackup.forEach(item => this.checklistSelection.select(item));
        if (this.checklistSelection?.selected?.length) {
            this.getSelectedCalendars();
        }
    }

    updateSelectedCalendar() {
        this.selectedCalendarBackup = this.checklistSelection.selected;
    }

    close(): void {
        this.dialogRef.close();
    }

    checkCalenderFolder(calenderFolder: CalendarFolder[]) {
        const allFolders = [...calenderFolder, ...CalenderUtils.getChildFolders(calenderFolder)];
        allFolders.map(f => {
            if (f.f && f.f.indexOf("#") !== -1) {
                this.checklistSelection.select(f);
                const index = this.selectedCalendarBackup.find(v => v?.id === f?.id);
                if (!index) this.selectedCalendarBackup.push(f);
                if (f.folder) {
                    const childFolders = CalenderUtils.getChildFolders([f]);
                    childFolders.map(fd => {
                        if (fd.f && fd.f.indexOf("#") !== -1) {
                            this.checklistSelection.select(fd);
                            const index = this.selectedCalendarBackup.find(v => v?.id === f?.id);
                            if (!index) this.selectedCalendarBackup.push(f);
                        }
                    });
                }
            }
        });
    }

    itemSelectionToggle(node: CalendarFolder, ev: MouseEvent): void {
        if (!this.isMobileScreen) {
            ev?.stopPropagation();
            ev?.preventDefault();
        }
        this.checklistSelection.toggle(node);
        const descendants = this.calendarTreeControl.getDescendants(node);
        this.checklistSelection.isSelected(node) ?
            this.checklistSelection.select(...descendants) : this.checklistSelection.deselect(...descendants);
        const selectUnselectNodes: string[] = [];
        selectUnselectNodes.push(node.id);
        if (descendants.length > 0) {
            descendants.map(f => selectUnselectNodes.push(f.id));
        }
        if (this.checklistSelection?.selected?.length) {
            this.getSelectedCalendars();
        } else {
            this.selectedCalendarFolder = "";
        }
    }

    changeViewType(option: string): void {
        this.printViewType = option;
        if (this.printViewType !== "day") {
            this.selectedRadio = "dateRange";
        } else {
            this.selectedRadio = "currentDate";
        }
    }

    handleStartTimeChanges(date: Date): void {
        this.startTimeControl = new Date(date);
    }

    handleEndTimeChanges(date: Date): void {
        this.endTimeControl = new Date(date);
    }

    printCalendar(): void {
        const ids: any[] = [];
        let date: string;
        let endDate: string;
        const od: boolean = false;
        const wd: boolean = this.oneDayPage;
        const ow: boolean = this.oneWeekPage;
        const timezone = jstimezonedetect.determine().name();
        let wdays = "";
        const imc = this.minicalendar;
        this.checklistSelection.selected.map(checkList => {
            ids.push(checkList.id);
        });
        if (this.selectedRadio === "currentDate") {
            date = moment(this.todayDateFormControl.value).format("YYYYMMDD") + "T" + "000000";
            endDate = moment(this.todayDateFormControl.value).format("YYYYMMDD") + "T" + "235959";
            if (this.printViewType === "week") {
                const start = moment(this.todayDateFormControl.value).startOf("week").format("YYYYMMDD");
                const end = moment(this.todayDateFormControl.value).endOf("week").format("YYYYMMDD");
                date = start + "T" + "000000";
                endDate = end + "T" + "235959";
            }
        } else {
            date = moment(this.startDateFormControl.value).format("YYYYMMDD") + "T" + "000000";
            endDate = moment(this.endDateFormControl.value).format("YYYYMMDD") + "T" + "235959";
            if (this.printViewType === "week") {
                const start = moment(this.startDateFormControl.value).startOf("week").format("YYYYMMDD");
                const end = moment(this.endDateFormControl.value).endOf("week").format("YYYYMMDD");
                date = start + "T" + "000000";
                endDate = end + "T" + "235959";
            }
        }
        if (this.printViewType === "month") {
            const start = moment(this.startDateFormControl.value).startOf("month").format("YYYYMMDD");
            const end = moment(this.endDateFormControl.value).endOf("month").format("YYYYMMDD");
            date = start + "T" + "000000";
            endDate = end + "T" + "235959";
        }
        if (this.printViewType === "week") {
            wdays = "1,2,3,4,5";
        }
        if (this.printViewType === "month"  && wd ) {
            wdays = "1,2,3,4,5";
        }
        const ftTime = moment(this.startTimeControl).format("HH:mm");
        const ttTime = moment(this.endTimeControl).format("HH:mm");
        const isCordovaOrElectron = environment.isCordova || environment.isElectron;
        let url = this.configService.API_URL + "/api/printCalendars?l=" + ids.toString() + "&view=" + this.printViewType +
        "&date=" + date + "&endDate=" + endDate + "&ft=" + ftTime + "&tt=" + ttTime + "&wd=" + wd + "&ow=" + ow +
        "&od=" + od + "&imc=" + imc + "&wdays=" + wdays + "&tz=" + timezone;
        if (isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            url = url + "&token=" + token;
        }
        window.open(url);
    }

    setTodayDateItem(): void {
        this.todayDateFormControl = new FormControl(new Date());
    }

    setRangeDate(): void {
        if (this.printViewType === "month") {
            this.startDateFormControl.setValue(moment(this.startDateFormControl.value).startOf("month").toDate());
            this.endDateFormControl.setValue(moment(this.startDateFormControl.value).endOf("month").toDate());
        } else if (this.printViewType === "week") {
            const endDateValue = new Date(this.startDateFormControl.value);
            endDateValue.setDate(endDateValue.getDate() + 4);
            this.endDateFormControl.setValue(endDateValue);
        } else if (this.printViewType === "list") {
            const endDateValue = new Date(this.startDateFormControl.value);
            endDateValue.setDate(endDateValue.getDate() + 14);
            this.endDateFormControl.setValue(endDateValue);
        }
    }
    toggleSpecifihours() {
        this.IsSpecificHours = !this.IsSpecificHours;
    }

    datesUpdatedEvent(event: any, type: string) {
        if (event) {
            if (type === "startDate") {
                this.startDateFormControl.setValue(event?.$d);
            } else if (type === "endDate") {
                this.endDateFormControl.setValue(event?.$d);
            } else if (type === "today") {
                this.todayDateFormControl.setValue(event?.$d);
            }
        }
    }

    getSelectedCalendars() {
        this.selectedCalendarFolder = "";
        this.checklistSelection.selected.forEach((checkList, index) => {
            if (this.selectedCalendarFolder) this.selectedCalendarFolder += `, ${checkList?.name}`;
            else this.selectedCalendarFolder = checkList?.name;
        });
    }

    setFlatFolderList(folders: any[]) {
        folders.forEach(v => {
          this.AllCalendarFolderFlatten.push(v);
          if (v.folder) {
            this.setFlatFolderList(v.folder);
          }
        });
    }
}
