
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="confirmation-dialog-comp" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon' }">
    <h1 mat-dialog-title>{{ data.titleKey | translate:data?.titleParam }}</h1>
    <div mat-dialog-content>
        <p class="action-{{ data.action }}" [innerHTML]="data.contentKey | translate: data?.contentParam"></p>
    </div>
    <div mat-dialog-actions *ngIf="!data?.isSpellCheck" style="gap: 10px;" [ngClass]="{ 'dfb-theme': currentTheme === 'dfb' }">
        <vnc-button class="no-hover" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{ (data.cancelKey || 'CANCEL') | translate }}" [fxShow.xs]="false" (click)="cancel()"></vnc-button>
        <vnc-button *ngIf="data.noKey" class="no-hover" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{ (data.noKey || 'NO_LABEL') | translate }}" [fxShow.xs]="false" (click)="no()"></vnc-button>
        <vnc-button [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="primary" (click)="ok()">
            <span>{{ data.actionKey | translate }}</span>
        </vnc-button>
    </div>
    <div mat-dialog-actions *ngIf="data?.isSpellCheck" [ngClass]="{ 'dfb-theme': currentTheme === 'dfb' }">
        <vnc-button class="no-hover" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{ (data.cancelKey || 'CANCEL') | translate }}" [fxShow.xs]="false" (click)="cancel()"></vnc-button>
        <vnc-button [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="primary" (click)="ok()">
            <span>{{ data.actionKey | translate }}</span>
        </vnc-button>
    </div>
</div>