
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, Inject, OnDestroy, NgZone, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MailBroadcaster } from "../../common/providers/mail-broadcaster.service";
import { MailConstants } from "../../common/utils/mail-constants";
import { ConfirmArgs } from "../models/confirm-args";
import { MailOperations } from "../../common/enums/mail-enum";
import { BroadcastKeys } from "../../common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/config.service";
import { ToastService } from "src/app/common/providers/toast.service";

@Component({
  selector: "vp-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html"
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {

  isDeleteFolder: boolean = false;
  isTrashFolder: boolean = false;
  mailOperations = MailOperations;
  manyRecipients:boolean = false;
  folder = {
    folderName: ""
  };
  subject = {
    subject: ""
  };
  private isAlive$ = new Subject<boolean>();
  currentUrl: string = "";
  currentTheme = localStorage.getItem("theme");
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private mailBroadcaster: MailBroadcaster,
    private configService: ConfigService,
    private toastService: ToastService,
    private ngZone: NgZone,
    private changeDetectionRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public confirmArgs: ConfirmArgs,
    private router: Router
  ) {
    this.folder.folderName = this.confirmArgs.folderName || "";
    if (this.folder.folderName.length > 10) {
      this.folder.folderName = this.folder.folderName.substr(0, 10) + "..";
    }
    setTimeout(() => {
      this.changeDetectionRef.detectChanges();
    }, 200);
    this.subject.subject = !!this.confirmArgs.subject ? this.confirmArgs.subject : "";
    this.currentUrl = this.router.routerState.snapshot.url;
    this.changeDetectionRef.markForCheck();
  }

  ngOnInit() {
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_CONFIRM_MAIN_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    if (this.confirmArgs?.manyRecipients) {
      this.manyRecipients = true;
    }
  }

  discardMail(): void {
    this.mailBroadcaster.broadcast(MailConstants.MAIL_DISCARD_BROADCAST);
    this.dialogRef.close({ discard: true });
  }

  cancel(): void {
    if (this.confirmArgs?.operationType === this.mailOperations?.SaveDraft) this.discardMail();
    else this.close();
  }

  saveMail(): void {
    const usedPercent = this.configService.getUsedQuotaPercent();
    if (usedPercent === 100) {
      this.toastService.show("MAIL_QUOTA_DRAFT");
      return;
    }
    this.mailBroadcaster.broadcast(MailConstants.MAIL_SAVE_BROADCAST);
    this.dialogRef.close({save: true});
  }

  plainTextAction(value: string): void {
    this.dialogRef.close({ value: value });
  }

  deleteEmptyFolder() {
    switch (this.confirmArgs.operationType) {
      case MailOperations.TrashFolder:
      case MailOperations.DeleteFolder:
      case MailOperations.EmptyFolder:
      case MailOperations.DeleteSavedSearch:
        this.dialogRef.close({ confirm: true });
        break;
    }
  }

  close(): void {
    this.dialogRef.close({ confirm: false });
  }

  deleteAttachment(): void {
    this.dialogRef.close({ confirm: true });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }
}
