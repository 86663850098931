
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, NgZone } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { takeUntil } from "rxjs";
import { Subject } from "rxjs";
import { PreferenceService } from "src/app/preference/shared/services/preference.service";
import { ConfigService } from "src/app/config.service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

@Component({
    selector: "vp-appearance-dialog",
    templateUrl: "./appearance-dialog.component.html"
})
export class AppearanceDialogComponent implements OnInit, OnDestroy {

    themes = [
        { title: "blue", value: "default", id: "general-theme-vncmail", selected: false, name: "Blue Ocean" },
        { title: "green", value: "dfb", id: "general-theme-dfb", selected: false, name: "Nature Green" },
        { title: "carbon", value: "carbon", id: "general-theme-carbon", selected: false, name: "Carbon" }
    ];
    isThemeChange: boolean = false;
    currentUserTheme: any;
    currentTheme: string
    isCordovaOrElectron: boolean;
    private isAlive$ = new Subject<boolean>();
    isMobileScreen: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<AppearanceDialogComponent>,
        private route: Router,
        private mailBroadcaster: MailBroadcaster,
        private broadcaster: MailBroadcaster,
        private ngZone: NgZone,
        private preferenceService: PreferenceService,
        private configService: ConfigService,
        private breakpointObserver: BreakpointObserver
    ) {
        const currentTheme = localStorage.getItem(MailConstants.THEME) || environment.theme;
        this.currentUserTheme = currentTheme;
        this.currentTheme = currentTheme;
        this.selectTheme(currentTheme);
        this.isCordovaOrElectron = environment.isCordova || environment.isElectron;
            this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
            this.breakpointObserver
              .observe(["(max-width: 767px)"])
              .pipe(takeUntil(this.isAlive$))
              .subscribe((state: BreakpointState) => {
                if (state.matches) {
                  this.isMobileScreen = true;
                } else {
                  this.isMobileScreen = false;
                }
              });
    }

    ngOnInit() {
        this.broadcaster.on<any>(BroadcastKeys.BACK_FROM_PREFERENCES_APPEARANCE)
        .pipe(takeUntil(this.isAlive$)).subscribe(val => {
          this.ngZone.run(() => {
            this.close();
          });
        });
    }

    ngOnDestroy() { }

    close(): void {
        this.dialogRef.close();
    }

    selectTheme(currentTheme: string): void {
        this.themes.map(item => {
            if (item.value === currentTheme) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        });
    }

    themeChange(theme: any): void {
        this.currentUserTheme = theme;
        const themeValue = theme.value;
        this.isThemeChange = true;
        this.selectTheme(themeValue);
    }

    updateTheme(): void {
        if (this.isCordovaOrElectron) {
            this.navigateToLastTab();
        }

        let zimbraTheme = "harmony";
        if (this.currentUserTheme.value === "dfb") {
            zimbraTheme = "tree";
        } else if (this.currentUserTheme?.value === "carbon") {
            zimbraTheme = "carbon";
        }
        localStorage.setItem(MailConstants.THEME, this.currentUserTheme.value);
        this.preferenceService.modifyPrefs([{key: "zimbraPrefSkin", value: zimbraTheme}]).subscribe(res => {
            this.configService.set("zimbraPrefSkin", zimbraTheme);
            this.close();
            localStorage.setItem(MailConstants.THEME, this.currentUserTheme.value);
            MailUtils.reloadApp();
        });
    }

    navigateToLastTab() {
        let lastVisitedTab = localStorage.getItem("lastVisitedTab");
        if (lastVisitedTab === "/contacts") {
            this.route.navigate([lastVisitedTab]);
        } else if (lastVisitedTab === "/briefcase") {
            this.route.navigate([lastVisitedTab]);
        } else if (lastVisitedTab === "/calendar") {
            this.route.navigate([lastVisitedTab]);
        } else {
            this.route.navigate(["/"]);
            this.mailBroadcaster.broadcast("CALL_FROM_USER_LIST");
            this.mailBroadcaster.broadcast(MailConstants.BROADCAST_MAIL_SELECTED_TAB);
        }
    }
}
