
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-legal-notice-dialog mail-dialog">
    <div class="mail__dialog-header mobile_header" layout="row center-center">
        <div class="back-header-icon show-in-mobile" (click)="close()">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Icon/product/arrow-back" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Icon-24px" transform="translate(4.000000, 4.000000)" fill="#fff">
                    <polygon id="Shape" points="16 7 3.83 7 9.42 1.41 8 0 0 8 8 16 9.41 14.59 3.83 9 16 9"></polygon>
                </g>
            </g>
          </svg>
        </div>
        <div class="hide-in-mobile">
          <button class="brand-color mat-button hide-in-mobile">
            <svg viewBox="0 0 24 24" style="height :24px;width:24px">
              <path fill="#ffffff" d="M12,3C10.73,3 9.6,3.8 9.18,5H3V7H4.95L2,14C1.53,16 3,17 5.5,17C8,17 9.56,16 9,14L6.05,7H9.17C9.5,7.85 10.15,8.5 11,8.83V20H2V22H22V20H13V8.82C13.85,8.5 14.5,7.85 14.82,7H17.95L15,14C14.53,16 16,17 18.5,17C21,17 22.56,16 22,14L19.05,7H21V5H14.83C14.4,3.8 13.27,3 12,3M12,5A1,1 0 0,1 13,6A1,1 0 0,1 12,7A1,1 0 0,1 11,6A1,1 0 0,1 12,5M5.5,10.25L7,14H4L5.5,10.25M18.5,10.25L20,14H17L18.5,10.25Z" />
            </svg>
          </button>
        </div>
        <div id="mobile_sidebar_about_legal_header" class="header_lbl">
            <span class="disable-select">{{ 'LEGAL_NOTICE' | translate }}</span>
        </div>
        <div class= "desktop-close hide-in-mobile" style="position: absolute;right: 16px;">
          <button class="brand-color mat-button" (click) = "close()">
              <mat-icon class="disable-select">close</mat-icon>
          </button>
      </div>
    </div>
  <div class="mail__dialog-body">
      <mat-list *ngIf="language === 'en'">
        <mat-list-item id="mobile_sidebar_about_legal_terms">
          <div class="icon-style-mobile-sidebar">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Icon/product/file-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M13.5857864,2 C13.8510029,2 14.1053568,2.10535684 14.2928932,2.29289322 L19.7071068,7.70710678 C19.8946432,7.89464316 20,8.14899707 20,8.41421356 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.8954305,22 4,21.1045695 4,20 L4,4 C4,2.8954305 4.8954305,2 6,2 L13.5857864,2 Z M10.999,3.999 L6,4 L6,20 L18,20 L18,10 L14,10 C12.4023191,10 11.0963391,8.75108004 11.0050927,7.17627279 L11,7 L10.999,3.999 Z M17.172,8 L13.171,4 L12.999,3.999 L13,7 C13,7.51283584 13.3860402,7.93550716 13.8833789,7.99327227 L14,8 L17.172,8 Z" id="Combined-Shape" fill="#000"></path>
              </g>
            </svg>
          </div>
          <a target="_blank" class="open-new-window disable-select non-decoration" href="{{termsOfUse_en}}">
            {{ 'TERMS_OF_USE' | translate }}
            <div class="icon-redirect">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/new-tab-redirect-new</title>
                <g id="Icon/product/new-tab-redirect-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M12,3 L12,5 L5,5 L5,19 L19,19 L19,12 L21,12 L21,19 C21,20.1 20.1,21 19,21 L19,21 L5,21 C3.89,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.89,3 5,3 L5,3 L12,3 Z M21,3 L21,10 L19,10 L19,6.41 L9.17,16.24 L7.76,14.83 L17.59,5 L14,5 L14,3 L21,3 Z" id="Shape" fill="#8B96A0"></path>
                </g>
              </svg>
            </div>
          </a>
        </mat-list-item>
        <mat-list-item id="mobile_sidebar_about_legal_data_privacy">
          <div class="icon-style-mobile-sidebar">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/lock-outline</title>
              <g id="Icon/product/lock-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#000">
                      <path d="M8,16 C9.1,16 10,15.1 10,14 C10,12.9 9.1,12 8,12 C6.9,12 6,12.9 6,14 C6,15.1 6.9,16 8,16 L8,16 Z M14,7 L13,7 L13,5 C13,2.24 10.76,0 8,0 C5.24,0 3,2.24 3,5 L3,7 L2,7 C0.9,7 0,7.9 0,9 L0,19 C0,20.1 0.9,21 2,21 L14,21 C15.1,21 16,20.1 16,19 L16,9 C16,7.9 15.1,7 14,7 L14,7 Z M4.9,5 C4.9,3.29 6.29,1.9 8,1.9 C9.71,1.9 11.1,3.29 11.1,5 L11.1,7 L4.9,7 L4.9,5 L4.9,5 Z M14,19 L2,19 L2,9 L14,9 L14,19 L14,19 Z" id="Shape"></path>
                  </g>
              </g>
            </svg>
          </div>
          <a target="_blank" class="open-new-window disable-select non-decoration" href="{{dataPrivacy_en}}">
            {{ 'DATA_PRIVACY' | translate }}
            <div class="icon-redirect">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/new-tab-redirect-new</title>
                <g id="Icon/product/new-tab-redirect-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M12,3 L12,5 L5,5 L5,19 L19,19 L19,12 L21,12 L21,19 C21,20.1 20.1,21 19,21 L19,21 L5,21 C3.89,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.89,3 5,3 L5,3 L12,3 Z M21,3 L21,10 L19,10 L19,6.41 L9.17,16.24 L7.76,14.83 L17.59,5 L14,5 L14,3 L21,3 Z" id="Shape" fill="#8B96A0"></path>
                </g>
              </svg>
            </div>
          </a>
        </mat-list-item>
        <mat-list-item id="mobile_sidebar_about_imprint" *ngIf="!hideImprintMenuItem">
          <div class="icon-style-mobile-sidebar">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Icon/product/file-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M13.5857864,2 C13.8510029,2 14.1053568,2.10535684 14.2928932,2.29289322 L19.7071068,7.70710678 C19.8946432,7.89464316 20,8.14899707 20,8.41421356 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.8954305,22 4,21.1045695 4,20 L4,4 C4,2.8954305 4.8954305,2 6,2 L13.5857864,2 Z M10.999,3.999 L6,4 L6,20 L18,20 L18,10 L14,10 C12.4023191,10 11.0963391,8.75108004 11.0050927,7.17627279 L11,7 L10.999,3.999 Z M17.172,8 L13.171,4 L12.999,3.999 L13,7 C13,7.51283584 13.3860402,7.93550716 13.8833789,7.99327227 L14,8 L17.172,8 Z" id="Combined-Shape" fill="#000"></path>
              </g>
            </svg>
          </div>
          <a target="_blank" class="open-new-window disable-select non-decoration" href="{{imprint_en}}">
            {{ 'IMPRINT' | translate }}
            <div class="icon-redirect">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/new-tab-redirect-new</title>
                <g id="Icon/product/new-tab-redirect-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M12,3 L12,5 L5,5 L5,19 L19,19 L19,12 L21,12 L21,19 C21,20.1 20.1,21 19,21 L19,21 L5,21 C3.89,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.89,3 5,3 L5,3 L12,3 Z M21,3 L21,10 L19,10 L19,6.41 L9.17,16.24 L7.76,14.83 L17.59,5 L14,5 L14,3 L21,3 Z" id="Shape" fill="#8B96A0"></path>
                </g>
              </svg>
            </div>
          </a>
        </mat-list-item>
      </mat-list>
      <mat-list *ngIf="language === 'de'">
          <mat-list-item id="mobile_sidebar_about_legal_terms">
              <mat-icon class="disable-select">assignment</mat-icon>
              <a target="_blank" class="open-new-window disable-select non-decoration" href="{{termsOfUse_de}}">{{ 'TERMS_OF_USE' | translate }}</a>
          </mat-list-item>
          <mat-list-item id="mobile_sidebar_about_legal_data_privacy">
              <mat-icon class="disable-select">lock</mat-icon>
              <a target="_blank" class="open-new-window disable-select non-decoration" href="{{dataPrivacy_de}}">{{ 'DATA_PRIVACY' | translate }}</a>
          </mat-list-item>
          <mat-list-item id="mobile_sidebar_about_imprint" *ngIf="!hideImprintMenuItem">
              <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-home-circle"></mat-icon>
              <a target="_blank" class="open-new-window disable-select non-decoration" href="{{imprint_de}}">{{ 'IMPRINT' | translate }}</a>
          </mat-list-item>
        </mat-list>
    </div>
</div>
