
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy, OnChanges, TemplateRef } from "@angular/core";
import { ConfigService } from "src/app/config.service";
import { Store, select } from "@ngrx/store";
import { Subject} from "rxjs";
import { distinctUntilChanged, filter, take, takeUntil } from "rxjs/operators";
import { getOnlineStatus, getLastPhotoUpdateByEmail, getUserProfile } from "src/app/reducers";
import { SetLastPhotoUpdate } from "src/app/actions/app";
import { AppState } from "src/app/reducers/app";
import { environment } from "src/environments/environment";
import { ElectronService } from "src/app/services/electron.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { DatabaseService } from "src/app/services/db/database.service";

@Component({
  selector: "vp-avatar",
  templateUrl: "./user-avatar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type;
  @Input() user;
  @Input() message;
  @Input() isAddressSelect;
  @Input() rightIconDefaultAvatarTemplate!: TemplateRef<any>;
  @Input() messageID: string;
  @Input() refreshAvatar: boolean = false;
  pendingOperation: boolean = false;
  avatarURL: string;
  photoLastUpdate: any;
  photoLastUpdateSubscription$: any;
  email: string;
  configAvatarURL: any;
  loadedFromURL: boolean;
  isOnline: boolean = false;
  isLoginUser: boolean = false;
  private isAlive$ = new Subject<boolean>();
  constructor(
    private config: ConfigService,
    private changeDetectionRef: ChangeDetectorRef,
    private store: Store<AppState>,
    private databaseService: DatabaseService,
    private electronService: ElectronService) {
        // console.log("avatar constructor: ", this.email, this.user, this.type);
    this.store.pipe(select(getOnlineStatus)).subscribe(v => {
      this.isOnline = v;
      this.changeDetectionRef.markForCheck();
    });
  }
  getColor() {
    let color = "#888888";
    if (this.user) {
      if (!this.avatarURL && this.user.color) {
        color = CommonUtils.getAvatarBackground(this.user?.firstLastCharacters.toUpperCase());
      }
    }
    return color;
  }

  ngOnInit(): void {
    if (this.user && this.user.email) {
      this.avatarURL = this.databaseService.avatar[this.user.email];
      this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
        if (!!res) {
          if (this.user?.email === res?.email) {
            this.isLoginUser = true;
          }
        }
      });
      // console.log("userAvatarInit: ", this.databaseService.avatar[this.user.email], this.databaseService.avatarLastUpdated[this.user.email]);
      this.store.pipe(select(getOnlineStatus), take(1)).subscribe(v => {
        this.isOnline = v;
      });

      if (!this.avatarURL) {
        this.buildAvatarURL();
      }
      const nts = Date.now();
      if (this.isOnline) {
        if (!!this.databaseService.avatarLastUpdated[this.user.email] && ((this.databaseService.avatarLastUpdated[this.user.email] - nts) > 86400000)) {
          this.buildAvatarURL();
        }
        if (!this.databaseService.avatarLastUpdated[this.user.email]) {
          this.buildAvatarURL();
        }
      }
      this.changeDetectionRef.markForCheck();
    }
    this.checkPendingOperation();
  }

  private loadAvatarFromDb() {
    this.databaseService.getAvatarByEmail(this.user.email).subscribe(v => {
      if (!!v && !this.loadedFromURL) {
        this.avatarURL = v?.data;
      } else if (!v && !this.loadedFromURL) {
        console.log("[loadAvatarFromDb] clear avatar 1", this.user?.email);
        this.avatarURL = null;
      }
      this.changeDetectionRef.markForCheck();
    }, () => {
      if (!this.loadedFromURL) {
        console.log("[loadAvatarFromDb] clear avatar 2", this.user?.email);
        this.avatarURL = null;
        this.changeDetectionRef.markForCheck();
      }
    });
  }

  ngOnChanges(data) {

    if (this.user && this.user.email) {
      this.email = this.user.email;
      this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
        if (!!res) {
          if (this.user?.email === res?.email) {
            this.isLoginUser = true;
          }
        }
      });
      if (!!this.databaseService.avatar[this.user.email] && (this.databaseService.storedAvatars.indexOf(this.user.email) > -1) && !this.loadedFromURL) {
        this.avatarURL = this.databaseService.avatar[this.user.email];
        this.changeDetectionRef.markForCheck();

      }
      this.checkPendingOperation();
      if (!this.isOnline) {
        if (!!this.avatarURL && this.avatarURL.startsWith("https://")) {
          console.log("[ngOnChanges] clear avatar2 ", this.user?.email, this.avatarURL);
          this.avatarURL = null;
          this.changeDetectionRef.markForCheck();
        }
        return;
      }
      if (this.databaseService.storedAvatars.indexOf(this.user.email) === -1) {
        this.avatarURL = null;
        this.changeDetectionRef.markForCheck();
      }
      if (this.photoLastUpdateSubscription$) {
        this.photoLastUpdateSubscription$.unsubscribe();
      }
      this.photoLastUpdateSubscription$ = this.store.select(state => getLastPhotoUpdateByEmail(state, this.user.email))
      .subscribe(photoLastUpdate => {

        if (!!photoLastUpdate) {
          this.photoLastUpdate = photoLastUpdate;
        } else {
          this.photoLastUpdate = new Date().getTime();
          this.store.dispatch(new SetLastPhotoUpdate({email: this.user.email, timestamp: this.photoLastUpdate}));
        }
        if (this.isOnline) {
          this.buildAvatarURL();
        }
      });
    } else {
      console.log("[ngOnChanges] clear avatar", this.user?.email);
      // this.avatarURL = null;
      // this.changeDetectionRef.markForCheck();
    }
  }

  checkPendingOperation() {
    this.databaseService.getAllPendingOperations().subscribe(ops => {
      if (ops.length > 0) {
        const filerOps = ops.find(op => op.objectId === this.messageID);
        if (filerOps && !this.isOnline && (filerOps.op === "!spam" || filerOps.op === "spam" || filerOps.op === "sendEmail" || filerOps.op === "trash" || filerOps.op === "read" || filerOps.op === "!read" || filerOps.op === "move")) {
          this.pendingOperation = true;
        } else {
          this.pendingOperation = false;
        }
        this.changeDetectionRef.markForCheck();
      } else this.pendingOperation = false;
      this.changeDetectionRef.markForCheck();
    });
  }

  ngOnDestroy() {
    if (this.photoLastUpdateSubscription$) {
      this.photoLastUpdateSubscription$.unsubscribe();
    }
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  imgLoadOnError(event) {
    console.log("[imgLoadOnError] clear avatar", this.user?.email);
    this.avatarURL = null;
    this.changeDetectionRef.markForCheck();
  }

  private avatarVersion() {
    let version;
    const currentTimestamp = new Date().getTime();
    // // load new ver of avatar
    if ((!this.photoLastUpdate
        || (this.photoLastUpdate > 0 && currentTimestamp - this.photoLastUpdate >= this.config.AVATAR_SYNC_INTERVAL)
        || (this.photoLastUpdate < 0 && currentTimestamp - Math.abs(this.photoLastUpdate) >= this.config.AVATAR_SYNC_INTERVAL)
      )
    ) {
      // set new version
      version = "?ver=" + currentTimestamp;
    // use old ver
    } else {
      if (this.photoLastUpdate && this.photoLastUpdate > 0) {
        version = "?ver=" + this.photoLastUpdate;
      } else {
        version = null; // no version available yet, so show a default image
      }
//      console.log("[AvatarComponent][avatarVersion] old", this.email, version);
    }
    return version;
  }

  private buildAvatarURL() {
    if (!!this.isAddressSelect) {
      return;
    }
    let isOnline = false;
    this.store.pipe(select(getOnlineStatus), take(1)).subscribe(v => {
      isOnline = v;
    });
    if (isOnline) {
      const avatarVersion = this.avatarVersion();
      const email = this.user.email;
      let avatarId = "";
      if (environment.isElectron) {
        avatarId = this.electronService.md5(email);
      } else {
        avatarId = md5(email);
      }
      if (this.configAvatarURL !== this.config.avatarURL) {
        this.configAvatarURL = this.config.avatarURL;
      }
      if (this.configAvatarURL) {
        const avatarURL = `${this.configAvatarURL}/${avatarId}.jpg${avatarVersion}`;
        this.changeDetectionRef.markForCheck();
        this.loadedFromURL = false;
        CommonUtils.getBase6ImageFromUrl(avatarURL).subscribe((url: string) => {
          this.avatarURL = url;
          this.loadedFromURL = true;
          this.databaseService.storeAvatar(url, email);
          this.changeDetectionRef.markForCheck();
        }, () => {
          this.store.pipe(select(getOnlineStatus), distinctUntilChanged(), take(1)).subscribe(v => {
            if (!!v) { // to make sure it is not fail by network
              // console.log("gravatar: ", email, avatarId);
              const gravatarURL = `https://www.gravatar.com/avatar/${avatarId}.jpg?d=404`;
              CommonUtils.getBase6ImageFromUrl(gravatarURL).subscribe((url: string) => {
                this.avatarURL = url;
                this.loadedFromURL = true;
                this.databaseService.storeAvatar(url, email);
                this.changeDetectionRef.markForCheck();
              }, () => {
                this.avatarURL = null;
                this.changeDetectionRef.markForCheck();
              });
            }
          });
        });
      }
    }
  }
}
