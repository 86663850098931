
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mdl-dialog__header" [ngClass]="{'desktop-tfa-popup': !isMobileScreen}">
    <div *ngIf="!isMobileScreen" class="header-text">{{  'AUTHENTICATOR_APP' | translate }}</div>
    <div *ngIf="!isMobileScreen" class="header-icon" (click)="onEsc()" matTooltip="{{ 'CLOSE' | translate }}">
        <ng-container [ngTemplateOutlet]="closeIcon"></ng-container>
    </div>
    <button *ngIf="isMobileScreen" id="closeGenSettingsMenu" class="hide-on-desktop" mdl-button mdl-button-type="icon" (click)="onEsc()">
      <mdl-icon>keyboard_arrow_left</mdl-icon>
    </button>
    <h3 *ngIf="isMobileScreen" class="mdl-dialog__title header-title">
      {{ 'AUTHENTICATOR_APP'| translate }}
    </h3>
    <button *ngIf="isMobileScreen" class="show-on-desktop close-dialog-btn" mdl-button-type="icon" mdl-button (click)="onEsc()">
      <mdl-icon>close</mdl-icon>
    </button>
  </div>

  <div class="mdl-dialog__content">
    <form [formGroup]="updateForm" (submit)="$event.preventDefault();updateForm.valid && submitForm()">
        <div class="tfa__body__div">
          <div>{{ 'AUTHENTICATOR_APP_TITLE'| translate }}</div>
          <div class="content-div">
              <div><span>1.</span><span class="content-span">{{ 'AUTHENTICATOR_APP_SUB_TITLE1'| translate }}</span></div>
              <div><span>2.</span><span class="content-span">{{ 'AUTHENTICATOR_APP_SUB_TITLE2'| translate }}</span></div>
              <div><img [src]=qrCode/></div>
              <div class="barcode_text margin-left-20 margin-bottom-15" (click)="isManualEnabled ? isManualEnabled=false : isManualEnabled=true">{{ 'CANT_BARCODE_TEXT' | translate }}</div>
	            <div *ngIf="isManualEnabled" class="margin-left-20 margin-top-15">{{ 'QR_CODE_MANUAL_TEXT' | translate }}</div>
              <div *ngIf="isManualEnabled" class="margin-left-20 margin-top-15"><span>{{ 'ACCOUNT_NAME' | translate }}:</span><span class="detail_value">{{ email }}</span></div>
              <div *ngIf="isManualEnabled" class="margin-left-20"><span>{{ 'SECRET_KEY' | translate }}:</span><span class="detail_value">{{ secret }}</span></div>
              <div *ngIf="isManualEnabled" class="margin-left-20 margin-bottom-15"><span>{{ 'TYPE' | translate }}:</span><span class="detail_value">{{ 'OTP_TYPE_VALUE' | translate }}</span></div>
              <div><span>3.</span><span class="content-span">{{ 'AUTHENTICATOR_APP_SUB_TITLE3'| translate }}</span></div>
              <div><input type="text" maxlength="6" required
                placeholder="{{ 'ENTER_OTP'|translate }} (*)" name="otpCode" [formControl]="otpCode"
                id="otpCode"></div>
          </div>
        </div>
    </form>
  </div>
  <vnc-footer *ngIf="!isMobileScreen" [secondaryLabel]="'CANCEL' | translate | uppercase"
  [primaryLabel]="'ADD' | translate | uppercase"
  [ngClass]="{ 'dfb-theme': currentTheme === 'dfb' }"
  [isPrimaryDisabled]="!(updateForm.valid && updateForm.dirty)"
  [primaryLabel]="'CALENDARS.SAVE_BTN_LBL' | translate | uppercase"
  [type]="'secondary-primary'"
  [color]="'white'"
  (onSecondaryClick)="onEsc()"
  (onPrimaryClick)="$event.preventDefault();updateForm.valid && submitForm()"></vnc-footer>

  <div class="form-footer" *ngIf="updateForm.valid && updateForm.dirty && isMobileScreen">
    <span>{{ 'SAVE_CHANGES'|translate }}</span>
  <button class="save-profile-btn" mdl-button mdl-button-type="mini-fab" mdl-colored="primary" mdl-ripple
          type="submit" [disabled]="!updateForm.valid" (click)="$event.preventDefault();updateForm.valid && submitForm()">
    <mdl-icon>check</mdl-icon>
  </button>
</div>


<ng-template #closeIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>