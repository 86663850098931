
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, Inject, ViewChild, Input, HostListener, ElementRef, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Appointment } from "src/app/common/models/appoinment.model";
import * as moment from "moment";
import { Store } from "@ngrx/store";
import { take, filter, takeUntil } from "rxjs/operators";
import { CalendarFolder, KeyValue, CalendarConfirmData, CalendarAppointment } from "src/app/common/models/calendar.model";
import { CalendarRepository } from "../repositories/calendar.repository";
import { getCalendarFolders, getOnlineStatus } from "src/app/reducers";
import { Observable, Subject, Subscription } from "rxjs";
import { UserProfile } from "src/app/shared/models";
import { CommonUtils } from "src/app/common/utils/common-util";
import { isArray } from "util";
import { ToastService } from "src/app/common/providers/toast.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { CalendarConfirmTemplate, CalendarConstants } from "src/app/common/utils/calendar-constants";
import { CommonService } from "src/app/services/ common.service.";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { CalenderUtils } from "../utils/calender-utils";
import { TranslateService } from "@ngx-translate/core";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { MoveCalendarDialogComponent } from "src/app/shared/components/move-calendar-dialog/move-calendar-dialog.component";
import { EditAppointmentDialogComponent } from "../edit-appointment/edit-appointment-dialog.component";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { ElectronService } from "src/app/services/electron.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import jstimezonedetect from "jstimezonedetect";
import { ProfileDetailDialogComponent } from "src/app/shared/components/profile-detail-dialog/profile-detail-dialog.component";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { CreateTagComponent } from "src/app/mail/create-tag/create-tag.component";
import { CalendarEvent, CalendarView } from "../vp-calendar/vp-calendar.module";
import { DatabaseService } from "src/app/services/db/database.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { ParticipantsDialogComponent } from "../participants-dialog/participants-dialog.component";
import { ConfirmSendInviteDialogComponent } from "src/app/shared/components/confirm-delete-send-invite-dialog/confirm-delete-send-invite-dialog.component";
import { DeleteAppointmentNotifyDialogComponent } from "src/app/shared/components/delete-appointment-notify-user/delete-appointment-notify-user.component";
import { DeleteSeriesConfirmDialogComponent } from "src/app/shared/components/delete-series-confirm-dialog/delete-series-confirm-dialog.component";
import { ConversationRepository } from "src/app/mail/repositories/conversation.repository";
import { SharedCalendarService } from "../service/shared-calendar-service";
import { MailTag } from "src/app/mail/models/mail-tag.model";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import * as _ from "lodash";

@Component({
    selector: "vp-appointment-preview-common-dialog",
    templateUrl: "./appointment-preview-common-dialog.component.html",
    styleUrls: ["./appointment-preview-common-dialog.component.scss"],
})
export class AppointmentPreviewCommonDialogComponent implements OnInit, OnDestroy {
    constructor(
        private dialogRef: MatDialogRef<AppointmentPreviewCommonDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private store: Store<CalendarState>,
        private calendarRepository: CalendarRepository,
        private changeDetectorRef: ChangeDetectorRef,
        private commonRepository: CommonRepository,
        private commonService: CommonService,
        private toastService: ToastService,
        private matDialog: MatDialog,
        private translateService: TranslateService,
        private broadcaster: MailBroadcaster,
        private electronService: ElectronService,
        private router: Router,
        private databaseService: DatabaseService,
        private breakpointObserver: BreakpointObserver,
        private convRepository: ConversationRepository,
        public sharedCalendarService: SharedCalendarService,
        public mailService: MailService,
    ) {

      this.store.select(getCalendarFolders).pipe(takeUntil(this.isAlive$)).subscribe(res => {
        this.calendarFolderList = res;
      });
        this.store.select(getOnlineStatus).subscribe(res => {
            this.isOnline = res;
        });

        this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");

        this.store.select(getCalendarFolders).pipe(take(1)).subscribe(res => {
            if (!!res && res !== null) {
                this.calendarFolders = res;
                this.calendarFolders = this.calendarFolders.filter(f => f.id !== "3");
            }
        });
        this.alaramList = this.calendarRepository.reminderOptions();
        this.appointment = this.data.appointment;
        if (this.data.isDeleted) {
          this.isDeleted = this.data.isDeleted;
        }
        console.log("PREVIEWAPP: ", this.data, JSON.stringify(this.data.event));
        if (this.calendarFolderList.length) {
          // console.log("chandraCheck cF: ", this.calendarFolderList);
          this.calendarFolderList.map(cF => {
            if (cF && cF.zid) {
              // console.log("chandraCheck: ", this.data, cF.zid);
              // console.log("chandraCheck type: ", typeof this.data?.event?.id);
              if ((typeof this.data?.event?.id === "string") && (this.data?.event?.id?.indexOf(cF.zid) !== -1)) {
                if (this.data.event.class === "PRI") {
                  this.isSharedCalendarPrivateEvent = true;
                }
              }
            }
          });
        }

        if (this.data.event.folderId !== "3" && this.data.event.otherAtt && !this.data.event.neverSent && this.data.event.isOrganizer) {
          this.hideCancel = false;
        }
        // console.log("[PreviewAppointment] : ", this.appointment.at);
        console.log("[PreviewAppointment] : ", this.appointment);
        this.setAttendeesName();
        this.readOnly = this.sharedCalendarService.isReadOnlyFolder(this.appointment.ciFolder);
        this.isInOwnFolder = this.appointment.ciFolder.indexOf(":") === -1;

        let clientTimeZoneId = jstimezonedetect.determine().name();
        clientTimeZoneId = clientTimeZoneId?.replace("Asia/Calcutta", "Asia/Kolkata");
        if (this.readOnly) {
          this.hideDelete = true;
        }
        if (this.readOnly && this.data.event.class === "PRI") {
          this.hideDelete = true;
        }

        if ( this.data.enableDelete ) {
          this.hideDelete = false;
          this.hideCancel = true;
        }

        if (this.data.type) {
          this.eventType = this.data.type;
        }

        if (this.data.ridZ) {
            const startDate = this.appointment?.startDateData[0]?.d ? moment(this.appointment?.startDateData[0]?.d) : moment();
            const endDate = this.appointment?.endDateData[0]?.d ? moment(this.appointment?.endDateData[0]?.d) : moment();
            const diffDay = endDate.diff(startDate, "days");
            const diffHours = endDate.diff(startDate, "hours");
            const newStartDate = moment(this.data.ridZ).toDate();
            const newEndDate = moment(moment(this.data.ridZ).toDate()).add(diffDay, "days").add(diffHours, "hours").toDate();
            this.startDate = moment(newStartDate).toDate();
            this.endDate = moment(newEndDate).toDate();
        } else if (this.appointment.startDateData && this.appointment.startDateData[0]) {
            if (this.appointment.startDateData[0].tz) {
                const inviteTimeZone = this.appointment.startDateData[0].tz;
                if (clientTimeZoneId === inviteTimeZone) {
                    const startDT = this.appointment.startDateData[0].d;
                    this.startDate = moment(startDT).toDate();
                    const endDT = this.appointment.endDateData[0].d;
                    this.endDate = moment(endDT).toDate();
                } else {
                    const startDT = this.appointment.startDateData[0].d;
                    this.startDate = MailUtils.convertDateToTimezone(moment(startDT).toDate(), inviteTimeZone, clientTimeZoneId);
                    const endDT = this.appointment.endDateData[0].d;
                    this.endDate = MailUtils.convertDateToTimezone(moment(endDT).toDate(), inviteTimeZone, clientTimeZoneId);
                }
            } else {
                const startDT = this.appointment.startDateData[0].d;
                this.startDate = moment(startDT).toDate();
                const endDT = this.appointment.endDateData[0].d;
                this.endDate = moment(endDT).toDate();
            }
        }

        if (this.startDate && this.dateCompare(this.startDate, this.endDate)) {
          this.multipleDayAppointment = true;
        }

        if (data?.event?.start && this.dateCompare(this.data?.event?.start, this.data?.event?.end)) {
          this.multipleDayAppointment = true;
        }

        if (!this.appointment?.recur) {
            this.recur = "NON";
        } else {
            this.isInSeries = true;
            console.log("this.appointment", this.appointment);
            if (this.appointment && this.appointment.recur && this.appointment.recur[0] &&
              this.appointment.recur[0]?.add &&
              this.appointment.recur[0]?.add[0] && this.appointment.recur[0]?.add[0].rule &&
              this.appointment.recur[0]?.add[0].rule[0] && this.appointment.recur[0].add[0].rule[0].freq
             ) {
               this.recur = this.appointment.recur[0]?.add[0]?.rule[0]?.freq;
             }
        }
        this.organizeByMe = this.appointment.isOrg || this.data.event.isOrganizer;
        this.sharedCalendarService.setAlarmToCompose(this.appointment);
        if (this.appointment.reply && this.appointment.reply[0]) {
            this.replyType = this.appointment.reply[0].ptst;
        }
        let body: string = "";
        if (this.appointment?.descHTML) {
            body = this.appointment.descHTML;
        } else if (this.appointment.desc) {
            body = CalenderUtils.plainTextToHTML(this.appointment.desc);
        }
        if (body.indexOf("~*~*~*~*~*~*~*~*") > -1 || body.indexOf("~ ~ ~ ~ ~ ~ ~ ~ ~") > -1) {
            if (body.indexOf("*~*~*~*~*~*~*~*~*~*") !== -1) {
                if (body.indexOf("The following meeting has been forwarded:") !== -1 || body.indexOf("Betreff: WG:") !== -1) {
                    const bodyItem = body.replace("</body></html>", "").split("*~*~*~*~*~*~*~*~*~*");
                    if (!!bodyItem && !!bodyItem[1]) {
                        body = bodyItem[1];
                    }
                    if (!!bodyItem && !!bodyItem[2]) {
                        body += bodyItem[2];
                    }
                } else {
                    body = body.replace("</body></html>", "").split("<div>*~*~*~*~*~*~*~*~*~*</div><br>")[1] ||
                    body.split("*~*~*~*~*~*~*~*~*~*")[1] || "";
                }
            }
            if (body.indexOf("~ ~ ~ ~ ~ ~ ~ ~ ~") !== -1) {
                body = body.replace("</body></html>", "").split("~ ~ ~ ~ ~ ~ ~ ~ ~")[1] || "";
            }
        }
        this.description = MailUtils.linkifyHtml(body);
    }

    @ViewChild("menuElement") menuElement: ElementRef<HTMLDivElement>;
    @ViewChild("parentElement") parentElement: ElementRef<HTMLDivElement>;
    currentTheme = localStorage.getItem("theme");
    CalendarConstants = CalendarConstants;
    multipleDayAppointment: boolean = false;
    appointment: Appointment;
    startDate: Date;
    endDate: Date;
    calendarFolders: CalendarFolder[] = [];
    allZimbraTags: MailTag[] = [];
    alarmReminder: string = "0";
    alarmReminderOption: any;
    alaramList: KeyValue[] = [];
    organizeByMe: boolean = true;
    private isAlive$ = new Subject<boolean>();
    currentUser: UserProfile;
    recur: string;
    replyType: string = "";
    readOnly: boolean = false;
    isInOwnFolder: boolean = false;
    isInSeries: boolean = false;
    locale: string = "en";
    description: string = "";
    attachedFileList: any[] = [];
    isLoading: boolean;
    organizerAvatar: string = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXUu3vw3S8WLFiJWU44aGj5MtyX3uno_ZHOKq3zfFjSg&s";
    showAttendeesNames: string = "";
    @Input() dataToComponent: any;
    zoomFull: boolean = false;
    isOnline: boolean = false;
    isMobileScreen: boolean = false;
    calendarFolderList: any = [];
    isSharedCalendarPrivateEvent: boolean = false;
    hideCancel: boolean = true;
    hideDelete: boolean = false;
    eventType: string = "default";
    isDeleted: boolean = false;
    unknownTagNamesToCreate = [];

    @ViewChild("groupParticipantList") groupParticipantList: MatMenuTrigger;

    ngOnInit() {
        const language =  this.electronService.isElectron
        ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE) : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
        if (!!language && language !== null) {
            this.locale = language;
        } else {
            this.locale = "en";
        }
        if (this.appointment.mp) {
            this.attachedFileList = MailUtils.getAttachments(this.appointment.mp);
        }
        this.broadcaster.on<any>("CLOSE_APPOINTMENT_PREVIEW").subscribe(() => {
            this.close();
        });
        this.commonRepository.getZimbraTagsList().pipe(takeUntil(this.isAlive$)).subscribe(allTags => {
          this.allZimbraTags = allTags;
        });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    cancel(): void {
        this.close();
    }

    close(): void {
        this.dialogRef.close();
    }

    edit(): void {
        this.dialogRef.close({ value: "edit" });
    }

    createUnknownTag(name) {
      if (name !== "") {
        this.commonRepository.createTag(name, MailConstants.DEFAULT_TAG_COLOR).subscribe(tag => {
          this.commonRepository.getZimbraTagsList().pipe(takeUntil(this.isAlive$)).subscribe(allTags => {
            this.allZimbraTags = allTags;
          });
        });
      }
    }

    getTags(tagNames: string): MailTag[] {
      const tNames = tagNames.split(",");
      tNames.forEach(tn => {
        const zTag = this.allZimbraTags.filter(t => t.name.toLowerCase() === tn.toLowerCase());
        if (zTag.length === 0) {
          if (this.unknownTagNamesToCreate.indexOf(tn) < 0) {
            this.unknownTagNamesToCreate.push(tn);
            this.createUnknownTag(tn);
          }
        }
      });
      let tags: MailTag[] = tagNames.split(",").map(tagName => {
        let tag: MailTag;
        const t = this.allZimbraTags.filter(tt => tt.name.toLowerCase() === tagName.toLowerCase())[0];
        if (!!t) {
          if (t.color && MailConstants.COLOR_LIST[t.color]) {
            t.color = MailConstants.COLOR_LIST[t.color];
          }
          if (t.rgb) {
            t.rgb = t.rgb.toLowerCase();
            if (t.rgb === "#999") {
              t.rgb = "#999999";
            }
          }
          tag = t;
        }
        return tag;
      }).filter(v => !!v);
      tags = _.sortBy(tags, t => t.name.toLowerCase());
      return tags;
    }

    getTagColor(color) {
      const isDark = (!!color) ? MailUtils.lightOrDark(color) === "dark" : true;
      if (isDark) {
        return "#ffffff";
      } else {
        return "#000000";
      }
    }

    removeTag(appointment, tag) {
      console.log("removeTagFromAppo: ", appointment, tag);
      let body;
      body = { id: appointment.apptId, op: "!tag", tn: tag.name };
      this.commonService.itemAction(body).pipe(take(1)).subscribe((r) => {
        // console.log("removeTagFromAppo done: ", r, this.data.appoinment, this.data.event);
        const evbody = {
          id: this.appointment.id
        };
        this.commonService.getMsgRequest(evbody).pipe(take(1)).subscribe(resp => {
          const appt = this.calendarRepository.mapAppointmentFromMsg(resp.m[0]);
          this.appointment = appt;
          // console.log("removeTagFromAppo done refresh: ", this.data.appoinment, this.data.event);
          this.broadcaster.broadcast("REFRESH_CAL_KEEP_DATE");
          this.changeDetectorRef.markForCheck();
        });
      });
    }

    sendReplyRequest(verb: string) {
        const body: any = {
            id: this.appointment.id,
            verb: verb,
            updateOrganizer: "TRUE",
        };
        if (this.data?.isInstance) {
          const oldDateTime = moment(this.appointment.startDateData[0].d).format("HHmmss");
          const selectedDate = moment(this.data?.event?.inst[0].ridZ).format("YYYYMMDD");
          const dateTime = selectedDate + "T" + oldDateTime;
          body["exceptId"] = {
            d: dateTime,
            tz: this.appointment.startDateData[0].tz
          };
        }
        if (this.isOnline) {
            this.commonService.sendInviteReply(body).pipe(take(1)).subscribe(res => {
              this.calendarRepository.getAllAppointments();
                if (verb === "ACCEPT") {
                    this.toastService.show("CALENDARS.ACCEPT_APPOINTMENT_MSG");
                } else if (verb === "DECLINE") {
                    this.toastService.show("CALENDARS.DECLINE_APPOINTMENT_MSG");
                } else if (verb === "TENTATIVE") {
                    this.toastService.show("CALENDARS.TENTETIVE_APPOINTMENT_MSG");
                }
                this.close();
            }, error => {
                this.toastService.showPlainMessage(error);
            });
        } else {
              let requestBody = { ...body };
              if (this.commonService.isFakeId(requestBody.id)) {
                delete requestBody.id;
              }
              if (this.commonService.isFakeId(requestBody.did)) {
                delete requestBody.did;
              }
              const request = {
                "url": "/api/sendInviteReply",
                "method": "post",
                "body":  requestBody
              };

              this.databaseService.addPendingOperation(body.id, "sendInviteReply", request).subscribe(res => {
                if (verb === "ACCEPT") {
                    this.toastService.show("CALENDARS.ACCEPT_APPOINTMENT_MSG");
                } else if (verb === "DECLINE") {
                    this.toastService.show("CALENDARS.DECLINE_APPOINTMENT_MSG");
                } else if (verb === "TENTATIVE") {
                    this.toastService.show("CALENDARS.TENTETIVE_APPOINTMENT_MSG");
                }
                this.databaseService.getAppointmentsById(this.appointment.id).subscribe((resp: any) => {
                    if (!resp || !resp.msg_request ) {
                        return;
                    }
                    if (resp.msg_request.reply && resp.msg_request.reply[0]) {
                        resp.msg_request.reply[0].ptst = verb === "ACCEPT" ? "AC" : verb === "DECLINE" ? "DE" : "TE";
                        const updatedAppointment = resp;
                        this.calendarRepository.saveAppointmentRequestApplyToDB(updatedAppointment).subscribe(() => {
                          this.calendarRepository.getAllAppointments();
                          this.close();
                        });
                    } else {
                        let reply = [{ptst : verb === "ACCEPT" ? "AC" : verb === "DECLINE" ? "DE" : "TE"}];
                        resp.msg_request.reply = reply;
                        const updatedAppointment = resp;
                        this.calendarRepository.saveAppointmentRequestApplyToDB(updatedAppointment).subscribe(() => {
                          this.calendarRepository.getAllAppointments();
                          this.close();
                        });
                    }
                });
            });

          }
    }

    printAppointment(): void {
        this.calendarRepository.printAppointment(this.appointment.id);
    }

    deleteAppointment() {
      switch (this.eventType) {
        case "default" : {
          this.sharedCalendarService.deleteDefaultAppointment(this.data);
          this.close();
          break;
        }
        case "series" : {
          this.deleteCalendarSeriesAppointment(this.data.event);
          break;
        }
        case "instance" : {
          this.deleteCalendarInstanceAppointment(this.data.event);
          break;
        }
      }
    }

    deleteCalendarEvent(event: CalendarAppointment, isInstanced: boolean = false, isFromShortcutForRecurEvent: boolean = false) {
        const deleteCalendarEventRef = this.matDialog.open(ConfirmDialogComponent, {
            width: "350px",
            data: <CalendarConfirmData>{
                event: event,
                type: "calendarEvent",
                confirmTemplate: isFromShortcutForRecurEvent
                    ? CalendarConfirmTemplate.CalendarAppointmentShortcutDeleteRecurConfirm
                    : CalendarConfirmTemplate.CalendarAppointmentDeleteConfirm
            }
        });
        deleteCalendarEventRef.afterClosed().subscribe(res => {
            if (res.confirm) {
                if (res.data.isInstance !== undefined) {
                    this.deleteCalendarEvent(res.data.confirmArgs.event, res.data.isInstance);
                } else {
                    this.calendarRepository.deleteAppointment(res.data.event, isInstanced);
                }
            }
        });
    }

     moveEvent(): void {
        if (!this.isOnline) {
            this.toastService.show("NO_INTERNET_CONNECTION");
            return;
        }

        const event = this.appointment;
        const folder = this.sharedCalendarService.getFolderById(event.ciFolder);
        const dialogRef = this.matDialog.open(MoveCalendarDialogComponent, {
        maxWidth: "100%",
        autoFocus: true,
        panelClass: "move__dialog",
        data: { folder: folder, isEventMove: true, appointment: this.appointment }
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe( res => {
            if (!!res && res.destinationFolder) {
                const destinationFolder = res.destinationFolder;
                const body = {
                    id: event.apptId,
                    l: destinationFolder.id,
                    op: "move"
                };
                this.commonService.itemAction(body).pipe(take(1)).subscribe( resp => {
                    this.translateService.get("CALENDARS.APPOINTMENT_MOVE_TO_MSG", { destinationFolder: destinationFolder.name
                     }).pipe(take(1)).subscribe((text: string) => {
                        this.toastService.show(text);
                     });
                     this.close();
                     this.calendarRepository.getAllAppointments();
                     this.calendarRepository.refreshCalendarListView$.next(true);
                }, error => {
                    this.toastService.showPlainMessage(error);
                });
            }
        });
     }

      copyEvent(): void {
        const appt = this.appointment;
        appt.alarmData = {
          group: "MINUTES_BEFORE",
          text: "5",
          value: 5
        };
        appt.recur = "NON";
        this.matDialog.open(EditAppointmentDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "edit-calender-appointment-dialog",
            id: "edit-calender-appointment-dialog",
            data: { appointment: appt, disableRepeat: false , isInstance: false, isNewAppointment: true, isCopyAppointment: true }
        });
        this.close();
      }

      downloadAttachment(file: any) {
        this.calendarRepository.openAttachment(this.appointment.id, file.part);
      }

    setAttendeesName() {
        if (this.appointment.at && this.appointment.at.length > 3 ) {
            this.appointment.at.map((at , index) => {
                if (index < 3) {
                    this.showAttendeesNames = this.showAttendeesNames + at.a;
                    this.showAttendeesNames = this.showAttendeesNames + (index === 2 ? "" : ", ");
                }
            });
            this.showAttendeesNames = this.showAttendeesNames + " +" + (this.appointment.at.length - 3);
        } else if (this.appointment.at && this.appointment.at.length === 3 ) {
            this.appointment.at.map((at , index) => {
                if (index <= 3) {
                    this.showAttendeesNames = this.showAttendeesNames + at.a;
                    this.showAttendeesNames = this.showAttendeesNames + (index === 2 ? "" : ", ");
                }
            });
        } else if (this.appointment.at && this.appointment.at.length === 2 ) {
            this.appointment.at.map((at , index) => {
                if (index <= 3) {
                    this.showAttendeesNames = this.showAttendeesNames + at.a;
                    this.showAttendeesNames = this.showAttendeesNames + (index === 1 ? "" : ", ");
                }
            });
        } else if (this.appointment.at && this.appointment.at.length === 1 ) {
            this.appointment.at.map((at , index) => {
                if (index <= 3) {
                    this.showAttendeesNames = this.showAttendeesNames + at.a;
                    this.showAttendeesNames = this.showAttendeesNames;
                }
            });
        }
    }
    openParticipantList() {
        if (this.isMobileScreen) {
            return;
        }
        this.groupParticipantList.openMenu();
    }

    closeParticipantList() {
        this.groupParticipantList.closeMenu();
    }

    zoomToFull() {
        this.zoomFull = true;
        const elements = document.querySelectorAll(".appointment-preview-dialog") as NodeListOf<HTMLElement>;
        if (elements && elements.length) {
            elements[0].style.height = "100vh";
            elements[0].style.width = "100vw";
            elements[0].style["max-height"] = "100vh";
        }
        const desktopEl = document.querySelectorAll(".desktop") as NodeListOf<HTMLElement>;
        if (elements && elements.length) {
            if (!this.organizeByMe && !this.readOnly) {
                desktopEl[0].style.height = "calc(100vh - 65px)";
            } else {
                desktopEl[0].style.height = "100vh";
            }
        }
        const header: any = document.getElementsByClassName("header-title-attendee-block");
        console.log("convHistory",  header[0].offsetWidth);
        const titleEl = document.getElementsByClassName("appointment-title");
        if (titleEl && titleEl.length) {
            if (header && header.length) {
                titleEl[0]["style"].width = header[0].offsetWidth + "px";
            }
        }
        const dialogContainer =  document.getElementsByClassName("appointment-dialog-container");
        if (dialogContainer && dialogContainer.length) {
            if (!this.organizeByMe && !this.readOnly) {
                dialogContainer[0]["style"]["max-height"] = "calc(100vh - 100px)";
            } else {
                dialogContainer[0]["style"]["max-height"] = "100vh";
                dialogContainer[0]["style"]["height"] = "calc(100vh - 100px";
            }
        }
    }

    zoomToDefaultSize() {
        this.zoomFull = false;
        const elements = document.querySelectorAll(".appointment-preview-dialog") as NodeListOf<HTMLElement>;
        if (elements && elements.length) {
            elements[0].style.height = "auto";
            elements[0].style.width = "500px";
            elements[0].style["max-height"] = "500px";
        }
        const desktopEl = document.querySelectorAll(".desktop") as NodeListOf<HTMLElement>;
        if (elements && elements.length) {
            desktopEl[0].style.height = "auto";
        }
        const titleEl = document.getElementsByClassName("appointment-title");
        if (titleEl && titleEl.length) {
            titleEl[0]["style"].width = "310px";
        }

        const dialogContainer =  document.getElementsByClassName("appointment-dialog-container");
        if (dialogContainer && dialogContainer.length) {
            dialogContainer[0]["style"]["max-height"] = "450px";
        }

        const header: any = document.getElementsByClassName("header-title-attendee-block");
        if (titleEl && titleEl.length && this.isMobileScreen) {
            if (header && header.length) {
                titleEl[0]["style"].width = header[0].offsetWidth + "px";
            }
        }

    }

    replyAllAppointment(): void {
        if (this.data.event === undefined) {
          return;
        }
        const eventItem: any = this.data.event;
        const body = {
          id:   eventItem.invId,
          ridz: eventItem.inst[0].ridZ
        };
        this.closeModal();
        this.sharedCalendarService.openComposeForReplyAll(body, this.data.event, this.isOnline);
      }

      forwardEvent(event: any = this.data.event, isInstance: boolean = true): void {
        console.log("forwardEvent", event);
        const eventItem: any = event;
        if (this.isOnline) {
            const body = {
                id:   eventItem.invId,
                ridz: eventItem.ridZ || (!!eventItem.inst && eventItem.inst[0] ? eventItem.inst[0].ridZ : "")
              };
              this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(res => {
                const appt = CommonUtils.mapAppointmentFromMsg(res.m[0]);
                this.matDialog.open(EditAppointmentDialogComponent, {
                  maxWidth: "100%",
                  autoFocus: false,
                  panelClass: "edit-calender-appointment-dialog",
                  id: "edit-calender-appointment-dialog",
                  data: { appointment: appt, disableRepeat: true , isInstance: false , isForward: true , isForwardInstance: isInstance }
                });
              }, error => {
                this.toastService.showPlainMessage(error);
              });
        } else {

            this.databaseService.getAppointmentsById(eventItem.id).pipe(take(1)).subscribe((res: any) => {
                if (!res || !res.msg_request) {
                    this.toastService.show("FORBIDDEN");
                    return;
                }

                this.matDialog.open(EditAppointmentDialogComponent, {
                    maxWidth: "100%",
                    autoFocus: false,
                    panelClass: "edit-calender-appointment-dialog",
                    id: "edit-calender-appointment-dialog",
                    data: { appointment: res.msg_request, disableRepeat: true , isInstance: false , isForward: true , isForwardInstance: isInstance }
                });
            }, error => {
                this.toastService.showPlainMessage(error);
            });
        }
        this.closeModal();
      }

      tagAppointment(event: any): void {
        const data = { calendarEvent: event, moduleType: "calendar" , isMultiple: true };
        const dlg = this.matDialog.open(CreateTagComponent, {
          width: "580px",
          height: "auto",
          autoFocus: true,
          panelClass: "tag_create_dialog",
          data: data
        });
        dlg.afterClosed().pipe(take(1)).subscribe(res => {
          if (!!res && res.close) {
            if (res.close) {
              if (!!this.calendarRepository.selectingCalendarView && this.calendarRepository.selectingCalendarView === CalendarView.List) {
                this.broadcaster.broadcast("UPDATE_LIST_VIEW");
              } else {
                this.calendarRepository.getAllAppointments();
              }
            }
          }
        });
        this.closeModal();
      }

      showOriginal(event: CalendarAppointment): void {
        const id = event.id;
        this.calendarRepository.showOriginalAppointment(id);
        this.dialogRef.close();
      }

      closeModal() {
        this.dialogRef.close();
      }


      replyAppointment(event): void {
        if (event === undefined) {
          return;
        }
        const eventItem: any = event;
        const body = {
          id:   eventItem.invId,
          ridz: eventItem.inst[0].ridZ
        };
        this.openComposeForReply(body, event);
      }

      openComposeForReply(body: any, event: any): void {
        if (this.isOnline) {
            this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(res => {
                const appt = CommonUtils.mapAppointmentFromMsg(res.m[0]);
                let description: string = "";
                if (appt.desc) {
                    description = MailUtils.plainTextToHTML(appt.desc);
                } else if (appt.descHTML) {
                    description = appt.descHTML;
                }
                const organizer: any[] = [];
                organizer.push({
                    a: appt.or.a,
                    d: appt.or.a,
                    t: "t"
                });
                const attndee: any[] = [];
                const optionalAteendee: any[] = [];
                appt.at.map(filed => {
                    if (filed.role === "REQ") {
                    attndee.push(filed.a);
                    }
                    if (filed.role === "OPT") {
                    optionalAteendee.push(filed.a);
                    }
                });
                if (description === "") {
                    let translation: any = {};
                    this.translateService.get([
                    "CALENDARS.ORIGINAL_APPT_LBL", "CALENDARS.ORGANIZER_LBL", "CALENDARS.FOLLOWING_NEW_MEETING_REQUEST",
                    "CALENDARS.TIME_LBL", "CALENDARS.INVITEES_LBL", "CALENDARS.SUBJECT_LBL", "CALENDARS.REQUIRED", "CALENDARS.OPTIONAL"]
                    ).pipe(take(1)).subscribe(resp => {
                    translation = resp;
                    const start = moment(event.start).format("LLLL");
                    const end = moment(event.end).format("LTS");
                    description += "<b>" + translation["CALENDARS.SUBJECT_LBL"] + "</b>" + ":" + event.title + "<br/>" +
                    "<b>" + translation["CALENDARS.ORGANIZER_LBL"] + "</b>" + ": " + appt.or.a + "<br/>" +
                    "<b>" + translation["CALENDARS.TIME_LBL"] + "</b>" + ": " + start + " - " + end + " <br/>" +
                    "<b>" + translation["CALENDARS.REQUIRED"] + "</b>" + ": " + attndee.toString();
                    if (!!optionalAteendee && optionalAteendee.length > 0) {
                        description += "<br/><b>" + translation["CALENDARS.OPTIONAL"] + "</b>" + ": " + optionalAteendee.toString();
                    }
                    description += "<br/><br/>*~*~*~*~*~*~*~*~*~*";
                    });
                }
                const id = appt.id;
                const subject = appt.name;
                this.router.navigate(["/mail/compose/reply"]);
                setTimeout(() => {
                    this.broadcaster.broadcast("COMPOSE_CALENDAR_REPLY", {
                    id: id,
                    desc: description,
                    attendee: organizer,
                    subject: subject
                    });
                }, 1500);
            });
        } else {
            this.databaseService.getAppointmentsById(event.id).pipe(take(1)).subscribe((res: any) => {
              if (!res || !res.msg_request) {
                this.toastService.show("FORBIDDEN");
                return;
              }
              let description: string = "";
              if (res.msg_request.desc) {
                description = MailUtils.plainTextToHTML(res.msg_request.desc);
              } else if (res.msg_request.descHTML) {
                description = res.msg_request.descHTML;
              }
              const organizer: any[] = [];
              organizer.push({
                a: res.msg_request.or.a,
                d: res.msg_request.or.a,
                t: "t"
              });
              const attndee: any[] = [];
              const optionalAteendee: any[] = [];
              res.msg_request.at.map(filed => {
                if (filed.role === "REQ") {
                  attndee.push(filed.a);
                }
                if (filed.role === "OPT") {
                  optionalAteendee.push(filed.a);
                }
              });
              if (description === "") {
                let translation: any = {};
                this.translateService.get([
                  "CALENDARS.ORIGINAL_APPT_LBL", "CALENDARS.ORGANIZER_LBL", "CALENDARS.FOLLOWING_NEW_MEETING_REQUEST",
                  "CALENDARS.TIME_LBL", "CALENDARS.INVITEES_LBL", "CALENDARS.SUBJECT_LBL", "CALENDARS.REQUIRED", "CALENDARS.OPTIONAL"]
                ).pipe(take(1)).subscribe(resp => {
                  translation = resp;
                  const start = moment(event.start).format("LLLL");
                  const end = moment(event.end).format("LTS");
                  description += "<b>" + translation["CALENDARS.SUBJECT_LBL"] + "</b>" + ":" + event.title + "<br/>" +
                  "<b>" + translation["CALENDARS.ORGANIZER_LBL"] + "</b>" + ": " + res.msg_request.or.a + "<br/>" +
                  "<b>" + translation["CALENDARS.TIME_LBL"] + "</b>" + ": " + start + " - " + end + " <br/>" +
                  "<b>" + translation["CALENDARS.REQUIRED"] + "</b>" + ": " + attndee.toString();
                  if (!!optionalAteendee && optionalAteendee.length > 0) {
                    description += "<br/><b>" + translation["CALENDARS.OPTIONAL"] + "</b>" + ": " + optionalAteendee.toString();
                  }
                  description += "<br/><br/>*~*~*~*~*~*~*~*~*~*";
                });
              }
              const id = res.msg_request.id;
              const subject = res.msg_request.name;
              this.router.navigate(["/mail/compose/reply"]);
              setTimeout(() => {
                this.broadcaster.broadcast("COMPOSE_CALENDAR_REPLY", {
                  id: id,
                  desc: description,
                  attendee: organizer,
                  subject: subject
                });
              }, 1500);
            }, error => {
                this.toastService.showPlainMessage(error);
            });
        }
        this.closeModal();
      }

      @HostListener("document:mousemove", ["$event"])
      onMouseMove(event: MouseEvent) {
        if (this.isMobileScreen) return;
        const {x, y, height, width} = this.parentElement.nativeElement.getBoundingClientRect();
        if (this.menuElement && this.menuElement.nativeElement?.contains(event.target as any)) return;
        if (event.x > x && event.x < (x + width) && event.y > (y - 10) && event.y < (y + height + 10)) return;
        this.closeParticipantList();
      }


      porposeNewTimeAppointment(): void {
        const body = {
          id: this.data.appointment.id
        };
        if (this.isOnline) {
          this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(resa => {
            this.dialogRef.close();
            const appt = this.calendarRepository.mapAppointmentFromMsg(resa.m[0]);
            this.matDialog.open(EditAppointmentDialogComponent, {
              maxWidth: "100%",
              autoFocus: false,
              panelClass: "edit-calender-appointment-dialog",
              id: "edit-calender-appointment-dialog",
              data: { appointment: appt, disableRepeat: true , isInstance: false, isProposeNewTime: true, messageItem: this.data }
            });
          });
        } else {
          this.databaseService.getAppointmentsById(this.data.appointment.eventId).pipe(take(1)).subscribe((res: any) => {
            if (!res || !res.msg_request) {
              this.toastService.show("FORBIDDEN");
              return;
            }

            this.dialogRef.close();
            this.matDialog.open(EditAppointmentDialogComponent, {
              maxWidth: "100%",
              autoFocus: false,
              panelClass: "edit-calender-appointment-dialog",
              id: "edit-calender-appointment-dialog",
              data: { appointment: res.msg_request, disableRepeat: true , isInstance: false, isProposeNewTime: true, messageItem: this.data }
            });
          }, error => {
            console.error(`[ContextMenuComponent][databaseService][getAppointmentsById]`, error);
          });
        }
    }

    isVnctalkMeeting(loc) {
        return !!loc && (loc.indexOf("assets/meeting/?jwt=") > -1);
    }

    openMeetingUrl(url) {
        if (typeof cordova !== "undefined") {
            if (device.platform === "iOS") {
                window.open(url, "_system");
            } else if (device.platform === "Android") {
                navigator.app.loadUrl(url, {
                    openExternal: true
                });
            }
        } else if (this.electronService.isElectron) {
            this.electronService.openExternalUrl(url);
        } else {
            window.open(url, "_blank");
        }
    }

    showParticipants(data) {
        console.log("showParticipants", data);
        const dialogArgs = {
            width: "376px",
            maxWidth: "85vw",
            height: "450px",
            autoFocus: false,
            panelClass: "participants-dialog",
            data: {participantsList: this.appointment}
        };
        const dialogRef = this.matDialog.open(ParticipantsDialogComponent, dialogArgs);
    }

    copyItem(email: any): void {
        MailUtils.copyFromTextClipboard(email);
        this.toastService.show("EMAIL_COPIED");
    }

    cancelCalendarAppointment(instance?: boolean): void {
      const appointmentItem: any = this.data.event;
      const body = {
        id:   appointmentItem.invId,
        ridz: appointmentItem.inst[0].ridZ
      };
      if (this.isOnline) {
        this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(res => {
          const appt = CommonUtils.mapAppointmentFromMsg(res.m[0]);
          const oldDateTime = moment(appt.startDateData[0].d).format("HHmmss");
          const selectedDate = moment(appointmentItem.inst[0].ridZ).format("YYYYMMDD");
          const dateTime = selectedDate + "T" + oldDateTime;
          const dialogRef = this.matDialog.open(ConfirmSendInviteDialogComponent, {
            maxWidth: "95%",
            autoFocus: false,
            panelClass: "cancel-send-invite-dialog"
          });
          dialogRef.afterClosed().pipe(take(1)).subscribe( resp => {
            if (!!resp) {
              if (resp.value) {
                const value = resp.value;
                const event = this.data?.isInstance;
                if (value === "send") {
                    if (instance || event) {
                      const ins = {
                        d: dateTime,
                        tz: appt.startDateData[0].tz
                      };
                      this.sendCancelRequest(appt, ins);
                      this.dialogRef.close();
                    } else {
                      this.sendCancelRequest(appt);
                      this.dialogRef.close();
                    }
                } else if (value === "edit") {
                  if (instance) {
                    const ins = {
                      d: dateTime,
                      tz: appt.startDateData[0].tz
                    };
                    this.cancelSendAppointment(appt, ins);
                    this.dialogRef.close();
                  } else {
                    this.cancelSendAppointment(appt);
                    this.dialogRef.close();
                  }
                }
              }
            }
          });
        }, error => {
          this.toastService.showPlainMessage(error);
        });
      } else {
        this.databaseService.getAppointmentsById(appointmentItem.eventId).pipe(take(1)).subscribe((res: any) => {
          if (!res || !res.msg_request) {
            this.toastService.show("FORBIDDEN");
            return;
          }

          const oldDateTime = moment(res.msg_request.startDateData[0].d).format("HHmmss");
          const selectedDate = moment(appointmentItem.inst[0].ridZ).format("YYYYMMDD");
          const dateTime = selectedDate + "T" + oldDateTime;
          const dialogRef = this.matDialog.open(ConfirmSendInviteDialogComponent, {
            maxWidth: "95%",
            autoFocus: false,
            panelClass: "cancel-send-invite-dialog"
          });
          dialogRef.afterClosed().pipe(take(1)).subscribe( resp => {
            if (!!resp) {
              if (resp.value) {
                const value = resp.value;
                const event = this.data?.isInstance;
                if (value === "send") {
                    if (instance || event) {
                      const ins = {
                        d: dateTime,
                        tz: res.msg_request.startDateData[0].tz
                      };
                      this.sendCancelRequest(res.msg_request, ins);
                      this.dialogRef.close();
                    } else {
                      this.sendCancelRequest(res.msg_request);
                      this.dialogRef.close();
                    }
                } else if (value === "edit") {
                  if (instance) {
                    const ins = {
                      d: dateTime,
                      tz: res.msg_request.startDateData[0].tz
                    };
                    this.cancelSendAppointment(res.msg_request, ins);
                    this.dialogRef.close();
                  } else {
                    this.cancelSendAppointment(res.msg_request);
                    this.dialogRef.close();
                  }
                }
              }
            }
          });
        }, error => {
          console.error(`[ContextMenuComponent][databaseService][getAppointmentsById]`, error);
        });
      }
    }

    sendCancelRequest(appt: any, inst?: any): void {
      const attandeesAndInvitee: any [] = [];
      attandeesAndInvitee.push({
        a: appt.or.a,
        t: "f"
      });
      const at = appt.at;
      at.map( invitee => {
        attandeesAndInvitee.push({
          a: invitee.a,
          t: "t"
        });
      });
      const ms = appt.ms;
      const id = appt.id;
      const name  =  appt.name;

      if (this.isOnline) {
        if (!!appt && !!appt.xprop && !!appt.xprop[0] && !!appt.xprop[0].value) {
          console.log("calSendCancelRequest - need to cancel meeting: ", appt.xprop[0].value);
          this.commonService.cancelScheduledMeeting(appt.xprop[0].value).pipe(take(1)).subscribe(res => {
            console.log("calSendCancelRequest res: ", res);
          }, error => {
            this.toastService.showPlainMessage(error);
          });
        }

          this.commonService.cancelCreateAppointment(ms, id, name, attandeesAndInvitee, inst).pipe(take(1)).subscribe( res => {
            this.sharedCalendarService.removefirebasenotificationOnDelete(id);
            this.databaseService.deleteAppointments([appt.eventId]);
            this.calendarRepository.getAllAppointments();
          }, error => {
            this.toastService.showPlainMessage(error);
          });

      } else {
        let cancelText = "Cancelled";
        this.translateService.get("CANCELED_LBL").pipe(take(1)).subscribe(v => cancelText = v);
        const req = {
          CancelAppointmentRequest: {
            "@": {
              xmlns: "urn:zimbraMail"
            },
            "ms": ms,
            "rev": ms,
            "id": id,
            "comp": "0",
            "m": {
              "e": attandeesAndInvitee,
              "su": `${cancelText}: ` + name,
              "mp": {
                "mp": [{
                  "ct": "text/plain",
                  "content": ""
                }],
                "ct": "multipart/alternative"
              }
            }
          }
        };
        if (inst) {
          req.CancelAppointmentRequest["inst"] = inst;
        }

        let requestBody = { ...req };
          // if (this.commonService.isFakeId(requestBody.id)) {
          //   delete requestBody.id;
          // }
          // if (this.commonService.isFakeId(requestBody.did)) {
          //   delete requestBody.did;
          // }
          const request = {
            "url": "/api/batchRequest",
            "method": "post",
            "body":  requestBody
          };

          // fake id
          // if (!events.id) {
            // let fakeid = this.commonService.createFakeId("deleteMultiple");
          // }
          this.databaseService.addPendingOperation(id, "batchRequest", request).subscribe(res => {
            this.sharedCalendarService.removefirebasenotificationOnDelete(id);
            this.databaseService.deleteAppointments([appt.eventId]);
            this.calendarRepository.getAllAppointments();
          });
      }
    }

    cancelSendAppointment(appt: Appointment, instance?: any): void {
      const at = appt.at;
      const attendee: any[] = [];
      at.map(atende => {
       attendee.push({
        a: atende.a,
        d: atende.a,
        t: "t"
       });
      });
      this.router.navigate(["/mail/compose/reply"]);
        setTimeout(() => {
          this.broadcaster.broadcast("COMPOSE_CALENDAR_CANCEL_APPOINTMENT_MESSAGE", {
            id: appt.id,
            ms: appt.ms,
            rev: appt.rev,
            attendee: attendee,
            subject: appt.name,
            instance: instance
          });
        }, 1500);
    }

    deleteCalendarSeriesAppointment(appointment: CalendarEvent): void {
      const ev: any = appointment;
      if (ev.isOrganizer !== undefined && !ev.isOrganizer) {
        this.deleteNotify(appointment);
        this.close();
        return;
      }
      const deleteCalendarAppointmentRef = this.matDialog.open(DeleteSeriesConfirmDialogComponent, {
        panelClass: "delete-event-series-confirm-dialog"
      });
      deleteCalendarAppointmentRef.afterClosed().subscribe(res => {
        if (!!res && !!res.value) {
          const value = res.value;
          const appointmentItem = <any> appointment;
            const body = {
              id:   appointmentItem.invId,
              ridz: appointmentItem.inst[0].ridZ
            };
          if (value === "occurence") {
            if (ev.folderId !== "3" && ev.otherAtt && !ev.neverSent && ev.isOrganizer) {
              this.cancelCalendarAppointment(ev);
              return;
            }
            let cancelText = "Cancelled";
            this.translateService.get("CANCELED_LBL").pipe(take(1)).subscribe(v => cancelText = v);
            if (this.isOnline) {
              this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(response => {
                const appt = CommonUtils.mapAppointmentFromMsg(response.m[0]);
                const bodyItem: any = {
                  ms: appt.ms,
                  rev: appt.rev,
                  id: appt.id,
                  su: `${cancelText}: ` + appt.name,
                  emailInfo: [{
                      "a": appt.or.a,
                      "t": "f"
                    }]
                };
                this.commonService.deleteAppointmentAppointment(bodyItem).pipe(take(1)).subscribe(resp => {
                  this.sharedCalendarService.removefirebasenotificationOnDelete(appointmentItem.invId);
                  this.databaseService.deleteAppointments([appointmentItem.eventId], "series-all");
                  this.calendarRepository.getAllAppointments();
                }, error => {
                  this.toastService.showPlainMessage(error);
                });
              });
            } else {
              this.databaseService.getAppointmentsById(appointmentItem.eventId).pipe(take(1)).subscribe((res: any) => {
                if (!res || !res.msg_request) {
                  this.toastService.show("FORBIDDEN");
                  return;
                }
                const bodyItem: any = {
                  ms: res.msg_request.ms,
                  rev: res.msg_request.rev,
                  id: res.msg_request.id,
                  su: `${cancelText}: ` + res.msg_request.name,
                  emailInfo: [{
                      "a": res.msg_request.or.a,
                      "t": "f"
                    }]
                };

                let requestBody = { ...bodyItem };
                if (this.commonService.isFakeId(requestBody.id)) {
                  delete requestBody.id;
                }
                if (this.commonService.isFakeId(requestBody.did)) {
                  delete requestBody.did;
                }
                const request = {
                  "url": "/api/cancelAppointment",
                  "method": "post",
                  "body":  requestBody
                };

                // fake id
                if (!bodyItem.id) {
                  bodyItem.id = this.commonService.createFakeId(bodyItem?.mp[0].content);
                }
                this.databaseService.addPendingOperation(bodyItem.id, "cancelAppointment", request).subscribe(res => {
                  this.sharedCalendarService.removefirebasenotificationOnDelete(appointmentItem.invId);
                  this.databaseService.deleteAppointments([appointmentItem.eventId], "series-all");
                  this.calendarRepository.getAllAppointments();
                });
              }, error => {
                console.error(`[ContextMenuComponent][databaseService][getAppointmentsById]`, error);
              });
            }
          } else {
            if (ev.folderId !== "3" && ev.otherAtt && !ev.neverSent && ev.isOrganizer) {
              this.cancelCalendarAppointment(ev);
              return;
            }
            const bodyItem = {
              id:   appointmentItem.invId,
              ridz: appointmentItem.inst[0].ridZ
            };


            if (this.isOnline) {
              this.commonService.getMsgRequest(bodyItem).pipe(take(1)).subscribe(respo => {
                const appt = CommonUtils.mapAppointmentFromMsg(respo.m[0]);
                const bodyItems = this.sharedCalendarService.getModifyApptBody(appt);
                this.commonService.modifyAppointment(bodyItems).pipe(take(1)).subscribe(resp => {
                  this.databaseService.deleteAppointments([appointmentItem.eventId], "instance-after");
                  this.calendarRepository.getAllAppointments();
                });
              });
            } else {
              this.databaseService.getAppointmentsById(appointmentItem.eventId).pipe(take(1)).subscribe((res: any) => {
                if (!res || !res.msg_request) {
                  this.toastService.show("FORBIDDEN");
                  return;
                }
                const bodyItems = this.sharedCalendarService.getModifyApptBody(res.msg_request);

                let requestBody = { ...bodyItems };
                if (this.commonService.isFakeId(requestBody.id)) {
                  delete requestBody.id;
                }
                if (this.commonService.isFakeId(requestBody.did)) {
                  delete requestBody.did;
                }
                const request = {
                  "url": "/api/modifyAppointment",
                  "method": "post",
                  "body":  requestBody
                };

                // fake id
                if (!bodyItems.id) {
                  bodyItems.id = this.commonService.createFakeId(bodyItems?.mp[0].content);
                }
                bodyItems.id = bodyItems.id + "instance_after";
                this.databaseService.addPendingOperation(bodyItems.id, "modifyAppointment", request).subscribe(res => {
                  this.databaseService.deleteAppointments([appointmentItem.eventId], "instance-after");
                  this.calendarRepository.getAllAppointments();
                });
              }, error => {
                console.error(`[ContextMenuComponent][databaseService][getAppointmentsById]`, error);
              });
            }
          }
        }
      });
    }

    deleteCalendarInstanceAppointment(appointment: CalendarEvent, isFromShortcutForRecurAppointment: boolean = false): void {
      const ev: any = appointment;
      if (ev.folderId !== "3" && ev.otherAtt && !ev.neverSent && ev.isOrganizer) {
        this.cancelCalendarAppointment(true);
        return;
      }
      if (ev.isOrganizer !== undefined && !ev.isOrganizer) {
        this.deleteNotify(appointment, true);
        this.close();
        return;
      }
      const deleteCalendarAppointmentRef = this.matDialog.open(ConfirmDialogComponent, {
        width: "350px",
        data: <CalendarConfirmData>{
          event: appointment,
          type: "calendarEvent",
          confirmTemplate: CalendarConfirmTemplate.CalendarAppointmentDeleteConfirm
        }
      });

      deleteCalendarAppointmentRef.afterClosed().subscribe(res => {
        if (!!res && !!res.confirm) {
          const appointmentItem = <any> appointment;
          const body = {
            id:   appointmentItem.invId,
            ridz: appointmentItem.inst[0].ridZ
          };
          let cancelText = "Cancelled";
          this.translateService.get("CANCELED_LBL").pipe(take(1)).subscribe(v => cancelText = v);

          if (this.isOnline) {
            this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(resItem => {
              const appt = CommonUtils.mapAppointmentFromMsg(resItem.m[0]);
              const oldDateTime = moment(appt.startDateData[0].d).format("HHmmss");
              const selectedDate = moment(appointmentItem.inst[0].ridZ).format("YYYYMMDD");
              const dateTime = selectedDate + "T" + oldDateTime;
              const bodyItem: any = {
                ms: appt.ms,
                rev: appt.rev,
                id: appt.id,
                su: `${cancelText}: ` + appt.name,
                inst: {
                  d: dateTime,
                  tz: appt.startDateData[0].tz
                } ,
                emailInfo: [{
                    "a": appt.or.a,
                    "t": "f"
                }],
                s: appt.startDateData[0].u
              };
              this.commonService.deleteAppointmentAppointment(bodyItem).pipe(take(1)).subscribe(resp => {
                this.sharedCalendarService.removefirebasenotificationOnDelete(appointmentItem.invId);
                this.databaseService.deleteAppointments([appointmentItem.eventId]);
                this.calendarRepository.getAllAppointments();
              }, error => {
                this.toastService.showPlainMessage(error);
              });
            });
          } else {
            this.databaseService.getAppointmentsById(appointmentItem.eventId).pipe(take(1)).subscribe((res: any) => {
              if (!res || !res.msg_request) {
                this.toastService.show("FORBIDDEN");
                return;
              }
              const oldDateTime = moment(res.msg_request.startDateData[0].d).format("HHmmss");
              const selectedDate = moment(appointmentItem.inst[0].ridZ).format("YYYYMMDD");
              const dateTime = selectedDate + "T" + oldDateTime;
              const bodyItem: any = {
                ms: res.msg_request.ms,
                rev: res.msg_request.rev,
                id: res.msg_request.id,
                su: `${cancelText}: ` + res.msg_request.name,
                inst: {
                  d: dateTime,
                  tz: res.msg_request.startDateData[0].tz
                } ,
                emailInfo: [{
                    "a": res.msg_request.or.a,
                    "t": "f"
                }],
                s: res.msg_request.startDateData[0].u
              };

              let requestBody = { ...bodyItem };
              if (this.commonService.isFakeId(requestBody.id)) {
                delete requestBody.id;
              }
              if (this.commonService.isFakeId(requestBody.did)) {
                delete requestBody.did;
              }
              const request = {
                "url": "/api/cancelAppointment",
                "method": "post",
                "body":  requestBody
              };

              // fake id
              if (!bodyItem.id) {
                bodyItem.id = this.commonService.createFakeId(bodyItem?.mp[0].content);
              }
              this.databaseService.addPendingOperation(bodyItem.id, "cancelAppointment", request).subscribe(res => {
                this.sharedCalendarService.removefirebasenotificationOnDelete(appointmentItem.invId);
                this.databaseService.deleteAppointments([appointmentItem.eventId]);
                this.calendarRepository.getAllAppointments();
              });
            }, error => {
              console.error(`[ContextMenuComponent][databaseService][getAppointmentsById]`, error);
            });
          }
        }
      });
    }

    deleteNotify(ev: any, instance?: boolean) {
      let type: string = "general";
      if (ev.isRepeatAppt) {
        if (instance) {
          type = "instance";
        } else {
          type = "all";
        }
      }
      const dlgRef = this.matDialog.open(DeleteAppointmentNotifyDialogComponent, {
        maxWidth: "95%",
        autoFocus: false,
        panelClass: "delete-evet-notify-user",
        id: "delete-event-notify-dialog",
        data: {type: type}
      });
      dlgRef.afterClosed().pipe(take(1)).subscribe( res => {
        if (!!res && !!res.value) {
          const value = res.value;
          let isSendNotifyReply = false;
          if (value === "yes") {
            isSendNotifyReply = true;
          } else if (value === "no") {
            isSendNotifyReply = false;
          }
          const body = {
            id:   ev.invId,
            ridz: ev.inst[0].ridZ
          };
          let cancelText = "Cancelled";
          this.translateService.get("CANCELED_LBL").pipe(take(1)).subscribe(v => cancelText = v);
          if (this.isOnline) {
            this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(resItem => {
              const appt = CommonUtils.mapAppointmentFromMsg(resItem.m[0]);
              const oldDateTime = moment(appt.startDateData[0].d).format("HHmmss");
              const selectedDate = moment(ev.inst[0].ridZ).format("YYYYMMDD");
              const dateTime = selectedDate + "T" + oldDateTime;
              const bodyItem: any = {
                ms: appt.ms,
                rev: appt.rev,
                id: appt.id,
                su: `${cancelText}: ` + appt.name,
                emailInfo: [{
                    "a": appt.or.a,
                    "t": "f"
                }],
                s: appt.startDateData[0].u
              };
              if (instance) {
                bodyItem["inst"] = {
                  d: dateTime,
                  tz: appt.startDateData[0].tz
                };
              }
              if (!isSendNotifyReply) {
                this.commonService.deleteAppointmentAppointment(bodyItem).pipe(take(1)).subscribe(resp => {
                  this.sharedCalendarService.removefirebasenotificationOnDelete(ev.invId);
                  this.calendarRepository.getAllAppointments();
                }, error => {
                  this.toastService.showPlainMessage(error);
                });
              } else {
                const body: any = {
                  id: ev.invId,
                  verb: "DECLINE",
                  updateOrganizer: "TRUE"
                };
                if (instance) {
                  body["exceptId"] = {
                    d: dateTime,
                    tz: appt.startDateData[0].tz
                  };
                }
                this.commonService.sendInviteReply(body).pipe(take(1)).subscribe(res => {
                  this.commonService.deleteAppointmentAppointment(bodyItem).pipe(take(1)).subscribe(resp => {
                    this.sharedCalendarService.removefirebasenotificationOnDelete(ev.invId);
                    this.calendarRepository.getAllAppointments();
                  }, error => {
                    this.toastService.showPlainMessage(error);
                  });
                });
              }
            });
          } else {
          this.databaseService.getAppointmentsById(ev.eventId).pipe(take(1)).subscribe((res: any) => {
            if (!res || !res.msg_request) {
              this.toastService.show("FORBIDDEN");
              return;
            }
            const oldDateTime = moment(res.msg_request.startDateData[0].d).format("HHmmss");
            const selectedDate = moment(ev.inst[0].ridZ).format("YYYYMMDD");
            const dateTime = selectedDate + "T" + oldDateTime;
            const bodyItem: any = {
              ms: res.msg_request.ms,
              rev: res.msg_request.rev,
              id: res.msg_request.id,
              su: `${cancelText}: ` + res.msg_request.name,
              emailInfo: [{
                  "a": res.msg_request.or.a,
                  "t": "f"
              }],
              s: res.msg_request.startDateData[0].u
            };
            if (instance) {
              bodyItem["inst"] = {
                d: dateTime,
                tz: res.msg_request.startDateData[0].tz
              };
            }
            if (!isSendNotifyReply) {
              let requestBody = { ...bodyItem };
              if (this.commonService.isFakeId(requestBody.id)) {
                delete requestBody.id;
              }
              if (this.commonService.isFakeId(requestBody.did)) {
                delete requestBody.did;
              }
              const request = {
                "url": "/api/cancelAppointment",
                "method": "post",
                "body":  requestBody
              };

              // fake id
              if (!bodyItem.id) {
                bodyItem.id = this.commonService.createFakeId(bodyItem?.mp[0].content);
              }
              this.databaseService.addPendingOperation(bodyItem.id, "cancelAppointment", request).subscribe(res => {
                this.sharedCalendarService.removefirebasenotificationOnDelete(ev.invId);
                this.databaseService.deleteAppointments([ev.eventId]);
                this.calendarRepository.getAllAppointments();
              });
            } else {
              const body: any = {
                id: ev.invId,
                verb: "DECLINE",
                updateOrganizer: "TRUE"
              };
              if (instance) {
                body["exceptId"] = {
                  d: dateTime,
                  tz: res.msg_request.startDateData[0].tz
                };
              }

              let requestBody = { ...bodyItem };
              if (this.commonService.isFakeId(requestBody.id)) {
                delete requestBody.id;
              }
              if (this.commonService.isFakeId(requestBody.did)) {
                delete requestBody.did;
              }
              const requestInvite = {
                "url": "/api/sendInviteReply",
                "method": "post",
                "body":  body
              };
              const request = {
                "url": "/api/cancelAppointment",
                "method": "post",
                "body":  requestBody
              };

              // fake id
              if (!bodyItem.id) {
                bodyItem.id = this.commonService.createFakeId(bodyItem?.mp[0].content);
              }
              this.databaseService.addPendingOperation(body.id, "sendInviteReply", requestInvite).subscribe(res => {
                this.databaseService.addPendingOperation(bodyItem.id, "cancelAppointment", request).subscribe(res => {
                  this.sharedCalendarService.removefirebasenotificationOnDelete(ev.invId);
                  this.databaseService.deleteAppointments([ev.eventId]);
                  this.calendarRepository.getAllAppointments();
                });
              });
            }
            }, error => {
              console.error(`[ContextMenuComponent][databaseService][getAppointmentsById]`, error);
            });
          }
        }
      });
    }

    mailDetailEmailLongPress(target) {
      if (target?.tagName === "A") {
          this.convRepository.copyContent(target?.href);
      }
  }
  getFolderInDirective(appointment) {
    return this.mailService?.getFolderNameKey(this.sharedCalendarService.getFolderNameById(appointment?.ciFolder), CalendarConstants.SYSTEM_FOLDERS, true);
  }

  showTitle() {
    if (this.isMobileScreen) {
      this.toastService.showPlainMessage(this.appointment.name, 1500);
    }
  }

  dateCompare(startDate, endDate): boolean {
    const sDate = new Date(startDate).toLocaleDateString();
    const eDate = new Date(endDate).toLocaleDateString();
    return sDate !== eDate;
  }
}
