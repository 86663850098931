
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, NgZone, ElementRef, ViewChild, ChangeDetectorRef } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MailFolder } from "../../../models/mail-folder.model";
import { Store } from "@ngrx/store";
import { MailRootState } from "../../../store";
import { Subject } from "rxjs/internal/Subject";
import { takeUntil, take } from "rxjs/operators";
import { MailFolderRepository } from "../../../repositories/mail-folder-repository";
import { getMailFolders } from "../../../store/selectors";
import { MailConstants } from "../../../../common/utils/mail-constants";
import { CommonUtils } from "../../../../common/utils/common-util";
import { TranslateService } from "@ngx-translate/core";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "../../../../common/enums/broadcast.enum";
import { CommonRepository } from "../../../repositories/common-repository";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { ToastService } from "../../../../common/providers/toast.service";
import { FormControl } from "@angular/forms";

const FOLDERS_PER_PAGE = 20;
@Component({
  selector: "vp-search-folder",
  templateUrl: "./search-folder.component.html"
})
export class SearchFolderComponent implements OnInit, OnDestroy {
  mailFolders: MailFolder[] = [];
  private isAlive$ = new Subject<boolean>();
  selectedMailFolder: MailFolder;
  rootUserFolders: MailFolder[] = [];
  loadedFolders: MailFolder[] = [];
  currentSelectedMailFolder: MailFolder;
  currentTheme = localStorage.getItem("theme");
  searchControl: FormControl = new FormControl("", []);
  @ViewChild("folderNameSearchInput", {static: false}) folderNameSearchInput: ElementRef<HTMLInputElement>;
  pageNumber = 1;
  constructor(
    private dialogRef: MatDialogRef<SearchFolderComponent>,
    private store: Store<MailRootState>,
    private mailFolderRepository: MailFolderRepository,
    private translate: TranslateService,
    private mailBroadcaster: MailBroadcaster,
    private commonRepository: CommonRepository,
    private ngZone: NgZone,
    private hotKeyService: NgxHotkeysService,
    private changeDetectionRef: ChangeDetectorRef,
    public toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    setTimeout(() => {
      this.changeDetectionRef.detectChanges();
    }, 50);
    this.setFocus();
  }

  ngOnInit() {
    this.store
      .select(getMailFolders)
      .pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        if (res) {
          this.rootUserFolders = res.filter(
            folder =>
              (!folder.perm &&
                folder.name !== MailConstants.STARRED_FOLDER_TITLE &&
                folder.name !== MailConstants.DRAFTS_FOLDER_TITLE &&
                folder.name !== MailConstants.SPAM_FOLDER_TITLE) ||
              (folder.perm && folder.perm !== "r")
          );

          this.translate.get(MailConstants.SYSTEM_FOLDERS)
          .pipe(take(1)).subscribe(result => {
            this.rootUserFolders = this.rootUserFolders.map(folder => {
              if (folder.icon) {
                folder.name = result[`${folder.name.toUpperCase()}_FOLDER`] || folder.name;
              }
              return folder;
            });
          });
          this.loadFolders();
        }
      });
    if (CommonUtils.isOnIOS) {
      this.mailBroadcaster.broadcast(BroadcastKeys.HANDLE_MAIL_LIST_SCROLL, true);
    }

    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_MOVE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.dialogRef.close();
      });
    });
  }

  getParentFolder() {
    const rootFolder: MailFolder = this.mailFolderRepository.getRootFolder(
      this.selectedMailFolder
    );
    this.selectedMailFolder = this.commonRepository.getSelectedSubFolder(
      this.selectedMailFolder.l,
      rootFolder
    );
    this.setFocus();
  }

  getChildrens(folder: MailFolder) {
    this.selectedMailFolder = folder;
    this.setFocus();
  }

  setFocus() {
    setTimeout(() => {
      let vncinput = <HTMLInputElement>document.querySelector("vnc-input input");
      vncinput.focus();
    }, 200);
  }

  close() {
    this.dialogRef.close();
  }

  select() {
    this.mailBroadcaster.broadcast(MailConstants.EXPAND_FOLDER, {selectedFolder: this.currentSelectedMailFolder});
    this.dialogRef.close(this.currentSelectedMailFolder);
  }

  selectFolder(folder) {
    this.currentSelectedMailFolder = folder;
  }

  ngOnDestroy() {
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
    if (CommonUtils.isOnIOS) {
      this.mailBroadcaster.broadcast(BroadcastKeys.HANDLE_MAIL_LIST_SCROLL, false);
    }
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  loadFolders() {
    let total = this.pageNumber * FOLDERS_PER_PAGE;
    if (total > this.rootUserFolders.length) {
      this.loadedFolders = this.rootUserFolders;
    } else {
      this.loadedFolders = this.rootUserFolders.slice(0, total);
    }
  }

  onUserScroll($event) {
    if ($event.target.scrollTop + $event.target.offsetHeight > $event.target.scrollHeight - 10) {
      this.pageNumber++;
      this.loadFolders();
    }
  }
}
