
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="about_dialog" [style.backgroundImage]="backgroundImage">
  <div class="mail__dialog-header mobile_header">
    <div class="back-header-icon" (click)="close()">
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/arrow-back" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-24px" transform="translate(4.000000, 4.000000)" fill="#fff">
                <polygon id="Shape" points="16 7 3.83 7 9.42 1.41 8 0 0 8 8 16 9.41 14.59 3.83 9 16 9"></polygon>
            </g>
        </g>
      </svg>
    </div>
    <div id="mobile_sidebar_about_header" class="header_lbl disable-select">
      {{ 'APP_INFO' | translate }}
    </div>
    <div class="submit"></div>
  </div>
  <div class="mail__dialog-header desktop_view">
    <div class="submit">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="mail__dialog-body mail__dialog-body--pad">
    <div class="content">
      <div class="vncmail_icon">
        <svg width="96px" height="96px" viewBox="0 0 96 96" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Icon/vnc/mail-new-simplified</title>
          <g id="Icon/vnc/mail-new-simplified" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <circle id="Oval" fill="#0F89F4" fill-rule="nonzero" cx="48" cy="48" r="48"></circle>
              <g id="Group" transform="translate(15.999419, 13.000000)" fill-rule="nonzero">
                  <path d="M45.8885458,42 C48.8964127,42 51.8485783,41.496 54.577939,40.376 L52.7954994,35.392 C50.7902548,36.232 48.4508028,36.68 46.167052,36.68 C37.0320489,36.68 30.9049126,31.136 30.6264064,21.616 C30.3479002,11.76 36.140829,5.264 46.167052,5.264 C52.5726944,5.264 61.4291914,8.512 61.3177889,20.16 C61.2620876,23.632 60.9835814,26.264 58.087117,26.264 C56.2489762,26.264 55.1906526,25.032 55.1906526,22.12 L55.3020551,11.592 L51.1801635,11.592 L51.1801635,12.544 C49.5648275,11.48 47.3924792,10.808 45.4986371,10.808 C39.7614096,10.808 35.3053105,14.784 35.3053105,21.112 C35.3053105,27.272 39.4829034,31.472 45.3872346,31.472 C47.6709854,31.472 50.0104374,30.8 51.7371758,28.896 C53.1297068,30.688 55.5805613,31.64 58.0314158,31.64 C64.3256558,31.64 66.8879127,27.44 66.9993152,20.104 C67.1107177,9.408 59.8695567,0 45.7214421,0 C34.5254931,0 25.0005813,7.224 25.0005813,21.784 C25.0005813,33.432 33.5785721,42 45.8885458,42 Z M45.6612956,26 C42.6612956,26 41.0005813,24.1460674 41.0005813,21 C41.0005813,17.9662921 42.5541528,16 45.8220099,16 C48.5541528,16 50.0005813,17.741573 50.0005813,21 C50.0005813,24.7640449 48.0720099,26 45.6612956,26 Z" id="@" fill="#FFFFFF" opacity="0.5"></path>
                  <path d="M0.499988381,32.4724364 C0.595877026,32.4724364 0.689743996,32.5000046 0.770400753,32.5518613 L20.8633842,45.4702618 C23.9927565,47.4820011 28.0084062,47.4820011 31.1377785,45.4702618 L51.2296025,32.5528236 C51.4618808,32.4034871 51.7712407,32.4707248 51.9205772,32.7030032 C51.9724283,32.7836526 52,32.8775097 52,32.9733891 L52.0005813,57.9895332 C52.0005813,62.4078112 48.4188593,65.9895332 44.0005813,65.9895332 L8.00058134,65.9895332 C3.58230334,65.9895332 0.000581338451,62.4078112 0.000581338451,57.9895332 L0,32.9724481 C0,32.6963057 0.223846006,32.4724364 0.499988381,32.4724364 Z M22.485657,17.0323399 C24.4738672,15.7542048 26.984943,15.6629094 29.0478483,16.7584538 L29.2838317,16.8901298 L29.5155057,17.0323399 L49.0167355,29.5689449 C49.24902,29.7182718 49.3162704,30.027629 49.1669435,30.2599135 C49.1282109,30.3201636 49.0769857,30.3713889 49.0167355,30.4101214 L29.5155057,42.9467264 L29.5155057,42.9467264 L29.2838317,43.0889365 C27.1788762,44.3214239 24.5503368,44.2740206 22.485657,42.9467264 L2.98327245,30.4101288 C2.75098391,30.2608082 2.68372512,29.9514528 2.83304573,29.7191643 C2.8717816,29.6589053 2.92301353,29.6076734 2.98327245,29.5689375 L22.485657,17.0323399 L22.485657,17.0323399 Z" id="Shape" fill="#FEFEFE"></path>
              </g>
          </g>
        </svg>
      </div>
      <h6 class="app_verion_name">
        <div class="logo-title">
            <span class="disable-select dfb_text">{{prefixBold}}</span>
            <span class="product_name disable-select">{{suffixNormal}}</span>
        </div>
      </h6>
      <span class="app_version disable-select">
        {{ 'VERSION_LBL' | translate }} {{ app_version }}
      </span>
      <p id="mobile_sidebar_about_version_changelog" class="change_log">
        <a class="open-new-window disable-select" href="{{changeLog}}" target="_blank">
          <div class="change-log-icon">
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/format-list-bulleted</title>
              <g id="Icon/product/format-list-bulleted" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(2.500000, 4.500000)" fill="#fff">
                      <path d="M1.5,6 C0.67,6 0,6.67 0,7.5 C0,8.33 0.67,9 1.5,9 C2.33,9 3,8.33 3,7.5 C3,6.67 2.33,6 1.5,6 L1.5,6 Z M1.5,0 C0.67,0 0,0.67 0,1.5 C0,2.33 0.67,3 1.5,3 C2.33,3 3,2.33 3,1.5 C3,0.67 2.33,0 1.5,0 L1.5,0 Z M1.5,12.17 C0.76,12.17 0.17,12.77 0.17,13.5 C0.17,14.23 0.77,14.83 1.5,14.83 C2.23,14.83 2.83,14.23 2.83,13.5 C2.83,12.77 2.24,12.17 1.5,12.17 L1.5,12.17 Z M4.5,14.5 L18.5,14.5 L18.5,12.5 L4.5,12.5 L4.5,14.5 L4.5,14.5 Z M4.5,8.5 L18.5,8.5 L18.5,6.5 L4.5,6.5 L4.5,8.5 L4.5,8.5 Z M4.5,0.5 L4.5,2.5 L18.5,2.5 L18.5,0.5 L4.5,0.5 L4.5,0.5 Z" id="Shape"></path>
                  </g>
              </g>
            </svg>
          </div>
          <span style="padding-left:4px ;">{{ 'CHANGELOG' | translate }}</span>
        </a>
      </p>
    </div>
  </div>
</div>