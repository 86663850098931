
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="desktop-header">
        <div class="header-text">{{  'CALENDARS.ADD_EXTERNAL_CALENDER' | translate }}</div>
        <div class="header-icon" (click)="close()" matTooltip="{{ 'CLOSE' | translate }}">
            <ng-container [ngTemplateOutlet]="closeIcon"></ng-container>
        </div>
    </div>
    <div class="content" style="padding: 7px 16px;">
        <div>
            <mat-form-field>
                <mat-label>{{ 'CALENDARS.TYPE_LBL' | translate }}</mat-label>
                <mat-select disableRipple [(ngModel)]="externalType">
                    <mat-option value="caldev">{{ 'CALENDARS.CAL_DEV_ACCOUNT' | translate }}</mat-option>
                    <mat-option value="ical">{{ 'CALENDARS.ICAL_SUBSCRIPTION' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="externalType === 'caldev'">
            <div>
                <mat-form-field class="example-full-width">
                    <mat-label>{{ 'CALENDARS.EMAIL_ADDRESS_LBL' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'CALENDARS.EMAIL_ADDRESS_EXAMPLE_LBL' | translate }}"
                        [(ngModel)]="externalEmail">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="example-full-width">
                    <mat-label>{{ 'CALENDARS.PASSWORD_LBL' | translate }}</mat-label>
                    <input matInput type="password" [(ngModel)]="externalPassword">
                </mat-form-field>

            </div>
            <div>
                <mat-form-field class="example-full-width">
                    <mat-label>{{ 'CALENDARS.CAL_DEV_SERVER' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'CALENDARS.WWW_EXAMPLE_COM_LBL' | translate }}"
                        [(ngModel)]="externalServer">
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="externalType === 'ical'">
            <div>
                <mat-form-field class="example-full-width">
                    <mat-label>{{ 'CALENDARS.ICS_URL_LBL' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'CALENDARS.WWW_EXAMPLE_COM_LBL' | translate }}"
                        [(ngModel)]="externalIcalUrl">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="footer">
        <vnc-footer class="calendar-menu-action" [secondaryLabel]="'CANCEL' | translate | uppercase"
        [primaryLabel]="'CALENDARS.NEXT_LBL' | translate | uppercase"
        [type]="'secondary-primary'"
        [color]="'white'"
        (onSecondaryClick)="close()"
        [ngClass]="{ 'dfb-theme': currentTheme === 'dfb' }"
        (onPrimaryClick)="nextPage()"></vnc-footer>
    </div>
</div>
<ng-template  #closeIcon>
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
        </g>
    </svg>
  </ng-template>