
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, NgZone, Inject, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "../../../../common/enums/broadcast.enum";
import { take, filter, takeUntil } from "rxjs/operators";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { Subject } from "rxjs";
import { ToastService } from "src/app/common/providers/toast.service";
import { ColorControlDialogComponent } from "src/app/mail/color-control-dialog/color-control-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { MailService } from "../../services/mail-service";
import { isArray } from "util";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/reducers/app";
import { getUserProfile } from "src/app/reducers";
import { MailUtils } from "src/app/mail/utils/mail-utils";

@Component({
    selector: "vp-accept-from-mail-dialog",
    templateUrl: "./accept-share-from-mail.component.html"
})
export class AcceptShareFromMailComponent implements OnInit, OnDestroy {
    private isAlive$ = new Subject<boolean>();
    share: any;
    folderTitle: string;
    folderColor: string;
    maxFolderTitleLength: number = 128;
    messageType: string = "1";
    fromAddress: string;
    note: string = "";
    grantorId: string;
    row1: string;
    row2: string;
    currentTheme = localStorage.getItem("theme");
    @ViewChild("folderNameInput", {static: false}) folderNameInput: ElementRef<HTMLInputElement>;
    constructor(
        public dialogRef: MatDialogRef<AcceptShareFromMailComponent>,
        private mailBroadcaster: MailBroadcaster,
        private ngZone: NgZone,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private hotKeyService: NgxHotkeysService,
        private toastService: ToastService,
        private matDialog: MatDialog,
        private translate: TranslateService,
        private mailService: MailService,
        private store: Store<AppState>
    ) {
        this.share = data.share;
        this.grantorId = data.grantorId;

        setTimeout(() => {
            if (this.folderNameInput) {
                this.folderNameInput.nativeElement.focus();
            }
        }, 200);

        this.store.select(getUserProfile).pipe(filter(v => !!v), take(1)).subscribe(res => {
            console.log("[getUserProfile]", res);
            if (res.email) {
                this.checkEmailArray(res.email);
            } else {
                this.checkEmailArray(MailUtils.getEmailFromStorage());
            }
        });

        const folderPath = this.share.folderPath;
        this.translate.get("FOLDER_WITH_YOU_SHARE", {
            ownerEmail: this.share.grantorEmail,
            folderName: this.share.name
        }).pipe(take(1)).subscribe(res => {
            this.row1 = res;
        });
        let grantLabel = "GRANTED_YOU";
        if (this.getRole().toLowerCase() === "view") {
            grantLabel = "GRANTED_YOU_VIEW";
        } else if (this.getRole().toLowerCase() === "manager") {
            grantLabel = "GRANTED_YOU_MANAGER";
        } else if (this.getRole().toLowerCase() === "admin") {
            grantLabel = "GRANTED_YOU_ADMIN";
        }
        this.translate.get(grantLabel, {
            role: this.getRole(),
            action: this.getAction()
        }).pipe(take(1)).subscribe(res => {
            this.row2 = res;
        });
    }

    ngOnInit() {
        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_ACCEPT_FOLDER_DIALOG).pipe(take(1)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        this.folderTitle = this.share.grantorName + "`s " + this.share.name;
    }

    close(value?: boolean) {
        this.dialogRef.close({ accept: value , folderName: this.folderTitle});
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.hotKeyService.unpause(this.hotKeyService.hotkeys);
    }

    updateDefaultColor(event) {
        this.folderColor = event.value;
    }

    openColorDialog() {
        const changeColorDialogRef = this.matDialog.open(ColorControlDialogComponent, {
            height: "auto",
            maxHeight: "70%",
            width: "99%",
            maxWidth: "244px",
            autoFocus: false,
            panelClass: "vp-color-control-dialog",
            data: { folderColor: this.folderColor }
        });
        changeColorDialogRef.afterClosed().subscribe(operation => {
            if (!!operation && operation !== null && operation.selectedColor) {
                this.folderColor = operation.selectedColor;
            }
        });
    }

    checkEmailArray(emails): void {
        if (isArray(emails)) {
            this.fromAddress = emails[0];
        } else if (emails) {
            this.fromAddress = emails;
        }
    }

    acceptFolder(): void {
        const body = {
            l: 1,
            name: this.folderTitle,
            zid: this.share.grantorId,
            rid: this.share.rid,
            view: this.share.view,
            color: this.folderColor
        };
        this.mailService.createMountPoint(body).pipe(take(1)).subscribe(res => {
            if (this.messageType === "1") {
                const createEmail = this.createEmail();
                this.sendEmail(
                    createEmail.subject,
                    createEmail.htmlContent,
                    createEmail.plainContent,
                    createEmail.xmlShare
                );
            } else if (this.messageType === "3") {
                const createEmail = this.createEmail();
                this.sendEmail(
                    createEmail.subject,
                    createEmail.htmlContent,
                    createEmail.plainContent,
                    createEmail.xmlShare
                );
            } else {
                this.close(true);
            }
        }, err => {
            if (err.indexOf("object with that name already exists") !== -1) {
                this.translate.get("SHARE_ALREADY_EXISTS_ERROR_MSG").pipe(take(1)).subscribe( res => {
                    this.toastService.showPlainMessage(res + " " + this.share.name + " - " + this.share.grantorName);
                });
            } else {
                this.toastService.show(err);
            }
        });
    }

    createEmail(): any {
        const folderName = this.share.name;
        let plainText = "";
        this.translate.get("ACCEPT_SHARE_PLAIN_TEXT", {
            granteeName: this.share.granteeName,
            folderName: folderName,
            ownerName: this.share.grantorEmail,
            action: this.getAction(),
            role: this.getRole()
        }).pipe(take(1)).subscribe((text: string) => {
            plainText = text;
        });

        let htmlContent = "";
        this.translate.get("ACCEPT_SHARE_HTML_TEXT", {
            granteeName: this.share.granteeName,
            folderName: folderName,
            ownerName: this.share.grantorEmail,
            action: this.getAction(),
            role: this.getRole()
        }).pipe(take(1)).subscribe((text: string) => {
            htmlContent = text;
        });

        if (this.note !== "" && this.note !== undefined && this.messageType === "3") {
            plainText = plainText + "\n*~*~*~*~*~*~*~*~*~*\n" + this.note;

            htmlContent = htmlContent +
                "<table border='0' cellpadding='3' cellspacing='0' style='color:#333;font:1em sans-serif;'>" +
                "<tr valign='top'><th align='right'>Notes:</th><td>" + this.note + "</td></tr>" +
                "</table>";
        }

        const xmlShare = "<share xmlns='urn:zimbraShare' version='0.2' action='accept' >" +
            "<grantee id=\"" + this.share.granteeId + "\" email=\"" + this.share.granteeName + "\" name=\"" +
            this.share.granteeName + "\" />" +
            "<grantor id=\"" + this.share.grantorId + "\" email=\"" + this.share.grantorEmail + "\" name=\"" +
            this.share.grantorEmail + "\" />" + "<link id=\"" + this.share.rid + "\" name=\"" +
            folderName + "\" view=\"message\" perm=\"" + this.share.perm + "\" />" +
            "<notes>" + this.note + "</notes>" +
            "</share>";

        let subject = "";
        this.translate.get("ACCEPTED_SHARE_SUBJECT", {
            grantor: this.share.grantorEmail,
            folderName: folderName
        }).pipe(take(1)).subscribe((text: string) => {
            subject = text;
        });
        return { plainText: plainText, htmlContent: htmlContent, xmlShare: xmlShare, subject: subject };
    }

    sendEmail(subject: string, htmlContent: string, plainContent: string, shareContent: string, ) {
        const request = {
            su: subject,
            content: htmlContent,
            e: [
                {
                    a: this.share.grantorEmail,
                    t: "t"
                },
                {
                    a: this.fromAddress,
                    t: "f"
                }
            ],
            mp: [
                {
                    "@": {
                        ct: "multipart/alternative"
                    },
                    mp: [
                        {
                            "@": {
                                ct: "text/plain"
                            },
                            content: plainContent
                        },
                        {
                            "@": {
                                ct: "text/html"
                            },
                            content: htmlContent
                        },
                        {
                            "@": {
                                ct: "xml/x-zimbra-share"
                            },
                            content: shareContent
                        }
                    ]
                }
            ]
        };

        const sendRequest = {
            SendMsgRequest: {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                m: request
            }
        };

        this.mailService.createBatchRequest(sendRequest).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.close(true);
        });
    }

    getRole(): string {
        let role = "";
        switch (this.share.perm) {
            case "":
                break;
            case "r":
                role = "View";
                break;
            case "rwidx":
                role = "Manager";
                break;
            case "rwidxa":
                role = "Admin";
                break;
        }
        return role;
    }

    getAction(): string {
        let action = "";
        switch (this.share.perm) {
            case "":
                action = "None";
                break;
            case "r":
                action = "View";
                break;
            case "rwidx":
                action = "View, Edit, Add, Remove";
                break;
            case "rwidxa":
                action = "View, Edit, Add, Remove, Administrator";
                break;
        }
        return action;
    }
}
