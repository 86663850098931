

/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ChangeDetectorRef, OnInit, HostListener, NgZone, OnDestroy, AfterViewChecked, Renderer2 } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { environment } from "../environments/environment";
import { ConfigService } from "./config.service";
import { AuthService } from "./common/providers/auth.service";
import { take, filter, distinctUntilChanged, takeUntil, combineLatest, takeWhile, sampleTime, bufferTime, debounceTime, skip } from "rxjs/operators";
import { MailService } from "./mail/shared/services/mail-service";
import { MailConstants } from "./common/utils/mail-constants";
import * as moment from "moment";
import {
  LoginSuccess,
  LoginFailed,
  OnlineStatus,
  SetUserProfile,
  SetPollingInterval,
  SetSession,
  ChangeSwipeAction,
  SetAvailableApps,
  SetAvailableZimlets,
  SetViewBy,
  SetReadingPanel,
  SetZimbraFeatures,
  ResetLastPhotoUpdate,
  SetDomainSpecificLogo,
  SetSearchFor,
  SetIncludeShared,
  DeviceReady,
  SetExpandMailFolders,
  SetIncomingFilters,
  SetUserContacts,
  SetGalContacts,
  SetUserSignatures,
  SetFolderTreeSash,
  SetPaneSashVertical,
  SetPaneSashHorizontal,
  SetLoadInsecureContent,
  SetShowGlobalTags,
  SetActiveProfile,
  SetRightSidebarStatus
} from "./actions/app";
import { isNullOrUndefined, isArray } from "util";
import { ActivationEnd, Router } from "@angular/router";
import { MailBroadcaster } from "./common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "./common/enums/broadcast.enum";
import * as QuillNamespace from "quill";
import { PreferenceService } from "./preference/shared/services/preference.service";
import { MailRootState } from "./mail/store";
import { timer, Subject, Observable, BehaviorSubject } from "rxjs";
import {
  getFolderById, getConversationsByIds, getMailFolders,
  getSharedFolderById, getTagById, getConversationById, getMessageById, getSealedDataById } from "./mail/store/selectors";
import { MailFolder } from "./mail/models/mail-folder.model";
import { MailUtils } from "./mail/utils/mail-utils";
import { Conversation, Message, SearchRequest } from "./mail/shared/models";
import { SearchConvRequest } from "./mail/shared/models/search-conv-request.model";
import { VNCNotificationsService } from "./shared/components/notifications";

import {
  getUserProfile, getIsLoggedIn, getActionProcessingState, getOnlineStatus,
  getPollingInterval, getSession, getCurrentFolder, getViewBy, getProps,
  getWaitDisallowed, getAllCalendarAppointments, getCalendarAppointmentsByIds, RootState,
  getCalendarFolders, getCalendarFolderById, getSharedCalendarFolderById, getZimbraFeatures, IsDatabaseReady, getVNCContacts } from "./reducers";
import { UpdateMailFolderSuccess, UpdateManyConversationsSuccess, CreateMailFolderSuccess,
  LoadConversationSuccessAction,
  UpdateMailTagSuccess,
  DeleteMultipleMailTag,
  CreateMailTagSuccess,
  LoadMailSuccessAction,
  UpdateManyMessagesSuccess,
  RemoveMessagesSuccess,
  RemoveManyMessages,
  RemoveManyConversations,
  UpdateConversationSuccess,
  UnSelectConversationsAction,
  DeleteMailFolderSuccess,
  UpdateSealedData,
  RemoveMultipleTabs} from "./mail/store/actions";
import * as _ from "lodash";
import { CommonUtils } from "./common/utils/common-util";
import { MailTag } from "./mail/models/mail-tag.model";
import { ElectronService } from "./services/electron.service";
import { OutOfOfficeAlertComponent } from "./mail/shared/components/out-of-office-alert-dialog/out-of-office-alert.component";
import { Preference } from "./preference/shared/models";
import { AppService } from "./services/app.service";
import { IdbUtils } from "src/app/services/idb.service";
import * as Raven from "@sentry/browser";
// import * as Raven from "raven-js";
import * as Sentry from "sentry-cordova";
import {
  getBriefcaseChildFolderById,
  getBriefcaseFolders,
  getBriefcaseFolderById,
  getBriefcaseSharedFolderById
} from "./briefcase/store/selectors";
import {
  UpdateBriefcaseFolderSuccess,
  CreateBriefcaseFolderSuccess,
  CreateBriefcaseSuccess,
  UpdateManyBriefcaseFileSuccess
} from "./briefcase/store/actions";
import { BriefcaseFolder } from "./briefcase/shared/models/briefcase-folder";
import { BriefcaseService } from "./briefcase/services/briefcase.service";
import { ErrorService } from "./common/providers/error-service";
import { ErrorType } from "./common/enums/mail-enum";
import { CalenderUtils } from "./calendar/utils/calender-utils";
import { LoadALLContactsSuccess } from "./actions/all-contacts.action";
import { Contact } from "./contacts/models/contact.model";
import { LoadAllGalContactsSuccess } from "./actions/all-gal-contacts.action";
import { BreakpointObserver } from "@angular/cdk/layout";
import {
  CreateCalendarAppointmentSuccessAction,
  DeleteCalendarAppointmentSuccessAction,
  DeleteCalendarAppointmentsSuccessAction,
  DeleteCalendarFolderSuccess,
  CreateCalendarFolderSuccess,
  UpdateCalendarFolderSuccess,
  UpdateCalendarAppointmentSuccessAction
} from "./actions/calendar.actions";
import { CalendarRepository } from "./calendar/repositories/calendar.repository";
import { CommonService } from "./services/ common.service.";
import { CalendarFolder, CalendarAppointment } from "./common/models/calendar.model";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { PreferenceRepository } from "./preference/repositories/preference.repository";
import { DatabaseService } from "./services/db/database.service";
import { ZimbraFeatures } from "src/app/common/utils/zimbra-features";
import {
  AppointmentInviteReplyDialogComponent
} from "./shared/components/event-invite-operation-dialog/event-invite-operation-dialog.component";
import { ConversationRepository } from "./mail/repositories/conversation.repository";
import { ToastService } from "./common/providers/toast.service";
import { DirectoryTag } from "./mail/models/directory-tag.model";
import { LoadDirectoryTagsFail, LoadDirectoryTagsSuccess, LoadMoreDirectoryTagsSuccessAction } from "./actions/directory-tag.action";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import TableModule from "./shared/quill/table";
import TableCell from "./shared/quill/table/TableCellBlot";
import TableRow from "./shared/quill/table/TableRowBlot";
import Table from "./shared/quill/table/TableBlot";
import ContainBlot from "./shared/quill/table/ContainBlot";
import { BulkLoadVNCContacts, SetVNCContactsList } from "./actions/contact.action";
import { ProfileDetailDialogComponent } from "./shared/components/profile-detail-dialog/profile-detail-dialog.component";
import { CommonRepository } from "./mail/repositories/common-repository";
import { VNCActionWheelMenuService, VncLibraryService } from "vnc-library";
import { SetContactsList } from "./contacts/store";
import { ContactService } from "./contacts/services/contact-service";
import { ConfirmArgs } from "src/app/mail/models/confirm-args";
import { MailOperations } from "src/app/common/enums/mail-enum";
import { ConfirmDialogComponent } from "src/app/mail/confirm-dialog/confirm-dialog.component";
import { EditAppointmentDialogComponent } from "src/app/calendar/edit-appointment/edit-appointment-dialog.component";
import { AppointmentPreviewCommonDialogComponent } from "src/app/calendar/appointment-preview-common-dialog/appointment-preview-common-dialog.component";
import { ModifyRecurAppointmentDialogComponent } from "src/app/shared/components/modify-recur-appointment-dialog/modify-recur-appointment-dialog.component";

const CALENDARCONSTANT: any = {
  DEFAULT_FOLDER_COLOR: 1,
  COLOR_CODES: [
    null,
    "BLUE",
    "CYAN",
    "GREEN",
    "PURPLE",
    "RED",
    "YELLOW",
    "PINK",
    "GRAY",
    "ORANGE"
  ],
  RGB_CODES: [
    "#999999", // GRAY
    "#42a5f6", // BLUE
    "#00BCD4", // CYAN
    "#66bb6a", // GREEN
    "#5B69C3", // PURPLE
    "#F1524A", // RED
    "#ef6c00", // YELLOW
    "#E91E63", // PINK
    "#999999", // GRAY
    "#ffa500"  // ORANGE
  ]
};

@Component({
  selector: "vp-app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  title = "VNCmail";
  isOnCordova = environment.isCordova;
  isCordovaOrElectron = environment.isCordova || environment.isElectron;
  isActionInProgress: boolean = false;
  isLoggedIn: boolean = false;
  secondInstancePopupShown: boolean = false;
  isDatabaseReady: boolean = false;
  options: any = {
    timeOut: 5000,
    lastOnBottom: true,
    clickToClose: true,
    maxLength: 60,
    maxStack: 5,
    showProgressBar: false,
    pauseOnHover: true,
    preventDuplicates: true,
    preventLastDuplicates: "visible",
    rtl: false,
    animate: "scale",
    position: ["right", "top"]
  };
  noOpPolling$: any;
  isOnline: {};
  interval: number;
  currentUser: any;
  private isAlive$ = new Subject<boolean>();
  private syncRequestStart = new BehaviorSubject<number>(0);
  private bgSyncMessagesStart = new BehaviorSubject<number>(0);
  private bgSyncMessagesDone = new BehaviorSubject<number>(0);
  private bgMetaSyncDone = new Subject<any>();
  private noOpTrigger = new Subject<any>();
  private resyncElectron = new Subject<any>();
  bgSyncInProgress = false;
  showSpinner = false;
  checkAuthenticationInterval: any;
  allFolders: MailFolder[] = [];
  currentFolderId: any;
  currentView = !!localStorage.getItem("currentView") ? localStorage.getItem("currentView") : "conversation";
  isUpdatedConv: boolean = false;
  isFirebaseSetUpCompleted = false;
  avatarPolling$: any;
  noOpRunning: boolean;
  nextNoOpTimeout: any;
  isMobileScreen: boolean = false;
  alarmEventQueue: CalendarAppointment[] = [];
  eventQueueTimer: any;
  events: CalendarAppointment[] = [];
  updateNoOp: any;
  recoverNoOp: number;
  lastNoOpStamp: number;
  lastNoOpResync: number;
  lastBGsyncQueryOffset: number = 0;
  waitDisallowed: boolean = false;
  syncRequestDone: boolean = false;
  updateMessagesNotInCurrentFolderIds: string[] = [];
  lockForOpenFromNotificationOrWidget: boolean = false;
  isProcessingSync: boolean = true;
  calendarOnly = environment.calendarOnly;
  hideHeader: boolean = false;
  constructor(
    private renderer: Renderer2,
    private auth: AuthService,
    private changeDetectionRef: ChangeDetectorRef,
    public configService: ConfigService,
    private contactService: ContactService,
    private broadcaster: MailBroadcaster,
    private router: Router,
    private snackBar: MatSnackBar,
    private vncLibraryService: VncLibraryService,
    public dialog: MatDialog,
    private ngZone: NgZone,
    private wheelActionService: VNCActionWheelMenuService,
    private preferenceRepo: PreferenceRepository,
    private preferenceService: PreferenceService,
    private notificationsService: VNCNotificationsService,
    private store: Store<MailRootState | RootState>,
    private matIconRegistry: MatIconRegistry,
    private mailService: MailService,
    private electronService: ElectronService,
    private appService: AppService,
    private matDialog: MatDialog,
    private databaseService: DatabaseService,
    private briefcaseService: BriefcaseService,
    private errorService: ErrorService,
    private breakpointObserver: BreakpointObserver,
    private calendarRepository: CalendarRepository,
    private convRepository: ConversationRepository,
    public commonService: CommonService,
    private toastService: ToastService,
    private commonRepository: CommonRepository,
    private translateService: TranslateService,
    private actionWheelMenuService: VNCActionWheelMenuService
  ) {
    if (localStorage.getItem("theme") === "carbon") {
      const body = document.querySelector("body");
      body.classList.add("active-dark-theme");
    }
    console.log("[AppComponent] constructor", JSON.stringify(window.location));
    this.actionWheelMenuService.getData().subscribe(v => {
      console.log("[AppComponent] actionWheelMenuService", v);
    });
    this.matIconRegistry.registerFontClassAlias("mdi");
    if (localStorage.getItem("federatedApps") !== null) {
      const federatedApps = JSON.parse(localStorage.getItem("federatedApps"));
      this.store.dispatch(new SetAvailableApps(federatedApps));
    }
    if (localStorage.getItem("getSearchFor") !== null) {
      try {
        this.store.dispatch(new SetSearchFor(localStorage.getItem("getSearchFor")));
      } catch (err) {
      }
    }

    if (environment.isCordova) {
      document.addEventListener("deviceready", this.onDeviceReady.bind(this), false);
    }
    // make sure that we do not block sync due to remnants when client was ended during sync
    // better: keep it for next sync - as obviously last sync was interrupted
    // localStorage.removeItem("lastMessageTimestamp");

    this.checkInternetConnection();
    this.setupOfflineData();
    this.handlePollingTimeout();
    this.store.select(getUserProfile).pipe(filter(v => !!v)).subscribe(res => {
      if (!!res) {
        this.currentUser = res;
      }
    });
    this.recoverNoOp = 0;
    this.lastNoOpResync = 0;
    this.store.select(getViewBy).pipe(takeUntil(this.isAlive$)).subscribe(value => {
      if (this.currentView !== value) {
        this.currentView = value;
      }
    });

    this.broadcaster.on<any>(BroadcastKeys.DETECT_CHANGES).subscribe(val => {
      this.changeDetectionRef.detectChanges();
    });

    this.broadcaster.on<any>("HIDE_SYNC_SPINNER").subscribe(val => {
      this.showSpinner = false;
      this.changeDetectionRef.detectChanges();
    });


    // for debugging / syncing lots of data - enable startOldSync() in header.component.html
    this.broadcaster.on<any>("STARTRESYNC").subscribe(() => {
      this.showSpinner = true;
      this.changeDetectionRef.detectChanges();
      if (CommonUtils.isOnAndroid()) {
        this.backgroundSyncLastMessages();
      } else {
        if (CommonUtils.isOfflineModeSupported()) {
          this.syncLastMessages();
        }
      }
    });

    this.store.pipe(select(getOnlineStatus), filter(v => !v), takeUntil(this.isAlive$)).subscribe(val => {
      console.log("[getOnlineStatus]", val);
      localStorage.setItem("lastTimeOnline", new Date().getTime().toString());
    });


    this.noOpTrigger.pipe(distinctUntilChanged(), filter(v => !!v), bufferTime(1000), takeUntil(this.isAlive$))
      .pipe(filter(v => v.length > 0))
      .subscribe(nval => {
        if ((window.location !== window.parent.location) && !this.isCordovaOrElectron) {
          console.log("nooptrigger: this.window.location: ", this.router.url);
          if ((this.router.url.startsWith("/calendar")) || (this.router.url.startsWith("/briefcase"))) {
            return;
          }
        }
        // ToDo Review: maybe don't call noOp before syncRequest is done?
        let force = false;
        nval.forEach(v => {
          if (!!v.force) {
            force = true;
          }
        });
        if (this.databaseService.dbReady) {
          this.databaseService.getAllPendingOperations().subscribe(ops => {
             console.log("[noOpTrigger][getAllPendingOperations] ops: ", ops);
            if (ops.length === 0) {
              this.noOp(force);
            } else {
              if (this.isOnline) {
                setTimeout(() => {
                  this.noOpTrigger.next({ts: Date.now(), force: force});
                  this.convRepository.processPendingOps().pipe(take(1)).subscribe(res => {
                    console.log("processedPending res: ", res);
                  });
                }, 2000);
              }
            }
          });
        }

      });

    this.resyncElectron.pipe(distinctUntilChanged(), filter(v => !!v), bufferTime(2000), takeUntil(this.isAlive$))
      .pipe(filter(v => v.length > 0))
      .subscribe(nval => {
        if (this.isOnline) {
          this.commonService.getConfigurations().pipe(take(1)).subscribe(() => {
            // we are online and have actually access to the server
            let syncToken: any = localStorage.getItem("syncRequestToken");

            console.log("[AppComponent] syncRequest, syncToken:", syncToken);
            this.electronService.remoteLog("[AppComponent] syncRequest, syncToken:", syncToken);
            if (!!syncToken) {
              this.showSpinner = true;

              this.convRepository.syncRequest(syncToken).subscribe(res => {
                console.log("[AppComponent] syncRequest, res: ", res);
                this.electronService.remoteLog("[AppComponent] syncRequest, res: ", res);
                this.processSyncRequestData(res);
              }, err => {
                console.error("[AppComponent] syncRequest1 error: ", err);
                this.electronService.remoteLog("[AppComponent] syncRequest1 error: ", err);
                this.convRepository.syncRequestResync().subscribe(res => {
                  console.log("[AppComponent] syncRequestAfterTimestamp, res: ", res);
                  this.electronService.remoteLog("[AppComponent] syncRequestAfterTimestamp, res: ", res);

                  this.processSyncRequestData(res);

                }, err => {
                  console.error("[AppComponent] syncRequest1 resync error: ", err);
                  this.electronService.remoteLog("[AppComponent] syncRequest1 resync error: ", err);
                });
              });
            }
          }, err => {
            console.log("[error validating online state] ", err);
            // try again in 5 seconds to allow WiFi to connect
            setTimeout(() => {
              const nts = Date.now();
              this.resyncElectron.next(nts);
            }, 5000);
          });
        } else {
          // try again in 5 seconds to allow WiFi to connect
          setTimeout(() => {
            const nts = Date.now();
            this.resyncElectron.next(nts);
          }, 5000);
        }
      });

    const isOnline$ = this.store.pipe(select(getOnlineStatus), filter(v => !!v), takeUntil(this.isAlive$));
    const isLoggedIn$ = this.store.pipe(select(getIsLoggedIn), filter(v => !!v), takeUntil(this.isAlive$));
    const isDatabaseReady$ = this.store.pipe(select(IsDatabaseReady), filter(v => !!v), takeUntil(this.isAlive$));
    isOnline$.pipe(combineLatest(isLoggedIn$, isDatabaseReady$)).subscribe(res => {
      console.log("[AppComponent] Logged in and online", res);

      this.isLoggedIn = true;
      this.isDatabaseReady = true;
      this.isOnline = true;
      this.noOpRunning = false;
      this.changeDetectionRef.markForCheck();
      this.initData();
      this.handlePolling();
      // this.convRepository.processPendingOperations().subscribe(res => {
      this.convRepository.processPendingOps().subscribe(res => {
        console.log("[AppComponent] processPendingOperations res", res);

        this.handlePolling();

        console.log("[AppComponent] REFRESH_BOTH");
        this.broadcaster.broadcast("REFRESH_FOLDERS");
        this.broadcaster.broadcast("REFRESH_ONE_PAGE");
      });

      localStorage.removeItem("lastTimeOnline");

      // get changes
      //
      let syncToken: any = localStorage.getItem("syncRequestToken");
      console.log("[AppComponent] syncRequest, syncToken:", syncToken);
      this.electronService.remoteLog("[AppComponent] syncRequest, syncToken:", syncToken);


      if (!!syncToken) {
        // rationale / explaination:
        // see https://files.zimbra.com/docs/soap_api/8.8.15/api-reference/zimbraMail/Sync.html
        //
        // using stored syncToken is fine - but (when the instance that stored the token is offline for a while) the
        // token can become invalid. When this happens the service returns an error that was not previously handled.
        // if the syncToken becomes invalid, a resync (w/o token) is required.
        this.syncRequestStart.next(Date.now());
        this.showSpinner = true;
        this.convRepository.syncRequest(syncToken).subscribe(res => {
          console.log("[AppComponent] startup syncRequest, res: ", res);
          if (environment.isCordova) {
            console.log("[AppComponent] startup syncRequest, goint to resync inbox ");
            this.convRepository.resyncMessagesFolder(MailConstants.SEARCH_CRITERIA.IN_INBOX, "/Inbox").subscribe(() => {
              this.processSyncRequestData(res);
            });
          } else {
            this.processSyncRequestData(res);
          }
        }, err => {
          console.error("[AppComponent] syncRequest1 error: ", err);
          this.convRepository.syncRequestResync().subscribe(res => {
            console.log("[AppComponent] syncRequestAfterTimestamp, res: ", res);

            this.processSyncRequestData(res);

          }, err => {
            console.error("[AppComponent] syncRequest1 resync error: ", err);
          });
        });
      } else {
        // without stored syncToken we need an initial sync where we sync 5 minutes;
        // a sync longer back in time does not help here - we just want to init
        // syncToken
        const sDate = Math.floor(new Date().getTime() / 1000) - 300;
        this.convRepository.syncRequestResync(sDate).subscribe(res => {
          console.log("[AppComponent] syncRequestAfterTimestamp, res: ", res);

          this.processSyncRequestData(res);

          setTimeout(() => {
            console.log("[AppComponent] noop REFRESH");
            this.broadcaster.broadcast(MailConstants.REFRESH_CONVS_LIST_SYNC);
          }, 4000);
        }, err => {
          console.error("AppComponent syncRequest resync error: ", err);
          setTimeout(() => {
            console.log("[AppComponent] noop REFRESH");
            this.broadcaster.broadcast(MailConstants.REFRESH_CONVS_LIST_SYNC);
          }, 2000);
        });
      }

      try {
        let pendingElectronOps = this.electronService.getFromStorage("openUri");
        console.log("found pendingElectronOps: ", pendingElectronOps);
        if (!!pendingElectronOps) {
          if (pendingElectronOps.startsWith("compose")) {
            pendingElectronOps = pendingElectronOps.replace(/\//g, "");
            const urlParams = new URLSearchParams(pendingElectronOps.split("compose?")[1]);
            console.log("[app.component][openUri] urlParams: ", urlParams);
            console.log("[app.component][openUri] urlParams has contact ", urlParams.has("contact_id"));
            console.log("[app.component][openUri] urlParams has to: ", urlParams.has("to"));
            let mailAddress;
            if (urlParams.has("to")) {
              mailAddress = urlParams.get("to");
            }
            let params = { to: mailAddress };
            if (urlParams.has("contact_id")) {
              params["contact_id"] = urlParams.get("contact_id");
            }

            this.electronService.deleteFromStorage("openUri");
            this.router.navigate(["/mail/compose"], { queryParams: params });

          }
          if (pendingElectronOps.startsWith("cal-ev-create")) {
            this.router.navigate(["/calendar"]);
            const mailAddress = pendingElectronOps.split("?to=")[1];
            setTimeout(() => {
              this.electronService.deleteFromStorage("openUri");
              this.broadcaster.broadcast("OPEN_APPOINTMENT_FULL_COMPOSE_FOR_ATEENDEE", {
                to: mailAddress
              });
            }, 2000);
          }
          if (pendingElectronOps.startsWith("main/calendar")) {
            this.router.navigate(["/calendar"]);
          }
          if (pendingElectronOps.startsWith("mail")) {
            setTimeout(() => {
              this.electronService.deleteFromStorage("openUri");
              this.router.navigate(["/" + pendingElectronOps]);
            }, 2000);
          }
        }
      } catch (e) {
        console.log("error: ", e);
      }
    });

    this.store.pipe(select(getIsLoggedIn), distinctUntilChanged()).subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        this.setPollingTimer();
      } else {
        if (this.noOpPolling$) {
          console.log("[handlePolling] noOp unsubscribe", new Date());
          clearInterval(this.noOpPolling$);
        }
      }
      this.changeDetectionRef.markForCheck();
    });

    this.store.pipe(select(getCurrentFolder)).subscribe(id => {
      // console.log("[AppComponentgetCurrentFolder]", id);

      this.currentFolderId = id;
      this.changeDetectionRef.markForCheck();
    });

    this.store.select(getActionProcessingState).subscribe(actionInProgress => {
      setTimeout(() => {
        this.isActionInProgress = actionInProgress;
        this.changeDetectionRef.markForCheck();
      }, 200);
    });


    // restore tokens
    //
    let token, serverURL;
    token = localStorage.getItem("token");
    serverURL = localStorage.getItem("serverURL");
    if (this.isCordovaOrElectron && !token && !!serverURL) {
      this.isLoggedIn = false;
      this.configService.loginIframe();
      return;
    } else if (!!token) {
      this.store.dispatch(new LoginSuccess());
      this.isLoggedIn = true;
      this.appService.getOICAPIToken();
      this.changeDetectionRef.markForCheck();
    }

    this.store.pipe(select(getOnlineStatus), distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(isOnline => {
      this.isOnline = isOnline;
      if (!isOnline) {
        console.log("[handlePolling] unsubscribe", new Date());
        this.setPollingTimer();
      }
      if (!isOnline && this.avatarPolling$) {
        console.log("[handlePolling] unsubscribe", new Date());
        this.avatarPolling$.unsubscribe();
      }
    });

    this.setSwipeConfig();

    this.broadcaster.on<any>("markForCheck").subscribe(val => {
      console.log("[markForCheck]");
      setTimeout(() => {
        this.changeDetectionRef.markForCheck();
        console.log("[markForCheck] 2");
      }, 1000);
    });

    this.broadcaster.on<any>(MailConstants.FORCE_SYNC_DATA).subscribe(val => {
      console.log("[AppComponent][FORCE_SYNC_DATA] REFRESH noOp");

      this.noOpTrigger.next({ts: Date.now(), force: true});
      // this.noOp(true);
      this.broadcaster.broadcast("REFRESH_FOLDERS");
      this.broadcaster.broadcast("REFRESH_ONE_PAGE");
    });

    this.broadcaster.on<any>("generateNewToken").pipe(distinctUntilChanged()).subscribe(val => {
      console.log("[generateNewToken] from backend");
    });
    this.broadcaster.on<any>("RE_GENERATE_USER_CONTACTS").pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.getUserContacts();
    });
    this.broadcaster.on<any>("UPDATE_INCOMING_FILTER_LIST").pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.getIncomigFilters();
    });
    this.broadcaster.on<any>("UPDATE_USER_SIGNATURES").pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.getUserSignatures();
    });

    this.broadcaster.on<any>("registerShortcuts").pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.registerShortcuts();
    });
    this.broadcaster.on<any>("unRegisterShortcuts").pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.unRegisterShortcuts();
    });
    this.bgSyncMessagesStart.pipe(debounceTime(200), filter(v => v > 0), distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(ts => {
      let syncRequestDone = this.convRepository.getSyncRequestDone();
      console.log("[AppComponent] backgroundSyncMessagesStart messages start with", ts, this.bgSyncMessagesDone.value, this.bgSyncInProgress, this.syncRequestStart.value, window.appInBackground, syncRequestDone);
      if (((ts - this.bgSyncMessagesDone.value) > 20000) || (this.bgSyncMessagesDone.value === 0) || !window.appInBackground ) {
        // lock
        this.bgSyncInProgress = true;
        this.showSpinner = true;
        let syncToken: any = localStorage.getItem("syncRequestToken");
        console.log("[AppComponent] backgroundSync syncRequest, syncToken:", syncToken);

        if (!!syncToken) {
          // rationale / explaination:
          // see https://files.zimbra.com/docs/soap_api/8.8.15/api-reference/zimbraMail/Sync.html
          //
          // using stored syncToken is fine - but (when the instance that stored the token is offline for a while) the
          // token can become invalid. When this happens the service returns an error that was not previously handled.
          // if the syncToken becomes invalid, a resync (w/o token) is required.
          this.syncRequestStart.next(Date.now());
          this.convRepository.backgroundSyncRequest(syncToken).subscribe(res => {
            console.log("[AppComponent] backgroundSync syncRequest, res: ", res);
            this.mobileProcessSyncRequestData(res);
          }, err => {
            console.error("[AppComponent] backgroundSync syncRequest, bgMetaSyncDone , error: ", err);
            // ToDo: recheck handling of invalid token -> cleanup DB, resync
            let nts = new Date().getTime();
            this.bgMetaSyncDone.next(nts);
            this.bgSyncInProgress = false;
            this.changeDetectionRef.markForCheck();
          });
        } else {
          console.log("[AppComponent] bgMetaSyncDone - no syncToken");
          // ToDo: init sync token?
          if (!window.appInBackground) {
            this.syncRequestStart.next(Date.now());
            cordova.plugin.http.clearCookies();
            this.convRepository.backgroundSyncRequest().subscribe(res => {
              console.log("[AppComponent] backgroundSync syncRequest, res: ", res);
              this.mobileProcessSyncRequestData(res);
            }, err => {
              console.error("[AppComponent] backgroundSync syncRequest, bgMetaSyncDone , error: ", err);
              let nts = new Date().getTime();
              this.bgMetaSyncDone.next(nts);
              this.bgSyncInProgress = false;
              this.changeDetectionRef.markForCheck();

            });

          } else {
            let nts = new Date().getTime();
            this.bgMetaSyncDone.next(nts);
            this.bgSyncInProgress = false;
            this.changeDetectionRef.markForCheck();
          }

        }


      } else {
        cordova.plugins.backgroundMode.isScreenOff((screenState) => {
          if (window.appInBackground) {
            console.log("BACKGROUNDSCREENSTATE in background: ", screenState);
          } else {
            console.log("BACKGROUNDSCREENSTATE not in background: ", screenState);
            if (!screenState) {
              setTimeout(() => {
                // REFRESH_BROADCAST
                if (!window.appInBackground) {
                  console.log("calling REFRESH_ONE_PAGE_SYNC_MOBILE ", window.appInBackground);
                  this.noOpTrigger.next({ ts: Date.now(), force: false });
                  this.broadcaster.broadcast("REFRESH_ONE_PAGE_SYNC_MOBILE");
                }
              }, 500);
            }
          }
        });
      }

    });
    this.bgSyncMessagesDone.pipe(filter(v => v > 0), distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(ts => {
      console.log("[AppComponent] backgroundSync metadata start ", ts, window.appInBackground, this.bgSyncInProgress);
      this.bgMetaSyncDone.next(ts);
      this.bgSyncInProgress = false;
      this.showSpinner = false;
      this.changeDetectionRef.markForCheck();
      if (!window.appInBackground) {
        if (this.isOnline && navigator.onLine) {
          setTimeout(() => {
            // this.syncData(true);
            this.handlePolling();
          }, 2000);
        } else {
          console.log("[resume] offline?", new Date());
          setTimeout(() => {
            this.broadcaster.broadcast("REFRESH_FOLDERS");
            this.broadcaster.broadcast("REFRESH_ALL");
          }, 1000);
        }
      }
    });

    this.bgMetaSyncDone.pipe(filter(v => v > 0), distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(ts => {
      console.log("[app.component] backgroundSync meta sync done, disabling background mode ", this.bgSyncInProgress);
      this.bgSyncInProgress = false;
      this.changeDetectionRef.markForCheck();
      if (typeof cordova !== "undefined") {
        try {
          cordova.plugins.backgroundMode.disable();
          window.plugins.insomnia.allowSleepAgain(() => {
            console.log("[app.component] backgroundSync meta sync done, insomnia sleep again done, setSyncRequestDone ", window.appInBackground);
            this.convRepository.setSyncRequestDone();
            cordova.plugins.backgroundMode.isScreenOff((screenState) => {
              if (window.appInBackground) {
                console.log("BACKGROUNDSCREENSTATE in background: ", screenState);
              } else {
                console.log("BACKGROUNDSCREENSTATE not in background: ", screenState);
                if (!screenState) {
                  setTimeout(() => {
                    // possible ToDo:
                    // make sure that app is in background - but this is unlikely
                    // cordova.plugins.backgroundMode.moveToBackground();
                    this.noOpTrigger.next({ ts: Date.now(), force: false });
                    this.broadcaster.broadcast("REFRESH_ONE_PAGE_SYNC_MOBILE");
                  }, 50);
                }
              }
            });



          }, error => {
            console.log("[app.component] backgroundSync meta sync done, error insomnia");
          });
        } catch (error) {
          console.log("[app.component] backgroundSync meta sync done, ending background mode");
        }
      }
    });
    if (this.electronService.ipcRenderer) {
      this.electronService.ipcRenderer.on("openUri", (event, message) => {
        console.log("[app.component][openUri] message: ", event, message);
        try {
          if (message.startsWith("compose")) {
            message = message.replace(/\//g, "");
            const urlParams = new URLSearchParams(message.split("compose?")[1]);
            console.log("[app.component][openUri2] urlParams: ", urlParams);
            console.log("[app.component][openUri2] urlParams has contact ", urlParams.has("contact_id"));
            console.log("[app.component][openUri2] urlParams has to: ", urlParams.has("to"));
            let mailAddress;
            if (urlParams.has("to")) {
              mailAddress = urlParams.get("to");
            }
            let params = { to: mailAddress };
            if (urlParams.has("contact_id")) {
              params["contact_id"] = urlParams.get("contact_id");
            }

            this.router.navigate(["/mail/compose"], { queryParams: params });
          }
          if (message.startsWith("mail")) {
            this.router.navigate(["/" + message]);
          }
          if (message.startsWith("cal-ev-create")) {
            this.router.navigate(["/calendar"]);
            const mailAddress = message.split("?to=")[1];
            setTimeout(() => {
              this.broadcaster.broadcast("OPEN_APPOINTMENT_FULL_COMPOSE_FOR_ATEENDEE", {
                to: mailAddress
              });
            }, 2000);
          }
          if (message.startsWith("main/calendar")) {
            this.router.navigate(["/calendar"]);
          }
        } catch (e) {
          console.error("error processing openUri: ", e);
        }
      });
      this.electronService.ipcRenderer.on("clearDB", (event, message) => {
        console.warn("received clearDB command !");
        IdbUtils.deleteDB();
        this.databaseService.clearDB();
        setTimeout(() => {
          this.electronService.ipcRenderer.sendSync("vnc-cleardb");
        }, 200);
      });
      this.electronService.ipcRenderer.on("powerMonitor", (event, message) => {
        console.log("[powerMonitor] message: ", message);
        this.electronService.remoteLog("[powerMonitor] message: ", message);
        if ((message === "resume") || (message === "unlock")) {
          this.broadcaster.broadcast(MailConstants.REFRESH_BROADCAST);
          this.syncRequestDone = false;
          const nts = Date.now();
          this.resyncElectron.next(nts);
        }
        if ((message === "lock") && this.syncRequestDone) {
          localStorage.setItem("lastTimeElectronLock", new Date().getTime().toString());
        }
        if ((message === "sleep") && this.syncRequestDone) {
          localStorage.setItem("lastTimeElectronSleep", new Date().getTime().toString());
        }
      });
    }

    if (window.location !== window.parent.location) {
      this.hideHeader = true;
      if (window.location.href.includes("/calendar")) {
        this.router.navigate(["/calendar"]);
      }
    }
    if (!(environment.isCordova || environment.isElectron)) {
      if (window.location === window.parent.location) {
        const bc = new BroadcastChannel("vncmail_channel");
        bc.onmessage = (ev) => {
          console.log("broadcastchannelevent: ", ev);
          // respond to new instance
          if (!!ev.data && ev.data.startsWith("appcomponent_startup")) {
            bc.postMessage("older_instance_exists");
          }
          // handle existing instance
          if (!!ev.data && ev.data.startsWith("older_instance_exists")) {
            if (!this.secondInstancePopupShown) {
              this.secondInstancePopupShown = true;
              const confirmArgs: ConfirmArgs = { operationType: MailOperations.SecondInstance };
              const dialogArgs = {
                width: "390px",
                maxWidth: "85vw",
                height: "218px",
                autoFocus: false,
                data: confirmArgs,
                panelClass: "sidebar_folder_delete",
                backdropClass: ["confirmation-dialog-backdrop"]
              };
              const dialogRef = this.matDialog.open(ConfirmDialogComponent, dialogArgs);
            }
          }

        };
        bc.postMessage("appcomponent_startup_" + Date.now());
      }
    }

  }

  private setSwipeConfig() {
    const swipeAction = this.electronService.isElectron
        ? this.electronService.getFromStorage("swipeAction")
        : localStorage.getItem("swipeAction");
      if (swipeAction) {
        const parsedSwipeAction = JSON.parse(swipeAction);
        this.store.dispatch(new ChangeSwipeAction(parsedSwipeAction));
      }
  }

  private setupOfflineData(): void {
    const preferences = this.electronService.isElectron
        ? this.electronService.getFromStorage("preferences")
        : localStorage.getItem("preferences");
    const profileUser = localStorage.getItem("profileUser");
    if (!!preferences) {
      const prefs = typeof preferences === "string" ? JSON.parse(preferences) : preferences;
      this.configService.setPreferences(prefs);
      this.getPollingInterval();
    }
    if (!!profileUser) {
      this.currentUser = MailUtils.parseUserProfile(profileUser);
    }
  }

  private handlePollingTimeout(): void {
    this.store.select(getPollingInterval)
      .pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(interval => {
        setTimeout(() => {
          this.interval = interval || MailConstants.MIN_POLLING_INTERVAL;
          if (this.interval < 10000) {
            this.interval = MailConstants.MIN_POLLING_INTERVAL;
          }
          console.log("handlePollingTimeOut => call handlePolling");
          this.handlePolling();
        }, 1000);
      });
  }

  private handlePolling(): void {
    let isSyncRequestDone = this.convRepository.getSyncRequestDone();
    console.log("[handlePolling]", this.isOnline, this.isLoggedIn, isSyncRequestDone);

    if (!this.isOnline || !this.isLoggedIn || !isSyncRequestDone) {
      return;
    }
    if (!this.interval || this.interval < MailConstants.MIN_POLLING_INTERVAL) {
      this.interval = MailConstants.MIN_POLLING_INTERVAL;
    }

    if (this.avatarPolling$) {
      this.avatarPolling$.unsubscribe();
    }
    this.setPollingTimer();
    this.avatarPolling$ = timer(0, this.configService.AVATAR_SYNC_INTERVAL).pipe(takeUntil(this.isAlive$)).subscribe(val => {
      if (this.isOnline) {
        this.store.dispatch(new ResetLastPhotoUpdate());
      }
    });
  }

  private setPollingTimer() {
    if (this.noOpPolling$) {
      console.log("[handlePolling] noOp setPollingTimer unsubscribe", new Date());
      clearInterval(this.noOpPolling$);
    }
    console.log("[handlePolling] noOp setPollingTimer start", this.interval);
    this.noOpTrigger.next({ts: Date.now(), force: false});
    // this.noOp();
    if (!!this.interval) {

      this.noOpPolling$ = setInterval(() => {
        if (this.isOnline) {
          console.log(`[handlePolling] call noOp by ${MailConstants.MIN_POLLING_INTERVAL} timer`, this.interval);
          this.noOpTrigger.next({ts: Date.now(), force: false});
          // reason:
          // after noop failure we need to resync
          if (CommonUtils.isOnAndroid()) {
            this.bgSyncMessagesStart.next(Date.now());
          } else {
            this.syncData();
          }
          // this.noOp();
        }
      }, this.interval);
    }
  }

  private getFromStorage(key): string {
    return localStorage.getItem(key);
  }

  private syncData(force?: boolean): void {
    console.log("[syncData]", force, new Date());
    let syncToken: any = localStorage.getItem("syncRequestToken");
    console.log("[AppComponent] syncData syncRequest, syncToken:", syncToken);


    if (!!syncToken) {
      // rationale / explaination:
      // see https://files.zimbra.com/docs/soap_api/8.8.15/api-reference/zimbraMail/Sync.html
      //
      // using stored syncToken is fine - but (when the instance that stored the token is offline for a while) the
      // token can become invalid. When this happens the service returns an error that was not previously handled.
      // if the syncToken becomes invalid, a resync (w/o token) is required.

      this.convRepository.syncRequest(syncToken).subscribe(res => {
        console.log("[AppComponent] syncData syncRequest, res: ", res);
        this.processSyncRequestData(res);

        setTimeout(() => {
          console.log("[AppComponent] syncrequest refresh");
          this.broadcaster.broadcast("REFRESH_FOLDERS");
          this.broadcaster.broadcast("REFRESH_ALL");
        }, 100);
      }, err => {
        console.error("[AppComponent] syncData error: ", err);
        this.convRepository.syncRequestResync().subscribe(res => {
          console.log("[AppComponent] syncData syncRequestAfterTimestamp, res: ", res);

          this.processSyncRequestData(res);

        });
      });
    } else {
      // without stored syncToken we need an initial sync where we sync last 15 days
      const sDate = Math.floor(new Date().getTime() / 1000) - 1296000;
      this.convRepository.syncRequestResync(sDate).subscribe(res => {
        console.log("[AppComponent] syncRequestAfterTimestamp, res: ", res);

        this.processSyncRequestData(res);

        console.log("[AppComponent] syncrequest refresh2");
        this.broadcaster.broadcast("REFRESH_FOLDERS");
        this.broadcaster.broadcast("REFRESH_ALL");
      }, err => {
        console.error("[AppComponent][syncData] resync error: ", err);
      });
    }

  }


  private initData(): void {
    console.log("[AppComponent][initData]");

    this.store.select(getOnlineStatus).pipe(filter(v => !!v), take(1)).subscribe(v => {
      this.appService.getMyProducts().pipe(take(1)).subscribe(data => {
        console.log("[AppComponent][initData] getMyProducts", data);

        if (data && data.products) {
          localStorage.setItem("federatedApps", JSON.stringify(data.products));
          this.store.dispatch(new SetAvailableApps(data.products));
        }
      });

      this.appService.getProperties().subscribe(props => {
        console.log("[getProperties]", props);
        localStorage.setItem("userProps", JSON.stringify(props));
        const sortContactOption = props.filter(p => p.zimlet === "vncmail" && p.name === MailConstants.SORT_CONTACTS_OPTION);
        if (sortContactOption.length > 0) {
          localStorage.setItem(MailConstants.SORT_CONTACTS_OPTION, sortContactOption[0]._content);
        } else {
          localStorage.setItem(MailConstants.SORT_CONTACTS_OPTION, "a-z_firstname");
          this.appService.setSortContactsOptions("a-z_firstname");
        }

        const paneSashVertical = props.filter(p => p.zimlet === "vncmail" && p.name === MailConstants.READING_PANE_SASH_VERTICAL);
        if (paneSashVertical.length > 0) {
          this.store.dispatch(new SetPaneSashVertical(paneSashVertical[0]._content));
        }
        const paneSashHorizontal = props.filter(p => p.zimlet === "vncmail" && p.name === MailConstants.READING_PANE_SASH_HORIZONTAL);
        if (paneSashHorizontal.length > 0) {
          this.store.dispatch(new SetPaneSashHorizontal(paneSashHorizontal[0]._content));
        }
        const treeSash = props.filter(p => p.zimlet === "vncmail" && p.name === MailConstants.FOLDER_TREE_SASH);
        if (treeSash.length > 0) {
          let value = treeSash[0]._content;
          if (!!value && value !== null && value.indexOf("px") !== -1) {
            const val = value.split("px")[0];
            value = (val <= 305 || val === undefined || val === null) ? "305px" : value;
          }
          console.log("[SetFolderTreeSash]: ", value);
          this.store.dispatch(new SetFolderTreeSash(value));
        }
        const insecureContent = props.filter(p => p.zimlet === "vncmail" && p.name === MailConstants.NOT_LOAD_INSECURE_CONTENT)[0];
        if (!!insecureContent) {
          console.log("[insecureContent]: ", insecureContent);
          this.store.dispatch(new SetLoadInsecureContent(insecureContent._content));
        } else {
          this.appService.setLoadInsecureContent("false");
        }

        const showGlobalTags = props.find(v => v.zimlet === "vncmail" && v.name === "showGlobalTags");
        if (showGlobalTags) {
          this.preferenceService.showGlobalTags = showGlobalTags._content === "true";
        }
        if (this.preferenceService.showGlobalTags) {
          this.store.dispatch(new SetShowGlobalTags("true"));
        } else {
          this.store.dispatch(new SetShowGlobalTags("false"));
        }

        const useQuickReply = props.find(v => v.zimlet === "vncmail" && v.name === "useQuickReply");
        const alwaysShowQuotedText = props.find(v => v.zimlet === "vncmail" && v.name === "alwaysShowQuotedText");
        if (useQuickReply) {
          this.preferenceService.useQuickReply = useQuickReply._content === "true";
        }
        if (alwaysShowQuotedText) {
          this.preferenceService.alwaysShowQuotedText = alwaysShowQuotedText._content === "true";
        }

      });
      this.mailService.getPreferences().subscribe(prefs => {
        const config: any = {};
        prefs.forEach(pref => {
          config[pref.key] = pref.value;
          if (pref.key === "zimbraPrefIncludeSharedItemsInSearch") {
            if (pref.value.toLowerCase() === "true") {
              this.store.dispatch(new SetIncludeShared(true));
              localStorage.setItem(MailConstants.INCLUDE_SHARED_ITEM, "true");
            } else {
              this.store.dispatch(new SetIncludeShared(false));
              localStorage.setItem(MailConstants.INCLUDE_SHARED_ITEM, "false");
            }
          }
        });
        if (!this.configService.useVNCdirectoryAuth) {
          this.setLanguage(prefs);
        } else {
          this.setLanguageFromDirectory();
        }
        this.applyTheme(prefs);
        this.outOfOfficeAlertOnLogin(prefs);
        this.configService.setPreferences(config);
        this.setUIWithConfig(config);
        this.getPollingInterval();
        this.setupEmoji();
        this.getBriefcaseShareFolders();
        this.calendarRepository.initCalendarAppointment();
        this.getExpandMailFolders();
      });

      setTimeout(() => {
        this.getAttributes();
      }, 5000);
    });
  }

  private setUIWithConfig(config): void {
    console.log("[setUIWithConfig]", config);
    if (config["zimbraPrefGroupMailBy"]) {
      // console.log("[setUIWithConfig] zimbraPrefGroupMailBy: ", config["zimbraPrefGroupMailBy"]);
      localStorage.setItem("currentView", config["zimbraPrefGroupMailBy"]);
      this.store.dispatch(new SetViewBy(config["zimbraPrefGroupMailBy"]));
      this.broadcaster.broadcast("setViewBy", config["zimbraPrefGroupMailBy"]);
    }
    if (config["zimbraPrefConversationOrder"]) {
      localStorage.setItem("expandConversations", config["zimbraPrefConversationOrder"]);
    }
    if (config["zimbraPrefSortOrder"]) {
      const sortOrder = config["zimbraPrefSortOrder"].split(",BDLV")[0];
      if (!!sortOrder && sortOrder.split(":").length === 2) {
        const sortOrderResult = sortOrder.split(":")[1];
        const sort = sortOrderResult.split(/Asc|Desc/i)[0];
        let order = "desc";
        if (/Asc|Desc/i.test(sortOrderResult)) {
          order = sortOrderResult.match(/Asc|Desc/i)[0];
        }
        if (sort === "date") {
          localStorage.setItem("groupBy", "date");
        } else if (sort === "size") {
          localStorage.setItem("groupBy", "size");
        } else if (["date", "size", "name"].indexOf(sort) === -1 || (sort === "name" && this.currentView === "conversation")) {
          localStorage.setItem("groupBy", "");
        }
        console.log("[setUIWithConfig]", sortOrderResult, sort, order);
        localStorage.setItem("sortBy", sort);
        localStorage.setItem("sortOrder", order);
      }
    }
    if (config["zimbraPrefReadingPaneLocation"]) {
      localStorage.setItem("previewPanel", config["zimbraPrefReadingPaneLocation"]);
      localStorage.setItem("readingPane", config["zimbraPrefReadingPaneLocation"]);
      this.store.dispatch(new SetReadingPanel(config["zimbraPrefReadingPaneLocation"]));
    }
    // zimbraPrefListViewColumns
  }

  noOp(withForce?: boolean): void {

    console.log("[handlePolling][noOp] NoOpStart", this.waitDisallowed, !!withForce);
    if (environment.isElectron) {
      const idleState = this.electronService.getSystemIdleState();
      if (idleState === "locked") {
        this.electronService.remoteLog("[handlePolling][noOp] skip while locked");
        return;
      }
    }
    this.electronService.remoteLog("[handlePolling][noOp] NoOpStart", this.waitDisallowed);
    let isOnline = false;
    this.store.select(getOnlineStatus).pipe(take(1)).subscribe(v => {
      isOnline = v;
    });
    if (!localStorage.getItem("token") || this.noOpRunning || window.appInBackground) {
      // return when a request is blocked as running or when there is no token...
      if (window.appInBackground) {
        console.log("skip noop call in background!");
      }
      return;
    }
    this.store.pipe(select(getSession), take(1)).subscribe(session => {
      this.noOpRunning = true;
      const body: any = {};
      body.session = {
        id: session.id,
      };
      body.seq = session.seq;
      if (!this.waitDisallowed) {
          body.wait = 1;
          body.timeout = 20;
          // body.limitToOneBlocked = 1;
      }
      console.log("[handlePolling][noOp] noOpRequest, waitDisallowed: ", this.waitDisallowed);

      if (isOnline) {
        this.mailService.noOpRequest(body).subscribe(res => {
          // console.log("[handlePolling][noOp] noOpRequest res", res);
          this.lastNoOpStamp = Date.now();
          this.noOpRunning = false;

          if (res.session) {
            const newSession: any = {
              id: res.session.id
            };
            if (res.notify) {
              if (res.notify[res.notify.length - 1].seq) {
                // console.log("[noOpRequest] seq", res.notify[res.notify.length - 1].seq);
                newSession.seq = res.notify[res.notify.length - 1].seq;
              }
              res.notify.forEach(notify => {
                this.processNotifiedData(notify);
              });
            }
            this.store.dispatch(new SetSession(newSession));
          }
          if (res.NoOpResponse && res.NoOpResponse.waitDisallowed !== undefined) {
            console.log("[handlePolling][noOpRequest] res SetWaitDisallowed due to: ", res.NoOpResponse);
            this.waitDisallowed = true;
          } else {
            this.tryToRecoverNoOp();
          }
          if (this.waitDisallowed) {
            this.noOpRunning = true;
            setTimeout(() => {
              this.noOpRunning = false;
              this.noOpTrigger.next({ts: Date.now(), force: false});
              // this.noOp();
            }, 15000);
            // clear noOp Interval - as we are back in normal mode
            if (this.noOpPolling$) {
              clearInterval(this.noOpPolling$);
            }
          } else {
            this.noOpTrigger.next({ts: Date.now(), force: false});
            // clear noOp Interval - as we are back in normal mode
            if (this.noOpPolling$) {
              clearInterval(this.noOpPolling$);
            }
            // this.noOp();
          }
        }, err => {
          console.error("[handlePolling][noOp] noOpRequest", err);

          if (err.error && err.error.msg && (err.error.msg === "auth credentials have expired"
            || err.error.msg === "no valid authtoken present")) {
              this.broadcaster.broadcast("generateNewToken", new Date().getMinutes());
          }
          this.noOpRunning = true;
          // make sure to call later in case of error
          setTimeout(() => {
            this.noOpRunning = false;
            this.noOpTrigger.next({ts: Date.now(), force: false});
            // this.noOp();
          }, 15000);
        });
      } else {
        this.noOpRunning = false;
      }
    });
  }

  private flattenFolderSyncData(inp: any) {
    let res = [];
    let sub = [];
    if (inp.length > 0) {
      inp.forEach(f => {
        if ((!f.view) || (f.view === "message") || (f.view === "conversation")) {
          if (f.m && f.m[0] && f.m[0].ids) {
            res[f.id] = f.m[0].ids;
          }
          if (f.folder && (f.folder.length > 0)) {
            sub = this.flattenFolderSyncData(f.folder);
          }
        }
      });
    }
    return res.concat(sub);
  }

  private backgroundSyncLastMessages(offset?: number) {
    const timestamp = localStorage.getItem("lastMessageTimestamp");
    const queryOffset = !!offset ? offset : 0;
    // console.log("calling backgroundSearchRequestOffset: ", queryOffset);

    if (!!timestamp && (queryOffset >= this.lastBGsyncQueryOffset)) {
      let query = {
        "fullConversation": 1,
        "limit": 30,
        "fetch": "all",
        "html": "1",
        "needExp": 1,
        "offset": queryOffset,
        "query": `after:${timestamp}`,
        "recip": "0",
        "sortBy": "dateDesc",
        "types": "message"
      };

      console.log("calling backgroundSearchRequest query: ", query);
      this.mailService.backgroundSearchRequest(query).pipe(take(1)).subscribe(res => {
        if (res && res.m && res.m.length > 0) {
          this.showSpinner = true;
          console.log("[backgroundsyncLastMessages] res.m:", res.m);
          this.databaseService.createOrUpdateMessages(res.m).subscribe(() => {
            if (!!res.more) {
              this.lastBGsyncQueryOffset = queryOffset + 30;
              setTimeout(() => {
                this.backgroundSyncLastMessages(queryOffset + 30);
              }, 1000);
            } else {
              localStorage.removeItem("lastMessageTimestamp");
              this.showSpinner = false;
              this.lastBGsyncQueryOffset = 0;
              let nts = new Date().getTime();
              this.bgSyncMessagesDone.next(nts);
              this.bgSyncInProgress = false;
              this.changeDetectionRef.markForCheck();
              this.convRepository.setSyncRequestDone();
              setTimeout(() => {
                // REFRESH_BROADCAST
                console.log("calling REFRESH_ONE_PAGE_SYNC ");
                this.broadcaster.broadcast("REFRESH_ONE_PAGE_SYNC_MOBILE");
              }, 50);
              this.commonRepository.getMailFolders();
            }
          });
        } else {
          localStorage.removeItem("lastMessageTimestamp");
          let nts = new Date().getTime();
          this.showSpinner = false;
          this.bgSyncMessagesDone.next(nts);
          this.bgSyncInProgress = false;
          this.changeDetectionRef.markForCheck();
          this.convRepository.setSyncRequestDone();
          setTimeout(() => {
            // REFRESH_BROADCAST
            console.log("calling REFRESH_ONE_PAGE_SYNC ");
            this.broadcaster.broadcast("REFRESH_ONE_PAGE_SYNC_MOBILE");
          }, 50);
          this.commonRepository.getMailFolders();
        }
      }, err => {
        localStorage.removeItem("lastMessageTimestamp");
        let nts = new Date().getTime();
        console.log("backgroundSearchError, setting bgSyncMessagesDone ");
        this.showSpinner = false;
        this.bgSyncMessagesDone.next(nts);
        this.bgSyncInProgress = false;
        this.changeDetectionRef.markForCheck();
        this.broadcaster.broadcast("REFRESH_ONE_PAGE_SYNC_MOBILE");
        this.commonRepository.getMailFolders();
      });
    }

  }


  private syncLastMessages(offset = 0) {
    const timestamp = localStorage.getItem("lastMessageTimestamp");
    // this.electronService.remoteLog("syncLastMessages... lastMessageTimeStamp: ", timestamp);
    // this.electronService.remoteLog("syncLastMessages... offset: ", offset);
    if (!!timestamp) {
      const query = {
        "fullConversation": 1,
        "limit": 30,
        "fetch": "all",
        "html": "1",
        "needExp": 1,
        "offset": offset,
        "query": `after:${timestamp}`,
        "recip": "0",
        "sortBy": "dateDesc",
        "types": "message"
      };

      this.mailService.searchRequest(query).pipe(take(1)).subscribe(res => {
        if (res && res.m && res.m.length > 0) {
          this.showSpinner = true;
          this.databaseService.createOrUpdateMessages(res.m).subscribe(() => {
            if (res.more) {
              // do not immediately fetch next batch, but do with 1s delay to not overload indexeddb processing
              setTimeout(() => {
                this.syncLastMessages(res.offset + 30);
              }, 1000);
            } else {
              localStorage.removeItem("lastMessageTimestamp");
              let nts = new Date().getTime();
              this.bgSyncMessagesDone.next(nts);
              this.showSpinner = false;
              setTimeout(() => {
                console.log("[syncLastMessages] :REFRESH_ONE_PAGE_SYNC");
                this.broadcaster.broadcast("REFRESH_ONE_PAGE_SYNC");
              }, 300);
              this.commonRepository.getMailFolders();
            }
          });
        } else {
          localStorage.removeItem("lastMessageTimestamp");
          this.showSpinner = false;
          let nts = new Date().getTime();
          this.bgSyncMessagesDone.next(nts);
          setTimeout(() => {
            console.log("[syncLastMessages] :REFRESH_ONE_PAGE_SYNC");
            this.broadcaster.broadcast("REFRESH_ONE_PAGE_SYNC");
          }, 300);
          this.commonRepository.getMailFolders();
        }
      });
    }

  }

  private mobileProcessSyncRequestData(resp: any, isResync = false) {
    console.log("[mobileProcessSyncRequestData] resp: ", resp);
    let isFolder = false;
    let isDeleted = false;
    try {
      isFolder = resp.folder && resp.folder[0] && resp.folder[0].folder;
      isDeleted = resp.deleted && resp.deleted[0] && resp.deleted[0].ids;
    } catch (e) {

    }

    if (!resp.m && !isFolder && !isDeleted) {
      console.log("[processSyncRequestData] bgMetaSyncDone nothing to do - setSyncRequestDone");
      this.syncRequestDone = true;
      this.bgSyncInProgress = false;
      this.showSpinner = false;
      this.changeDetectionRef.markForCheck();
      this.convRepository.setSyncRequestDone();
      let nts = new Date().getTime() + 2;
      this.bgMetaSyncDone.next(nts);
      if (!!resp.token) {
        localStorage.setItem("syncRequestToken", resp.token);
      }
      setTimeout(() => {
        console.log("[syncLastMessages] :REFRESH_ONE_PAGE_SYNC");
        this.broadcaster.broadcast("REFRESH_ONE_PAGE_SYNC_MOBILE");
      }, 350);
      return;
    }

    if (isDeleted) {
      const deleted = resp.deleted[0].ids;
      const deletedList = { id: deleted };
      const deletedIds = deleted.split(",");
      console.log("processSyncRequestData - deletedList: ", deletedList, deletedIds);

      // ToDOREVIEW:
      this.convRepository.removeMessageFromConvRedux(deletedIds);

      this.processDeletedData(deletedList);

    }

    if (isFolder) {
      const folders = resp.folder[0].folder;
      console.log("[processSync] processSyncfolders: ", folders);
      const folderRes = this.flattenFolderSyncData(folders);
      console.log("[processSync] folderRes: ", folderRes);

    }
    if (!!resp.m) {

      this.showSpinner = true;
      let filterFolderId = !!this.currentFolderId ? this.currentFolderId : "2";
      const messagesNotInCurrentFolderIds = resp.m.filter(m => m.l !== filterFolderId).map(m => m.id);
      const messagesInTrashIds = resp.m.filter(m => m.l === "3").map(m => m.id);
      messagesInTrashIds.forEach(id => {
        this.convRepository.addConversationOrMessageToTrash(id);
      });
      this.store.dispatch(new RemoveManyMessages(messagesNotInCurrentFolderIds));
      console.log("[processSync] messagesNotInCurrentFolderIds ", messagesNotInCurrentFolderIds.join(","), this.currentFolderId, filterFolderId);
      // this.store.dispatch(new RemoveMultipleTabs(messagesNotInCurrentFolderIds));

      // updateMessages in DB
      // const allCreateOrUpdateIds = resp.m.map(m => m.id);
      // console.log("[processSyncRequestData] storing createOrUpdate in db: ", allCreateOrUpdateIds.join(","));
      this.databaseService.createOrUpdateMessages(resp.m).subscribe(() => {
        const messageUpdatedTimes = resp.m.map(m => parseInt(m.md, 10)).sort((a, b) => a - b);

        console.log("[processSyncRequestData] processing messageUpdateTimes: ", messageUpdatedTimes[0], messageUpdatedTimes);

        const lastMessageTimestamp = (messageUpdatedTimes[0] * 1000) - 60000;

        const oldLastMessagesTimestamp = localStorage.getItem("lastMessagesTimestamp");
        if (!!resp.token) {
          localStorage.setItem("syncRequestToken", resp.token);
        }
        if (!oldLastMessagesTimestamp) {
          localStorage.setItem("lastMessageTimestamp", "" + lastMessageTimestamp);
          console.log("[mobileprocessSyncRequestData] storing lastMessagesTimestamp: ", lastMessageTimestamp);
        } else {
          if (parseInt(oldLastMessagesTimestamp, 10) < lastMessageTimestamp) {
            console.log("[mobileprocessSyncRequestData] keeping existing lastMessagesTimestamp: ", lastMessageTimestamp);
          } else {
            localStorage.setItem("lastMessageTimestamp", "" + lastMessageTimestamp);
          }
        }
        this.backgroundSyncLastMessages();
      }, error => {
        console.log("[processSync] update changes in db error - not storing new syncRequestToken");

      });
    }

    if (!!resp.appt) {
      console.log("[AppointmentSyncMobile] ", resp.appt);
      const syncDateStamps = resp.appt.map(a => parseInt(a.d, 10)).sort((a, b) => a - b);  // messageUpdatedTimes = resp.m.map(m => parseInt(m.md, 10)).sort((a, b) => a - b);
      const syncAppts = resp.appt;
      let apptIds = [];
      syncAppts.forEach(ap => {
        apptIds.push({ apptId: ap.id });
      });

      const fq = this.calendarRepository.getAllFolderQuery();
      console.log("[AppointmentSync] process ", apptIds, syncDateStamps, fq);
      this.calendarRepository.addAllAppointmentsToDB(apptIds, fq);
    }
  }


  private processSyncRequestData(resp: any, isResync = false) {
    console.log("[processSyncRequestData] resp: ", resp);
    const newSyncToken = !!resp.token ? resp.token : "none";
    const hasLastMessageTimestamp = localStorage.getItem("lastMessageTimestamp");
    this.electronService.remoteLog("[processSyncRequestData] hasLastMessageTimestamp: ", hasLastMessageTimestamp);
    this.electronService.remoteLog("[processSyncRequestData] resp: ", resp);
    if (!environment.isCordova && CommonUtils.isOfflineModeSupported() && resp.m && resp.m.length > 0) {
      const messageUpdatedTimes = resp.m.map(m => parseInt(m.md, 10)).sort((a, b) => a - b);
      const lastMessageTimestamp = (messageUpdatedTimes[0] * 1000) - 60000;
      if (!hasLastMessageTimestamp) {
        localStorage.setItem("lastMessageTimestamp", "" + lastMessageTimestamp);
        console.log("[mobileprocessSyncRequestData] storing lastMessagesTimestamp: ", lastMessageTimestamp);
      } else {
        if (parseInt(hasLastMessageTimestamp, 10) < lastMessageTimestamp) {
          console.log("[mobileprocessSyncRequestData] keeping existing lastMessagesTimestamp: ", lastMessageTimestamp);
        } else {
          localStorage.setItem("lastMessageTimestamp", "" + lastMessageTimestamp);
        }
      }
      this.electronService.remoteLog("[processSyncRequestData resp.m: ", resp.m);
      this.electronService.remoteLog("[processSyncRequestData storing lastMessageTimestamp: ", lastMessageTimestamp);
      this.showSpinner = true;
      localStorage.setItem("lastMessageTimestamp", "" + lastMessageTimestamp);
      this.syncLastMessages();
    }
    if (isResync) {
      // isResync is set when syncRequest is called while application is in use
      // rationale: keep syncToken up to date and only fetch messages since last application start / wake up
      // otherwise when using electron the whole day w/o sleep, next resync returns all messages again
      // console.log("processSyncRequest isResync - bailing out");
      return;
    }
    let isFolder = false;
    let isDeleted = false;
    try {
      isFolder = resp.folder && resp.folder[0] && resp.folder[0].folder;
      isDeleted = resp.deleted && resp.deleted[0] && resp.deleted[0].ids;
    } catch (e) {

    }

    if (!resp.m && !isFolder && !isDeleted && !resp.appt) {
      console.log("[processSyncRequestData] bgMetaSyncDone nothing to do - setSyncRequestDone");
      this.syncRequestDone = true;
      this.bgSyncInProgress = false;
      this.changeDetectionRef.markForCheck();
      this.convRepository.setSyncRequestDone();
      let nts = new Date().getTime() + 2;
      this.bgMetaSyncDone.next(nts);
      return;
    }

    if (isDeleted) {
      const deleted = resp.deleted[0].ids;
      const deletedList = {id: deleted};
      const deletedIds = deleted.split(",");
      console.log("processSync - deletedList: ", deletedList, deletedIds);

      this.store.dispatch(new RemoveManyMessages(deletedIds));
      this.convRepository.removeMessageFromConvRedux(deletedIds);

      this.processDeletedData(deletedList);

    }

    if (isFolder) {

      const folders = resp.folder[0].folder;
      console.log("[processSync] processSyncfolders: ", folders);
      const folderRes = this.flattenFolderSyncData(folders);
      console.log("[processSync] folderRes: ", folderRes);

    }
    if (!!resp.m) {
      this.showSpinner = true;
      let filterFolderId = !!this.currentFolderId ? this.currentFolderId : "2";
      const messagesNotInCurrentFolderIds = resp.m.filter(m => m.l !== filterFolderId).map(m => m.id);
      const messagesInTrashIds = resp.m.filter(m => m.l === "3").map(m => m.id);
      messagesInTrashIds.forEach(id => {
        this.convRepository.addConversationOrMessageToTrash(id);
      });
      this.store.dispatch(new RemoveManyMessages(messagesNotInCurrentFolderIds));
      console.log("[processSync] messagesNotInCurrentFolderIds ", messagesNotInCurrentFolderIds.join(","));
      // this.store.dispatch(new RemoveMultipleTabs(messagesNotInCurrentFolderIds));

      // updateMessages in DB
      // const allCreateOrUpdateIds = resp.m.map(m => m.id);
      // console.log("[processSyncRequestData] storing createOrUpdate in db: ", allCreateOrUpdateIds.join(","));
      this.databaseService.createOrUpdateMessages(resp.m).subscribe(() => {
        if (!!resp.token) {
          if (!CommonUtils.isOnAndroid()) {
            localStorage.setItem("syncRequestToken", resp.token);
          }
        }
        if (environment.isElectron) {
          const idleState = this.electronService.getSystemIdleState();
          if (idleState === "locked") {
            this.electronService.remoteLog("[handlePolling][noOp] skip while locked");
            return;
          }
        }
        if (CommonUtils.isOnAndroid()) {
          const messageUpdatedTimes = resp.m.map(m => parseInt(m.md, 10)).sort((a, b) => a - b);

          console.log("[processSyncRequestData] processing messageUpdateTimes: ", messageUpdatedTimes[0], messageUpdatedTimes);

          const lastMessageTimestamp = (messageUpdatedTimes[0] * 1000) - 60000;

          const oldLastMessagesTimestamp = localStorage.getItem("lastMessagesTimestamp");
          if (!!resp.token) {
            localStorage.setItem("syncRequestToken", resp.token);
          }
          if (!oldLastMessagesTimestamp) {
            localStorage.setItem("lastMessageTimestamp", "" + lastMessageTimestamp);
            console.log("[mobileprocessSyncRequestData] storing lastMessagesTimestamp: ", lastMessageTimestamp);
          } else {
            if (parseInt(oldLastMessagesTimestamp, 10) < lastMessageTimestamp) {
              console.log("[mobileprocessSyncRequestData] keeping existing lastMessagesTimestamp: ", lastMessageTimestamp);
            } else {
              localStorage.setItem("lastMessageTimestamp", "" + lastMessageTimestamp);
            }
          }
          this.backgroundSyncLastMessages();

        } else {
          this.broadcaster.broadcast("REFRESH_ONE_PAGE_SYNC");
        }

      }, error => {
        console.log("[processSync] update changes in db error - not storing new syncRequestToken");

      });
    }

    if (!!resp.appt) {
      console.log("[AppointmentSync] ", resp.appt);
      const syncDateStamps = resp.appt.map(a => parseInt(a.d, 10)).sort((a, b) => a - b);  // messageUpdatedTimes = resp.m.map(m => parseInt(m.md, 10)).sort((a, b) => a - b);
      const syncAppts = resp.appt;
      const fq = this.calendarRepository.getAllFolderQuery();
      console.log("[AppointmentSync] process ", syncAppts, syncDateStamps, fq);
      this.calendarRepository.addAllAppointmentsToDB(syncAppts, fq);
    }

    setTimeout(() => {
      console.log("[processSyncRequestData] done => bgMetaSyncDone, setSyncRequestDone");
      if (newSyncToken !== "none") {
        this.electronService.remoteLog("[processSyncRequestData] done => bgMetaSyncDone, newSyncToken: ", newSyncToken);
        localStorage.setItem("syncRequestToken", newSyncToken);
      }
      this.syncRequestDone = true;
      this.convRepository.setSyncRequestDone();
      this.bgSyncInProgress = false;
      this.changeDetectionRef.markForCheck();
      let nts = new Date().getTime();
    	this.bgMetaSyncDone.next(nts);
    }, 2000);
  }

  private processNotifiedData(notify) {
    console.log("processNotifiedData - setSyncRequestInProgress");
    this.convRepository.setSyncRequestInProgess();
    const createdList = notify.created || {};
    const modifiedList = notify.modified || {};
    const deletedList = notify.deleted;
    console.log("[ProcessNoOpRequest] nooplist-createdList", createdList);
    console.log("[ProcessNoOpRequest] nooplist-modifiedList",  modifiedList);
    console.log("[ProcessNoOpRequest] nooplist-deletedList",  deletedList);

    if (modifiedList.mbx && modifiedList.mbx[0].s) {
      this.configService.setUsedQuota(modifiedList.mbx[0].s);
    }
    this.processModifiedData(modifiedList, deletedList);
    this.processCreatedData(createdList, modifiedList);
    this.processDeletedData(deletedList);

    if (this.router.url.startsWith("/briefcase")) {
      if (createdList.doc && createdList.doc.length) {
        this.store.dispatch(new CreateBriefcaseSuccess({files: createdList.doc}));
      }
      if (modifiedList.doc && modifiedList.doc.length) {
        this.store.dispatch(new UpdateManyBriefcaseFileSuccess(modifiedList.doc));
      }
    }
  }

  private processDeletedData(deletedList: any): void {
    console.log("[AppComponent][processDeletedData]", deletedList, this.router.url);


    if (deletedList) {
      if (deletedList.id) {
        // ref: https://vncproject.vnc.biz/issues/30003049-8143
        // filter out notifications about events from other mailboxes
        // that did not share a mailfolder
        const rawIds = deletedList.id.split(",");
        let ids = [];
        rawIds.forEach(rawid => {
          if (rawid.indexOf(":") > -1) {
            const rawzid = rawid.split(":")[0];
            if (this.mailService.mailShareZids.indexOf(rawzid) > -1) {
              ids.push(rawid);
            }
          } else {
            ids.push(rawid);
          }
        });
        console.log("[AppComponent][processDeletedData] ids", ids);

        // clear redux
        this.store.dispatch(new RemoveMultipleTabs(ids));
        this.store.dispatch(new DeleteMultipleMailTag(ids));
        this.store.dispatch(new UnSelectConversationsAction(ids));
        //
        this.store.dispatch(new RemoveManyConversations(ids));
        this.store.dispatch(new RemoveManyMessages(ids));

        this.convRepository.removeMessageFromConvRedux(ids);
        //
        ids.forEach(id => {
          if (this.mailService.flatFolders[id]) {
            this.removeChildFromParent(this.mailService.flatFolders[id]);
          }
        });
        this.broadcaster.broadcast("deletedConversations", ids);
        this.broadcaster.broadcast("deletedMessages", ids);

        // remove from DB as well
        this.convRepository.deleteMessagesFromDB(ids).subscribe(() => {
          this.convRepository.deleteConversationsFromDB(ids).subscribe(() => {
            console.log("[AppComponent] deleted refresh");
            this.broadcaster.broadcast("REFRESH_ALL");
          });
        });
      } else {

        // {id: "742,-742"}
        setTimeout(() => {
            console.log("[AppComponent] deleted2 refresh");
          this.broadcaster.broadcast("REFRESH_ALL");
        }, 300);
      }

    }

    if (deletedList && this.router.url.startsWith("/calendar")) {
      if (deletedList.id) {
        const ids = deletedList.id.split(",");
        // console.log("[processCalendarDeletedData]", ids);
        this.store.dispatch(new DeleteMultipleMailTag(ids));
        let flatFolders: CalendarFolder [] = [];
        this.store.select(getCalendarFolders).pipe(take(1)).subscribe(fold => {
          flatFolders = fold;
        });
        ids.forEach(id => {
          const f = CalenderUtils.getCalendarFolderById(flatFolders, id);
          if (f) {
            this.removeCalendarChildFromParent(f);
          }
        });
      }
    }
  }

  private processModifiedData(modifiedList: any, deletedList?: any): void {
    // TAG mail
    if (modifiedList.tag && this.router.url.startsWith("/mail")) {
      const tags = Array.isArray(modifiedList.tag) ? modifiedList.tag : [modifiedList.tag];
      // console.log("[processModifiedData] tags", tags);
      tags.forEach(tag => {
        const updatedTag = tag;
        this.store.dispatch(new UpdateMailTagSuccess({id: updatedTag.id, changes: updatedTag}));
      });
    }

    // MESSAGES mail
    if (modifiedList.m && this.router.url.startsWith("/mail")) {

      // set 'query' field from 'l' field
      //
      let correctedQueries = {};
      if (modifiedList.m) {
        modifiedList.m.forEach(m => {
          if (m.l) {
            m.query = MailUtils.getQueryByFolderId(parseInt(m.l, 10));
            correctedQueries[parseInt(m.id, 10)] = m.query;
          }
        });
      }
      if (modifiedList.c && Object.keys(correctedQueries).length > 0) {
        modifiedList.c.forEach(c => {
          const convId = Math.abs(parseInt(c.id, 10));
          if (correctedQueries[convId]) {
            c.query = correctedQueries[convId];
          }
        });
      }
      console.log("[AppComponent][processModifiedData] modifiedList.m", modifiedList.m);
      console.log("[AppComponent][processModifiedData] modifiedList.c", modifiedList.c);
      console.log("[AppComponent][processModifiedData] correctedQueries", correctedQueries, Object.keys(correctedQueries));

      const messages = Array.isArray(modifiedList.m) ? modifiedList.m : [modifiedList.m];
      console.log("[processModifiedData] messages", messages);

      this.broadcaster.broadcast("updatedMessages", messages);
      const messagesToMove = messages.filter(m => !!m.query);
      console.debug("[processModifiedData] messagesToMove1", messagesToMove);

      if (messages.length > 0) {
        this.databaseService.updateMessages(messages).subscribe(res => {
          if ((this.currentView === "message") && (messagesToMove.length === 0)) {
            console.log("[processModifiedData] messagesToMove1 updateMessages, REFRESH_ONE_PAGE", messagesToMove.length, messages, res);
            this.store.dispatch(new UpdateManyMessagesSuccess(messages));
            this.broadcaster.broadcast("REFRESH_ONE_PAGE");
          }
          console.log("[processSyncRequestData] processModified updated in DB, setSyncRequestDone");
          this.convRepository.setSyncRequestDone();
        });
      }

      const messagesToMoveHash = {};
      messagesToMove.forEach(m => { // collect per 'folderId'
        let axistingArray = messagesToMoveHash[parseInt(m.l, 10)];
        if (!axistingArray) {
          axistingArray = [];
          messagesToMoveHash[parseInt(m.l, 10)] = axistingArray;
        }
        axistingArray.push(m);
      });
      console.debug("[processModifiedData] messagesToMoveHash", messagesToMoveHash);
      //
      Object.keys(messagesToMoveHash).forEach(folderId => {
        this.processMessagesMoveOp(messagesToMoveHash[folderId], parseInt(folderId, 10));
      });
      //
      //

      // when messages is translated into conv
      messages.map(msg => {
        if (msg && msg.cid) {
          const cid = this.getNormalize(msg.id) * -1;
          // console.log("[AppComponent][processModifiedData] messages getConversationById", msg.id, cid);

          this.store.select(state => getConversationById(state, msg.cid)).pipe(take(1)).subscribe(conv => {
            // console.log("[processModifiedData] messages getConversationById cid", msg.cid, conv);
            if (!!conv) {
              conv.id = msg.cid.toString();
              const oldMsg = conv.m.find(m => m.id === msg.id);
              let changes: any = {id : msg.cid.toString()};
              // console.log("[processModifiedData] messages", conv, oldMsg);
              this.store.dispatch(new UpdateConversationSuccess({id: cid.toString(), changes: changes}));
              this.isUpdatedConv = true;
            }
          });
          // console.log("[processModifiedData] messages getConversationById", cid);
          this.store.select(state => getConversationById(state, cid.toString())).pipe(take(1)).subscribe(conv => {
            // console.log("[processModifiedData] messages getConversationById cid", cid, conv);

            // remove old temp conv (with -)
            this.convRepository.deleteConversationsFromDB([cid.toString()], true).subscribe();
            //
            // also we need to update initial message, and replace 'cid' field to a new one
            let updatedMessages: Message[];
            this.convRepository.getMessagesByIds([msg.id]).pipe(take(1)).subscribe(messages => {
              messages.forEach(m => {
                m.cid = msg.cid;
              });
              this.convRepository.updateMessagesInDB(messages).subscribe();
            });

            if (!!conv) {
              conv.id = msg.cid.toString();
              let changes: any = {id : msg.cid.toString()};
              // console.log("[processModifiedData] messages", messages, conv, changes);
              this.store.dispatch(new UpdateConversationSuccess({id: cid.toString(), changes: changes}));
              this.isUpdatedConv = true;
            }
          });
        }
      });


    }

    // CONVS mail
    if (modifiedList.c && this.router.url.startsWith("/mail")) {
      let updatedConversations = Array.isArray(modifiedList.c) ? modifiedList.c : [modifiedList.c];
      //
      const convsIdsToTrash = [];
      //
      updatedConversations = updatedConversations.map(c => {
        const conv = c;
        if (conv.tn) {
          conv.tags = conv.tn.split(",");
        }


        if (parseInt(conv.l, 10) === MailConstants.FOLDER_ID.TRASH || conv.query === MailConstants.SEARCH_CRITERIA.IN_TRASH) {
          convsIdsToTrash.push(conv.id);
        }

        if (deletedList && deletedList.id) {
          const ids = deletedList.id.split(",");
          this.store.dispatch(new RemoveMultipleTabs(ids));
          this.store.select(state => getConversationById(state, conv.id)).pipe(take(1)).subscribe(conversation => {
            if (!!conversation && conversation.m) {
              conversation.m = conversation.m.filter(m => ids.indexOf(m.id) === -1);
              conv.m = conversation.m;
            }
          });
        }
        if (conv.u === 0) {
          console.log("[processModifiedData] updatedConversations set unread", conv);
        }
        const expanded = this.convRepository.getIsConvExpanded(conv.id);
        // console.log("[processModifiedData] updatedConversations is expanded", expanded, conv);
        conv.isExpand = expanded;

        return conv;
      });

      const updatedExpandedConvs = updatedConversations.filter(c => c.isExpand);
      const updatedCollapsedConvs = updatedConversations.filter(c => !c.isExpand);
      // console.log("[processModifiedData] updatedConversations expanded, collapsed", updatedExpandedConvs, updatedCollapsedConvs);

      if (updatedExpandedConvs.length > 0) {
        updatedExpandedConvs.forEach(updatedExpandedConv => {
          this.convRepository.getConversationById(updatedExpandedConv.id).pipe(take(1)).subscribe(c => {
            // console.log("[processModifiedData] updatedConversations expanded existingConv", c);
            const query: SearchConvRequest = {
              cid: updatedExpandedConv.id,
              fetch: "0",
              query: c?.query,
              offset: "0",
              limit: "250"
            };
            // this.convRepository.getConversationMessages(query, updatedExpandedConv).pipe(take(2)).subscribe(messages => {
            this.convRepository.getConversationMessages(query, updatedExpandedConv).subscribe(messages => {
              updatedExpandedConv.m = messages;
              this.store.dispatch(new UpdateManyConversationsSuccess([updatedExpandedConv]));
              this.databaseService.updateConversations([updatedExpandedConv]).subscribe(res => {
                console.log("[processModifiedData] updatedExpandedConv REFRESH_ONE_PAGE", updatedExpandedConv, res);
                if (this.currentView === "conversation") {
                  this.broadcaster.broadcast("REFRESH_ONE_PAGE");
                }
              });
            });
          });
        });
      }

      if (updatedCollapsedConvs.length > 0) {
        this.store.dispatch(new UpdateManyConversationsSuccess(updatedCollapsedConvs));
        this.databaseService.updateConversations(updatedConversations).subscribe(res => {
          console.log("[processModifiedData] updateConversations REFRESH_ONE_PAGE", updatedConversations, res);
          if (this.currentView === "conversation") {
            this.broadcaster.broadcast("REFRESH_ONE_PAGE");
          }
        });
      }


      //
      // clean DB
      // TODO: do it also for ALL other ops
      if (convsIdsToTrash.length > 0) {
        this.convRepository.conversationActionApplyToDB(
          {
            op: "trash",
            l: MailConstants.FOLDER_ID.TRASH,
            id: convsIdsToTrash.join(",")
          }).subscribe(() => {
            console.log("conversationActionApplyToDB REFRESH_ONE_PAGE");
            if (this.currentView === "conversation") {
              this.broadcaster.broadcast("REFRESH_ONE_PAGE");
            }
          });
      }
    }

    // invitations
    if (this.router.url.startsWith("/mail") && modifiedList.appt) {
      console.log("ProcessNoOpRequest modifiedAppts0: ", modifiedList.appt);
      const modifiedAppointmentsList = modifiedList.appt;
      let apptIds = [];
      modifiedAppointmentsList.forEach(modifiedAppointment => {
        this.broadcaster.broadcast("MODIFIED_APPT_INVITE", modifiedAppointment.id);
        apptIds.push({ apptId: modifiedAppointment.id});
      });
      // notify fluid about calendar updates
      if ((window.location !== window.parent.location) && !this.isCordovaOrElectron) {
          window.parent.postMessage({
            type: "VNCCALENDAR_UPDATE"
          }, "*");
      }

      console.log("ProcessNoOpRequest modifiedAppts: ", modifiedAppointmentsList, apptIds);

      this.calendarRepository.addAllAppointmentsToDB(apptIds).subscribe(() => {
        console.log("ProcessNoOpRequest sync appointments: ", modifiedList.appt);
      });

      const deletedAppointmentList = modifiedAppointmentsList.filter(a => a.l === "3");
      const deletedAppointmentIds = deletedAppointmentList.map(a => a.id);
      if (deletedAppointmentList.length > 0) {
        this.databaseService.deleteAppointments(deletedAppointmentIds).subscribe(() => {
          deletedAppointmentList.forEach(a => {

            this.store.dispatch(new DeleteCalendarAppointmentSuccessAction({ appointment: a }));
          });
        });
      }
    }

    // calendar
    if (this.router.url.startsWith("/calendar") && modifiedList.appt) {
      const modifiedAppointmentList = modifiedList.appt;
      modifiedAppointmentList.map(app => {
        if (app.inv) {
          const component = app.inv[0].comp[0];
          const ridZ = component.allDay === true ? component.s[0].d : this.getAppointmentRidz(component.s[0].d);
          const eventId =  app.id + "_" + ridZ;
          let appts: CalendarAppointment[] = [];
          this.store.select(getAllCalendarAppointments).pipe(take(1)).subscribe((calendarEvents: CalendarAppointment[]) => {
            appts = calendarEvents;
          });
          const apt = appts.filter(ap => ap.id === app.id)[0];
          if (!!apt && apt !== null) {
            console.log("[Modified Appointment] : ", app);
            const calEvent = this.getCalenderEvent(app);
            this.store.dispatch(new DeleteCalendarAppointmentSuccessAction({ appointment: apt }));
            this.store.dispatch(new CreateCalendarAppointmentSuccessAction({ appointment: calEvent }));
          } else {
            const calEvent = this.getCalenderEvent(app);
            this.calendarRepository.addAllAppointmentsToDB([calEvent]).subscribe(() => {
              console.log("[app.component] processCreatedData stored to DB, setSyncRequestDone ");
              // this.convRepository.setSyncRequestDone();
              this.calendarRepository.getAllAppointments();
            });
            this.store.dispatch(new CreateCalendarAppointmentSuccessAction({ appointment: calEvent }));
          }
        } else if (app.l === "3") {
          this.store.select(getAllCalendarAppointments).pipe(take(1)).subscribe((calendarEvents: CalendarAppointment[]) => {
            calendarEvents.map( apt => {
              if (apt.id === app.id) {
                console.log("[Deleted Appointment] : ", apt);
                this.store.dispatch(new DeleteCalendarAppointmentSuccessAction({ appointment: apt }));
              }
            });
          });
        }
      });
    }

    // FOLDERS calendar
    if (modifiedList.folder && this.router.url.startsWith("/calendar")) {
      const folders = Array.isArray(modifiedList.folder) ? modifiedList.folder : [modifiedList.folder];
      console.log("[processModifiedData] folders", folders);
      let flatFolders: CalendarFolder [] = [];
      this.store.select(getCalendarFolders).pipe(take(1)).subscribe(fold => {
        flatFolders = fold;
      });
      // folders.filter(f => Object.keys(f).length > 2 && f.id !== "1").forEach(folder => {
      //   const updatedFolder = folder;
      //   if (updatedFolder.id.indexOf(":") === -1) {
      //     const f = CalenderUtils.getCalendarFolderById(flatFolders, updatedFolder.id);
      //     console.log("[processModifiedData]getFolderById", updatedFolder, f);
      //       if (!!f) {
      //         if (updatedFolder.l && f.l !== updatedFolder.l) {
      //           if (f.l === "1") {
      //             this.store.dispatch(new DeleteCalendarFolderSuccess({folder: f}));
      //             console.log("[processModifiedData][DeleteMailFolderSuccess]");
      //           } else {
      //             this.deleteCalendarFolderFromParent(f);
      //           }
      //           const newFolder = {...f, ...updatedFolder};
      //           this.moveToNewCalendarParent(newFolder, updatedFolder.l);
      //         } else {
      //           const newFolder = {...f, ...updatedFolder};
      //           this.updateCalendarFolder(newFolder);
      //         }
      //         this.updateCalendarEventColor(f);
      //       } else {
      //         this.updateCalendarFolder(folder);
      //         this.updateCalendarEventColor(folder);
      //       }
      //   } else {
      //     this.store.pipe(select(state => getSharedCalendarFolderById(state, updatedFolder.id)), take(1)).subscribe(f => {
      //       console.log("[getSharedFolderById]", updatedFolder.id, f);
      //       if (!!f) {
      //         this.store.dispatch(new UpdateCalendarFolderSuccess({ id: updatedFolder.id, changes: updatedFolder }));
      //       }
      //     });
      //   }
      // });
    }

    // LINKS calendar
    if (modifiedList.link && this.router.url.startsWith("/calendar")) {
      const folders = Array.isArray(modifiedList.link) ? modifiedList.link : [modifiedList.link];
      console.log("[processModified Share Calendar] folders", folders);
      let flatFolders: CalendarFolder [] = [];
      this.store.select(getCalendarFolders).pipe(take(1)).subscribe(fold => {
        flatFolders = fold;
      });
      folders.filter(f => Object.keys(f).length > 2 && f.id !== "1").forEach(folder => {
        const updatedFolder = folder;
        if (updatedFolder.id.indexOf(":") === -1) {
          const f = CalenderUtils.getCalendarFolderById(flatFolders, updatedFolder.id);
          console.log("[processModifiedData]getFolderById", updatedFolder, f);
            if (!!f) {
              if (updatedFolder.l && f.l !== updatedFolder.l) {
                if (f.l === "1") {
                  this.store.dispatch(new DeleteCalendarFolderSuccess({folder: f}));
                  console.log("[processModifiedData][DeleteMailFolderSuccess]");
                } else {
                  this.deleteCalendarFolderFromParent(f);
                }
                const newFolder = {...f, ...updatedFolder};
                this.moveToNewCalendarParent(newFolder, updatedFolder.l);
              } else {
                const newFolder = {...f, ...updatedFolder};
                this.updateCalendarFolder(newFolder);
              }
              this.updateCalendarEventColor(f);
            } else {
              this.updateCalendarFolder(folder);
              this.updateCalendarEventColor(folder);
            }
        } else {
          this.store.pipe(select(state => getSharedFolderById(state, updatedFolder.id)), take(1)).subscribe(f => {
            console.log("[getSharedFolderById]", updatedFolder.id, f);
            if (!!f) {
              this.store.dispatch(new UpdateCalendarFolderSuccess({ id: updatedFolder.id, changes: updatedFolder }));
            }
          });
        }
      });
    }

    // FOLDERS mail
    if (modifiedList.folder && this.router.url.startsWith("/mail")) {
      const folders = Array.isArray(modifiedList.folder) ? modifiedList.folder : [modifiedList.folder];
      // console.log("[AppComponent][processModifiedData] folders", folders);

      folders.filter(f => Object.keys(f).length > 2 && f.id !== "1").forEach(folder => {
        const updatedFolder = folder;
        if (this.electronService.isElectron && folder.id === "2" && folder.u !== undefined) {
          this.electronService.setBadge(folder.u);
        }
        if (updatedFolder.id.indexOf(":") === -1) {
          let f = this.mailService.flatFolders[updatedFolder.id];
          console.log("[AppComponent][processModifiedData] getFolderById", updatedFolder, this.mailService.flatFolders, f);
            if (!!f) {
              if (updatedFolder.l && f.l !== updatedFolder.l) {
                if (f.l === "1") {
                  this.store.dispatch(new DeleteMailFolderSuccess({folder: f}));
                } else {
                  this.deleteFolderFromParent(f);
                }
                const newFolder = {...f, ...updatedFolder};
                this.moveToNewParent(newFolder, updatedFolder.l);
                this.mailService.flatFolders[updatedFolder.id] = newFolder;
              } else {
                const newFolder = {...f, ...updatedFolder};
                if (!this.router.url.startsWith("/mail/compose") ||
                this.router.url.startsWith("/mail/compose") && updatedFolder.id !== "6") {
                  this.updateFolder(newFolder);
                  // console.log("[AppComponent][processModifiedData] updateFolder", updatedFolder, f);
                }
              }
            } else {
              this.updateFolder(folder);
            }
        } else {
          this.store.pipe(select(state => getSharedFolderById(state, updatedFolder.id)), take(1)).subscribe(f => {
            // console.log("[getSharedFolderById]", updatedFolder.id, f);
            if (!!f) {
              this.store.dispatch(new UpdateMailFolderSuccess({ id: updatedFolder.id, changes: updatedFolder }));
            }
          });
        }
      });
      // this.broadcaster.broadcast("GET_ALL_FOLDERS");
    }

    // FLDERS briefcase
    if (modifiedList.folder && this.router.url.startsWith("/briefcase")) {
      const folders = Array.isArray(modifiedList.folder) ? modifiedList.folder : [modifiedList.folder];
      // console.log("[processModifiedData] folders", folders);
      folders.filter(f => Object.keys(f).length > 2).forEach(folder => {
        const updatedFolder = folder;
        if (updatedFolder.id.indexOf(":") === -1) {
          this.store.pipe(select(state => getBriefcaseFolderById(state, updatedFolder.id)), take(1)).subscribe(f => {
            if (!!f) {
              this.store.dispatch(new UpdateBriefcaseFolderSuccess({ id: updatedFolder.id, changes: updatedFolder }));
            } else {
              this.store.pipe(select(state => getBriefcaseChildFolderById(state, updatedFolder.id)), take(1)).subscribe(child => {
                if (!!child) {
                  this.store.pipe(select(getBriefcaseFolders), take(1)).subscribe(_folders => {
                    // this.allFolders = _folders;
                    const parentFolder = MailUtils.getParent(_folders, child.l);
                       if (parentFolder) {
                        MailUtils.updateChildFolder(parentFolder, updatedFolder);
                    }
                  });
                }
              });
            }
          });
        } else {
          this.store.pipe(select(state => getBriefcaseSharedFolderById(state, updatedFolder.id)), take(1)).subscribe(f => {
            // console.log("[getSharedFolderById]", updatedFolder.id, f);
            if (!!f) {
              this.store.dispatch(new UpdateBriefcaseFolderSuccess({ id: updatedFolder.id, changes: updatedFolder }));
            }
          });
        }
      });
      this.broadcaster.broadcast("UPDATE_BRIEFCASE_FOLDERS");
    }
  }

  private processMessagesMoveOpSync(messages: any[], toFolderId: number, norefresh?: boolean) {
    console.log("[AppComponent][processMessagesMoveOpSync]", messages.length, messages, toFolderId);

    let op;
    if (toFolderId === MailConstants.FOLDER_ID.TRASH) {
      op = "trash";
    } else if (toFolderId === MailConstants.FOLDER_ID.JUNK) {
      op = "spam";
    } else {
      op = "move";
    }
    //
    // TODO: what other ops can be?

    if (messages.length > 0) {
      const mids = messages.map(m => m.id);
      if (this.router.url.indexOf("/detail/m/") !== -1) {
        let msgId = this.router.url.split("/detail/m/")[1];
        console.log("[AppComponent][processMessagesMoveOpSync] check it should close the mail detail ", msgId, op, toFolderId);
        if ((messages.find(v => v.id === msgId)) && (!CommonUtils.isOnMobileDevice())) {
          console.log("[AppComponent][processMessagesMoveOpSync] send signal to close the mail detail ", msgId);
          this.broadcaster.broadcast("messageIsMoved", messages.find(v => v.id === msgId));
        }
      } else if (this.router.url.indexOf("/detail/") !== -1) {
        let convId = this.router.url.split("/detail/")[1];
        console.log("[AppComponent][processMessagesMoveOpSync] check it should close the mail detail ", convId, op, toFolderId);
        this.broadcaster.broadcast("messagesAreMoved", messages);
      }
      // update messages DB
      const req = {
        op,
        l: toFolderId,
        id: mids.join(",")
      };
      // console.log("[app.component][this.convRepository.messageActionApplyToDB] ", req);
      this.convRepository.messageActionApplyToDB(req).subscribe();

      // update messages redux - cleanup if other folder is opened now
      let currentFolderId: number;
      this.store.select(getCurrentFolder).subscribe(fId => {
        console.debug("[AppComponent][processMessagesMoveOpSync] currentFolderId: ", fId);
        currentFolderId = fId;
      });

      // ToDo: check if we can skip redux in background
      // const skipRedux = environment.isCordova ? window.appInBackground : false;

      if (toFolderId !== currentFolderId) {
        console.debug("[AppComponent][processMessagesMoveOpSync] RemoveManyMessages", mids);
        this.store.dispatch(new RemoveManyMessages(mids));
        this.store.dispatch(new RemoveMultipleTabs(mids));
      }

      console.log("[AppComponent][processMessagesMoveOpSync] fin: ", toFolderId, currentFolderId, op, messages.length, messages);

      // update conv (DB & Redux)

      if (messages.length > 0) {
        this.databaseService.updateConvMessagesinDB([...messages], toFolderId).pipe(take(1)).subscribe(() => {
          console.log("[AppComponent][processMessagesMoveOpSync] finished db updates");
        });
      }

    } else {
      this.bgSyncInProgress = false;
      this.changeDetectionRef.markForCheck();
    }
  }


  private processMessagesMoveOp(messages: any[], toFolderId: number, norefresh?: boolean) {
    console.log("[AppComponent][processMessagesMoveOp1]", messages.length, messages, toFolderId);

    let op;
    if (toFolderId === MailConstants.FOLDER_ID.TRASH) {
      op = "trash";
    } else if (toFolderId === MailConstants.FOLDER_ID.JUNK) {
      op = "spam";
    } else {
      op = "move";
    }
    //
    // TODO: what other ops can be?

    if (messages.length > 0) {
      const mids = messages.map(m => m.id);
      if (this.router.url.indexOf("/detail/m/") !== -1) {
        let msgId = this.router.url.split("/detail/m/")[1];
        console.log("[AppComponent][processMessagesMoveOp] check it should close the mail detail ", msgId, op, toFolderId);
        if ((messages.find(v => v.id === msgId)) && (!CommonUtils.isOnMobileDevice())) {
          console.log("[AppComponent][processMessagesMoveOp] send signal to close the mail detail ", msgId);
          this.broadcaster.broadcast("messageIsMoved", messages.find(v => v.id === msgId));
        }
      } else if (this.router.url.indexOf("/detail/") !== -1) {
        let convId = this.router.url.split("/detail/")[1];
        console.log("[AppComponent][processMessagesMoveOp] check it should close the mail detail ", convId, op, toFolderId);
        this.broadcaster.broadcast("messagesAreMoved", messages);
      }
      // update messages DB
      const req = {
        op,
        l: toFolderId,
        id: mids.join(",")
      };
      // console.log("[app.component][this.convRepository.messageActionApplyToDB] ", req);
      this.convRepository.messageActionApplyToDB(req).subscribe(() => {
        if ((this.currentView === "message") && (messages.length > 0)) {
          this.store.dispatch(new UpdateManyMessagesSuccess(messages));
          setTimeout(() => {
            if (CommonUtils.isOnAndroid()) {
              this.broadcaster.broadcast("REFRESH_ONE_PAGE_SYNC_MOBILE");
            } else {
              this.broadcaster.broadcast("REFRESH_ONE_PAGE_SYNC");
            }
          }, 500);
        }
      });

      // update messages redux - cleanup if other folder is opened now
      let currentFolderId: number;
      this.store.select(getCurrentFolder).subscribe(fId => {
        currentFolderId = parseInt(fId, 10);
      });

      if (toFolderId !== currentFolderId) {
        this.store.dispatch(new RemoveManyMessages(mids));
        this.store.dispatch(new RemoveMultipleTabs(mids));
      }
      const movedMailIds = messages.map(m => m.id);
      console.log("[AppComponent][processMessagesMoveOpfin] fin: ", toFolderId, currentFolderId, op, messages.length, messages);
      const moved = {
        toFolderId: toFolderId,
        ids: movedMailIds
      };
      this.broadcaster.broadcast("PENDING_MESSAGES_MOVED", moved);


      // update conv (DB & Redux)

      if (messages.length > 0) {
        this.applyMessageMoveOpToConv([...messages], toFolderId, op, norefresh);
      }

    } else {
      this.bgSyncInProgress = false;
      this.changeDetectionRef.markForCheck();
    }
  }

  private applyMessageMoveOpToConv(messages: any[], toFolderId: number, op: string, norefresh: boolean) {
    console.debug("[applyMessageMoveOpToConv1]", messages.length, [...messages], toFolderId, op);
    if ((messages.length > 1) && (this.currentView === "conversation")) {
      console.log("applyMessageMoveOpToConv -> setSyncRequestInProgress");
      this.convRepository.setSyncRequestInProgess();
    } else {
      setTimeout(() => {
        console.log("[AppComponent][applyMessageMoveOpToConv] bgMetaSyncDone, setSyncRequestDone");
        this.convRepository.setSyncRequestDone();
        let nts = new Date().getTime() + 5;
      	this.bgMetaSyncDone.next(nts);
        this.bgSyncInProgress = false;
        this.changeDetectionRef.markForCheck();
      }, 2000);
    }
    // process one by one
    const m = messages.shift();
    if (!!m) {
      // console.log("[applyMessageMoveOpToConv] m: ", m);
      this.convRepository.getMessageCidFromDB(m).subscribe(cid => {
        if (cid) {
          // console.debug("[applyMessageMoveOpToConv]1", messages, m, cid);
          this._applyMessageMoveOpToConvInternal(messages, toFolderId, op, m.id, cid, norefresh);
        } else {
          // try to find cid by mid from redux (this is a bit slow)...
          this.convRepository.getCurrentConversations().pipe(take(1)).subscribe(convs => {
            let cid: string;
            for (let conv of convs) {
              console.debug("[applyMessageMoveOpToConv] conv: ", conv);
              const convMsg = conv.m?.find(cm => cm.id === m?.id);
              if (convMsg) {
                cid = conv.id;
              }
            }
            if (cid) {
              // console.debug("[applyMessageMoveOpToConv]2", messages, cid);
              // console.log("[AppComponent][applyMessageMoveOpToConv] found cid for mid in redux", m.id, cid);
              this._applyMessageMoveOpToConvInternal(messages, toFolderId, op, m.id, cid, norefresh);
            } else {
              // console.debug("[applyMessageMoveOpToConv]3", messages, toFolderId, op);
              if (messages.length > 0) {
                this.applyMessageMoveOpToConv(messages, toFolderId, op, norefresh);
              }
            }
          });
        }
      });
    } else {
      this.bgSyncInProgress = false;
      this.changeDetectionRef.markForCheck();
      // console.warn("[applyMessageMoveOpToConv] no m? ");
    }

  }

  private _applyMessageMoveOpToConvInternal(messages: any[], toFolderId: number, op: string, mid: string, cid: string, norefresh: boolean) {
    // console.debug("_applyMessageMoveOpToConvInternal start", messages, toFolderId, op, mid, cid);
    this.convRepository.getConversationByIdFromDB(cid).subscribe(conv => {
      // console.debug("_applyMessageMoveOpToConvInternal conv: ", conv);
      if (conv && conv.m) {
        const convMsg = conv.m.find(cm => cm.id === mid);
        // console.log("[AppComponent][_applyMessageMoveOpToConv] getConversationByIdFromDB, conv:", conv.id, [...conv.m], convMsg, mid);

        if (convMsg) {
          convMsg.l = toFolderId + "";

          // if all conv's messages moved -> then move the entire conv
          if (conv.m.every(el => +el.l === +toFolderId)) {
            // console.log("[AppComponent][_applyMessageMoveOpToConv] all conv messages are moved");
            this.bgSyncInProgress = false;
            this.changeDetectionRef.markForCheck();

            // update in redux - cleanup if other folder is opened now
            let currentFolderId: number;
            this.store.select(getCurrentFolder).subscribe(fId => {
              currentFolderId = fId;
            });
            if (toFolderId !== currentFolderId) {
              // console.log("[AppComponent][_applyMessageMoveOpToConv] RemoveManyConversations: ", conv.id);
              this.store.dispatch(new RemoveManyConversations([conv.id]));
              this.store.dispatch(new RemoveMultipleTabs([conv.id]));
            // update in redux
            } else {
              const changes: any = {
                m: conv.m,
                l: toFolderId + "",
                query: conv.query
              };
              // console.log("[AppComponent][_applyMessageMoveOpToConv] changes: ", changes);
              this.store.dispatch(new UpdateConversationSuccess({id: conv.id,  changes}));
              if (!norefresh) {
                if (window.appInBackground) {
                  console.log("skip conv refresh in background");
                } else {
                  setTimeout(() => {
                    console.log("[AppComponent][_applyMessageMoveOpToConv] REFRESH");
                    this.bgSyncInProgress = false;
                    this.changeDetectionRef.markForCheck();
                    this.broadcaster.broadcast(MailConstants.REFRESH_CONVS_LIST_SYNC);
                  }, 200);
                }
              }

            }

            // update conv related data in DB
            this.convRepository.conversationActionApplyToDB(
              {
                op,
                l: toFolderId,
                id: conv.id
              }).subscribe();

            // update conv in DB
            conv.l = toFolderId + "";
            conv.query = MailUtils.getQueryByFolderId(parseInt(conv.l, 10));
            //
            // console.log("[AppComponent][_applyMessageMoveOpToConv] updateDB: ", conv);
            this.convRepository.updateConversationsInDB([conv]).subscribe(res => {
              if (messages.length > 0) {
                this.applyMessageMoveOpToConv(messages, toFolderId, op, norefresh);
              }
            });
          } else {
            this.convRepository.updateConversationsInDB([conv]).subscribe(res => {
              if (messages.length > 0) {
                this.applyMessageMoveOpToConv(messages, toFolderId, op, norefresh);
              }
            });
          }
        } else {
          if (messages.length > 0) {
            this.applyMessageMoveOpToConv(messages, toFolderId, op, norefresh);
          } else {
            console.log("[AppComponent][_applyMessageMoveOpToConv] finished - bgMetaSyncDone");
            let nts = new Date().getTime();
            this.bgMetaSyncDone.next(nts);
            this.bgSyncInProgress = false;
            this.changeDetectionRef.markForCheck();
          }
        }
      } else {
        if (messages.length > 0) {
          // console.log("[AppComponent][_applyMessageMoveOpToConv] else messages: ", messages);
          this.applyMessageMoveOpToConv(messages, toFolderId, op, norefresh);
        } else {
          // handle stale indexedDB content - remove from redux only as it is not in DB
          // console.log("[AppComponent][_applyMessageMoveOpToConv] stale: ", cid);
          this.bgSyncInProgress = false;
          this.changeDetectionRef.markForCheck();
          this.store.dispatch(new RemoveManyConversations([cid]));
          this.store.dispatch(new RemoveMultipleTabs([cid]));
        }
      }
    }, err => {
      // console.log("[AppComponent][_applyMessageMoveOpToConv] error: ", err);
      let nts = new Date().getTime();
      this.bgMetaSyncDone.next(nts);
      this.bgSyncInProgress = false;
      this.changeDetectionRef.markForCheck();
    });
  }

  deleteFolderFromParent(f: MailFolder) {
    // console.log("[AppComponent][deleteFolderFromParent]", f);
    this.removeChildFromParent(f);
  }

  addChildFolder(parent: MailFolder, newFolder: any): void {
    if (parent.id === newFolder.l) {
      if (!parent.children) {
        parent.children = [newFolder as MailFolder];
      } else if (!parent.children.find(fd => fd.id === newFolder.id)) {
        parent.children.push(newFolder as MailFolder);
      }
    } else if (parent.children && parent.children.length > 0) {
      parent.children = parent.children.map(folder => {
        this.addChildFolder(folder, newFolder);
        return folder;
      });
    }
  }

  addChildToParent(folder) {
    console.log("[addChildToParent]", folder);
    if (!!folder) {
      this.ngZone.run(() => {
        const parentFolder = MailUtils.getParentById(this.mailService.flatFolders, folder.l);
        if (parentFolder) {
          MailUtils.addChildFolder(parentFolder, folder);
          this.store.dispatch(new UpdateMailFolderSuccess({ id: parentFolder.id, changes: parentFolder }));
          console.log("[addChildToParent] found the parent and update", parentFolder);
        }
      });
    }
  }

  removeChildFromParent(folder) {
    const updatedFolder = this.mailService.flatFolders[folder.id];
    if (updatedFolder) {
      const parentFolder = MailUtils.getParentById(this.mailService.flatFolders, updatedFolder.l);
      console.log("[removeChildFromParent] found the folder", updatedFolder);
      if (parentFolder) {
        MailUtils.removeChildFolder(parentFolder, folder);
        this.store.dispatch(new UpdateMailFolderSuccess({ id: parentFolder.id, changes: parentFolder }));
        console.log("[removeChildFromParent] found the parent and update", parentFolder);
      }
    }
  }

  private processCreatedData(createdList: any, modifiedList?: any): void {
    console.log("[processCreatedData]",  createdList);

    let messages = [];

    // set 'query' from 'l'
    if (createdList.m) {
      createdList.m.forEach(m => {
        if (m.l) {
          m.query = MailUtils.getQueryByFolderId(parseInt(m.l, 10));
        }
      });
    }

    // MESSAGES
    if (createdList.m) {
      messages = Array.isArray(createdList.m) ? createdList.m : [createdList.m];
      messages = messages.map(m => {
        return MailUtils.mapEmailMessage(m);
      });

      // store messages in DB
      this.convRepository.addMessagesToDB(messages).subscribe(() => {
        console.log("[app.component] processCreatedData stored to DB, setSyncRequestDone ");
        this.convRepository.setSyncRequestDone();
      });

      messages.forEach(m => {
        let senderEmail = m.e.find(v => v.t === "f").a;

        if (this.currentView === "conversation") {
          this.store.select(state => getConversationById(state, m.cid)).pipe(take(1)).subscribe(conv => {
            // console.log("[createdList] getConversationById", m.cid, conv);
            if (!!conv) {
              if (senderEmail && this.currentUser && senderEmail !== this.currentUser.email && !this.isImapFolder(conv.l)) {
                if (!this.isUpdatedConv) {
                  this.handleMessageNotification(m);
                }
                this.isUpdatedConv = false;
              }
              if (!conv.m.find(msg => msg.id === m.id)) {
                conv.fr = m.fr;
                m.body = "";
                conv.m.push(m);
                conv.e = m.e;
                let changes: any = {e: conv.e, m: conv.m};
                if (this.currentFolderId.toString() === "5") {
                  conv.d = m.d;
                  changes = {e: conv.e, m: conv.m, d: m.d};
                }
                if (!!localStorage.getItem("sortBy") && localStorage.getItem("sortBy") === "date"
                && this.currentFolderId.toString() === "5") {
                  changes.sf = m.d;
                }
                // console.log("[createdList] UpdateConversationSuccess", m.cid, conv);
                this.store.dispatch(new UpdateConversationSuccess({id: conv.id, changes: changes}));
              }
            }
          });
        } else {
          if (senderEmail && this.currentUser && senderEmail !== this.currentUser.email && !this.isImapFolder(m.l)) {
            // console.log("[createdList] handleMessageNotification", senderEmail);
            this.handleMessageNotification(m);
          }
        }
      });

      if (this.currentView === "message") {
        const msgs = messages.filter(m => m.l.toString() === this.currentFolderId.toString());
        this.store.dispatch(new LoadMailSuccessAction(msgs));

        // console.log("[LoadMailSuccessAction]", messages);
      }

      // for electron / web: sync messgae content in background when there are new mails and the last sync was > 5 minutes ago
      const hasLastMessageTimestamp = localStorage.getItem("lastMessageTimestamp");
      const nts = Date.now();
      // console.log("[processNoopRequestData maybestart: ", createdList.m, hasLastMessageTimestamp, CommonUtils.isOfflineModeSupported(), this.lastNoOpResync);
      if (!environment.isCordova && CommonUtils.isOfflineModeSupported() && !hasLastMessageTimestamp && createdList.m && createdList.m.length > 0 && ((nts - this.lastNoOpResync) > 300000)) {
        this.lastNoOpResync = nts;

        let syncToken: any = localStorage.getItem("syncRequestToken");
        console.log("[AppComponent] syncRequest, syncToken:", syncToken);
        // this.electronService.remoteLog("[AppComponent] syncRequest, syncToken:", syncToken);
        if (!!syncToken) {
          this.convRepository.syncRequest(syncToken).subscribe(res => {
            console.log("[AppComponent] processCreated syncRequest, res: ", res);
            // this.electronService.remoteLog("[AppComponent] syncRequest, res: ", res);
            this.processSyncRequestData(res, true);
          }, err => {
            console.error("[AppComponent] syncRequest1 error: ", err);
            // this.electronService.remoteLog("[AppComponent] syncRequest1 error: ", err);
            this.convRepository.syncRequestResync().subscribe(res => {
              console.log("[AppComponent] syncRequestAfterTimestamp, res: ", res);
              // this.electronService.remoteLog("[AppComponent] syncRequestAfterTimestamp, res: ", res);

              this.processSyncRequestData(res, true);

            }, err => {
              console.error("[AppComponent] syncRequest1 resync error: ", err);
              this.electronService.remoteLog("[AppComponent] syncRequest1 resync error: ", err);
            });
          });
        }
      }
    }


    // FOLDERS
    if (createdList.folder && this.router.url.startsWith("/mail")) {
      const folders = Array.isArray(createdList.folder) ? createdList.folder : [createdList.folder];
      console.log("[createdList] folders", folders);
      folders.forEach(folder => {
        this.store.pipe(select(state => getFolderById(state, folder.id)), take(1)).subscribe(f => {
          if (!f) { // if folder is not in store
            if (folder.l.toString() !== MailConstants.ROOT_MAIL_FOLDER_ID) { // if the parent is not root folder
              if (!!folder && folder.f && folder.f.indexOf("y") !== -1) {
                this.mailService.flatFolders[folder.id] = folder;
                this.mailService.flatArrayFolders.push(folder);
              }
              this.addChildToParent(folder);
            } else {
              this.store.dispatch(new CreateMailFolderSuccess({ folder: folder as MailFolder }));
            }
          }
        });
      });
    }
    //
    //
    if (createdList.folder && this.router.url.startsWith("/briefcase")) {
      const folders = Array.isArray(createdList.folder) ? createdList.folder : [createdList.folder];
      folders.forEach(folder => {
        folder = folder;
        this.store.pipe(select(state => getBriefcaseChildFolderById(state, folder.id)), take(1)).subscribe(f => {
          if (!f) { // not root folder
            if (folder.l.toString() !== MailConstants.ROOT_MAIL_FOLDER_ID) {
              this.store.pipe(select(getBriefcaseFolders), take(1)).subscribe(_folders => {
                const parentFolder = MailUtils.getParent(_folders, folder.l);
                   if (parentFolder) {
                    MailUtils.addChildFolder(parentFolder, folder);
                    this.store.dispatch(new UpdateBriefcaseFolderSuccess({ id: parentFolder.id, changes: parentFolder }));
                }
              });
            } else {
              this.store.dispatch(new CreateBriefcaseFolderSuccess({ folder: folder as BriefcaseFolder }));
            }
          }
        });
      });
      console.log("[createdList Briefcase] folders", folders);
    }

    // TAG
    if (createdList.tag && this.router.url.startsWith("/mail")) {
      const tags = Array.isArray(createdList.tag) ? createdList.tag : [createdList.tag];
      // console.log("[createdList] tags", tags);
      tags.forEach(tag => {
        const newTag = tag as MailTag;
        this.store.pipe(select(state => getTagById(state, newTag.id)), take(1)).subscribe(t => {
          if (!t) {
            // console.log("[CreateMailTagSuccess]", newTag);
            this.store.dispatch(new CreateMailTagSuccess(newTag));
          }
        });
      });
    }

    // CONVS
    let newConvIds = [];
    if (createdList.c) {
      const conversations = Array.isArray(createdList.c) ? createdList.c : [createdList.c];

      // process
      const newConversations = conversations.map(c => {
        const m = messages.filter(message => message.cid === c.id);
        let folderId = 2;
        if (m.length > 0) {
          folderId = m[0].l;
          c.l = m[0].l;
          c.fr = m[0].fr;
        }
        if (!!localStorage.getItem("originalConv")) {
          try {
            let originalConv = JSON.parse(localStorage.getItem("originalConv"));
            const convId = c.id.toString();
            // console.log("[processCreatedData] originalConv", originalConv[convId]);
            if (originalConv[convId]) {
              if (this.currentFolderId.toString() !== "5") {
                c.sf = originalConv[convId].sf;
                c.d = originalConv[convId].d;
              }
              // console.log("[processCreatedData] originalConv", c, originalConv[convId]);
              delete originalConv[convId];
              localStorage.setItem("originalConv", JSON.stringify(originalConv));
            }
          } catch (ex) {
          }
        }
        if (!c.sf) {
          c.sf = c.d;
        }
        c.m = m;
        if (c.n === 2 && c.m.length === 1 && modifiedList && modifiedList.m && modifiedList.m.find((msg: any) => c.id === msg.cid)) {
          const message = modifiedList.m.find((msg: any) => c.id === msg.cid);
          c.m.push({...message, ...{l: folderId, d: c.d.toString()}});
          // folderId = 2;
        }
        if (this.router.url.startsWith("/mail/sent") && m.filter(v => v.l === "5").length > 0) {
          folderId = 5;
        }
        // console.log("[processCreatedData][createdList] c", c);
        if (folderId.toString().indexOf(":") !== -1) {
          this.store.pipe(select(state => getSharedFolderById(state, folderId.toString())), take(1)).subscribe(f => {
            if (!!f) {
              folderId = f.id;
            }
          });
        }
        let query = MailUtils.getQueryByFolderId(folderId);
        const conv = MailUtils.mapConversation(c, { query: query, originalQuery: query, types: "conversation" });
        conv.l = folderId.toString();
        if (m[0]) {
          conv.e = m[0].e;
        }
        const allRecipients = [];
        if (conv.m) {
          conv.m.forEach(m => {
            if (m.e) {
              m.e.forEach(e => {
                if (allRecipients.find(r => r.a === e.a && r.t === e.t)) {
                  allRecipients.push(e);
                }
              });
            }
          });
        }
        // console.log("[processCreatedData][createdList] check send to me", conv);
        if (this.currentUser && this.currentFolderId === 2 && conv.l === "5" && conv.senderEmail === this.currentUser.email
          && (allRecipients.find(e => e.t === "t" && e.a === this.currentUser.email) || conv.n.toString() === "2")) {
            if (conv.f && conv.f === "sv" && conv.l && conv.l === "5" && !this.configService.deleteInvitationOnProposeNewTime) {
              conv.query = "in:sent";
              conv.l = "5";
            } else {
              conv.query = "in:inbox";
              conv.l = "2";
            }
            // console.log("[processCreatedData][createdList] check send to me => found", conv);
        }
        return conv;
      });


      // store convs in DB
      this.convRepository.addConversationsToDB(newConversations).subscribe();


      // console.log("[processCreatedData][newConversations]", newConversations);
      if (this.currentUser && newConversations.length > 0 && this.router.url.startsWith("/mail")) {
        let existingIds = [];
        this.store.select(state => getConversationsByIds(state, newConversations.map(c => c.id))).pipe(take(1)).subscribe(convs => {
          existingIds = convs.filter(c => !!c).map(c => c.id);
        });
        let newConversationsToAppend = newConversations.filter(c => existingIds.indexOf(c.id) === -1);
        const newConversationsToUpdate = newConversations.filter(c => existingIds.indexOf(c.id) !== -1);
        if (this.currentFolderId === 2) {
          newConversationsToAppend = newConversationsToAppend
          .filter(c => c.n > 1 || (c.n === 1
            && (c.senderEmail !== this.currentUser.email || c.senderEmail === this.currentUser.email
              && c.e.find(e => e.t === "t" && e.a === this.currentUser.email))));
        }
        if (newConversationsToUpdate.length > 0) {
          // console.log("[processCreatedData][newConversationsToUpdate]", newConversationsToUpdate);
          this.store.dispatch(new UpdateManyConversationsSuccess(newConversationsToUpdate));
        }
        const calenarInviteReplyMail = newConversationsToAppend.filter( c => c.f && c.f.indexOf("r") !== -1 && c.f.indexOf("v") !== -1)[0];
        if (!!calenarInviteReplyMail && calenarInviteReplyMail !== null) {
          const index = _.findIndex(newConversationsToAppend, {id: calenarInviteReplyMail.id.toString()});
          if (index !== -1) {
            newConversationsToAppend.splice(index, 1);
          }
        }
        newConvIds = newConversationsToAppend.map(conv => conv.id);
        // console.log("[processCreatedData][newConversationsToAppend]", newConversationsToAppend);
        this.store.dispatch(new LoadConversationSuccessAction(newConversationsToAppend));
      }

      if (this.currentUser) {
        newConversations.filter(c => c.senderEmail && c.senderEmail !== this.currentUser.email).forEach(conv => {
          if (!this.isDataSourceFolder(conv.l)) {
            if (!this.isImapFolder(conv.l) && this.currentView === "conversation" && conv.fr) {
              this.handleNotification(conv);
            }
          }
        });
      }
    }

    // MESSAGES (again?)
    if (createdList.m) {
      // console.log("[processCreatedData] m", messages, this.currentFolderId.toString());

      messages.forEach(m => {
        if (m.e) {
          let sender = m.e.find(f => f.t === "f").a;
          if (!!sender) {
            m.senderEmail = sender.a;
          }
        }
        if (this.currentView === "conversation") {
          this.store.select(state => getConversationById(state, m.cid)).pipe(take(1)).subscribe(conv => {
            // console.log("[processCreatedData] getConversationById", m.cid, conv);
            if (!!conv) {
              if (!conv.m.find(msg => msg.id === m.id)) {
                conv.fr = m.fr;
                m.body = "";
                if (this.currentFolderId.toString() === "5") {
                  conv.d = m.d;
                }
                if (!!localStorage.getItem("sortBy") && localStorage.getItem("sortBy") === "date"
                && this.currentFolderId.toString() === "5") {
                  conv.sf = m.d;
                }
                conv.e = m.e;
                conv.m.push(m);
                if (newConvIds.indexOf(conv.id) !== -1 && m.l === "5") {
                  conv.query = "in:sent";
                  conv.originalQuery = "in:sent";
                }
                // console.log("[processCreatedData] UpdateManyConversationsSuccess", conv);
                this.store.dispatch(new UpdateManyConversationsSuccess([conv]));
                this.broadcaster.broadcast(MailConstants.UPDATE_SELECTED_CONVERSATION_MESSAGE, m);
              }
            }
          });
        }
      });

      if (this.currentView === "message") {
        messages = messages.filter(m => m.l.toString() === this.currentFolderId.toString());
        this.store.dispatch(new LoadMailSuccessAction(messages));
      }
    }

    // CALENDAR Event
    if (createdList.appt) {
      const createAppointmentList = createdList.appt;
      createAppointmentList.map(app => {
        const component = app.inv[0].comp[0];
        const ridZ = this.getAppointmentRidz(component.s[0].d);
        const eventId =  app.id + "_" + ridZ;
        this.store.select(state => getCalendarAppointmentsByIds(state, [eventId])).pipe(take(1)).subscribe(events => {
          const appointmentItem = events[0];
          if (appointmentItem === undefined) {
            const calEvent: any = this.getCalenderEvent(app);
            if (app.nextAlarm) {
              const componentItem = app.inv[0].comp[0];
              calEvent.alarmData = [
                {
                  "nextAlarm": app.nextAlarm,
                  "invId": app.id,
                  "compNum": 0,
                  "name": componentItem.name,
                  "loc": "",
                  "alarm": [
                    {
                      "action": "DISPLAY",
                      "trigger": [
                        {
                          "rel": [
                            {
                              "neg": true,
                              "m": 5,
                              "related": "START"
                            }
                          ]
                        }
                      ],
                      "desc": [
                        {}
                      ]
                    }
                  ]
                }
              ];
            }
            console.log("[NoOp][createList][Create Appointment] : ", calEvent);

             // store createAppointmentList in DB
            this.calendarRepository.addAllAppointmentsToDB([calEvent]).subscribe(() => {
              console.log("[app.component] processCreatedData stored to DB, setSyncRequestDone ");
              // this.convRepository.setSyncRequestDone();
              this.calendarRepository.getAllAppointments();
            });
            this.store.dispatch(new CreateCalendarAppointmentSuccessAction({ appointment: calEvent }));
          }
        });
      });
    }
    //
    //
    if (this.router.url.startsWith("/calendar") && createdList.folder) {
      const folders = Array.isArray(createdList.folder) ? createdList.folder : [createdList.folder];
      folders.forEach(folder => {
        folder = folder;
        this.store.pipe(select(state => getCalendarFolderById(state, folder.id)), take(1)).subscribe(f => {
          if (!f) {
            if (folder.l.toString() !== MailConstants.ROOT_MAIL_FOLDER_ID) {
              this.addChildCalendarFolderToParent(folder);
            } else {
              this.store.dispatch(new CreateCalendarFolderSuccess({ folder: folder as CalendarFolder }));
            }
          }
        });
      });
      // console.log("[createdList] folders", folders);
    }

  }

  private handleNotification(conv: Conversation): void {
    // console.log("[handleNotification]", conv, this.currentUser, this.configService.prefs);
    console.log("[handleNotification][conversation]: ", conv);
    console.log("[handleNotification][zimbraPrefMailToasterEnabled]: ", this.configService.prefs.zimbraPrefMailToasterEnabled);
    if (conv.f && conv.f.indexOf("u") !== -1) {
      this.zimbraNotification();
      if (this.configService.prefs.zimbraPrefMailToasterEnabled === "TRUE") {
        const isShowNotification = this.isShowNotification(conv);
        console.log("[handleNotification][isShowNotification]: ", isShowNotification);
        if (isShowNotification) {
          this.notificationsService.newConversation(conv.su, conv.fr, conv);
        }
      }
    }
  }

  private zimbraNotification(): void {
    if (this.configService.prefs.zimbraPrefMailFlashTitle === "TRUE") {
      this.notificationsService.flashTitle("NEW_MESSAGE");
    }
    if (this.configService.prefs.zimbraPrefMailSoundsEnabled === "TRUE") {
      this.notificationsService.playReceiveMessage();
    }
  }

  private handleMessageNotification(msg: Message): void {
    console.log("[handleMessageNotification]", msg, this.currentUser);
    if (msg.f && msg.f.indexOf("u") !== -1) {
      this.zimbraNotification();
      console.log("[handleMessageNotification][zimbraPrefMailToasterEnabled]: " , this.configService.prefs.zimbraPrefMailToasterEnabled);
      if (this.configService.prefs.zimbraPrefMailToasterEnabled === "TRUE") {
        const isShowNotification = this.isShowNotification(msg);
        console.log("[handleMessageNotification][isShowNotification]", isShowNotification);
        if (isShowNotification) {
          this.notificationsService.newConversation(msg.su, msg.fr, msg);
        }
      }
    }
  }

  private getPollingInterval(): void {
    const newInterval = MailUtils.getPollingInterval(
      this.configService.prefs.zimbraPrefMailPollingInterval, MailConstants.MIN_POLLING_INTERVAL
      );
    this.store.dispatch(new SetPollingInterval(newInterval));
  }

  private onDeviceReady(): void {
    console.log("[AppComponent][onDeviceReady]");

    if (environment.isCordova) {
      console.log("[AppComponent][onDeviceReady] - locking orientation?", !CommonUtils.isOnIpad());
      if (!CommonUtils.isOnIpad()) {
        try {
          if (screen && screen.orientation && screen.orientation.lock) {
            if (CommonUtils.isOnIOS() && !CommonUtils.isOnIpad()) {
              screen.orientation.lock("portrait");
            }
          }
        } catch (error) {
          console.error("[AppComponent][onDeviceReady] - locking portrait error", error);
        }
      }
    }

    this.handleBackButton();

    document.addEventListener("online", this.handleConnectionChange.bind(this), false);
    document.addEventListener("offline", this.handleConnectionChange.bind(this), false);

    if (environment.isCordova && typeof navigator !== "undefined" && navigator.splashscreen) {
      navigator.splashscreen.hide();
      console.log("[AppComponent] hide splashscreen", new Date());
    }

    document.addEventListener("pause", () => {
      console.log("[root.component] PAUSE");
      window.appInBackground = true;
      const nts = Date.now();
      console.log("pause => setSyncRequestInProgress, this.bgSyncMessagesStart ", nts);
      this.bgSyncMessagesStart.next(nts);
      this.syncRequestDone = false;
      this.convRepository.setSyncRequestInProgess();
      if (this.noOpPolling$) {
        console.log("[handlePolling] unsubscribe", new Date());
        clearInterval(this.noOpPolling$);
      }
      if (this.avatarPolling$) {
        console.log("[handlePolling] unsubscribe avatarPolling$", new Date());
        this.avatarPolling$.unsubscribe();
      }
      localStorage.setItem("lastTimeInBackground", new Date().getTime().toString());
      if (environment.isCordova) {
        if (cordova.plugin.AndroidMailWidgetPlugin) {
          cordova.plugin.AndroidMailWidgetPlugin.updateWidget();
        } else {
          console.warn("[AppComponent][onDeviceReady] AndroidMailWidgetPlugin is null");
        }
        if (cordova.plugin.AndroidCalendarWidgetPlugin) {
          console.warn("[AppComponent][onDeviceReady] AndroidCalendarWidgetPlugin updateWidget");
          cordova.plugin.AndroidCalendarWidgetPlugin.updateWidget();
        } else {
          console.warn("[AppComponent][onDeviceReady] AndroidCalendarWidgetPlugin is null");
        }

        this.broadcaster.broadcast("SAVEDRAFTMOVETOBACKGROUD");

        cordova.plugins.backgroundMode.on("disable", function() {
          console.log("[root.component] BACKGROUNDMODE DISABLE");
          // this.isSyncLocked = false;
        });
        cordova.plugins.backgroundMode.on("deactivate", function() {
          console.log("[root.component] BACKGROUNDMODE DEACTIVATE");
          // this.isSyncLocked = false;
        });

        cordova.plugins.backgroundMode.on("activate", function() {
          cordova.plugins.backgroundMode.disableWebViewOptimizations();
        });

        this.stopEventQueueTimer();

        cordova.plugins.backgroundMode.disableBatteryOptimizations();

        cordova.plugins.backgroundMode.setDefaults({
          resume: true,
          hidden: false,
          showWhen: false,
          visibility: "public"
        });
      }
    });

    document.addEventListener("resume", () => {
      console.log("RESUME - navigator.onLine: ", navigator.onLine);
      this.convRepository.lastTimeResume = new Date().getTime();
      window.appInBackground = false;
      this.databaseService.getMessagesByFolder("in:trash", null).subscribe(trashMessages => {
        if (!!trashMessages && !!trashMessages.length && (trashMessages.length > 0)) {
          const trashMessageIds = trashMessages.filter(v => !!v).map(m => m.id);
          trashMessageIds.forEach(id => {
            this.convRepository.addConversationOrMessageToTrash(id);
          });
        }

      });

      if (this.updateMessagesNotInCurrentFolderIds.length > 0) {
        this.store.dispatch(new RemoveManyMessages(this.updateMessagesNotInCurrentFolderIds));
        this.store.dispatch(new RemoveMultipleTabs(this.updateMessagesNotInCurrentFolderIds));
        this.updateMessagesNotInCurrentFolderIds = [];
      }
      const nts = Date.now();
      console.log("resume => this.bgSyncMessagesStart ", nts);
      this.bgSyncMessagesStart.next(nts);
      if (CommonUtils.isOnAndroid()) {
        cordova.plugins.DozeOptimize.IsIgnoringBatteryOptimizations((response) => {
          console.log("IsIgnoringBatteryOptimizations: " + response);
          if (response === "false") {
            cordova.plugins.DozeOptimize.RequestOptimizations((response) => {
              console.log(response);
            }, (error) => {
              console.error("BatteryOptimizations Request Error" + error);
            });
          } else {
            console.log("Application already Ignoring Battery Optimizations");
          }
        }, (error) => {
          console.error("IsIgnoringBatteryOptimizations Error" + error);
        });
      } else {

        if (this.isOnline && navigator.onLine) {
          setTimeout(() => {
            this.syncData(true);
            console.log("resume => handlePolling");
            this.handlePolling();
          }, 2000);
        } else {
          console.log("[resume] offline?", new Date());
          setTimeout(() => {
            this.broadcaster.broadcast("REFRESH_FOLDERS");
            this.broadcaster.broadcast("REFRESH_ALL");
          }, 1000);
        }
      }

      this.eventForAlarm();
      this.broadcaster.broadcast("DISMISS_UNDO");
    });

    this.broadcaster.on<any>("onOpenNotification").pipe(takeUntil(this.isAlive$)).subscribe(res => {
      console.log("[onOpenNotification]");
      this.changeDetectionRef.markForCheck();
      this.changeDetectionRef.detectChanges();
    });

    this.broadcaster.on<any>(BroadcastKeys.HANDLE_BACK_BUTTON).subscribe(val => {
      this.changeDetectionRef.detectChanges();
    });
    if (typeof Keyboard !== "undefined") {
      try {
        Keyboard.shrinkView(true);
      } catch (ex) {

      }
    }
    if (CommonUtils.isOnAndroid()) {
      CommonUtils.requestPermissions();
      this.checkForExtentendedAdroidPermissions();
    }

    if (screen && screen.orientation && screen.orientation.lock) {
      if (CommonUtils.isOnIOS() && !CommonUtils.isOnIpad()) {
        screen.orientation.lock("portrait");
      }
    } else {
      console.warn("[AppComponent][onDeviceReady] screen/screen.orientation undefined", screen);
    }

    this.store.select(getProps).pipe(takeWhile(() => !this.isFirebaseSetUpCompleted), filter(v => !!v)).subscribe(v => {
      this.firebaseSetup();
    });

    this.deepLinksHandlerSetup();

    this.store.dispatch(new DeviceReady(true));


    console.log("[AppComponent][onDeviceReady] StatusBar.show");
    StatusBar.show();
    if (CommonUtils.isOnIOS()) {
      const currentName = localStorage.getItem("theme") || environment.theme;
      if (currentName === "dfb") {
        StatusBar.backgroundColorByHexString("#20ae80");
      } else if (currentName === "dfb_blue") {
        StatusBar.backgroundColorByHexString("#004b85");
      } else {
        StatusBar.backgroundColorByHexString("#317bbc");
      }
    }

    this.broadcaster.on<any>("OPEN_INVITE_REPLY_DIALOG").pipe(takeUntil(this.isAlive$)).subscribe(res => {
      console.log("[OPEN_INVITE_REPLY_DIALOG]", res);
      if (!!res && res.id) {
        const mid = res.id;
        this.commonService.getMsgRequest({id: mid}).pipe(take(1)).subscribe(resp => {
          if (resp.m) {
            const message: any = resp.m[0];
            this.matDialog.open(AppointmentInviteReplyDialogComponent, {
              maxWidth: "100%",
              autoFocus: false,
              panelClass: "appointment-preview-dialog",
              id: "appointment-preview-dialog",
              data: { message: message }
            });
          }
        }, error => {
          console.error("[Error in openInviteReply]", error);
        });
      }
    });
    if (typeof universalLinks !== "undefined") {
      console.log("[universalLinks][launchedAppFromLink]");
      universalLinks.subscribe("launchedAppFromLink", this.onApplicationDidLaunchFromLink.bind(this));
      universalLinks.subscribe("openCalendarEvent", () => {
        this.router.navigate(["/calendar"]);
      });
    }
  }

  private checkInternetConnection() {
    this.store.dispatch(new OnlineStatus(navigator.onLine));
    if (!environment.isCordova) {
      window.addEventListener("online", this.handleConnectionChange.bind(this));
      window.addEventListener("offline", this.handleConnectionChange.bind(this));
    }
  }

  private handleConnectionChange(event): void {
    console.log("NETWORK connection status is now: ", event);
    if (!navigator.onLine) {
      console.log("set lastTimeOnline", event);
      localStorage.setItem("lastTimeOnline", new Date().getTime().toString());
    } else {
      this.eventForAlarm();
    }
    this.store.dispatch(new OnlineStatus(navigator.onLine));
  }

  hoveredUrl: string | null = null;

  ngOnInit() {
    // Listen for `mouseover` on all <a> tags
    this.renderer.listen('document', 'mouseover', (event: MouseEvent) => {
      const target = event.target as HTMLAnchorElement;
      if (target.tagName === 'A' && target.href) {
        if (environment.isElectron) {
          this.hoveredUrl = target.href;
        }
      }
    });

    // Listen for `mouseout` to clear the tooltip
    this.renderer.listen('document', 'mouseout', (event: MouseEvent) => {
      const target = event.target as HTMLAnchorElement;
      if (target.tagName === 'A') {
        this.hoveredUrl = null;
      }
    });

    console.log("[AppComponent][ngOnInit]", this.configService.selectedServer, environment, new Date());
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.convRepository.getMailTabs().subscribe(tabs => {
      console.log("getMailTabs", tabs);
    });
    this.registerQuillTable();
    this.registerSignature();
    this.appService.getSearchQueries("", 100).subscribe();

    if (!this.configService.selectedServer) {
      this.changeDetectionRef.markForCheck();
      return;
    }
    try {
      this.initWebWorker();
    } catch (error) {
      console.error("[AppComponent-ngOnInit] init worker error: ", error);
      if (environment.isCordova) {
        CommonUtils.workerSentryLog("AppComponent-ngOnInit] init worker error");
      }
    }
    if (!environment.isCordova) {
      this.registerShortcuts();
    }
    if (!!localStorage.getItem("storedContacts")) {
      try {
        this.store.dispatch(new BulkLoadVNCContacts(JSON.parse(localStorage.getItem("storedContacts"))));
      } catch (error) {

      }
    }
    this.store.pipe(select(getOnlineStatus)).subscribe(isOnline => {
      if (isOnline) {
        this.loadAllVNCdContacts(0, 100).subscribe(contacts => {
          console.log("[loadAllVNCdContacts]", contacts);
          this.store.dispatch(new BulkLoadVNCContacts(contacts));
        });
      }
    });

    this.ngZone.run(() => {
      this.store.pipe(select(getOnlineStatus), filter(v => !!v), take(1)).subscribe(isOnline => {
        this.isLoggedIn = isOnline;
        this.loadConfig();
        this.loadProfile();
      });
    });

    this.store.select(getVNCContacts).pipe(debounceTime(1000)).subscribe(contacts => {
      console.log("[getVNCContacts]", contacts);
      localStorage.setItem("storedContacts", JSON.stringify(contacts || []));
    });

    if (!this.isOnline) {
      this.getUserSignatures();
    }

    const user = localStorage.getItem("profileUser");
    if (user) {
      const parsedUser = typeof user === "string" ? JSON.parse(user) : user;
      this.store.dispatch(new SetUserProfile(parsedUser));
    }

    this.registerBroadCasts();

    if (environment.isElectron) {
    //   console.log("[AppComponent][ElectronService] check is focused and blurred");
      this.electronService.onFocused$.asObservable().pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(val => {
        console.log("[AppComponent][ElectronService] app is focused ", val, this.lastNoOpStamp);
        if (!!this.lastNoOpStamp && ((val - this.lastNoOpStamp) > 120000)) {
          this.broadcaster.broadcast(MailConstants.REFRESH_BROADCAST);
          this.syncRequestDone = false;
          const nts = Date.now();
          this.resyncElectron.next(nts);
        }
       });
    }
    // } else
    if (!environment.isCordova) {
      document.addEventListener("focus", () => {
        console.log("[AppComponent] focus - handlepolling");
        this.syncData(true);
        this.handlePolling();
      });
      document.addEventListener("blur", () => {
        console.log("[AppComponent] blur");
        localStorage.setItem("lastTimeInBackground", new Date().getTime().toString());
      });
    }

    this.store.select(getAllCalendarAppointments)
      .pipe(takeUntil(this.isAlive$))
      .subscribe((calendarEvents: CalendarAppointment[]) => {
        this.events = calendarEvents;
        this.calendarRepository.totalCalendarAppointments = calendarEvents;
        this.changeDetectionRef.markForCheck();
        this.appointmentList();
      });
  }

  ngAfterViewChecked(): void {
  }

  registerBroadCasts(): void {
    this.broadcaster.on<any>(MailConstants.CALL_NO_OP_REQUEST).pipe(debounceTime(2000)).subscribe(val => {
      console.log("CALL_NO_OP_REQUEST noOp", new Date());
      this.noOpTrigger.next({ts: Date.now(), force: false});
      // this.noOp();
    });
    this.broadcaster.on<string>(BroadcastKeys.HIDE_APPOINTMENT_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(v => {
      this.commonService.isEventReminderActivated = false;
    });
    this.broadcaster.on<any>("CALL_NO_OP_FORCE_REQUEST").subscribe(val => {
      this.noOpTrigger.next({ts: Date.now(), force: true});
      // this.noOp(true);
    });

    this.broadcaster.on<any>(BroadcastKeys.PROCESS_PENDING_OPERATIONS).pipe(debounceTime(300), takeUntil(this.isAlive$)).subscribe(val => {
      console.log("[AppComponent][PROCESS_PENDING_OPERATIONS] mode res", val, this.isDatabaseReady, this.isOnline);
      if (this.isDatabaseReady && this.isOnline) {
        // this.convRepository.processPendingOperations().pipe(take(1)).subscribe(res => {
        this.convRepository.processPendingOps().pipe(take(1)).subscribe(res => {
          console.log("[AppComponent][PROCESS_PENDING_OPERATIONS] processPendingOperations online mode res", res);
          if (res > 0) {
            console.log("[AppComponent][PROCESS_PENDING_OPERATIONS] call noOp");
            this.noOpTrigger.next({ts: Date.now(), force: true});
            // this.noOp(true);
          }
        });
      }
    });
  }

  private lastContactsFetchTimeStamp() {
    let date = localStorage.getItem("lastContactsFetchTimeStamp");
    if (date) {
      date = date.split(".")[0];
      if (date[date.length - 1] !== "Z") {
        date = date + "Z"; // compatibility, previously we used with milliseconds
      }
    }
    return date;
  }

  private setLastContactsFetchTimeStamp() {
    const date = new Date().toISOString().split(".")[0] + "Z"; // remove milliseconds
    localStorage.setItem("lastContactsFetchTimeStamp", date);
  }

  loadAllVNCdContacts(offset: number, limit: number): Observable<any> {
    const response = new BehaviorSubject<any>([]);
    let updateAfter = this.lastContactsFetchTimeStamp();
    this.commonService.getAllVNCdContacts(updateAfter, offset, limit).subscribe(res => {
      if (res.total_count > offset + limit) {
        this.loadAllVNCdContacts(offset + limit, limit).subscribe(contacts => response.next(contacts));
      } else {
        this.setLastContactsFetchTimeStamp();
      }
      response.next(res?.contacts || []);
      this.databaseService.addUsers(res?.contacts);
    });
    return response.asObservable().pipe(skip(1));
  }

  ngOnDestroy(): void {
    if (this.noOpPolling$) {
      console.log("[handlePolling] ngOnDestroy unsubscribe", new Date());
      clearInterval(this.noOpPolling$);
    }
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  registerQuillTable() {
    const Quill: any = QuillNamespace;
    Quill.register(TableCell);
    Quill.register(TableRow);
    Quill.register(Table);
    Quill.register(ContainBlot);
    Quill.register("modules/table", TableModule);
  }

  registerSignature() {
    const Quill: any = QuillNamespace;
    const BlockEmbed = Quill.import("blots/block/embed");
    const BlockInline = Quill.import("blots/inline");
    class VNCSignature extends BlockInline {
      static create(value) {
        const node = super.create(value);
        return node;
      }
      static value(node) {
        return {
          style: node.getAttribute("style")
        };
      }
    }
    VNCSignature.blotName = "signature";
    VNCSignature.className = "signature";
    VNCSignature.tagName = "signature";
    Quill.register({
      "formats/signature": VNCSignature
    });

    const FontStyle = Quill.import("attributors/style/font");
    Quill.register(FontStyle, true);

    const Link = Quill.import("formats/link");
    Link.sanitize = function(url) {
      if ( !url.toLowerCase().startsWith("http") && !url.toLowerCase().startsWith("https") && !url.toLowerCase().startsWith("mailto:")) {
        return "https://" + url;
      }
      return url;
    };

    const Inline = Quill.import("blots/inline");
    class MisspelledBlot extends Inline {
      static create(value) {
        const node = super.create();
        console.log("MisspelledBlot", value);
        node.setAttribute("class", "misspelled");
        node.setAttribute("word", value.word);
        return node;
      }

      static formats(node) {
        console.log("[MisspelledBlot] formats", node);
        return { word: node.getAttribute("word") };
      }

      static value(node) {
        console.log("[MisspelledBlot] value", node);
        return { word: node.getAttribute("word") };
      }
    }
    MisspelledBlot.blotName = "misspelled";
    MisspelledBlot.tagName = "misspelled";

    Quill.register(MisspelledBlot);

    const icons = Quill.import("ui/icons");
    this.changeEditorToolbarIcon(icons);

    const size = Quill.import("attributors/style/size");
    size.whitelist = ["12px", "14px", "18px", "24px"];
    Quill.register(size, true);

    class DividerBlot extends BlockEmbed {
      static create(value) {
        const node = super.create("hr");
        const className = (value.class || "");
        node.className = ("vnc-divider " + className.trim()).trim();
        if (value.style) {
          node.setAttribute("style", value.style);
        }
        if (value.id) {
          node.setAttribute("id", value.id);
        }
        return node;
      }
      static value(node) {
        return {
          style: node.getAttribute("style"),
          class: node.getAttribute("class"),
          id: node.getAttribute("id")
        };
      }
    }
    DividerBlot.blotName = "divider";
    DividerBlot.tagName = "hr";
    DividerBlot.className = "vnc-divider";
    Quill.register(DividerBlot);

    /* Quill paste handling */
    const Clipboard = Quill.import("modules/clipboard");
    const Delta = Quill.import("delta");
    const self = this;
    class PlainClipboard extends Clipboard {
      onPaste (e) {
        e.preventDefault();
        const range = this.quill.getSelection();
        const clipboardData = e.clipboardData || (<any>window).clipboardData;
        const isImage = clipboardData.types.length && clipboardData.types.join("").includes("Files");
        if (isImage) {
          const delta =  new Delta().retain(range.index).delete(range.length);
        } else {
          const text = e.clipboardData.getData("text/plain");
          let html = e.clipboardData.getData("text/html");
          let delta = new Delta().retain(range.index).delete(range.length);
          let content = text;
          if (html) {
            if (MailUtils.isStringHasImage(html)) {
              self.showToastMessage();
              html = MailUtils.convertImageToLink(html);
            }
            delta = delta.concat(this.convert(html));
          } else {
              delta = delta.insert(content);
          }
          this.quill.updateContents(delta, Quill.sources.USER);
          delta = this.convert(content);
          if (this.keepSelection) {
            this.quill.setSelection(range.index, delta.length(), Quill.sources.SILENT);
          } else {
            this.quill.setSelection(range.index + delta.length(), Quill.sources.SILENT);
          }
          this.quill.scrollIntoView();
        }
      }
    }
    Quill.register("modules/clipboard", PlainClipboard, true);

    const ImageBlotBase = Quill.import("formats/image");
    class ImageBlot extends ImageBlotBase {
      static get ATTRIBUTES() {
        return [ "alt", "height", "width", "class", "data-original", "data-width", "data-height", "style-data", "dfsrc", "data-mce-src" ];
      }

      static formats(domNode) {
        return this.ATTRIBUTES.reduce(function(formats, attribute) {
          if (domNode.hasAttribute(attribute)) {
            formats[attribute] = domNode.getAttribute(attribute);
          }
          return formats;
        }, {});
      }

      format(name, value) {
        if (ImageBlot.ATTRIBUTES.indexOf(name) > -1) {
          if (value) {
            this.domNode.setAttribute(name, value);
          } else {
            this.domNode.removeAttribute(name);
          }
        } else {
          super.format(name, value);
        }
      }
    }

    Quill.register(ImageBlot);

    window.sharedQuill = Quill;
  }

  loadConfig() {
    console.log("[root.ts] inside loadConfig()");
    this.mailService.getConfiguration().pipe(take(1)).subscribe(config => {
      console.log("[getConfiguration]", config);
      if (!!config && config.avatarURL) {
        this.configService.updateConfig(config);
      }
      if (config.changePasswordConfig) {
        localStorage.setItem("changePasswordType", config.changePasswordConfig.changePasswordType);
      }
      if (config.vframeURL) {
        localStorage.setItem("vframeURL", config.vframeURL);
      }
      if (config.enableBlockchainSeal) {
        localStorage.setItem("enableBlockchainSeal", config.enableBlockchainSeal);
      }
      if (config.URLS) {
        this.configService.URLS = config.URLS;
      }
      if (config.showHideSettingsMenu) {
        this.configService.showHideSettingsMenu = config.showHideSettingsMenu;
        this.broadcaster.broadcast("SHOW_HIDEMENU_ITEMS");
      }

      if (config.two_factor_authentication) {
        this.configService.two_factor_authentication = config.two_factor_authentication;
        localStorage.setItem("twoFactorAuthentication", config.two_factor_authentication ? "true" : "false");
      } else {
        this.configService.two_factor_authentication = false;
        localStorage.setItem("twoFactorAuthentication", "false");
      }

      if (config.showContactActions) {
        this.configService.set("showContactActions", config.showContactActions);
        localStorage.setItem("showContactActions", config.showContactActions.toString());
      }
      if (config.showContactActivity) {
        this.configService.set("showContactActivity", config.showContactActivity);
        localStorage.setItem("showContactActivity", config.showContactActivity.toString());
      }
      if (config.displayHeaderAvatar) {
        this.configService.set("displayHeaderAvatar", config.displayHeaderAvatar);
        localStorage.setItem("displayHeaderAvatar", config.displayHeaderAvatar.toString());
      }
      if (config.avatarURL) {
        this.configService.set("avatarURL", config.avatarURL);
        localStorage.setItem("avatarURL", config.avatarURL);
      }
      if (config.publicVncDirectoryUrl) {
        this.configService.set("publicVncDirectoryUrl", config.publicVncDirectoryUrl);
        localStorage.setItem("publicVncDirectoryUrl", config.publicVncDirectoryUrl);
      }
      if (config.canUpdateAvatar) {
        this.configService.set("canUpdateAvatar", config.canUpdateAvatar);
        localStorage.setItem("canUpdateAvatar", config.canUpdateAvatar.toString());
      }
      if (config.prefixBold) {
        this.configService.set("prefixBold", config.prefixBold);
        localStorage.setItem("prefixBold", config.prefixBold);
      }
      if (config.suffixNormal) {
        this.configService.set("suffixNormal", config.suffixNormal);
        localStorage.setItem("suffixNormal", config.suffixNormal);
      }
      if (config.hideAppsMenu) {
        this.configService.hideAppsMenu = config.hideAppsMenu;
        this.broadcaster.broadcast("HIDE_APPS_MENU");
      }
      if (config.zimbraURL) {
        this.configService.zimbraURL = config.zimbraURL;
      }
      if (config.showMailDeleteConfirmDialog) {
        this.configService.showMailDeleteConfirmDialog = config.showMailDeleteConfirmDialog;
        this.broadcaster.broadcast("SHOW_MAIL_DELETE_CONFIRM");
      }
      if (config.hideProfile) {
        this.configService.set("hideProfile", config.hideProfile);
        localStorage.setItem("hideProfile", config.hideProfile.toString());
        // if (config.hideProfile === true) {
        //   this.configService.set("displayHeaderAvatar", false);
        //   localStorage.setItem("displayHeaderAvatar", "false");
        // }
      }
      if (config.briefcaseRenameFileExtensionReadOnly) {
        this.configService.briefcaseRenameFileExtensionReadOnly = config.briefcaseRenameFileExtensionReadOnly;
        this.broadcaster.broadcast("BRIF_RENAME_FILE_EXT_READONLY");
      }
      if (config.useFullComposeModeOnly) {
        this.configService.useFullComposeModeOnly = config.useFullComposeModeOnly;
        this.broadcaster.broadcast("USE_FULL_COMPOSE_MODEONLY");
      }
      if (config.backgroundSend) {
        this.configService.backgroundSend = config.backgroundSend;
      }
      if (config.showZimletsOptionInSidebar) {
        this.configService.showZimletsOptionInSidebar = config.showZimletsOptionInSidebar;
        this.broadcaster.broadcast("SHOW_ZIMLETS_OPTION_IN_SIDEBAR");
      }
      if (config.hideComposeDirectionFromSettings) {
        this.configService.set("hideComposeDirectionFromSettings", config.hideComposeDirectionFromSettings);
        localStorage.setItem("hideComposeDirectionFromSettings", config.hideComposeDirectionFromSettings.toString());
      }
      if (config.hideSpamMailOptionsFromSettings) {
        this.configService.set("hideSpamMailOptionsFromSettings", config.hideSpamMailOptionsFromSettings);
        localStorage.setItem("hideSpamMailOptionsFromSettings", config.hideSpamMailOptionsFromSettings.toString());
      }
      if (config.hideTrustedAddressesFromSettings) {
        this.configService.set("hideTrustedAddressesFromSettings", config.hideTrustedAddressesFromSettings);
        localStorage.setItem("hideTrustedAddressesFromSettings", config.hideTrustedAddressesFromSettings.toString());
      }
      if (config.domainSpecificLogo) {
        const attributes = config.domainSpecificLogo;
        const domainSpecLogo: any[] = [];
        for (const key in attributes) {
          if (attributes.hasOwnProperty(key)) {
            const domainAndLogo: any = {
              domain: key ,
              logo: attributes[key]
            };
            domainSpecLogo.push(domainAndLogo);
          }
        }
        console.log("[DomainSpecificLogo] : ", domainSpecLogo);
        this.store.dispatch(new SetDomainSpecificLogo(domainSpecLogo));
      }
      if (config.isZimbraUIAvailable !== null) {
        this.configService.isZimbraUIAvailable = config.isZimbraUIAvailable;
      }
      if (config.hideAddExternalCalender !== null) {
        this.configService.hideAddExternalCalender = config.hideAddExternalCalender;
      }
      if (config.hideResourceOptionsInCalender !== null) {
        this.configService.hideResourceOptionCalendar = config.hideResourceOptionsInCalender;
      }
      if (config.hideAppleIcalDelegationFromPrefs !== null) {
        this.configService.hideAppleIcalDelegationFromPrefs = config.hideAppleIcalDelegationFromPrefs;
      }
      if (config.hideSendFreeBusyLinkOptionsInCalender !== null) {
        this.configService.hideSendFreeBusyLinkOptionsInCalender = config.hideSendFreeBusyLinkOptionsInCalender;
      }
      if (config.displayHeaderAvatar !== null) {
        this.configService.displayHeaderAvatar = config.displayHeaderAvatar;
      }
      if (config.avatarURL !== null) {
        this.configService.avatarURL = config.avatarURL;
      }
      if (config.useVNCdirectoryAuth !== null) {
        if (config.useVNCdirectoryAuth) {
          this.configService.useVNCdirectoryAuth = true;
        }
      }
      if (config.deleteInvitationOnProposeNewTime !== null) {
        this.configService.deleteInvitationOnProposeNewTime = config.deleteInvitationOnProposeNewTime || false;
      }
      if (config.dropZoneUrl !== null && config.dropZoneUrl !== "none") {
        this.configService.isDropzoneAvailable = true;
      }

      if (this.configService.useVNCdirectoryAuth) {
        this.contactService.getContactsList().subscribe(v => {
          console.log("getContactsList", v);
          this.store.dispatch(new SetVNCContactsList(v.contact_lists || []));
        });
      }
      this.changeDetectionRef.markForCheck();
    });
  }

  private loadProfile() {
    console.log("[AppComponent][loadProfile]");

    this.auth.getProfile().subscribe(res => {
      console.log("[root.ts][AppComponent][loadProfile] res", res);

      if (res !== null && res.user) {
        this.store.dispatch(new LoginSuccess());
        this.isLoggedIn = true;
        /* Get directory tags if useVNCdirectoryAuth is enabled */
        setTimeout(() => {
          console.log("[root.ts][getAllDirectoryTag][Call]:", this.configService.useVNCdirectoryAuth);
          if (this.configService.useVNCdirectoryAuth) {
            this.getDirectoryTags(0);
          }
        }, 2000);
        const tmpUser = {
          firstName: res.user.firstName,
          lastName: res.user.lastName,
          email: res.user.email,
          redmineApiKey: res.user.redmineApiKey
        };

        // save user profile
        if (this.electronService.isElectron) {
          this.electronService.setToStorage("profileUser", tmpUser);
        } else {
          localStorage.setItem("profileUser", JSON.stringify(tmpUser));
        }

        this.validateDatabaseUser(res.user.email);

        console.log("[root.ts][AppComponent][loadProfile] saveAuthToken", res);
        this.appService.saveAuthToken(res.secret);

        // save secret
        if (res.secret) {
          localStorage.setItem("token", res.secret);
          if (this.electronService.isElectron) {
            this.electronService.setToStorage("token", res.secret);
          }
          if (!!this.configService.worker && this.isCordovaOrElectron && this.configService.isWorkerReady) {
            this.configService.worker.postMessage({ type: "initConfig", id: new Date().getTime(), args: { apiUrl: this.configService.API_URL, token: res.secret } });
          }
          if (!this.configService.worker) {
            try {
              this.initWebWorker();
            } catch (error) {
              console.error("[AppComponent] init worker error: ", error);
            }
          }
         }

        this.store.dispatch(new SetUserProfile(tmpUser));

        // save current user email & display name to local store
        this.appService.saveCurrentUserAddressAndDisplayName(res.user.email, `${res.user.firstName} ${res.user.lastName}`);

        this.changeDetectionRef.markForCheck();

        if (environment.isCordova) {
          Sentry.configureScope(scope => {
            scope.setUser({ id: res.user.email, email: res.user.email, username: res.user.email });
          });
        } else if (environment.production || environment.isElectron) {
          Raven.setUser({
            email: res.user.email,
            id: res.user.email
          });
        }
        this.getDataSource();
        this.getSendASAndBehalfOf();
        this.getIncomigFilters();
        this.getUserContacts();
        this.getUserSignatures();
      } else {
        console.error("[AppComponent][loadProfile] res/user is empty");
        this.handleLoginFailed();
      }
    }, err => {
      console.error("[AppComponent][loadProfile] err", err);
      if (err.status === 504) {
        this.handleLoginFailed();
      }
    });
  }

  private _base64toNormalSize(base64): number {
    if (!base64 || base64 === -1) { // -1 is unlimited
      return base64;
    }
    return Math.floor(base64 / 1.82);
  }

  private validateDatabaseUser(email): void {
    this.store.pipe(select(IsDatabaseReady), filter(v => !!v), takeUntil(this.isAlive$)).subscribe(() => {
      this.databaseService.getCurrentDBUser().pipe(take(1)).subscribe(dbuser => {
        console.log("[root.ts][AppComponent][loadProfile] getCurrentDBUser: ", dbuser);
        if (!!dbuser && !!dbuser[0] && !!dbuser[0].email) {
          console.log("[root.ts][AppComponent][loadProfile] getCurrentDBUser email: ", dbuser[0].email);
          if (email !== dbuser[0].email) {
            this.databaseService.clearDB().pipe(take(1)).subscribe(() => {
              this.databaseService.setCurrentDBUser(email);
              setTimeout(() => {
                console.log("[root.ts][AppComponent][loadProfile] getCurrentDBUser is different - refresh: ", dbuser[0].email);
                this.broadcaster.broadcast(MailConstants.REFRESH_BROADCAST);
              }, 500);
            });
          }
        } else {
          this.databaseService.setCurrentDBUser(email);
        }
      });
    });

  }

  private handleLoginFailed(): void {
    this.store.dispatch(new LoginFailed());

    if (this.isCordovaOrElectron) {
      if (CommonUtils.isOnAndroid()) {
        this.store.pipe(select(IsDatabaseReady), filter(v => !!v), takeUntil(this.isAlive$)).subscribe(() => {
          this.databaseService.clearDB().subscribe(() => {
            this.isLoggedIn = false;
            this.configService.loginIframe();

          }, err => {
            this.isLoggedIn = false;
            this.configService.loginIframe();

          });
        });
      } else {
        this.isLoggedIn = false;
        this.configService.loginIframe();
      }
    } else {
      window.location.href = this.configService.API_URL + "/api/call-logout";
    }
  }

  @HostListener("window:message", ["$event"])
  windowMessageEventHandler(event: MessageEvent) {
    const eventData = event.data;

    if (eventData.source && eventData.source.startsWith("angular-devtools")) {
      // Chrome angular-devtools extension message
      return;
    }

    console.log("[AppComponent] windowMessageEventHandler ", eventData);

    if (eventData.source && eventData.source === "@devtools-page") {
      // Chrome Redux-devtools extension message
      return;
    }

    if (eventData && eventData.type === "GO_TO_SERVER_URL_PAGE") {
      console.log("[AppComponent] GO_TO_SERVER_URL_PAGE", eventData);
      this.ngZone.run(() => {
        this.configService.selectedServer = false;
        this.changeDetectionRef.markForCheck();
      });
      this.unRegisterShortcuts();
      if (document.querySelector("#loginIframe") !== null) {
        document.querySelector("#loginIframe").remove();
      }
    } else if (eventData && eventData.type === "TFA_OTP_VERIFICATION") {
      if (eventData.token && eventData.token.trim().length > 0) {
        localStorage.setItem("unVerifiedToken", eventData.token);
        if (document.querySelector("#loginIframe") !== null) {
          document.querySelector("#loginIframe").remove();
        }
        this.configService.tfaOtpIframe();
        return;
      }
    } else if (eventData && eventData.type === "GO_TO_LOGIN_SCREEN") {
      this.configService.hideTfaOtpIframe();
      this.configService.loginIframe();
    } else if (eventData && eventData.type === "VNC_PORTAL_POST_MESSAGE") {
      console.log("[AppComponent] login postback update: ", eventData);
      let unverifiedToken = localStorage.getItem("unVerifiedToken");
      if (unverifiedToken) {
        localStorage.removeItem("unVerifiedToken");
        this.configService.hideTfaOtpIframe();
      }
      if (this.electronService.isElectron) {
        this.electronService.setToStorage("token", eventData.token);
        localStorage.setItem("token", eventData.token);
      } else {
        localStorage.setItem("token", eventData.token);
      }
      if (!!this.configService.worker && this.isCordovaOrElectron) {
        this.configService.worker.postMessage({ type: "initConfig", id: new Date().getTime(), args: { apiUrl: this.configService.API_URL, token: eventData.token } });
      }
      if (!this.configService.worker) {
        try {
          this.initWebWorker();
        } catch (error) {
          console.error("[AppComponent] init worker error: ", error);
          if (environment.isCordova) {
            CommonUtils.workerSentryLog("[AppComponent] init worker error");
          }
        }
      }
      if (document.querySelector("#loginIframe") !== null) {
        document.querySelector("#loginIframe").remove();
      }

      this.store.dispatch(new OnlineStatus(navigator.onLine));
      if (!environment.isCordova) {
        this.registerShortcuts();
      }
      this.ngZone.run(() => {
        this.store.pipe(select(getOnlineStatus), filter(v => !!v), take(1)).subscribe(isOnline => {
          this.loadConfig();
          this.loadProfile();
          this.broadcaster.on<any>("unRegisterShortcuts").pipe(takeUntil(this.isAlive$)).subscribe( res => {
            this.unRegisterShortcuts();
          });
          this.broadcaster.on<any>("registerShortcuts").pipe(takeUntil(this.isAlive$)).subscribe( res => {
            this.registerShortcuts();
          });
          this.router.navigate(["/"], { skipLocationChange: true, replaceUrl: true });
          this.changeDetectionRef.markForCheck();
          this.registerBroadCasts();
        });
      });

    } else if (eventData && !isNullOrUndefined(eventData.link) && (typeof eventData.link) !== "function") {

      console.log("[AppComponent] data.link snippet: ", event, eventData.link);

      if (this.electronService.isElectron) {
        this.electronService.openExternalUrl(eventData.link);
      } else if (device.platform === "iOS") {
        window.open(eventData.link, "_system");
      } else {
        navigator.app.loadUrl(eventData.link, {
          openExternal: true
        });
      }
    }
    this.changeDetectionRef.markForCheck();
  }

  @HostListener("document:click", ["$event"])
  documentClickEventHandler(event: any) {
    /* Check for mailto click event */
    console.log("[documentClickEventHandler]", event);
    if (document.querySelector(".mat-snack-bar-container .vnc-notification .action .action-label")) {
      this.vncLibraryService.closeSnackBar();
      this.snackBar.dismiss();
    }
    this.wheelActionService.hideCircularMenu();
    if (event.target && event.target.href && event.target.href.startsWith("mailto:")) {
      event.stopPropagation();
      event.preventDefault();
      const mailAddress = event.target.href.split("mailto:")[1];
      this.router.navigate(["/mail/compose"], { queryParams: { to: mailAddress } });
    }

    if (this.isCordovaOrElectron) {
      let url: string;
      if (event.path && event.path.find(v => v.classList && v.classList.contains("open-new-window"))) {
        url = event.path.find(v => v.classList && v.classList.contains("open-new-window"))?.href;
      } else if (!event.target.classList.contains("open-new-window") && event.target.parentNode
      && event.target.parentNode.classList.contains("open-new-window")) {
        url = event.target.parentNode.href;
      } else if (event.target.id.startsWith("seal-image-") && event.path[1] && event.path[1].href) {
        url = event.path[1].href;
      } else if (event.target.target === "_blank" || event.target.classList.contains("open-new-window") || event.target.classList.contains("ql-preview")) {
        url = event.target.href;
      } else if ( event?.target?.nodeName !== "A" && event.target?.parentNode?.nodeName === "A" && (event.target.parentNode?.target === "_blank" || event.target.parentNode?.classList?.contains("open-new-window"))) {
        url = event.target.parentNode.href;
      }
      console.log("[documentClickEventHandler] url", url, event.target);
      if (url) {
        event.stopPropagation();
        event.preventDefault();
        if (this.electronService.isElectron) {
          this.electronService.openExternalUrl(url);
        } else {
          cordova.InAppBrowser.open(url, "_system");
        }
      }

    }
  }

  registerShortcuts(): void {
  }

  unRegisterShortcuts(): void {
  }

  private handleBackButton(): void {
    console.log("[handleBackButton] call");
    document.addEventListener("backbutton", (e) => {
      console.log("[handleBackButton] event", e);
        if (document.querySelector("#loginIframe") !== null) {
          navigator.app.exitApp();
        } else if (document.querySelector("#tfaOtpIframe") !== null) {
          this.configService.hideTfaOtpIframe();
          this.configService.loginIframe();
        } else if (document.getElementById("mobileSearchInput") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_SEARCH_PANEL);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_SEARCH_PANEL);
        } else if (document.querySelector("vp-search-tabs.search_tab_view") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_SEARCH_TAB);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_SEARCH_PANEL);
        } else if (document.querySelector("vp-calendar-component") !== null) {
          if (!this.calendarOnly) {
            this.broadcaster.broadcast(BroadcastKeys.HIDE_CALENDAR_DIALOG);
          }
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        } else if (document.querySelector("vp-calender-appointment-reminder-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_APPOINTMENT_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        } else if (document.querySelector(".vereign-dialog") !== null) {
          this.broadcaster.broadcast("hideVereignDialog");
        } else if (document.querySelector("vp-confirm-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_CONFIRM_MAIN_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_CONFIRM_MAIN_DIALOG);
        } else if (document.querySelector("vp-advanced-search-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_ADVANCED_SEARCH_DIALOG);
        } else if (document.querySelector("vp-confirm-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_CONFIRM_MAIN_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_CONFIRM_MAIN_DIALOG);
        } else if (document.querySelector("vp-file-preview-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_PREVIEW_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        } else if (document.querySelector("vp-general-settings-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_GENERAL_SETTINGS);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_GENERAL_SETTINGS);
        } else if (document.querySelector("vp-mail-folder-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_MAIL_FOLDERS_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_MAIL_FOLDERS_DIALOG);
        } else if (document.querySelector("vp-redirect-mail-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_REDIRECT_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_REDIRECT_DIALOG);
        } else if (document.querySelector("vp-create-filter") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_FILTER_CREATE_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_FILTER_CREATE_DIALOG);
        } else if (document.querySelector("vp-create-folder") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_CREATE_FOLDER_MODAL);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_CREATE_FOLDER_MODAL);
        } else if (document.querySelector("vp-share-folder") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_SHARE_FOLDER_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_SHARE_FOLDER_DIALOG);
        } else if (document.querySelector("vp-folder-revoke-share") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_REVOKE_SHARE_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_REVOKE_SHARE_DIALOG);
        } else if (document.querySelector("vp-edit-folder-properties") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_EDIT_FOLDER_PROPERTIES_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_EDIT_FOLDER_PROPERTIES_DIALOG);
        } else if (document.querySelector("vp-create-tag") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_TAG_CREATE_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_TAG_CREATE_DIALOG);
        } else if (document.querySelector("vp-attach-email-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_ATTACH_EMAIL_DIALOG);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_ATTACH_EMAIL_DIALOG);
        } else if (document.querySelector("vp-mobile-select-address-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_MOBILE_ADDRESS_SELECT_DIALOG);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_MOBILE_ADDRESS_SELECT_DIALOG);
        } else if (document.querySelector("vp-compose") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_COMPOSE_MAIL);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_COMPOSE_MAIL);
        } else if (document.querySelector("vp-send-read-receipt-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_READ_RECEIPT_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_READ_RECEIPT_DIALOG);
        } else if (document.querySelector("vp-mail-detail") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.BACK_FROM_MAIL_DETAIL);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.BACK_FROM_MAIL_DETAIL);
        } else if (document.querySelector("vp-apps-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_APP_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_APP_DIALOG);
        } else if (document.querySelector("vp-changelog-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_CHANGELOG_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_CHANGELOG_DIALOG);
        } else if (document.querySelector("vp-servicedesk-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_SERVICEDESK);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_SERVICEDESK);
        } else if (document.querySelector("vp-help-faq-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_FAQ_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_FAQ_DIALOG);
        } else if (document.querySelector("vp-about-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_VERSION_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_VERSION_DIALOG);
        } else if (document.querySelector("vp-confirmation-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_GENERAL_CONFIRM_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_GENERAL_CONFIRM_DIALOG);
        } else if (document.querySelector("vp-color-control-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_COLOR_DIALOG);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_COLOR_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        } else if (document.querySelector("vp-move-folder-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_MOVE_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_MOVE_DIALOG);
        } else if (document.querySelector("vp-mail-folder-operation-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_FOLDER_OPERATION_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_FOLDER_OPERATION_DIALOG);
        } else if (document.querySelector(".search-folder-form") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_SEARCH_FOLDER_FORM);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_FOLDER_MODAL);
        } else if (document.querySelector("vp-sub-folder-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_FOLDER_MODAL);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_FOLDER_MODAL);
        } else if (document.querySelector("vp-avatar-cropper-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_AVATAR_CROPPER_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_AVATAR_CROPPER_DIALOG);
        } else if (document.querySelector("vp-mobile-change-password") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_CHANGE_PASSWORD_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_CHANGE_PASSWORD_DIALOG);
        } else if (document.querySelector("vp-avatar-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_PROFILE_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_PROFILE_DIALOG);
        } else if (document.querySelector("vp-refine-search-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_REFINE_SEARCH);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_REFINE_SEARCH);
        } else if (document.querySelector("vp-swipe-actions-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_SWIPE_ACTIONS_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_SWIPE_ACTIONS_DIALOG);
        } else if (document.querySelector("vp-swipe-config-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_SWIPE_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_SWIPE_DIALOG);
        } else if (document.querySelector("vp-legal-notice-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_LEGAL_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_LEGAL_DIALOG);
        } else if (document.querySelector("vp-mobile-about-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_ABOUT_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_ABOUT_DIALOG);
        } else if (document.querySelector(".maillist-mobile-header") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_MAIL_SELECTION_PANEL);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_MAIL_SELECTION_PANEL);
        } else if (document.querySelector("vp-tag-list-operation-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_TAG_OPERATION_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_TAG_OPERATION_DIALOG);
        } else if (document.querySelector("vp-mobile-tags-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_TAG_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_TAG_DIALOG);
        } else if (document.getElementById("appSwitchBtn") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_SIDEBAR_DRAWER);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_SIDEBAR_DRAWER);
        } else if (document.querySelector("vp-appearance-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.BACK_FROM_PREFERENCES_APPEARANCE);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.BACK_FROM_PREFERENCES_APPEARANCE);
        } else if (document.querySelector("vp-preference") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.BACK_FROM_PREFERENCES);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.BACK_FROM_PREFERENCES);
        } else if (document.querySelector("vp-mail-history-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_MAIL_HISTORY_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_MAIL_HISTORY_DIALOG);
        } else if (document.querySelector("vp-mobile-briefcase-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_BRIEFCASE_MODAL);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
        } else if (document.querySelector("vp-upload-briefcase-files") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_BRIEFCASE_UPLOAD_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_BRIEFCASE_UPLOAD_DIALOG);
        } else if (document.querySelector("vp-briefcase-file-rename-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_BRIEFCASE_RENAME_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_BRIEFCASE_RENAME_DIALOG);
        } else if (document.querySelector("vp-share-link-confirm-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_BRIEFCASE_SHARE_LINK_CONFIRM_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_BRIEFCASE_SHARE_LINK_CONFIRM_DIALOG);
        } else if (document.querySelector("vp-briefcase-upload-conflict-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.BRIEFCASE_UPLOAD_CONFLICT_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.BRIEFCASE_UPLOAD_CONFLICT_DIALOG);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_BRIEFCASE_LIST);
        } else if (document.querySelector("vp-briefcase-sort-selection-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_BRIEFCASE_SHORTING_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_BRIEFCASE_SHORTING_DIALOG);
        } else if (document.querySelector("vp-briefcase-move-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_BRIEFCASE_MOVE_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_BRIEFCASE_MOVE_DIALOG);
        } else if (document.querySelector("vp-briefcase-mobile-folder-operation-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_MOBILE_SUB_FOLDER_OPERATION_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_MOBILE_SUB_FOLDER_OPERATION_DIALOG);
        } else if (document.querySelector("vp-briefcase-sub-folder-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_BRIEFCASE_SUB_FOLDER_SELECT_DIALOG);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_BRIEFCASE_SUB_FOLDER_SELECT_DIALOG);
        } else if (document.querySelector("vp-briefcase-files-list") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_BRIEFCASE_LIST);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_BRIEFCASE_LIST);
        } else if (document.querySelector(".vp-contact-warning-dialog") !== null) {
          this.broadcaster.broadcast("hideWarningDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideWarningDialog");
        } else if (document.querySelector(".vp-contact-folder-operation-dialog") !== null) {
          this.broadcaster.broadcast("hideMoreFolderOperationDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideMoreFolderOperationDialog");
        } else if (document.querySelector(".vp-contact-add-folder-dialog") !== null) {
          this.broadcaster.broadcast("hideAddFolderDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideAddFolderDialog");
        } else if (document.querySelector(".vp-contact-file-import-dialog") !== null) {
          this.broadcaster.broadcast("hideContactImportDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideContactImportDialog");
        } else if (document.querySelector(".vp-contact-export-dialog") !== null) {
          this.broadcaster.broadcast("hideContactExportDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideContactExportDialog");
        } else if (document.querySelector(".vp-contact-print-dialog") !== null) {
          this.broadcaster.broadcast("hidePrintDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hidePrintDialog");
        } else if (document.querySelector(".vp-contact-option-dialog") !== null) {
          this.broadcaster.broadcast("hideMoreOptionDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideMoreOptionDialog");
        } else if (document.querySelector(".vp-contact-confirm-dialog") !== null) {
          this.broadcaster.broadcast("hideContactConfirmDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideContactConfirmDialog");
        } else if (document.querySelector(".vp-contact-sidebar-list-dialog") !== null) {
          this.broadcaster.broadcast("hideSidebarListDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideSidebarListDialog");
        } else if (document.querySelector(".vp-contact-move-dialog") !== null) {
          this.broadcaster.broadcast("hideMoveDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideMoveDialog");
        } else if (document.querySelector(".vp-contact-image-cropper-dialog") !== null) {
          this.broadcaster.broadcast("hideImageCropperDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideImageCropperDialog");
        } else if (document.querySelector(".vp-contact-login-profile-dialog") !== null) {
          this.broadcaster.broadcast("hideUserProfileDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideUserProfileDialog");
        } else if (document.querySelector(".vp-contact-detail-dialog") !== null) {
          this.broadcaster.broadcast("hideContactDetailDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideContactDetailDialog");
        } else if (document.querySelector(".vp-contact-create-dialog") !== null) {
          this.broadcaster.broadcast("hideContactCreateDialog");
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("onBacKButton", "hideContactCreateDialog");
        } else if (document.querySelector("vp-contact-mobile-floating-window") !== null) {
          // this.broadcaster.broadcast("hideMobileFloating");
          if (!this.calendarOnly) {
            this.router.navigate(["/mail/inbox"]);
          } else {
            this.router.navigate(["/calendar"]);
          }
          setTimeout(() => {
            this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          }, 500);
          console.log("onBacKButton", "hideMobileFloating");
        } else if (document.querySelector("vp-appointment-invite-operation-dialog") !== null) {
          this.broadcaster.broadcast(BroadcastKeys.HIDE_APPOINTMENT_INVITE_DIALOG_OPERATION);
          this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
          console.log("[handleBackButton]", BroadcastKeys.HIDE_APPOINTMENT_INVITE_DIALOG_OPERATION);
        }
      setTimeout(() => {
        this.changeDetectionRef.detectChanges();
      }, 20);
    }, false);
  }

  getDataSource(): void {
    this.preferenceService.getDataSources().subscribe(res => {
      this.preferenceService.dataSource$ = res;
    });
  }

  isDataSourceFolder(folderId: any): boolean {
    if (this.preferenceService.dataSource$ === undefined || this.preferenceService.dataSource$.length === 0) {
      return false;
    }
    const dataSource =  this.preferenceService.dataSource$.filter(item => item.l.toString() === folderId.toString());
    if (dataSource.length > 0) {
      return true;
    }
    return false;
  }

  isImapFolder(folderId: any): boolean {
    if (!folderId) {
      return false;
    }
    let isImap = false;
    const folder = this.mailService.flatFolders[folderId];
    if (folder && folder.f && folder.f.indexOf("y") !== -1) {
      isImap = true;
    }
    return isImap;
  }

  private getAttributes() {
    this.mailService.getAttributes("attrs,zimlets,idents,attrs,mbox").pipe(take(1)).subscribe(res => {
      if (!!res) {
        if (res.zimlets) {
          this.store.dispatch(new SetAvailableZimlets(res.zimlets.zimlet));
        }
        if (res.identities && res.identities.identity && isArray(res.identities.identity)) {
          this.preferenceService.personas = res.identities.identity;
        }
        if (res.attrs && res.attrs._attrs) {
          const featuresAttributes = res.attrs._attrs;
          const preferences: Preference[] = [];
          for (const key in featuresAttributes) {
            if (featuresAttributes.hasOwnProperty(key)) {
              const preference: Preference = {
                key: key,
                value: featuresAttributes[key]
              };
              preferences.push(preference);
            }
          }
          this.store.dispatch(new SetZimbraFeatures(preferences));
          localStorage.setItem("sectionAttributes", JSON.stringify(res));
          this.configService.set(
            "zimbraFeatureChangePasswordEnabled",
            res.attrs._attrs.zimbraFeatureChangePasswordEnabled
          );
          console.log("app.component uploadAttachment2 setting limit: ", res.attSizeLimit);
          this.preferenceService.uploadAttachmentSizeLimit = this._base64toNormalSize(res.attSizeLimit);
        }
        if (res.used) {
          this.configService.setUsedQuota(res.used);
        }
      }
      this.getUserGalContacts();
    });
  }

  private outOfOfficeAlertOnLogin(prefs: Preference[]): void {
    const startDate =  prefs.filter(pref => pref.key === "zimbraPrefOutOfOfficeFromDate" && pref.value !== "");
    const endDate =  prefs.filter(pref => pref.key === "zimbraPrefOutOfOfficeUntilDate" && pref.value !== "");
    let isAfter = false;
    if (!!endDate && endDate.length > 0 && !!startDate && startDate.length > 0) {
      const start = moment(startDate[0].value, "YYYYMMDDhhmmssZ").toDate();
      const end = moment(endDate[0].value, "YYYYMMDDhhmmssZ").toDate();
      const currentDate = moment(new Date());
      const isBetween = currentDate.isBetween(start, end);
      console.log("[outOfOfficeAlertOnLogin][isBetween]", isBetween);
      if (isBetween) {
        isAfter = true;
      }
    }
    const replyEnabled =  prefs.filter(pref => pref.key === "zimbraPrefOutOfOfficeReplyEnabled" && pref.value === "TRUE");
    const alertOnLogin = prefs.filter(item => item.key === "zimbraPrefOutOfOfficeStatusAlertOnLogin" && item.value === "TRUE");
    if (replyEnabled.length > 0 && alertOnLogin.length > 0 && isAfter) {
      const dialogArgs = {
        width: "325px",
        autoFocus: false,
        panelClass: "out-of-office-alert-dialog"
      };
      this.matDialog.open(OutOfOfficeAlertComponent, dialogArgs);
    }
  }

  applyTheme(prefs: Preference[]): void {
    const zimbraTheme = prefs.filter(item => item.key === "zimbraPrefSkin");
    if (zimbraTheme?.length > 0 && localStorage.getItem(MailConstants.THEME) === null) {
      if (zimbraTheme[0].value === "tree") {
          localStorage.setItem(MailConstants.THEME, "dfb");
          this.broadcaster.broadcast(MailConstants.UPDATE_THEME_DFB);
          this.loadTheme();
      }
      else if (zimbraTheme[0].value === "carbon") {
        localStorage.setItem(MailConstants.THEME, "carbon");
        this.broadcaster.broadcast(MailConstants.UPDATE_THEME_DFB);
        this.loadTheme();
      }
    }
    if (environment.theme === MailConstants.DFB) {
      const currentTheme = prefs.filter(item => item.key === "zimbraPrefSkin");
      const zimbraSkin = currentTheme[0].value;
      if (localStorage.getItem(MailConstants.THEME) === null) {
        if (zimbraSkin === MailConstants.ZIMBRA_THEME_DFB) {
          localStorage.setItem(MailConstants.THEME, MailConstants.DFB);
          this.broadcaster.broadcast(MailConstants.UPDATE_THEME_DFB);
        } else if (zimbraSkin === MailConstants.ZIMBRA_THEME_HARMONY) {
          localStorage.setItem(MailConstants.THEME, MailConstants.DFB_BLUE);
          this.broadcaster.broadcast(MailConstants.UPDATE_THEME_DFB);
          this.loadTheme();
        }
      } else {
        const skin = zimbraSkin === MailConstants.ZIMBRA_THEME_DFB ? MailConstants.DFB : MailConstants.DFB_BLUE;
        if (localStorage.getItem(MailConstants.THEME) !== skin) {
          localStorage.setItem(MailConstants.THEME, skin);
          this.broadcaster.broadcast(MailConstants.UPDATE_THEME_DFB);
          this.loadTheme();
        }
      }
    }
  }

  private loadTheme() {
    MailUtils.reloadApp();
  }

  private getNormalize(id) {
    if (typeof(id) !== "string") {
      return id;
    }
    const idx = id.indexOf(":");
    const localId = (idx === -1) ? id : id.substr(idx + 1);
    return localId;
  }

  private changeEditorToolbarIcon(icons: any): void {
    icons["bold"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-bold' fonticon='mdi-format-bold' fontset='mdi' role='img'>" +
    "</mat-icon>";
    icons["italic"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-italic' fonticon='mdi-format-italic' fontset='mdi' " +
    "role='img'></mat-icon>";
    icons["underline"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-underline' fonticon='mdi-format-underline' " +
    "fontset='mdi' role='img'></mat-icon>";
    icons["strike"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-strikethrough-variant' " +
    "fonticon='mdi-format-strikethrough-variant' fontset='mdi' role='img'></mat-icon>";
    icons["blockquote"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-quote-close' fonticon='mdi-format-quote-close' " +
    "fontset='mdi' role='img'></mat-icon>";
    icons["code-block"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-code-tags' fonticon='mdi-format-code-tags' " +
    "fontset='mdi' role='img'></mat-icon>";
    icons["link"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-link' fonticon='mdi-link' fontset='mdi' role='img'></mat-icon>";
    icons["image"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-image' fonticon='mdi-image' fontset='mdi' role='img'></mat-icon>";
    icons["clean"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-clear' fonticon='mdi-format-clear' " +
    "fontset='mdi' role='img'></mat-icon>";
    icons["list"]["ordered"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-list-numbered' fonticon='mdi-format-list-numbered'" +
    "fontset='mdi' role='img'></mat-icon>";
    icons["list"]["bullet"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-list-bulleted' fonticon='mdi-format-list-bulleted' " +
    "fontset='mdi' role='img'></mat-icon>";
    icons["header"]["1"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-header-1' fonticon='mdi-format-header-1' " +
    "fontset='mdi' role='img'></mat-icon>";
    icons["header"]["2"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-header-2' fonticon='mdi-format-header-2' " +
    "fontset='mdi' role='img'></mat-icon>";
    icons["script"]["sub"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-subscript' fonticon='mdi-format-subscript' " +
    "fontset='mdi' role='img'></mat-icon>";
    icons["script"]["super"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-superscript' fonticon='mdi-format-superscript' " +
    "fontset='mdi' role='img'></mat-icon>";
    icons["indent"]["-1"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-indent-decrease' fonticon='mdi-format-indent-decrease' " +
    "fontset='mdi' role='img'></mat-icon>";
    icons["indent"]["+1"] = "<mat-icon class='mdi-18px mat-icon mdi mdi-format-indent-increase' fonticon='mdi-format-indent-increase' " +
    "fontset='mdi' role='img'></mat-icon>";
  }

  getSendASAndBehalfOf(): void {
    const fromAddress: any[] = [];
    this.preferenceService.discoverRightsRequest().pipe(take(1)).subscribe( res => {
      if (res.DiscoverRightsResponse && res.DiscoverRightsResponse[0].targets && isArray(res.DiscoverRightsResponse[0].targets)) {
        const discoverRights = res.DiscoverRightsResponse[0].targets;
        discoverRights.map( disCover => {
          if (disCover.right === "sendOnBehalfOf") {
            if (disCover.target && isArray(disCover.target)) {
              const behalf = disCover.target;
              behalf.map( b => {
                 fromAddress.push({
                  value: b.email[0].addr,
                  displayValue: "on behalf of " + b.email[0].addr
                });
              });
            }
          } else if (disCover.right === "sendAs") {
            if (disCover.target && isArray(disCover.target)) {
              const sendAs = disCover.target;
              sendAs.map( s => {
                fromAddress.push({
                  value: s.email[0].addr,
                  displayValue: s.email[0].addr
                });
              });
            }
          }
        });

        this.preferenceService.sendAsAndBehalf = fromAddress;
      }
    });
  }

  private setupEmoji() {
    let emojiType = "google";
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") !== -1;
    if (CommonUtils.isOnIOS() || CommonUtils.isOnIOSMobile() || isMac) {
      emojiType = "apple";
    }
    wdtEmojiBundle.defaults.emojiType = emojiType;
    wdtEmojiBundle.defaults.emojiSheets = {
      "google": CommonUtils.getFullUrl("/assets/emoji/sheet_google_64_indexed_128.png"),
      "apple": CommonUtils.getFullUrl("/assets/emoji/sheet_apple_64_indexed_128.png")
    };
    if (!wdtEmojiBundle.emoji) {
      console.log("EmojiConvertor", EmojiConvertor);
      wdtEmojiBundle.emoji = new EmojiConvertor();

      wdtEmojiBundle.emoji.img_set = emojiType;
      wdtEmojiBundle.emoji.use_sheet = true;
      wdtEmojiBundle.emoji.supports_css = true;
      wdtEmojiBundle.emoji.allow_native = true;
      wdtEmojiBundle.emoji.img_sets = {
        "google": {
          mask: 2,
          path: "emoji-data/img-google-64/",
          sheet: CommonUtils.getFullUrl("/assets/emoji/sheet_google_64_indexed_128.png")
        },
        "apple": {
          mask: 2,
          path: "emoji-data/img-apple-64/",
          sheet: CommonUtils.getFullUrl("/assets/emoji/sheet_apple_64_indexed_128.png")
        }
      };
      document.querySelector("body").dataset.wdtEmojiBundle = emojiType;

      this.changeDetectionRef.markForCheck();
    }
   }

  private firebaseClearAllNotifications() {
    // remove all notifications on opening the app
    window.FirebasePlugin.clearAllNotifications( () => {
      console.log("[AppComponent][FirebasePlugin clearAllNotifications] success");
    }, error => {
      console.error("[AppComponent][FirebasePlugin clearAllNotifications] error", error);
    });
  }

  private setFirebaseToken() {
    window.FirebasePlugin.onTokenRefresh((token) => {
      console.log("[AppComponent]Firebase token", token);
      const tokenToUse = CommonUtils.isOnIOS() ? "ios-" + token : token;
      if (token && token.trim().length > 0) {
        this.appService.setFirebaseToken(tokenToUse).subscribe(res => {
          if (!!res) {
            this.isFirebaseSetUpCompleted = true;
            console.log("[AppComponent] Firebase token set");
          }
        }, err => {
          console.error("[AppComponent] Firebase token not set", err);
        });
      }
    }, error => {
      console.error("[AppComponent] Firebase onTokenRefresh error", error);
    });
  }

  private handleOpenUrlDetail(url) {
    this.lockForOpenFromNotificationOrWidget = true;
    const folderPath = MailUtils.getFolderPath("2");
    const urlSplited = url.split("/");
    const mid = urlSplited[urlSplited.length - 1];

    if (this.syncRequestDone) {
      console.log("DebugRouterNavigation [handleOpenUrlDetail] syncrequestDone: ", url);
      this.lockForOpenFromNotificationOrWidget = true;
      this.router.navigate([`${folderPath}/detail/m/${mid}`]);
      this.convRepository.markReadUnreadMessages([mid], true);
      setTimeout(() => {
        this.ngZone.run(() => {
          this.broadcaster.broadcast("onOpenNotification");
          this.lockForOpenFromNotificationOrWidget = false;
        });
      }, 200);

    } else {
      console.log("[handleOpenUrlDetail] bgMetaSyncDone waiting for syncRequestDone - ", url);
      if (!this.isOnline) {
        this.syncRequestDone = true;
        this.convRepository.setSyncRequestDone();
        let nts = new Date().getTime();
      	this.bgMetaSyncDone.next(nts);
        this.bgSyncInProgress = false;
        this.changeDetectionRef.markForCheck();
        setTimeout(() => {
          this.handleOpenUrlDetail(url);
        }, 200);
      } else {
        console.log("[handleOpenUrlDetail] online - fetching from server - ", url);
        this.ngZone.run(() => {
          this.router.navigate([`${folderPath}/detail/m/${mid}`], { queryParams: { openFromNotification: true } });
        });
        /*
        setTimeout(() => {
          this.ngZone.run(() => {
            this.broadcaster.broadcast("onOpenNotification");
            this.lockForOpenFromNotificationOrWidget = false;
          });
        }, 200);
        this.convRepository.retrieveMessagesByIdFromServer(mid).pipe(take(1)).subscribe(() => {
          console.log("DebugRouterNavigation [handleOpenUrlDetail] online - fetching from server done, navigating  - ", url);
          console.log("DebugRouterNavigation [handleOpenUrlDetail] online - fetching from server done, navigating detail - ", folderPath);
          this.router.navigate([`${folderPath}/detail/m/${mid}`]);
          this.convRepository.markReadUnreadMessages([mid], true);
          setTimeout(() => {
            this.ngZone.run(() => {
              this.broadcaster.broadcast("onOpenNotification");
              this.lockForOpenFromNotificationOrWidget = false;
            });
          }, 200);
        });
        */
      }

    }
  }

  private deepLinksHandlerSetup() {
    window.handleOpenURL = url => {
      console.log("[AppComponent][handleOpenURL]", url);

      if (url.startsWith("vncmail:")) {
        console.log("[deepLinksHandlerSetup][Mail] Actions");
        // ‘vncmail://compose’
        // ‘vncmail://main’
        // ‘vncmail://details/<mail_conv_id>’

        if (url.includes("compose")) {
          const mailAddress = url.split("?to=")[1];
          setTimeout(() => {
            console.log("[deepLinksHandlerSetup][composemail] ", mailAddress);
            this.router.navigate(["/mail/compose"], { queryParams: { to: mailAddress } });
            this.ngZone.run(() => {
              this.broadcaster.broadcast("onOpenCompose");
            });
          }, 2000);
        } else if (url.includes("cal-ev-create")) {
            const mailAddress = url.split("?to=")[1];
            this.router.navigate(["/calendar"]);
            console.log("[deepLinksHandler openUrl onApplicationDidLaunchFromLink]:", url, mailAddress);
            setTimeout(() => {
              console.log("[deepLinksHandler openUrl broadcasting]:", url, mailAddress);
              this.broadcaster.broadcast("OPEN_APPOINTMENT_FULL_COMPOSE_FOR_WIDGET", {
                to: mailAddress
              });
            }, 2000);
        } else if (url.includes("main")) {
          if (url === "vncmail://main/calendar") {
            console.log("[deepLinksHandlerSetup][Mail -> Calendar] navigate");
            this.router.navigate(["/calendar"]);
            setTimeout(() => {
              this.ngZone.run(() => {
                this.broadcaster.broadcast("onOpenCalendarApp");
              });
            }, 200); // was 2000 ?
          } else {
            this.router.navigate(["/mail/inbox"]);
            setTimeout(() => {
              this.ngZone.run(() => {
                this.broadcaster.broadcast("onOpenMore");
              });
            }, 200);
          }
        } else if (url.includes("details")) {
          this.handleOpenUrlDetail(url);
        }
      } else if (url.startsWith("vnccalendar:")) {
        console.log("[deepLinksHandlerSetup][Calendar] Actions");
        // "vnccalendar://compose
        // "vnccalendar://main"
        // "vnccalendar://details/%s"
        // "vnccalendar://appointment_details/%s/%s"; //where %s is id of event in format 'xxxxx-xxxxx' and %s for ridz

        if (url.includes("compose")) {
          this.router.navigate(["/calendar"]);
          setTimeout(() => {
            this.ngZone.run(() => {
              console.log("[OPEN_APPOINTMENT_FULL_COMPOSE_FOR_WIDGET]");
              this.broadcaster.broadcast("OPEN_APPOINTMENT_FULL_COMPOSE_FOR_WIDGET");
            });
          }, 2000);
        } else if (url.includes("main")) {
          this.router.navigate(["/calendar"]);
          setTimeout(() => {
            this.ngZone.run(() => {
              this.broadcaster.broadcast("onOpenCalendarApp");
            });
          }, 2000);
        } else if (url.includes("appointment_detail")) {
          this.router.navigate(["/calendar"]);
          const urlSplited = url.split("/");
          const eventId = urlSplited[3];
          const ridz = urlSplited[4];
          this.router.navigate(["/calendar"]);
          if (document.querySelector("vp-appointment-preview-dialog") !== null) {
            this.broadcaster.broadcast(BroadcastKeys.HIDE_PREVIE_EVENT_DIALOG);
          }
          setTimeout(() => {
            this.ngZone.run(() => {
              console.log("[appointment_detail]: eventId: ", eventId, " ridz: ", ridz);
              this.broadcaster.broadcast("OPEN_PREVIEW_DIALOG_FRON_APPOINTMENT_ID", {
                eventId: eventId,
                ridz: ridz
              });
            });
          }, 2000);
        } else if (url.includes("details")) {
          this.router.navigate(["/calendar"]);
          const urlSplited = url.split("/");
          const date = urlSplited[urlSplited.length - 1];
          console.log("[urlSplited][date]: ", date);
          const openDate = moment(date).toDate();
          console.log("[event][openDate]: ", openDate);
          setTimeout(() => {
            this.ngZone.run(() => {
              this.broadcaster.broadcast("SET_DAY_VIEW_WITH_DATE", openDate);
            });
          }, 2000);
        }
      }
    };
  }

  private firebaseSetup() {
      console.log("[AppComponent][firebaseSetup]");

      try {
        this.appService.saveApiUrl();

        window.FirebasePlugin.hasPermission(hasPermission => {
          console.log("[app.component][firebaseSetup] Permission is ", hasPermission);
          if (!hasPermission) {
            // request permission
            window.FirebasePlugin.grantPermission(hasGratedPermission => {
              console.log("[app.component][firebaseSetup] GrantPermission is ", hasGratedPermission);
            });
          }
        });


        window.FirebasePlugin.initCrashlytics(() => {
          console.log("[AppComponent][firebaseSetup][initCrashlytics] OK");
        }, function (error) {
          console.error("[AppComponent][firebaseSetup][initCrashlytics]", error);
        });
        const isOnline$ = this.store.pipe(select(getOnlineStatus), filter(v => !!v), takeWhile(() => !this.isFirebaseSetUpCompleted));
        const isLoggedIn$ = this.store.pipe(select(getIsLoggedIn), filter(v => !!v), takeWhile(() => !this.isFirebaseSetUpCompleted));
        isOnline$.pipe(combineLatest(isLoggedIn$)).subscribe(res => {
          console.log("firebaseSetup Logged in and online", res);
          this.setFirebaseToken();
        });
        window.FirebasePlugin.onNotificationOpen(($event) => {
          this.lockForOpenFromNotificationOrWidget = true;
          console.log("[AppComponent][firebaseSetup][onNotificationOpen]", $event, window.appInBackground);
          if ($event.tap) {
            if ($event.mid) {
              console.log("[AppComponent][firebaseSetup][onNotificationOpen] mail detail", $event.mid);
              let folderId = "2";
              if ($event.folderId) {
                folderId = $event.folderId;
              }
              const folderPath = MailUtils.getFolderPath(folderId);
              if ($event.notificationType && ($event.notificationType === "appointment" || $event.notificationType === "appointment_invite_reply")) {
                console.log("[AppComponent][firebaseSetup][onNotificationOpen] appointment: ", $event.mid);
                if (this.isOnline) {
                  this.commonService.getBatchAppointmentRequest([$event.mid]).subscribe(resp => {

                    if (!!resp && resp.appt && resp.appt.length > 0) {
                      console.log("[$event.notificationType]: appointment_invite_reply getBatchAppo resp:", resp);
                      let openAppo = this.calendarRepository.mapAppointmentFromAppo(resp.appt[0]);
                      try {

                        if (!openAppo.invId) {
                          openAppo.invId = resp.appt[0].inv[0].id;
                        }
                      } catch (error) {
                        console.error("appoconv error: ", error);
                      }
                      this.callOpenAppointment({ event: openAppo });

                    }
                  });
                } else {
                  this.databaseService.getAppointmentsById($event.mid).subscribe(resp => {
                    if (!!resp && resp.length > 0) {
                      console.log("[$event.notificationType]: appointment_invite_reply db resp :", resp);
                    }
                  });
                }
              } else {
                if ($event.type && $event.type === "message") {
                  console.log("DebugRouterNavigation openFromMailOrWidget1 ", $event.mid);
                  this.router.navigate([`${folderPath}/detail/m/${$event.mid}`]);
                  this.mailService.markConvMsgAsReadFromNotification(true, $event.mid);
                } else {
                  console.log("DebugRouterNavigation openFromMailOrWidget2 ", $event.mid);
                  this.router.navigate([`${folderPath}/detail/m/${$event.mid}`]);
                  this.mailService.markConvMsgAsReadFromNotification(true, $event.mid);
                }
              }
              setTimeout(() => {
                this.ngZone.run(() => {
                  if (document.querySelector("vp-appointment-invite-operation-dialog") !== null) {
                    this.broadcaster.broadcast(BroadcastKeys.HIDE_APPOINTMENT_INVITE_DIALOG_OPERATION);
                  }
                  this.broadcaster.broadcast("onOpenNotification");
                  this.lockForOpenFromNotificationOrWidget = false;
                });
              }, 200);
            } else {
              this.router.navigate(["/mail/inbox"]);
            }
            if (CommonUtils.isOnIOS()) {
              this.firebaseClearAllNotifications();
            }
          }
        }, function (error) {
          console.error("[AppComponent][firebaseSetup][onNotificationOpen]", error);
        });
        // will be called only in foreground
        window.FirebasePlugin.onNotificationReceived(($event) => {
          console.log("[AppComponent][FirebasePlugin][onNotificationReceived][notification]", $event);
          if (MailUtils.isOnAndroid() && !window.appInBackground) {
           if (this.configService.prefs.zimbraPrefMailToasterEnabled === "TRUE") {
              console.log("[AppComponent][FirebasePlugin][onNotificationReceived] calling noOp ", window.appInBackground);
              this.noOpTrigger.next({ts: Date.now(), force: false});
              // this.noOp();
              if (($event.notificationType && $event.notificationType === "appointment") ||
              ($event.notificationType && $event.notificationType === "appointment_invite_reply")) {
                console.log("[$event.notificationType] appointment | appointment_invite_reply");
                this.notificationsService.scheduleCalendarNotification($event.fromDisplay, $event.mid,
                  $event.subject, $event.body, $event.folderId, $event.ntype, $event.cid, $event.fromAddress,
                  $event.notificationType, $event.appointmentId);
              } else {
                console.log("[scheduleMailNotification] call");
                this.notificationsService.scheduleLocalMailNotification($event.fromDisplay, $event.mid,
                  $event.subject, $event.body, $event.folderId, $event.ntype, $event.cid, $event.fromAddress);
              }
            }
          } else {
            if (MailUtils.isOnAndroid() && window.appInBackground) {
              console.log("[AppComponent][firebaseSetup][onNotificationReceived] background - maybe perform sync");
              try {
                let lastBGsync = localStorage.getItem("lastbgsync");
                let lastbgts;
                if (!!lastBGsync) {
                  lastbgts = parseInt(lastBGsync, 10);
                } else {
                  lastbgts = parseInt(localStorage.getItem("lastTimeInBackground"), 10);
                }
                let nts = new Date().getTime();
                console.log("[AppComponent][firebaseSetup][onNotificationReceived] background - timestamps: ", nts, lastbgts);
                if ((nts - lastbgts) > 300000) {
                  console.log("[AppComponent][firebaseSetup][onNotificationReceived] background - need to perform sync");
                  cordova.plugins.backgroundMode.enable();

                  if (!this.configService.worker) {
                    try {

                      this.initWebWorker();
                    } catch (error) {
                      if (environment.isCordova) {
                        CommonUtils.workerSentryLog("[AppComponent] init worker error2");
                      }
                    }
                  }
                  let lastMessageTimestamp = lastbgts - 300000;
                  // localStorage.setItem("lastMessageTimestamp", lastMessageTimestamp.toString());
                  try {
                    window.plugins.insomnia.keepAwake(() => {
                      console.log("[AppComponent][firebaseSetup][onNotificationReceived] background - insomnia active: ", nts);
                      // this.backgroundSyncLastMessages();
                      this.bgSyncMessagesStart.next(nts);
                    }, error => {
                      console.warn("[AppComponent][firebaseSetup][onNotificationReceived] background - insomnia failed: ", error);
                    });

                  } catch (error) {
                    console.warn("[AppComponent][firebaseSetup][onNotificationReceived] background - insomnia failed2: ", error);
                  }


                  localStorage.setItem("lastbgsync", nts.toString());
                  if (cordova.plugin.AndroidMailWidgetPlugin) {
                    console.warn("[AppComponent][onDeviceReady] AndroidMailWidgetPlugin updateWidget");
                    cordova.plugin.AndroidMailWidgetPlugin.updateWidget();
                  }
                  if (cordova.plugin.AndroidCalendarWidgetPlugin) {
                    console.warn("[AppComponent][onDeviceReady] AndroidCalendarWidgetPlugin updateWidget");
                    cordova.plugin.AndroidCalendarWidgetPlugin.updateWidget();
                  }
                } else {
                  console.log("[AppComponent][firebaseSetup][onNotificationReceived] background - skip sync");
                  if (!this.configService.worker) {
                    this.initWebWorker();
                  }
                  // this.handlePushLocalMessage($event);
                }
              } catch (error) {
                console.error("[AppComponent][firebaseSetup][onNotificationReceived] background ", error);
              }
            }
          }
        }, function (error) {
          console.error("[AppComponent][firebaseSetup][onNotificationReceived]", error);
        });
      } catch (e) {
        console.error("[[AppComponent][firebaseSetup] error", e);
      }
  }

  setLanguage(prefs: Preference[]): void {
    let havezimbraPrefLocale = false;
    prefs.map(p => {
      if (p.key === "zimbraPrefLocale") {
        havezimbraPrefLocale = true;
        const lang = MailUtils.getCurrentLanguage(p.value);
        this.configService.updateLanguage(lang);
        if (this.electronService.isElectron) {
          this.electronService.setToStorage(MailConstants.MAIL_LANGUAGE, lang);
        } else {
          localStorage.setItem(MailConstants.MAIL_LANGUAGE, lang);
        }
      }
    });

    if (!havezimbraPrefLocale) {
      this.configService.updateLanguage("en");
      if (this.electronService.isElectron) {
        this.electronService.setToStorage(MailConstants.MAIL_LANGUAGE, "en");
      } else {
        localStorage.setItem(MailConstants.MAIL_LANGUAGE, "en");
      }

      this.setDefaultLanguage();
    }
  }

  setDefaultLanguage() {
    this.translateService.use("en");
    this.translateService.reloadLang("en");
    this.broadcaster.broadcast(MailConstants.CHANGE_LANGUAGE);
    this.configService.setCurrentLanguage("en");
    if (this.electronService.isElectron) {
      this.electronService.setToStorage(MailConstants.MAIL_LANGUAGE, "en");
    } else {
      localStorage.setItem(MailConstants.MAIL_LANGUAGE, "en");
    }
  }


  updateFolder(folder) {
    const updatedFolder = this.mailService.flatFolders[folder.id];
    // console.log("[AppComponent][updateFolder]", folder, updatedFolder);

    if (updatedFolder) {
      this.mailService.flatFolders[folder.id] = {...updatedFolder, ...folder};
        // console.log("[AppComponent] [updateFolder] found the folder", updatedFolder);
        let timeout = 1000;
        if (environment.isCordova) {
          timeout = 3000;
        }
        setTimeout(() => {
          this.ngZone.runOutsideAngular(() => {
            const parentFolder = MailUtils.getParentById(this.mailService.flatFolders, updatedFolder.l);
            if (parentFolder) {
              MailUtils.updateChildFolder(parentFolder, folder);
              this.store.dispatch(new UpdateMailFolderSuccess({ id: parentFolder.id, changes: parentFolder }));
              // console.log("[AppComponent] [updateFolder] found the parent and update", parentFolder);
            } else {
              this.store.dispatch(new UpdateMailFolderSuccess({ id: folder.id, changes: folder }));
            }
          });
        });
    }
  }

  moveToNewParent(newFolder, newParentId) {
    console.log("[AppComponent][moveToNewParent]", newFolder, newParentId);

    const parent = this.mailService.flatFolders[newParentId];
    if (parent) {
      const children = parent.children || [];
      if (!_.find(children, {id: newFolder.id})) {
        children.push(newFolder);
        parent.children = children;
        this.updateFolder(parent);
      }
    }
  }

  @HostListener("dragover", ["$event"])
  onDragOver(event) {
      event.preventDefault();
      event.stopPropagation();
      // console.log("[AppComponent][onDragOver]", event);
      this.broadcaster.broadcast("onDragOver");
      return false;
  }

  @HostListener("dragleave", ["$event"])
  onDragLeave(event) {
      event.preventDefault();
      event.stopPropagation();
      console.log("[AppComponent][onDragLeave]", event);
      this.broadcaster.broadcast("onDragLeave");
      return false;
  }

  @HostListener("drop", ["$event"])
  onDrop(event) {
      event.preventDefault();
      event.stopPropagation();
      console.log("[AppComponent][onDrop]", event);
      this.broadcaster.broadcast("onDrop");
      return false;
  }

  getBriefcaseShareFolders(): void {
    this.briefcaseService.getBriefcaseFolders({ view: "document" }, false).subscribe(
      res => {
        this.setBriefcaseShareQuery(res);
      },
      err => {
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    );
  }

  getExpandMailFolders(): void {
    this.mailService.getMailBoxMetaData().subscribe(response => {
      const attributes = response.GetMailboxMetadataResponse["0"].meta["0"]._attrs;
      if (attributes && attributes.zimbraPrefFoldersExpanded) {
        this.store.dispatch(new SetExpandMailFolders(attributes.zimbraPrefFoldersExpanded));
      }
    });
  }

  setBriefcaseShareQuery(response: any): void {
    const childFolders = MailUtils.getChildFolders(response);
    const allFolders = [...response, ...childFolders];
    const searchQuery = this.getShareFoldersQuery(allFolders);
    if (this.electronService.isElectron) {
      this.electronService.setToStorage("BrifcaseSearchQuery", searchQuery);
    } else {
      localStorage.setItem("BrifcaseSearchQuery", searchQuery);
    }
  }

  getShareFoldersQuery(folders: BriefcaseFolder[]): string {
    const ids: string [] = [];
    folders.map( f => {
      if (f.perm) {
        if (f.id.indexOf(":") !== -1 ) {
          ids.push("inid:" + "\"" + f.id + "\"");
        } else {
          ids.push("inid:" + f.id);
        }
        const children = MailUtils.getChildFolders([f]);
        if (children.length > 0) {
          children.map( c => {
            ids.push("inid:" + "\"" + c.id + "\"");
          });
        }
      }
    });
    ids.push("is:local");
    return ids.join(",").replace(/,/g, " OR ");
  }

  getCalenderFolderColorById(folderId: string): String {
    let folderColor: string = "#000099";
    this.store.select(getCalendarFolders).subscribe(folders => {
      const fld = [...folders , ...CalenderUtils.getChildFolders(folders)];
        if (!!folderId && !!fld) {
          console.log("getCalendarFolderColorById ", folderId);
          fld.map( f => {
              if (folderId.toString().indexOf(":") !== -1) {
                  const zid = folderId.split(":")[0];
                  const rid = folderId.split(":")[1];
                  if (!!f.rid && f.rid) {
                      if (f.zid === zid && f.rid.toString() === rid) {
                          folderColor = f.folderColor;
                      }
                  } else {
                      if (f.id === folderId) {
                          folderColor = f.folderColor;
                      }
                  }
              } else {
                  if (f.id === folderId) {
                      folderColor = f.folderColor;
                  }
              }
          });
      }
    });
    return folderColor;
  }

  getCalenderEvent(app: any): CalendarAppointment {
    const component = app.inv[0].comp[0];
    const ridZ = component.allDay === true ? component.s[0].d : this.getAppointmentRidz(component.s[0].d);
    const calAppointment = <CalendarAppointment> {
      allDay : component.allDay ? true : false,
      fb : component.fb,
      fba: component.fba,
      alarm: component.alarm ? component.alarm : [],
      start: moment(component.s[0].d).toDate(),
      end: moment(component.e[0].d).toDate(),
      apptId: component.apptId,
      id: component.apptId,
      location: component.loc ? component.loc : "",
      bgcolor: this.getCalenderFolderColorById(app.l),
      title: component.name,
      folderId: component.ciFolder,
      invId: app.id + "-" + app.inv[0].id,
      inst: [{
        ridZ: ridZ
      }],
      eventId: app.id + "_" + ridZ,
      draggable: this.isMobileScreen ? false : true,
      idbKey: component.recur && component.recur.length && Object.keys(component.recur[0]).length ?  app.id + "_" + ridZ : component.apptId,
    };
    return calAppointment;
  }

  getAllContacts() {
    const body = {
      view: "contact",
    };
    this.mailService.getContactFolders(body).subscribe(
      res => {
        let que = "";
        if (res.folder[0].link) {
          for (let i = 0; i < res.folder[0].link.length; i++) {
            if (i === 0) {
              que += "inid:" + res.folder[0].link[i].id;
            } else {
              que += " OR inid:" + res.folder[0].link[i].id;
            }
          }
        }
        if (que.length > 0) {
          que = "(" + que + " OR is:local)";
        } else {
          que = "(is:local)";
        }
        const query: SearchRequest = {
          field: "contact",
          limit: 1000,
          needExp: 1,
          offset: 0,
          query: que,
          sortBy: "nameAsc",
          types: "contact"
        };
        this.mailService.searchRequest(query).subscribe(searchRes => {
          let allEmailAddresses = [];
          if (searchRes.cn) {
            let contacts: Contact[] = searchRes.cn;
            this.store.dispatch(new LoadALLContactsSuccess({ contact: contacts }));
          }
          this.changeDetectionRef.markForCheck();
        }, error => {
          this.errorService.emit({ id: ErrorType.Generic, messages: error });
        });
      },
      err => {
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    );
  }

  getGalContacts() {
    const query = {
      name: "",
    };
    this.mailService.searchGalRequest(query).pipe(take(1)).subscribe(res => {
      if (res.cn) {
        let contacts: Contact[] = res.cn;
        this.store.dispatch(new LoadAllGalContactsSuccess({ contact: contacts }));
      }
      this.changeDetectionRef.markForCheck();
    }, error => {
      this.errorService.emit({ id: ErrorType.Generic, messages: error });
    });
  }

  getAppointmentRidz(timeStamp: string): string {
    return moment(moment(timeStamp).toDate()).utc().format("YYYYMMDDTHHmmss") + "Z";
  }

  appointmentList(): void {
    if (!!this.events && this.events.length > 0) {
      this.alarmEventQueue = [];
      this.events.map( (appt: any) => {
        if (!!appt.alarmData && Array.isArray(appt.alarmData) && !this.isReadOnlyFolder(appt.folderId)) {
          if (appt.isRepeatAppt && appt.isRepeatAppt === true) {
            const today = moment(new Date()).format("YYYY-MM-DD");
            const instanceDate = moment(appt.start).format("YYYY-MM-DD");
            if (moment(today).isSame(instanceDate)) {
              this.alarmEventQueue.push(appt);
            }
          } else {
            this.alarmEventQueue.push(appt);
          }
          this.changeDetectionRef.markForCheck();
        }
      });
      this.alarmEventQueue = this.alarmEventQueue.filter((f: any) => !!f.alarmData);
      this.changeDetectionRef.markForCheck();
      console.log("[appointmentList][alarmEventQueue]: ", this.alarmEventQueue);
      this.eventForAlarm();
    }
  }

  startEventQueueTimer() {
    this.eventQueueTimer = timer(0, 60000).pipe(takeUntil(this.isAlive$)).subscribe(val => {
      if (val > 0) {
        if (this.alarmEventQueue.length > 0) {
          console.log("[Event Reminder Timer]");
          this.eventForAlarm();
        }
      }
    });
  }

  stopEventQueueTimer() {
    if (this.eventQueueTimer) {
      this.eventQueueTimer.unsubscribe();
      this.eventQueueTimer = undefined;
    }
  }

  eventForAlarm() {
    console.log("[eventForAlarm][AllEvents]: ", this.alarmEventQueue, this.commonService.alarmEventsActive, this.eventQueueTimer);
    // this.alarmEventQueue
    this.alarmEventQueue.map((dd: any) => {
      this.databaseService.getAppointmentsById(dd.id).subscribe((resp: any) => {
        if (resp) {
          if (dd.id === resp.id) {
            dd = resp;
          }
        }
      });
    });
    if (this.alarmEventQueue.length > 0) {
      const today = moment(new Date());
      this.alarmEventQueue.map( (appt: any) => {
        if (!!appt.alarmData && !!appt.alarmData[0] && !!appt.alarmData[0].nextAlarm) {
          const apptAlarm = moment(appt.alarmData[0].nextAlarm);
          // console.log("eventForAlarmMapDebug: ", apptAlarm, appt);
          if (today.isSame(apptAlarm, "minute") || today.isAfter(apptAlarm, "minute")) {
            if (this.commonService.alarmEventsActive.length === 0) {
              appt.alarmItem = "1-MINUTE";
              this.commonService.alarmEventsActive.push(appt);
              this.translateService.get("CALENDARS.APPOINTMENT_REMINDER_LBL").pipe(take(1)).subscribe((title: string) => {
                this.openNotification(title, appt.title);
              });
            } else {
              let apptIndex = -1;
              this.commonService.alarmEventsActive.forEach((activeAppt, indx) => {
                if (activeAppt.id === appt.id) {
                  apptIndex = indx;
                }
              });
              if (apptIndex === -1) {
                appt.alarmItem = "1-MINUTE";
                this.commonService.alarmEventsActive.push(appt);
                this.translateService.get("CALENDARS.APPOINTMENT_REMINDER_LBL").pipe(take(1)).subscribe((title: string) => {
                  this.openNotification(title, appt.title);
                });
                appt.activated = true;
              }
            }
          }
        }
      });
      if (this.commonService.alarmEventsActive.length > 0) {
        this.commonService.isEventReminderActivated = true;
        this.changeDetectionRef.markForCheck();
      }
      if (this.eventQueueTimer === undefined) {
        this.startEventQueueTimer();
      }
    } else  {
      if (this.eventQueueTimer) {
        this.eventQueueTimer.unsubscribe();
        this.eventQueueTimer = undefined;
      }
    }
  }

  isReadOnlyFolder(folderId: string): boolean {
    let calendarFolders: CalendarFolder [] = [];
    this.store.select(getCalendarFolders).pipe(take(1)).subscribe(res => {
      if (!!res && res !== null) {
        calendarFolders = res;
      }
    });
    const fld = [...calendarFolders , ...CalenderUtils.getChildFolders(calendarFolders)];
    let readOnly: boolean = false;
    fld.map( f => {
      // console.log("calendar isReadOnlyFolder ", folderId);
      if (!!folderId && (folderId.toString().indexOf(":") !== -1)) {
        const zid = folderId.split(":")[0];
        const rid = folderId.split(":")[1];
        if (!!f.rid && f.rid) {
            if (f.zid === zid && f.rid.toString() === rid) {
                readOnly = true;
            }
        } else if (f.id === folderId) {
            readOnly = true;
        }
     } else {
       if (f.id === folderId && f.perm) {
         readOnly = true;
       }
     }
    });
    return readOnly;
  }

  updateCalendarFolder(folder) {
    let flatFolders: CalendarFolder [] = [];
    this.store.select(getCalendarFolders).pipe(take(1)).subscribe(folders => {
      flatFolders = folders;
    });
    const updatedFolder = CalenderUtils.getCalendarFolderById(flatFolders, folder.id);
    if (updatedFolder) {
      const parentFolder = CalenderUtils.getParentById(flatFolders, updatedFolder.l);
      console.log("[updateFolder] found the folder", updatedFolder);
      if (parentFolder) {
        CalenderUtils.updateChildFolder(parentFolder, folder);
        parentFolder.folderColor = this.getColor(parentFolder);
        this.store.dispatch(new UpdateCalendarFolderSuccess({ id: parentFolder.id, changes: parentFolder }));
        console.log("[updateFolder] found the parent and update", parentFolder);
      } else {
        folder.folderColor = this.getColor(folder);
        this.store.dispatch(new UpdateCalendarFolderSuccess({ id: folder.id, changes: folder }));
      }
    }
  }

  removeCalendarChildFromParent(folder) {
    let flatFolders: CalendarFolder [] = [];
      this.store.select(getCalendarFolders).pipe(take(1)).subscribe(folders => {
        flatFolders = folders;
    });
    const updatedFolder = CalenderUtils.getCalendarFolderById(flatFolders, folder.id);
    if (updatedFolder) {
      const parentFolder = CalenderUtils.getParentById(flatFolders, updatedFolder.l);
      console.log("[removeChildFromParent] found the folder", updatedFolder);
      if (parentFolder) {
        CalenderUtils.removeChildFolder(parentFolder, folder);
        this.store.dispatch(new UpdateCalendarFolderSuccess({ id: parentFolder.id, changes: parentFolder }));
        console.log("[removeChildFromParent] found the parent and update", parentFolder);
      }
    }
  }

  addChildCalendarFolderToParent(folder) {
    let flatFolders: CalendarFolder [] = [];
    this.store.select(getCalendarFolders).pipe(take(1)).subscribe(folders => {
      flatFolders = folders;
    });
    const parentFolder = CalenderUtils.getParentById(flatFolders, folder.l);
    console.log("[addChildToParent] found the folder", parentFolder);
    if (parentFolder) {
      CalenderUtils.addChildFolder(parentFolder, folder);
      this.store.dispatch(new UpdateCalendarFolderSuccess({ id: parentFolder.id, changes: parentFolder }));
      console.log("[addChildToParent] found the parent and update", parentFolder);
    }
  }

  deleteCalendarFolderFromParent(f: CalendarFolder) {
    console.log("[deleteCalendarFolderFromParent]", f);
    this.removeCalendarChildFromParent(f);
  }

  moveToNewCalendarParent(newFolder, newParentId) {
    let flatFolders: CalendarFolder [] = [];
      this.store.select(getCalendarFolders).pipe(take(1)).subscribe(folders => {
        flatFolders = folders;
    });
    const parent = CalenderUtils.getCalendarFolderById(flatFolders, newParentId);
    if (parent) {
      const children = parent.folder || [];
      if (!_.find(children, {id: newFolder.id})) {
        children.push(newFolder);
        parent.folder = children;
        this.updateCalendarFolder(parent);
      }
    }
  }

  updateCalendarEventColor(folder: CalendarFolder): void {
    if (!!folder) {
      this.store.select(getAllCalendarAppointments)
      .pipe(take(1))
      .subscribe((calendarEvents: CalendarAppointment[]) => {
        if (!!calendarEvents) {
          const events = calendarEvents.filter(ev => ev.folderId === folder.id);
          if (!!events && events.length) {
            events.map(eve => {
              const updateFolder = this.getCalendarFolder(folder);
              if (!!updateFolder) {
                eve.bgcolor = updateFolder.folderColor;
                this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: eve.eventId, changes: eve }));
              }
            });
          }
        }
      });
    }
  }

  getCalendarFolder(folder): CalendarFolder {
    let calFolder: CalendarFolder;
    this.store.select(getCalendarFolders).pipe(take(1)).subscribe(res => {
      calFolder = CalenderUtils.getCalendarFolderById(res, folder.id);
    });
    return calFolder;
  }

  getColor(folder: CalendarFolder): string {
    if (folder["rgb"]) {
      return folder["rgb"];
    } else if (folder["color"]) {
      const colorIndex = CALENDARCONSTANT.COLOR_CODES.indexOf(folder["color"]);
      if (colorIndex >= 0) {
        return CALENDARCONSTANT.RGB_CODES[colorIndex];
      } else {
        return CALENDARCONSTANT.COLOR_CODES[folder["color"]].toLowerCase();
      }
    } else if (folder["owner"]) {
      return CALENDARCONSTANT.RGB_CODES[CALENDARCONSTANT.DEFAULT_FOLDER_COLOR];
    } else {
      return CALENDARCONSTANT.RGB_CODES[1];
    }
  }

  getIncomigFilters(): void {
    this.preferenceService.getFilters("incoming").pipe(take(1)).subscribe( res => {
      if (res.incomingFilters && isArray(res.incomingFilters)) {
        const filters = res.incomingFilters;
        this.store.dispatch(new SetIncomingFilters(filters));
      }
    });
  }

  getUserContacts(): void {
    const groupMemberIds: any [] = [];
    this.mailService.getAllUserContacts().pipe(take(1)).subscribe(res => {
      if (!!res && res.cn && isArray(res.cn)) {
        const contacts = res.cn;
        contacts.map( c => {
          if (!!c.m && c.m !== null) {
            groupMemberIds.push(c.id);
          }
        });
        if (!!groupMemberIds && groupMemberIds !== null && groupMemberIds.length > 0) {
          this.getAllDerefGroupMember(groupMemberIds, contacts);
        }
        console.log("[appComponent][allUserContacts] ", contacts);
        this.setUserContacts(contacts);
        this.databaseService.createOrUpdateContacts(contacts).subscribe(() => {
          console.log("[appComponent][allUserContacts] stored");
        });
      }
    });
  }

  getUserGalContacts(): void {
    setTimeout(() => {
      let iszimbraFeatureGalEnabled = false;
      this.store.select(getZimbraFeatures).pipe(takeUntil(this.isAlive$)).subscribe(res => {
        iszimbraFeatureGalEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_GAL_ENABLED);
      });
      if (iszimbraFeatureGalEnabled) {
        const body = {
          name: "*",
          limit: 100
        };
        this.commonService.searchGalRequest(body).pipe(take(1)).subscribe(res => {
          if (!!res && res.cn && isArray(res.cn)) {
            const contacts = res.cn;
            this.store.dispatch(new SetGalContacts(contacts));
          }
        });
      }
    }, 500);
  }

  isShowNotification(msg: any): boolean {
    let isShowNotificationItem: boolean = true;
    const showAllNewNotification = this.configService.prefs.zimbraPrefShowAllNewMailNotifications;
    if (!!showAllNewNotification && showAllNewNotification !== null && !!msg && msg.l) {
      const showOtherFolder = showAllNewNotification === "TRUE" ? true : false;
      if (showOtherFolder) {
        isShowNotificationItem = true;
      } else if (!showOtherFolder && msg.l === "2") {
        isShowNotificationItem = true;
      } else {
        isShowNotificationItem = false;
      }
    }
    console.log("[isShowNotification] ", isShowNotificationItem);
    return isShowNotificationItem;
  }

  getAllDerefGroupMember(groupMemberId: any, contacts: any): void {
    const contactIds: any[] = [];
    groupMemberId.map((id, index) => {
      contactIds.push({
        "@": {
          xmlns: "urn:zimbraMail"
        },
        _jsns: "urn:zimbraMail",
        cn: {
          id: id
        },
        requestId: index,
        returnCertInfo: "1",
        derefGroupMember: "1"
      });
    });
    const request = {
      GetContactsRequest: contactIds
    };
    this.mailService.createBatchRequest(request).pipe(take(1)).subscribe(res => {
      if (!!res.GetContactsResponse && isArray(res.GetContactsResponse)) {
        const contactItem = res.GetContactsResponse;
        contactItem.map( item => {
          if (item.cn) {
            const cnItem = item.cn[0];
            if (cnItem.m) {
              const member = cnItem.m;
              member.map(m => {
                if (m.type && (m.type === "G" || m.type === "C") && m.cn) {
                  const con = m.cn[0];
                  if (m.type === "G" && m.value) {
                    con["value"] = m.value;
                  }
                  contacts.push(con);
                }
              });
            }
          }
        });
        this.setUserContacts(contacts);
      }
    });
  }

  setUserContacts(contacts): void {
    this.store.dispatch(new SetUserContacts(contacts));
  }

  getUserSignatures(): void {
    console.log("[AppComponent][getUserSignatures]");
    this.store.pipe(select(IsDatabaseReady), filter(v => !!v), takeUntil(this.isAlive$)).subscribe(() => {
      this.preferenceRepo.retrieveSignatures().pipe(take(1)).subscribe( signatures => {
        const userSignatures = Array.isArray(signatures) ? signatures : [];
        this.store.dispatch(new SetUserSignatures(userSignatures));
      });
    });
  }

  // launchedAppFromLink Event Handler
  onApplicationDidLaunchFromLink (eventData: any): void {
    console.log("[eventData]:", eventData);
    if (eventData) {
      if (eventData.params) {
        const params = eventData.params;
        console.log("[params]", params);
        if (eventData.params && eventData.path === "/mail/compose") {
          console.log("[onApplicationDidLaunchFromLink]:", params.to);
          setTimeout(() => {
            this.router.navigate(["/mail/compose"], { queryParams: { to: params.to } });
          }, 2000);
        } if (eventData.params && eventData.path === "/calendar/cal-ev-create") {
          console.log("[onApplicationDidLaunchFromLink]:", params.to);
          setTimeout(() => {
            this.broadcaster.broadcast("OPEN_APPOINTMENT_FULL_COMPOSE_FOR_ATEENDEE", {
              to: params.to
            });
          }, 2000);
        } else {
          console.log("DebugRouterNavigation [onApplicationDidLaunchFromLink]:", eventData.path);
          this.router.navigate([eventData.path], { queryParams: params });
        }
      }
    }
  }

  showToastMessage(): void {
    this.toastService.show("INCORRECT_PASTE_MSG");
  }

  getDirectoryTags(offsetRange: number): void {
    console.log("[configService.useVNCdirectoryAuth]: ", this.configService.useVNCdirectoryAuth);
    if (this.configService.useVNCdirectoryAuth) {
      const allDirectoryTags: DirectoryTag [] = [];
      const offset = offsetRange;
      const limit = 500;
      console.log("[getDirectoryTags] [offset]: ", offset , " [limit]:", limit);
      this.appService.getDirectoryTags(offset, limit).pipe(take(1)).subscribe(res => {
        console.log("[getDirectoryTags]: ", res);
        if (res && res.tags && res.tags.length > 0) {
          const tags = res.tags;
          tags.map((t: any) => {
            const dt: DirectoryTag = {} as DirectoryTag;
            dt.id = t.id;
            dt.name = t.name;
            if (t.color_hex && t.color_hex !== null) {
              dt.color = t.color_hex;
            } else {
              dt.color = MailConstants.DEFAULT_COLOR;
            }
            if (dt.name.indexOf("\"") === -1) {
              allDirectoryTags.push(dt);
            }
          });
          if (offset === 0) {
            this.store.dispatch(new LoadDirectoryTagsSuccess(allDirectoryTags));
          } else {
            this.store.dispatch(new LoadMoreDirectoryTagsSuccessAction(allDirectoryTags));
          }
          if (tags.length === limit) {
            this.getDirectoryTags(offsetRange + limit);
          }
        }
      }, error => {
        console.log("[getDirectoryTags][Fail][Error]: ", error);
        this.store.dispatch(new LoadDirectoryTagsFail());
      });
    }
  }

  openNotification(title: string, content: string): void {
    this.notificationsService.newConversation(title, content);
  }

  setLanguageFromDirectory(): void {
    // this.appService.getDirectoryAccountInfo().pipe(take(1)).subscribe(resp => {
    //   if (!!resp && resp.user) {
    //     const user = resp.user;
    //     const language = user.language;
    //     const lang = MailUtils.getCurrentLanguage(language);
    //     this.configService.updateLanguage(lang);
    //     if (this.electronService.isElectron) {
    //       this.electronService.setToStorage(MailConstants.MAIL_LANGUAGE, lang);
    //     } else {
    //       localStorage.setItem(MailConstants.MAIL_LANGUAGE, lang);
    //     }
    //   }
    // });
  }

  private tryToRecoverNoOp(): void {
    if (this.recoverNoOp > 5) {
      this.recoverNoOp = 0;
      this.waitDisallowed = false;
    } else {
      ++this.recoverNoOp;
    }
  }

  private initWebWorker() {
    if (typeof Worker !== "undefined") {
      // const useToken = ;
      const token = (environment.isCordova || environment.isElectron) ? localStorage.getItem(MailConstants.TOKEN) : "";
      try {
        this.configService.worker = new Worker(new URL("./app.worker", import.meta.url), { type: "module" });

      } catch (error) {
        console.error("unable to init worker: ", error);
        if (environment.isCordova) {
          CommonUtils.workerSentryLog("[AppComponent] init worker error");
        }

      }

      this.configService.worker.onmessage = ({ data }) => {
        console.log(`[worker] onworkermessage response`, data);

        if (!!data.type) {
          if (data.type === "constructorInit") {
            this.configService.worker.postMessage({ type: "initConfig", id: new Date().getTime(), args: { apiUrl: this.configService.API_URL, token: token } });
            this.configService.isWorkerReady = true;
          }

          if (data.type === "sentryLog") {

            console.log("sentryLog: ", data.response);
            CommonUtils.workerSentryLog(data.response);

          }

          if (data.type === "processPendingOps") {
            if (!!data.response && (data.response === "MAIL_FOLDER_UPDATE_AFTER_MOVE")) {
              this.broadcaster.broadcast("MAIL_FOLDER_UPDATE_AFTER_MOVE");
            }
            if (!!data.response && (data.response === "locked")) {

              console.log("processPendingWorker still locked");
              CommonUtils.workerSentryLog("processPendingWorker still locked");
            }

            if (!!data.response && ((data.response === "no pending ops") || (data.response.indexOf("unlocked") > 0))) {
              console.log("processPendingWorkerCLEANED - unlock multiTabPendingLock");
              this.convRepository.setMultiTabPendingLock(0);
            }
          }

          if (data.type === "MAIL_SENT_FAIL_DRAFT") {

            const confirmArgs: ConfirmArgs = {
              operationType: MailOperations.sendFailSavedDraft,
              subject: (!!data.response && !!data.response.subject) ? data.response.subject : "",
              manyRecipients: data?.response?.manyRecipients || false,
            };
            // height: "190px",
            const dialogArgs = {
              width: "390px",
              maxWidth: "85vw",
              autoFocus: false,
              data: confirmArgs,
              panelClass: "sidebar_folder_delete",
              backdropClass: ["confirmation-dialog-backdrop"]
            };
            const dialogRef = this.matDialog.open(ConfirmDialogComponent, dialogArgs);

          }

          if (data.type === "RemoveManyMessages") {
            this.store.dispatch(new RemoveManyMessages([data.response]));
          }
          if (data.type === "RemoveManyConversations") {
            this.store.dispatch(new RemoveManyConversations([data.response]));
          }
          if (data.type === "DeleteCalendarAppointmentsSuccessAction") {
            this.store.dispatch(new DeleteCalendarAppointmentsSuccessAction({ appointmentIds: [data.response] }));
          }
          if (data.type === "retrieveMessagesById") {
            this.convRepository.retrieveMessagesById(data.response).subscribe();
          }
          if (data.type === "pendingOperationSucces") {
            console.log("[app.component] pendingOpSucces ", data);
            if (data.op === "saveDraft") {
              this.broadcaster.broadcast("WORKER_SAVEDRAFT_SUCCESS", { id: data.response, res: data.res });
              if (data.response && data.response.startsWith("fake#")) {
                console.log("============== cleanupfakedraft: ", data.response, data.res);
                this.databaseService.deleteMessage(data.response).subscribe(() => {
                  this.store.dispatch(new RemoveManyMessages([data.response]));
                });
              }
            }
          }
        }
      };
    } else {
      console.info(`Web Workers are not supported in this environment.`);
      if (environment.isCordova) {
        CommonUtils.workerSentryLog("[AppComponent] init worker error not supported");
      }

      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  }

  private handlePushLocalMessage(pushObject: any) {
    if (typeof Worker !== "undefined") {
      const msg = MailUtils.createLocalMessageFromPushObject(pushObject);
      console.log("[handlePushLocalMessage] adding message: ", msg);
      this.configService.worker.postMessage({type: "createOrUpdateMessages", id: new Date().getTime(), args: [msg]});
    }
  }

  handleActionClick(v) {
    const jid = v.data;
    const action = v.action;
    switch (action) {
      case "one" : this.startTalkAudioCall(jid); break;
      case "two" : this.startTalkChat(jid); break;
      case "three" : this.startTalkVideoCall(jid); break;
      case "four" : this.sendEmail(jid); break;
      case "five" : this.createTicket(jid); break;
      case "six" : this.createTask(jid); break;
      case "info" : this.openProfile(jid); break;
      default: break;
    }
  }

  startTalkAudioCall(email) {
    if (email === this.currentUser?.email) {
        this.toastService.show("CAN_NOT_CALL_YOURSELF");
        return;
    }
    this.commonRepository.makeTalkAudioChatVideoOperation(email, "audio-call", "group-audio");
  }

  startTalkVideoCall(email) {
      if (email === this.currentUser?.email) {
          this.toastService.show("CAN_NOT_CALL_YOURSELF");
          return;
      }
      this.commonRepository.makeTalkAudioChatVideoOperation(email, "video-call", "group-video");
  }

  startTalkChat(email) {
      if (email === this.currentUser?.email) {
          this.toastService.show("CAN_NOT_CHAT_YOURSELF");
          return;
      }
      this.commonRepository.makeTalkAudioChatVideoOperation(email, "start-chat", "group-chat");
  }

  createTask(email) {
      if (email === this.currentUser?.email) {
          this.mailService.getLoggedInUserContactInfo(email).pipe(take(1)).subscribe(res => {
              if (res.products) {
                  let taskApp = res.products.filter( ap => ap.name.toLowerCase() === "vnctask");
                  if (taskApp.length > 0) {
                      this.commonRepository.createTask(res.username);
                  } else {
                      this.toastService.show("NO_ACCESS_FOR_APP");
                  }
              } else {
                  this.toastService.show("NO_ACCESS_FOR_APP");
              }
          });
      } else {
          this.mailService.getContactInfo(email).pipe(take(1)).subscribe(res => {
              if (res.products) {
                  let taskApp = res.products.filter( ap => ap.name.toLowerCase() === "vnctask");
                  if (taskApp.length > 0) {
                      this.commonRepository.createTask(res.username);
                  } else {
                      this.toastService.show("NO_ACCESS_FOR_APP");
                  }
              } else {
                  this.toastService.show("NO_ACCESS_FOR_APP");
              }
          });
      }
  }

  sendEmail(email) {
      console.log("[sendEmail]::", email);
      this.commonRepository.sendEmail(email);
  }

  openProfile(email) {
    if (this.isMobileScreen) {
      this.openProfileDialog(email);
    } else {
      this.store.dispatch(new SetActiveProfile(email));
      this.store.dispatch(new SetRightSidebarStatus(true));
    }
  }

  openProfileDialog(email) {
      if (email === this.currentUser?.email) {
          this.mailService.getLoggedInUserContactInfo(email).pipe(take(1)).subscribe(res => {
              console.log("[getContactInfo]::", res);
              let contact = res;
              this.matDialog.open(ProfileDetailDialogComponent, {
                  maxWidth: "100%",
                  width: "600px",
                  height: "600px",
                  autoFocus: false,
                  data: { email: email, contact: contact, isLoggedInUser: true },
                  panelClass: "user_contact_detail_dialog"
              });
          });
      } else {
          this.mailService.getContactInfo(email).pipe(take(1)).subscribe(res => {
              console.log("[getContactInfo]::", res);
              let contact = res;
              this.matDialog.open(ProfileDetailDialogComponent, {
                  maxWidth: "100%",
                  width: "600px",
                  height: "600px",
                  autoFocus: false,
                  data: { email: email, contact: contact , isLoggedInUser: false },
                  panelClass: "user_contact_detail_dialog"
              });
          });
      }
  }

  createTicket(email) {
      if (email === this.currentUser?.email) {
          this.mailService.getLoggedInUserContactInfo(email).pipe(take(1)).subscribe(res => {
              if (res.products) {
                  let projectApp = res.products.filter( ap => ap.name.toLowerCase() === "vncproject");
                  if (projectApp.length > 0) {
                      this.commonRepository.createTicket(res.username);
                  } else {
                      this.toastService.show("NO_ACCESS_FOR_APP");
                  }
              } else {
                  this.toastService.show("NO_ACCESS_FOR_APP");
              }
          });
      } else {
          this.mailService.checkTicketAccess(email).pipe(take(1)).subscribe(res => {
              console.log("checkTicketAcces: ", res);
              if (res.result) {
                      this.commonRepository.createTicket(res.username);
              } else {
                  this.toastService.show("NO_ACCESS_FOR_APP");
              }
          });
      }
  }



  async checkForExtentendedAdroidPermissions() {
    console.log("[checkForExtendedAndroidPermissions]");
    try {
      await this.checkcheckReadMediaImagesPermission();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("checkcheckReadMediaImagesPermission() rejected?");
    }
    try {
      await this.checkcheckReadMediaAudioPermission();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("checkcheckReadMediaImagesPermission() rejected?");
    }
    try {
      await this.checkcheckReadMediaVideoPermission();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("checkcheckReadMediaImagesPermission() rejected?");
    }

  }

  async checkcheckReadMediaImagesPermission() {
    return new Promise((resolve, reject) => {
      cordova.plugins.permissions.checkPermission(cordova.plugins.permissions.READ_MEDIA_IMAGES, (status) => {
        // eslint-disable-next-line no-console
        console.log("checkReadMediaImagesPermission status: ", status);
        if (status.hasPermission) {
          resolve(true);
        } else {
          cordova.plugins.permissions.requestPermission(cordova.plugins.permissions.READ_MEDIA_IMAGES, (s) => {
            if (s.hasPermission) {
              resolve(true);
            } else {
              reject(false);
            }
          }, (er) => {
            // eslint-disable-next-line no-console
            console.log("requestPermissionError: ", er);
            reject(false);
          });
        }
      }, () => {
        reject(false);
      });

    });
  }

  async checkcheckReadMediaAudioPermission() {
    return new Promise((resolve, reject) => {
      cordova.plugins.permissions.checkPermission(cordova.plugins.permissions.READ_MEDIA_AUDIO, (status) => {
        // eslint-disable-next-line no-console
        console.log("checkReadMediaAudiPermission status: ", status);
        if (status.hasPermission) {
          resolve(true);
        } else {
          cordova.plugins.permissions.requestPermission(cordova.plugins.permissions.READ_MEDIA_AUDIO, (s) => {
            if (s.hasPermission) {
              resolve(true);
            } else {
              reject(false);
            }
          }, (er) => {
            // eslint-disable-next-line no-console
            console.log("requestPermissionError: ", er);
            reject(false);
          });
        }
      }, () => {
        reject(false);
      });

    });
  }

  async checkcheckReadMediaVideoPermission() {
    return new Promise((resolve, reject) => {
      cordova.plugins.permissions.checkPermission(cordova.plugins.permissions.READ_MEDIA_VIDEO, (status) => {
        // eslint-disable-next-line no-console
        console.log("checkReadMediaVideoPermission status: ", status);
        if (status.hasPermission) {
          resolve(true);
        } else {
          cordova.plugins.permissions.requestPermission(cordova.plugins.permissions.READ_MEDIA_VIDEO, (s) => {
            if (s.hasPermission) {
              resolve(true);
            } else {
              reject(false);
            }
          }, (er) => {
            // eslint-disable-next-line no-console
            console.log("requestPermissionError: ", er);
            reject(false);
          });
        }
      }, () => {
        reject(false);
      });

    });
  }

  callOpenAppointment(event): void {
    if (!!event) {
      const eventItem = event.event;
      const isReadOnly = this.calendarRepository.isReadOnlyFolder(eventItem.folderId);
      console.log("handleReminderDoubleclick: ", event);
      if (eventItem.isRepeatAppt) {
        const dlgRef = this.matDialog.open(ModifyRecurAppointmentDialogComponent, {
          maxWidth: "95%",
          autoFocus: false,
          panelClass: "modify-recur-event-dialog",
          id: "modify-recur-event-dialog",
          data: { eventName : eventItem.title , openEvent: true}
        });
        dlgRef.afterClosed().pipe(take(1)).subscribe( res => {
          if (!!res && !!res.value) {
            const val = res.value;
            if (val === "instance") {
                if (this.isOnline) {
                  const body = {
                    id:   eventItem.invId,
                    ridz: eventItem.inst[0].ridZ
                  };
                  this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(resp => {
                    const appt = this.calendarRepository.mapAppointmentFromMsg(resp.m[0]);
                    appt.descHTML = MailUtils.getInlineImage(appt.descHTML, resp.m[0]);
                    const dialog =  this.matDialog.open(AppointmentPreviewCommonDialogComponent, {
                        maxWidth: this.isMobileScreen ? "90%" : "100%",
                        height: this.isMobileScreen ? "50%" : "auto",
                        minWidth: this.isMobileScreen ? "90%" : "580px",
                        autoFocus: false,
                        panelClass: "appointment-preview-dialog",
                        id: "appointment-preview-dialog",
                        data: { appointment: appt, event: eventItem , isInstance: true }
                    });
                    dialog.afterClosed().pipe(take(1)).subscribe( resp => {
                                            if (!!resp && resp.value === "edit") {
                        this.matDialog.open(EditAppointmentDialogComponent, {
                          maxWidth: "100%",
                          autoFocus: false,
                          panelClass: "edit-calender-appointment-dialog",
                          id: "edit-calender-appointment-dialog",
                          data: { appointment: appt , disableRepeat: true, isInstance: true , ridZ: eventItem.inst[0].ridZ }
                        });
                      }
                   });
                  }, error => {
                    this.toastService.showPlainMessage(error);
                  });
                } else {
                  this.databaseService.getAppointmentsById(eventItem.eventId).pipe(take(1)).subscribe((res: any) => {
                    if (!res || !res.isMapped) {
                      console.log("calendar.component handleDoubleClick openAppointement res: ", res);
                      this.toastService.show("FORBIDDEN");
                      return;
                    }
                    const dialog =  this.matDialog.open(AppointmentPreviewCommonDialogComponent, {
                      maxWidth: this.isMobileScreen ? "90%" : "100%",
                      height: this.isMobileScreen ? "50%" : "auto",
                      minWidth: this.isMobileScreen ? "90%" : "580px",
                      autoFocus: false,
                      panelClass: "appointment-preview-dialog",
                      id: "appointment-preview-dialog",
                      data: { appointment: res, event: eventItem , isInstance: true}
                    });
                    dialog.afterClosed().pipe(take(1)).subscribe( resp => {
                      if (!!resp && resp.value === "edit") {
                        this.matDialog.open(EditAppointmentDialogComponent, {
                          maxWidth: "100%",
                          autoFocus: false,
                          panelClass: "edit-calender-appointment-dialog",
                          id: "edit-calender-appointment-dialog",
                          data: { appointment: res , disableRepeat: true, isInstance: true , ridZ: eventItem.inst[0].ridZ }
                        });
                      }
                   });
                  }, error => {
                    console.error(`[ConversationRepository][databaseService][getAppointmentsById]`, error);
                  });
                }

            } else if (val === "series") {

                if (this.isOnline) {
                  const body = {
                    id: eventItem.invId
                  };
                  this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(resp => {
                    const appt = this.calendarRepository.mapAppointmentFromMsg(resp.m[0]);
                    appt.descHTML = MailUtils.getInlineImage(appt.descHTML, resp.m[0]);
                    const dialog =  this.matDialog.open(AppointmentPreviewCommonDialogComponent, {
                        maxWidth: this.isMobileScreen ? "90%" : "100%",
                        height: this.isMobileScreen ? "50%" : "auto",
                        minWidth: this.isMobileScreen ? "90%" : "580px",
                        autoFocus: false,
                        panelClass: "appointment-preview-dialog",
                        id: "appointment-preview-dialog",
                        data: { appointment: appt, event: eventItem , isInstance: false}
                    });
                    dialog.afterClosed().pipe(take(1)).subscribe( resp => {
                      if (!!resp && resp.value === "edit") {
                        this.matDialog.open(EditAppointmentDialogComponent, {
                          maxWidth: "100%",
                          autoFocus: false,
                          panelClass: "edit-calender-appointment-dialog",
                          id: "edit-calender-appointment-dialog",
                          data: { appointment: appt, disableRepeat: false , isInstance: false }
                        });
                      }
                    });
                  }, error => {
                    this.toastService.showPlainMessage(error);
                  });
                } else {
                  this.databaseService.getAppointmentsById(eventItem.eventId).pipe(take(1)).subscribe((res: any) => {
                    if (!res || !res.isMapped) {
                      console.log("calendar.component FORBIDDEN res: ", res);
                      this.toastService.show("FORBIDDEN");
                      return;
                    }
                    const dialog =  this.matDialog.open(AppointmentPreviewCommonDialogComponent, {
                      maxWidth: this.isMobileScreen ? "90%" : "100%",
                      height: this.isMobileScreen ? "50%" : "auto",
                      minWidth: this.isMobileScreen ? "90%" : "580px",
                      autoFocus: false,
                      panelClass: "appointment-preview-dialog",
                      id: "appointment-preview-dialog",
                      data: { appointment: res, event: eventItem , isInstance: false}
                    });
                    dialog.afterClosed().pipe(take(1)).subscribe( resp => {
                      if (!!resp && resp.value === "edit") {
                        this.matDialog.open(EditAppointmentDialogComponent, {
                          maxWidth: "100%",
                          autoFocus: false,
                          panelClass: "edit-calender-appointment-dialog",
                          id: "edit-calender-appointment-dialog",
                          data: { appointment: res, disableRepeat: false , isInstance: false }
                        });
                      }
                    });
                  }, error => {
                    console.error(`[ConversationRepository][databaseService][getAppointmentsById]`, error);
                  });
                }

            }
          }
        });
      } else {
          if (this.isOnline) {
            const body = {
              id:   eventItem.invId,
              ridz: (!!eventItem.inst && !!eventItem.inst[0] && !!eventItem.inst[0].ridZ) ? eventItem.inst[0].ridZ : 0
            };
            this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(res => {
              const appt = this.calendarRepository.mapAppointmentFromMsg(res.m[0]);
              appt.descHTML = MailUtils.getInlineImage(appt.descHTML, res.m[0]);
              console.log("previewAppointmentOpen: ", eventItem, appt);
              const dialog =  this.matDialog.open(AppointmentPreviewCommonDialogComponent, {
                  maxWidth: this.isMobileScreen ? "90%" : "100%",
                  height: this.isMobileScreen ? "50%" : "auto",
                  minWidth: this.isMobileScreen ? "90%" : "580px",
                  autoFocus: false,
                  panelClass: "appointment-preview-dialog",
                  id: "appointment-preview-dialog",
                  data: { appointment: appt, event: eventItem}
              });
              dialog.afterClosed().pipe(take(1)).subscribe( resp => {
                if (!!resp && resp.value === "edit") {
                  this.matDialog.open(EditAppointmentDialogComponent, {
                    maxWidth: "100%",
                    autoFocus: false,
                    panelClass: "edit-calender-appointment-dialog",
                    id: "edit-calender-appointment-dialog",
                    data: { appointment: appt, disableRepeat: false , isInstance: false }
                  });
                }
              });
            }, error => {
              this.toastService.showPlainMessage(error);
            });
          } else {
            const openId = !!eventItem.invId ? eventItem.invId : eventItem.id;
            this.databaseService.getAppointmentsById(openId).pipe(take(1)).subscribe((res: any) => {
              if (!res || (!res.isMapped && !res.inv)) {
                console.log("calendar.component FORBIDDEN1 res: ", eventItem, res);
                this.toastService.show("FORBIDDEN");
                return;
              }
              const appt = this.calendarRepository.mapAppointmentFromAppo(res);
              console.log("previewAppointmentOpen: ", eventItem, res, appt);
              const dialog =  this.matDialog.open(AppointmentPreviewCommonDialogComponent, {
                maxWidth: this.isMobileScreen ? "90%" : "100%",
                height: this.isMobileScreen ? "50%" : "auto",
                minWidth: this.isMobileScreen ? "90%" : "580px",
                autoFocus: false,
                panelClass: "appointment-preview-dialog",
                id: "appointment-preview-dialog",
                data: { appointment: appt, event: eventItem}
              });
              dialog.afterClosed().pipe(take(1)).subscribe( resp => {
                if (!!resp && resp.value === "edit") {
                  this.matDialog.open(EditAppointmentDialogComponent, {
                    maxWidth: "100%",
                    autoFocus: false,
                    panelClass: "edit-calender-appointment-dialog",
                    id: "edit-calender-appointment-dialog",
                    data: { appointment: appt, disableRepeat: false , isInstance: false }
                  });
                }
              });
            }, error => {
              console.error(`[ConversationRepository][databaseService][getAppointmentsById]`, error);
            });
          }

      }
    }
  }

}
