
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="color-picker-dialog" [ngClass]="{ 'carbon-theme' : currentTheme == 'carbon' }">
  <color-chrome [color] = "existingColor" (onChange)="handleColorChange($event)"></color-chrome>
  <div class = "color-action">
      <!-- <button (click)="close()" class="brand-color mat-button" (click) = "changeColor()">
          <mat-icon class="disable-select">check</mat-icon>
      </button>
      <button (click)="close()" class="brand-color mat-button" (click) = "close()">
          <mat-icon class="disable-select">close</mat-icon>
      </button> -->

      <vnc-button class="no-hover" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{'CANCEL_MAIL_LABEL' | translate }}" (click)="close()"></vnc-button>

      <vnc-button [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" (click)="close(); changeColor()">
          <span>{{'ADD_LABEL' | translate }}</span>
      </vnc-button>
  </div>
</div>

