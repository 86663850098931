
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
<div class="mail_edit_folder_properties_dialog mail-dialog" [ngClass]="{ 'dfb-theme' : currentTheme == 'dfb', 'carbon-theme' : currentTheme == 'carbon' }">
    <!-- <div class="folder_properties__dialog-header mail__dialog-header mobile_header" layout="row center-center">
        <h4 class="heading_header" *ngIf="moduleType==='mail'">{{ 'EDIT_FOLDER' | translate}}</h4>
        <h4 class="heading_header" *ngIf="moduleType==='briefcase' || moduleType==='calendar'">{{ 'EDIT_PROPERTIES_LBL' | translate}}</h4>
        <div class="desktop-close-button" [fxHide.xs]="true">
            <button class="brand-color mat-button" (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="mobile_heading">
        <h4 class="header" *ngIf="moduleType==='mail'"> {{ 'EDIT_FOLDER' | translate}}</h4>
        <h4 class="header" *ngIf="moduleType==='briefcase' || moduleType==='calendar'"> {{ 'EDIT_PROPERTIES_LBL' | translate}}</h4>
    </div> -->
    <vnc-header
        class="message"
        title="{{ 'FOLDER_SETTINGS' | translate }}"
        type="title-close"
        size="l"
        (onCloseClick)="close()"
        [showCloseIcon]="true"
        closeIconToolTip="{{ 'CLOSE' | translate }}">
    </vnc-header>
    <div class="dialog_body mail__dialog-body mail__dialog-body--pad">

        <div class="content">
            <div>
                <vnc-input #folderNameInput placeholder="{{'FOLDER_NAME_LBL'| translate }}" [showLabel]="true" [form]="folderTitleControl" ></vnc-input>
                <mat-hint>{{folderTitleControl.value.length}}/{{maxFolderTitleLength}}</mat-hint>
                <!-- <mat-form-field>
                    <input [(ngModel)]="folderTitle" #folderNameInput matInput
                        placeholder="{{ 'FOLDER_NAME_LBL'| translate }}" (keyup)="changeText($event)" autocomplete="off"
                        autocorrect="off" autocapitalize="off" spellcheck="false" [maxLength]="maxFolderTitleLength"
                        [disabled]="data.targetFolder.id === '2' || data.targetFolder.id === '6' || data.targetFolder.id === '5' || data.targetFolder.id === '10' || data.targetFolder.id === '16'">
                    <mat-hint>{{folderNameInput.value.length}}/{{maxFolderTitleLength}}</mat-hint>
                </mat-form-field> -->
            </div>
            <ng-container *ngIf="moduleType === 'mail'">
            <div class="info">
                <div class="lbl"> {{ 'PREFERENCES.TYPE' | translate }} :</div>
                <div class="item">
                    <vnc-icon-container >
                            <ng-container [ngTemplateOutlet]="folderIcon"></ng-container>
                    </vnc-icon-container>
                    {{ 'MAIL_FOLDER_LBL' | translate }}
                </div>
            </div>
            <div class="info">
                <div class="lbl">{{ 'MESSAGES' | translate }}</div>
                <div class="item">
                    <vnc-icon-container >
                        <ng-container [ngTemplateOutlet]="fileIcon"></ng-container>
                    </vnc-icon-container>

                    {{ data.targetFolder.n ? data.targetFolder.n : 0 }} </div>
            </div>
            <div class="info" *ngIf="data.targetFolder.u">
                <div class="lbl"> {{ 'SELECT_UNREAD' | translate }}</div>
                <div class="item">
                    <vnc-icon-container >
                        <ng-container [ngTemplateOutlet]="unreadIcon"></ng-container>
                    </vnc-icon-container>
                    {{data.targetFolder.u}}
                </div>
            </div>
            <div class="info" *ngIf="data.targetFolder.s">
                <div class="lbl"> {{ 'TOTAL_SIZE' | translate }}</div>
                <div class="item">
                    <vnc-icon-container >
                        <ng-container [ngTemplateOutlet]="zipIcon"></ng-container>
                    </vnc-icon-container>
                    {{data.targetFolder.s| vpFormatFileSize:false}} </div>
            </div>
            </ng-container>

            <ng-container *ngIf="moduleType === 'briefcase'">
                <div class="info">
                    <div class="lbl"> {{ 'SHARING_FOLDER_TYPE' | translate }} :</div>
                    <div class="item"> {{ 'BRIEFCASE_TITLE' | translate }} </div>
                </div>
            </ng-container>
            <div *ngIf="data.targetFolder.perm">
                <div class="info">
                    <div class="lbl"> {{ 'OWNER_LBL' | translate }} : </div>
                    <div class="item">{{data.targetFolder.owner}} </div>
                </div>
                <div class="info">
                    <div class="lbl">{{ 'PREFERENCES.PERMISSIONS' | translate }} : </div>
                    <div class="item" *ngIf="data.targetFolder && data.targetFolder.perm==='r'">{{ "VIEWER_VIEW" | translate }}</div>
                    <div class="item" *ngIf="data.targetFolder && data.targetFolder.perm==='rwidxc'">{{ "MANAGER_VIEW" | translate }}</div>
                    <div class="item" *ngIf="data.targetFolder && data.targetFolder.perm==='rwidxa'">{{ "ADMIN_VIEW" | translate }}</div>
                    <div class="item" *ngIf="data.targetFolder && data.targetFolder.perm==='rwidxac'">{{ "ADMIN_VIEW" | translate }}</div>
                </div>
            </div>

            <div class="info">
                <div class="lbl"> {{ 'FOLDER_COLOR' | translate }}</div>
                <div class="item">
                    <div class="color-action">
                        <mat-radio-group class="create-folder-color-selection" (change)="updateDefaultColor($event)"
                            [(ngModel)]="folderColor">
                            <mat-radio-button class="grey-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#A8B0B9'}" value="#A8B0B9" (click)="updateDefaultColor('#A8B0B9')">
                                <span *ngIf="folderColor?.toUpperCase() === '#A8B0B9'">
                                  <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                                </span>
                              </mat-radio-button>
                              <mat-radio-button class="sky-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#00B8D4'}" value="#00B8D4" (click)="updateDefaultColor('#00B8D4')">
                                <span *ngIf="folderColor?.toUpperCase() === '#00B8D4'">
                                  <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                                </span>
                              </mat-radio-button>
                              <mat-radio-button class="blue-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#388EDA'}" value="#388EDA" (click)="updateDefaultColor('#388EDA')">
                                <span *ngIf="folderColor?.toUpperCase() === '#388EDA'">
                                  <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                                </span>
                              </mat-radio-button>
                              <mat-radio-button class="navy-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#6358C7'}" value="#6358C7" (click)="updateDefaultColor('#6358C7')">
                                <span *ngIf="folderColor?.toUpperCase() === '#6358C7'">
                                  <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                                </span>
                              </mat-radio-button>
                              <mat-radio-button class="red-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#F24336'}" value="#F24336" (click)="updateDefaultColor('#F24336')">
                                <span *ngIf="folderColor?.toUpperCase() === '#F24336'">
                                  <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                               </span>
                              </mat-radio-button>
                              <mat-radio-button class="orange-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#FFAD16'}" value="#FFAD16" (click)="updateDefaultColor('#FFAD16')">
                                <span *ngIf="folderColor?.toUpperCase() === '#FFAD16'">
                                  <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                                </span>
                              </mat-radio-button>
                              <mat-radio-button class="green-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#27AB7F'}" value="#27AB7F" (click)="updateDefaultColor('#27AB7F')">
                                <span *ngIf="folderColor?.toUpperCase() === '#27AB7F'">
                                  <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                                </span>
                              </mat-radio-button>
                        </mat-radio-group>
                        <button style="margin-left: 4px;" matTooltip="{{ 'MORE_TITLE' | translate }}" matTooltipPosition="above" id="mobile_Sidebar_createfolder_morecplor" (click)="openColorDialog()" class="brand-color mat-button more-color-button">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <g id="Icon/product/more-horiz" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <g id="Icon-24px" transform="translate(4.000000, 10.000000)" fill="#8B96A0">
                                      <path d="M2,0 C0.9,0 0,0.9 0,2 C0,3.1 0.9,4 2,4 C3.1,4 4,3.1 4,2 C4,0.9 3.1,0 2,0 L2,0 Z M14,0 C12.9,0 12,0.9 12,2 C12,3.1 12.9,4 14,4 C15.1,4 16,3.1 16,2 C16,0.9 15.1,0 14,0 L14,0 Z M8,0 C6.9,0 6,0.9 6,2 C6,3.1 6.9,4 8,4 C9.1,4 10,3.1 10,2 C10,0.9 9.1,0 8,0 L8,0 Z" id="Shape"></path>
                                  </g>
                              </g>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>


            <div class="acl" *ngIf="data.targetFolder && data.targetFolder.acl && data.targetFolder.acl.grant && isSharingFeatureEnabled">
                <div class="heading">

                    {{ 'SHARING_FOR_THIS_FOLDER' | translate }}</div>
                <div class="scroll_div">
                    <div *ngFor="let grantItem of grant;let i =index;" class="grantee">
                        <div class="desktop">
                            <div class="name">
                                <mat-icon>remove_red_eye</mat-icon>
                                <span *ngIf="grantItem.gt=== 'guest'">{{ grantItem.zid }}</span>
                                <span *ngIf="grantItem.gt=== 'pub'">{{ 'PUBLIC' | translate }}</span>
                                <span *ngIf="grantItem.gt=== 'grp' || grantItem.gt=== 'usr'">{{ grantItem.d }}</span>
                            </div>
                            <div class="btn">
                                <mat-icon class="operation" (click)="edit(grantItem)" *ngIf="grantItem.gt !== 'pub'">edit</mat-icon>
                                <mat-icon class="operation" (click)="revoke(grantItem, i)">close</mat-icon>
                                <mat-icon class="operation" (click)="resend(grantItem)" *ngIf="grantItem.gt !== 'pub'">refresh</mat-icon>
                            </div>
                        </div>
                        <span class="mobile">
                            <div class="name">
                                <span *ngIf="grantItem.gt=== 'guest'">{{ grantItem.zid }}</span>
                                <span *ngIf="grantItem.gt=== 'pub'">{{ 'PUBLIC' | translate }}</span>
                                <span *ngIf="grantItem.gt=== 'grp' || grantItem.gt=== 'usr'">{{ grantItem.d }}</span>
                            </div>
                            <div class="more" [matMenuTriggerFor]="granteeOperation"
                                [matMenuTriggerData]="{grantItem:grantItem}">
                                <mat-icon>more_vert</mat-icon>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="actions">
                <div *ngIf="isSharingFeatureEnabled" class="share-section">
                    <vnc-icon-container >
                        <ng-container [ngTemplateOutlet]="addIcon"></ng-container>
                    </vnc-icon-container>
                    <a (click)="shareDialog()" [ngStyle]="{ 'text-transform': currentLocale == 'en' ? 'lowercase' : 'none' }" [hidden]="data.targetFolder.perm && data.targetFolder.perm==='r'">{{
                        'ADD_SHARE' | translate }}</a>
                </div>
                <span *ngIf="moduleType==='mail'">
                <div class="retention_header_lbl">
                    {{ 'RETENTION_LBL_MSG' | translate }}
                </div>
                <div *ngIf="showRetention">
                    <!-- Retention Section -->

                    <div class="keep-section-block">
                        <div class="keep-title"> {{ 'ENABLE_MSG_RETENTION' | translate }}</div>
                        <div class="sub-title-section">
                            <div class="sub-title">
                                {{ 'RETENTION_MSG_RETENTION' | translate }}
                            </div>
                            <div class="toggle-section">
                                <vnc-toggle-switch [size]="'s'" [checked]="enableRention" (changeEvent)="enableRention = $event.checked"></vnc-toggle-switch>
                            </div>
                        </div>

                        <div class="selection-section" *ngIf="enableRention">
                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="custom" [disabled]="!enableRention">
                                        <mat-option [value]="'custom'">
                                            {{ 'CUSTOM' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input matInput autocomplete="off" [disabled]="!enableRention" [(ngModel)]="retensionValue" type="number" min="1" onkeypress="return event.charCode >= 48">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="rangeUnit" [disabled]="!enableRention">
                                        <mat-option [value]="'d'">
                                            {{ 'DAYS_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'w'">
                                            {{ 'WEEK_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'m'">
                                            {{ 'MONTHS_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'y'">
                                            {{ 'YEARS_LBL' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                        </div>
                    </div>

                    <!-- Message Disposal Section -->
                    <div class="keep-section-block">
                        <div class="keep-title"> {{ 'ENABLE_MSG_DISPOSAL' | translate }}</div>
                        <div class="sub-title-section">
                            <div class="sub-title">
                                {{ 'DISPOSAL_MSG_MSG' | translate }}
                            </div>
                            <div class="toggle-section">
                                <vnc-toggle-switch [size]="'s'" [checked]="enableDisposal" (changeEvent)="enableDisposal = $event.checked"></vnc-toggle-switch>
                            </div>
                        </div>

                        <div class="selection-section" *ngIf="enableDisposal">
                                <mat-form-field appearance="outline">
                                        <mat-select [(ngModel)]="custom" [disabled]="!enableDisposal">
                                            <mat-option [value]="'custom'">
                                                {{ 'CUSTOM' | translate }}
                                            </mat-option>
                                        </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input matInput autocomplete="off" [disabled]="!enableDisposal" [(ngModel)]="thresholdValue" type="number" min="1" onkeypress="return event.charCode >= 48">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="thresholdUnit" [disabled]="!enableDisposal">
                                        <mat-option [value]="'d'">
                                            {{ 'DAYS_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'w'">
                                            {{ 'WEEK_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'m'">
                                            {{ 'MONTHS_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'y'">
                                            {{ 'YEARS_LBL' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                        </div>
                    </div>
                    <!-- <div class="keep">
                        <mat-checkbox [(ngModel)]="enableRention" (change)="changeRetensionBox($event)">{{ 'ENABLE_MSG_RETENTION' | translate }}</mat-checkbox>
                        <div class="msg">
                            {{ 'RETENTION_MSG_RETENTION' | translate }}
                        </div>
                        <div class="settings">
                            <span>{{ 'RETENTION_RANGE' | translate }}:</span>
                            <span>
                                <mat-form-field>
                                    <mat-select [(ngModel)]="custom" [disabled]="!enableRention">
                                        <mat-option [value]="'custom'">
                                            {{ 'CUSTOM' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput autocomplete="off" [disabled]="!enableRention" [(ngModel)]="retensionValue" type="number" min="1" onkeypress="return event.charCode >= 48">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-select [(ngModel)]="rangeUnit" [disabled]="!enableRention">
                                        <mat-option [value]="'d'">
                                            {{ 'DAYS_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'w'">
                                            {{ 'WEEK_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'m'">
                                            {{ 'MONTHS_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'y'">
                                            {{ 'YEARS_LBL' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </span>
                        </div>
                    </div>

                    <div class="purge">
                        <mat-checkbox [(ngModel)]="enableDisposal" (change)="changeDisposalBox($event)">{{ 'ENABLE_MSG_DISPOSAL' | translate }}</mat-checkbox>
                        <div class="msg">
                            {{ 'DISPOSAL_MSG_MSG' | translate }}
                        </div>
                        <div class="settings">
                            <span>{{ 'DISPOSAL_THRESHOLD' | translate }}:</span>
                            <span>
                                <mat-form-field>
                                    <mat-select [(ngModel)]="custom" [disabled]="!enableDisposal">
                                        <mat-option [value]="'custom'">
                                            {{ 'CUSTOM' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput autocomplete="off" [disabled]="!enableDisposal" [(ngModel)]="thresholdValue" type="number" min="1" onkeypress="return event.charCode >= 48">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-select [(ngModel)]="thresholdUnit" [disabled]="!enableDisposal">
                                        <mat-option [value]="'d'">
                                            {{ 'DAYS_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'w'">
                                            {{ 'WEEK_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'m'">
                                            {{ 'MONTHS_LBL' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'y'">
                                            {{ 'YEARS_LBL' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </span>
                        </div>
                    </div> -->

                </div>
            </span>
            </div>
        </div>
    </div>
    <vnc-footer [secondaryLabel]="'CANCEL' | translate | uppercase"
    [primaryLabel]="data?.selectedData?.uid? 'UPDATE' : 'SAVE' | translate | uppercase"
    [type]="'secondary-primary'"
    [color]="'white'"
    (onSecondaryClick)="closeDialog()"
    (onPrimaryClick)="folderAction()"></vnc-footer>
    <mat-menu #granteeOperation="matMenu">
        <ng-template matMenuContent let-grantItem="grantItem">
            <button mat-menu-item *ngIf="grantItem.gt !== 'pub'" (click)="edit(grantItem)">
                <mat-icon>edit</mat-icon>
                <span>{{'SHARE_EDIT'|translate}}</span>
            </button>
            <button mat-menu-item (click)="revoke(grantItem)">
                <mat-icon>close</mat-icon>
                <span>{{'SHARE_REVOKE'|translate}}</span>
            </button>
            <button mat-menu-item (click)="resend(grantItem)" *ngIf="grantItem.gt !== 'pub'">
                <mat-icon>refresh</mat-icon>
                <span>{{'SHARE_RESEND'|translate}}</span>
            </button>
        </ng-template>
    </mat-menu>
</div>


<ng-template #folderIcon>
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/folder-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-24px" transform="translate(2.000000, 4.000000)" fill="#8B96A0">
                <path d="M7.00922203,0 C7.49995184,0 8.18025928,0.262988878 8.59481703,0.605726148 L8.71075654,0.71075654 L9.28924346,1.28924346 C9.65129486,1.65129486 10.3113868,1.94247309 10.8421188,1.99242242 L10.9970301,2 L18,2 C19.05,2 19.9177686,2.82004132 19.9944872,3.85130541 L20,4 L20,14 C20,15.05 19.1799587,15.9177686 18.1486946,15.9944872 L18,16 L2,16 C0.95,16 0.082231405,15.1799587 0.00551277235,14.1486946 L0,14 L0.01,2 C0.01,0.95 0.820929752,0.082231405 1.85136551,0.00551277235 L2,0 L7.00922203,0 Z M7.00922203,2 L2.00999931,2.00166667 L2,14 L18,14 L18,4 L10.9970301,4 C9.98506984,4 8.81419501,3.54226902 8.03565409,2.85431634 L7.8750299,2.70345702 L7.29654298,2.1249701 L7.27022108,2.10994272 L7.0847011,2.02870664 L7.00922203,2 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
</ng-template>

<ng-template #fileIcon>
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/file-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M13.5857864,2 C13.8510029,2 14.1053568,2.10535684 14.2928932,2.29289322 L19.7071068,7.70710678 C19.8946432,7.89464316 20,8.14899707 20,8.41421356 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.8954305,22 4,21.1045695 4,20 L4,4 C4,2.8954305 4.8954305,2 6,2 L13.5857864,2 Z M10.999,3.999 L6,4 L6,20 L18,20 L18,10 L14,10 C12.4023191,10 11.0963391,8.75108004 11.0050927,7.17627279 L11,7 L10.999,3.999 Z M17.172,8 L13.171,4 L12.999,3.999 L13,7 C13,7.51283584 13.3860402,7.93550716 13.8833789,7.99327227 L14,8 L17.172,8 Z" id="Combined-Shape" fill="#8B96A0"></path>
        </g>
    </svg>
</ng-template>

<ng-template #zipIcon>
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/file-zip-box" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M18.984375,21 C19.546875,21 20.0234375,20.8046875 20.4140625,20.4140625 C20.8046875,20.0234375 21,19.546875 21,18.984375 L21,5.015625 C21,4.421875 20.8046875,3.9375 20.4140625,3.5625 C20.0234375,3.1875 19.546875,3 18.984375,3 L5.015625,3 C4.421875,3 3.9375,3.1875 3.5625,3.5625 C3.1875,3.9375 3,4.421875 3,5.015625 L3,18.984375 C3,19.546875 3.1953125,20.0234375 3.5859375,20.4140625 C3.9765625,20.8046875 4.453125,21 5.015625,21 L18.984375,21 Z M12,6.984375 L9.984375,6.984375 L9.984375,5.015625 L12,5.015625 L12,6.984375 Z M12,15 L9.984375,15 L9.984375,12.984375 L12,12.984375 L12,15 Z M12,11.015625 L14.015625,11.015625 L14.015625,12.984375 L12,12.984375 L12,11.015625 Z M12,9 L12,11.015625 L9.984375,11.015625 L9.984375,9 L12,9 Z M12,6.984375 L14.015625,6.984375 L14.015625,9 L12,9 L12,6.984375 Z M12,15 L14.015625,15 L14.015625,17.015625 L12,17.015625 L12,15 Z" id="" fill="#8B96A0" fill-rule="nonzero"></path>
        </g>
    </svg>

</ng-template>

<ng-template #unreadIcon>
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/mail-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M19,4 C20.6568542,4 22,5.34314575 22,7 L22,17 C22,18.6568542 20.6568542,20 19,20 L5,20 C3.34314575,20 2,18.6568542 2,17 L2,7 C2,5.34314575 3.34314575,4 5,4 L19,4 Z M19.7744108,6.3672689 L14.1213203,12.0208153 C12.9497475,13.1923882 11.0502525,13.1923882 9.87867966,12.0208153 L4.22558922,6.3672689 C4.10776024,6.51131027 4.0293244,6.68882894 4.00672773,6.88337887 L4,7 L4,17 C4,17.5128358 4.38604019,17.9355072 4.88337887,17.9932723 L5,18 L19,18 C19.5128358,18 19.9355072,17.6139598 19.9932723,17.1166211 L20,17 L20,7 C20,6.75993895 19.91541,6.53963495 19.7744108,6.3672689 Z M17.314,6 L6.685,6 L11.2928932,10.6066017 C11.6555229,10.9692314 12.2273683,10.9951335 12.619886,10.6843081 L12.7071068,10.6066017 L17.314,6 Z" id="Combined-Shape" fill="#8B96A0"></path>
        </g>
    </svg>
</ng-template>

<ng-template #addIcon>
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-24px" transform="translate(5.000000, 5.000000)" fill="#337cbd">
                <polygon id="Shape" points="14 8 8 8 8 14 6 14 6 8 0 8 0 6 6 6 6 0 8 0 8 6 14 6"></polygon>
            </g>
        </g>
    </svg>
</ng-template>

<ng-template #checkIcon>
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/check" stroke="none" stroke-width="1" fill="#FFFF" fill-rule="evenodd">
            <polygon id="Shape" fill="#FFFF" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"></polygon>
        </g>
    </svg>
</ng-template>