
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="select_addresses_dialog create-tag-dialog">

  <!-- <div class="select-addresses__dialog-header" layout="row center-center">
    <div class="mobile-back-button" [fxHide.gt-sm]="true">
      <button (click)="close()" class="brand-color mat-button">
        <mat-icon class="disable-select">chevron_left</mat-icon>
      </button>
    </div>
    <h4>{{ 'CALENDARS.SELECT_ATTENDEES' | translate}}</h4>
    <div class="desktop-close-button">
      <button class="brand-color mat-button" (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div> -->

  <div class="desktop-header select-addresses__dialog-header">
    <div class="header-text">{{  'CALENDARS.SELECT_ATTENDEES' | translate }}</div>
    <div class="header-icon" (click)="close()" matTooltip="{{ 'CLOSE' | translate }}">
        <ng-container [ngTemplateOutlet]="closeIcon"></ng-container>
    </div>
  </div>


  <div class="content">
    <div class="contact-loading-spinner" *ngIf="isLoading">
      <vp-custom-spinner></vp-custom-spinner>
    </div>
    <div class="main-select-addresses-div">
      <div class="select-addresses-list">
        <div fxLayout="row" fxLayoutAlign="start center" class="address-search-bar">
          <input matInput [(ngModel)]="searchText" type="text"
            placeholder="{{'SEARCH_USERS' | translate}}" />
          <button mat-icon-button (click)="searchEmailAddresses()">
            <mat-icon>search</mat-icon>
          </button>
        </div>
        <div fxLayout="row" class="show-names-options">
          <span>{{ 'SHOW_NAMES_FROM' | translate }}:</span>
          <mat-form-field class="select_values_form_field">
            <mat-select panelClass="show-name-select" (selectionChange)="resetOffset();changeShowNames($event.value)" [(ngModel)]="selectedShowOption">
              <mat-option [ngClass]="{ 'dark-menu-option': currentTheme === 'carbon' }" [value]="'contacts'">{{ 'CONTACTS' | translate }}</mat-option>
              <mat-option [disabled]="!isOnline" [value]="'personalandshared'">{{ 'PERSONAL_AND_SHARED_CONTACTS' | translate }}</mat-option>
              <mat-option [disabled]="!isOnline" *ngIf="iszimbraFeatureGalEnabled" [value]="'global'">{{ 'GLOBAL_ADDRESS_LIST' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th width="100px">{{ 'PREFERENCES.NAME' | translate }}</th>
              <th width="260px">{{ 'COMMON.EMAIL' | translate }}</th>
            </tr>
          </thead>
        </table>
        <mat-list class="select-address-mat-list" id="allEmailAddressesList" [fxShow]="allEmailAddresses && allEmailAddresses.length > 0" vpScrollList
          (onUserScroll)="onUserScroll($event);">
          <mat-list-item *ngFor="let address of allEmailAddresses; index as i;" class="no-select"
            (click)="selectEmailAddress(address, i, $event)"
            [ngClass]="{'is-selected': isSelectedEmailAddress(address)}">
            <span class="name">{{ address.name }}</span>
            <span class="email">{{ address.email }}</span>
          </mat-list-item>
        </mat-list>
      </div>
      <div class="select-addresses-action action-div">
        <button mat-button (click)="addSelectedinTo()" [disableRipple]=true>{{'CALENDARS.ADD_LBL' | translate}}</button>
        <button class="remove-btn" [disabled]="selectedFinalEmailAddresses.length === 0 || selectedFinalEmailAddresses == undefined" (click)="removeSelectedAddresses()" mat-button>{{'COMMON.REMOVE' | translate}}</button>
      </div>
      <div class="select-addresses-list">
        <table class="table">
          <thead>
            <tr>
              <th width="100px">{{ 'PREFERENCES.NAME' | translate }}</th>
              <th width="280px">{{ 'COMMON.EMAIL' | translate }}</th>
            </tr>
          </thead>
        </table>
        <mat-list id="addressList" class="select-address-final-list" vpScrollList (onUserScroll)="onUserScroll($event);">
          <mat-list-item *ngFor="let address of finalEmailAddresses; index as i;" class="no-select"
            (click)="selectFinalEmailAddresses(address, i, $event)"
            [ngClass]="{'is-selected': isselectFinalEmailAddresses(address)}">
            <span class="name">{{ address.name }}</span>
            <span class="email">{{ address.email }}</span>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <div class="select-addresses-footer calendar-select-footer">
      <div class="next-previous">
        <button mat-button [disableRipple]=true (click)="loadPreviousContact()" [disabled]="offset===0">
          <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
          <span>{{'PREVIOUS_LBL' | translate}}</span>
        </button>
        <button mat-button [disableRipple]=true (click)="loadNextContact()" [disabled]="!isContact">
          <span>{{'NEXT_LBL' | translate}}</span>
          <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-arrow-right"></mat-icon>
        </button>
      </div>
      <div class="close-select-division">
        <vnc-button
        class="themed-control"
        [height]="'medium'"
        (click)="close()"
        [shape]="'rectangle'"
        label="{{ 'CANCEL' | translate | uppercase }}"
        [type]="'secondary'">
        </vnc-button>
        <vnc-button
          class="themed-control"
          [height]="'medium'"
          [shape]="'rectangle'"
          label="{{ 'ENABLE_SELECT_ACTION' | translate | uppercase }}"
          (click)="setSelected()">
       </vnc-button>
        <!-- <button mat-button (click)="close()" [disableRipple]=true>{{'CANCEL' | translate}}</button>
        <button mat-button (click)="setSelected()" [disableRipple]=true>{{'ENABLE_SELECT_ACTION' | translate}}</button> -->
      </div>
    </div>
  </div>
</div>

<ng-template  #closeIcon>
  <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
      </g>
  </svg>
</ng-template>