<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
<vp-common-dialog (closeClicked)="cancel()" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon'  ,'custom-height-format-savedraft': confirmArgs.operationType === mailOperations.SaveDraft || confirmArgs.operationType === mailOperations.FormatPlainText ,'common-warning-dialog': confirmArgs.operationType === mailOperations.EmptyFolder || confirmArgs.operationType === mailOperations.DeleteSavedSearch || confirmArgs.operationType === mailOperations.RemoveAttachment || confirmArgs.operationType === mailOperations.sendFailSavedDraft || confirmArgs.operationType === mailOperations.SecondInstance }">
  <div dialog-title>
    <ng-container
      *ngIf="confirmArgs.operationType === mailOperations.EmptyFolder || confirmArgs.operationType === mailOperations.DeleteSavedSearch || confirmArgs.operationType === mailOperations.RemoveAttachment || confirmArgs.operationType === mailOperations.sendFailSavedDraft || confirmArgs.operationType === mailOperations.SecondInstance">
      {{ "WARNING" | translate }}</ng-container>
    <ng-container *ngIf="confirmArgs.operationType === mailOperations.SaveDraft">{{ "SAVE_DRAFT_HEADER_LABEL" | translate }}
    </ng-container>
    <ng-container *ngIf="confirmArgs.operationType === mailOperations.FormatPlainText">{{ "PREFERENCES.FORMAT_TEXT" | translate }}
    </ng-container>
    <ng-container *ngIf="currentUrl.startsWith('/calendar')">
      <ng-container
        *ngIf="confirmArgs.operationType === mailOperations.TrashFolder||confirmArgs.operationType === mailOperations.DeleteFolder">
        {{ 'CALENDARS.DELETE_CALENDAR_LBL' | translate }}</ng-container>
    </ng-container>

    <ng-container *ngIf="!currentUrl.startsWith('/calendar')">
      <ng-container
        *ngIf="confirmArgs.operationType === mailOperations.TrashFolder||confirmArgs.operationType === mailOperations.DeleteFolder">
        {{ "DELETE_FOLDER_ACTION" | translate }}</ng-container>
    </ng-container>
  </div>

  <div dialog-body>
    <ng-container *ngIf="currentUrl.startsWith('/calendar')">
      <ng-container *ngIf="confirmArgs.operationType === mailOperations.TrashFolder">
        {{ "CALENDARS.TRASH_CALENDAR_CONFIRM_MSG" | translate: folder}}
      </ng-container>
      <ng-container *ngIf="confirmArgs.operationType === mailOperations.DeleteFolder">
        {{ "CALENDARS.DELETE_CALENDAR_CONFIRM_MSG" | translate: folder}}
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!currentUrl.startsWith('/calendar')">
      <ng-container *ngIf="confirmArgs.operationType === mailOperations.TrashFolder">
        {{ "TRASH_FODLER_CONFIRM_MSG" | translate: folder}}
      </ng-container>

      <ng-container *ngIf="confirmArgs.operationType === mailOperations.DeleteFolder">
        {{ "DELETE_FODLER_CONFIRM_MSG" | translate: folder}}
      </ng-container>
    </ng-container>

    <ng-container class="empty-folder-conf-msg" *ngIf="confirmArgs.operationType === mailOperations.EmptyFolder">
      {{ "EMPTY_FODLER_CONFIRM_MSG" | translate: folder}}</ng-container>
    <ng-container class="empty-folder-conf-msg" *ngIf="confirmArgs.operationType === mailOperations.SaveDraft">
      {{ "SAVE_DRAFT_CONFIRMATION" | translate: folder}}
    </ng-container>
    <ng-container class="empty-folder-conf-msg" *ngIf="confirmArgs.operationType === mailOperations.FormatPlainText">
      {{ "SWITCH_TO_PLAIN_TEXT_WARNING_MSG" | translate: folder}}
    </ng-container>
    <ng-container class="empty-folder-conf-msg" *ngIf="confirmArgs.operationType === mailOperations.SecondInstance">
      {{ "SECOND_INSTANCE_CONFIRM_MSG" | translate: folder}}</ng-container>
    <ng-container class="empty-folder-conf-msg" *ngIf="confirmArgs.operationType === mailOperations.sendFailSavedDraft && subject.subject ===''">
      <p *ngIf="manyRecipients" style="margin: 0;"> {{ "TOO_MANY_RECIPIENTS" | translate: folder}}</p>
      {{ "SEND_MAIL_FAILED_DRAFT_NO_SUBJECT" | translate: folder}}
    </ng-container>
    <ng-container class="empty-folder-conf-msg" *ngIf="confirmArgs.operationType === mailOperations.sendFailSavedDraft && subject.subject !==''">
      <p *ngIf="manyRecipients" style="margin: 0;"> {{ "TOO_MANY_RECIPIENTS" | translate: folder}}</p>
      {{ "SEND_MAIL_FAILED_DRAFT" | translate: subject}}</ng-container>
    <ng-container *ngIf="confirmArgs.operationType === mailOperations.RemoveAttachment">
      {{ "REMOVE_ATTACHMENT_WARNING_MSG" | translate: folder}}</ng-container>
    <ng-container class="delete-search-conf-msg" *ngIf="confirmArgs.operationType === mailOperations.DeleteSavedSearch">
      {{ "DELETE_SAVED_SEARCH_CONFIRM_MSG" | translate: folder}}</ng-container>

  </div>

  <div dialog-footer>
    <div class="buttons" [ngClass]="{'custom-vncfooter-actions' : confirmArgs.operationType === mailOperations.SaveDraft || confirmArgs.operationType === mailOperations.FormatPlainText}">
        <button mat-stroked-button
          *ngIf="confirmArgs.operationType === mailOperations.TrashFolder||confirmArgs.operationType === mailOperations.DeleteFolder || confirmArgs.operationType === mailOperations.DeleteSavedSearch"
          class="save_btn" (click)="deleteEmptyFolder()">
          {{ "DELETE_LBL" | translate }}
        </button>
        <vnc-button *ngIf="confirmArgs.operationType === mailOperations.EmptyFolder" [padding]="'xl'" [shape]="'rectangle'" [type]="'primary'" (click)="deleteEmptyFolder()">
          <span>{{ "OK_LBL" | translate }}</span>
        </vnc-button>
        <vnc-button *ngIf="confirmArgs.operationType === mailOperations.SecondInstance || confirmArgs.operationType === mailOperations.sendFailSavedDraft" [padding]="'xl'" [shape]="'rectangle'" [type]="'primary'" (click)="cancel()">
          <span>{{ "OK_LBL" | translate }}</span>
        </vnc-button>
        <vnc-footer *ngIf="confirmArgs.operationType === mailOperations.SaveDraft" [secondaryLabel]="'DISCARD_MAIL_LABEL' | translate | uppercase"
        [primaryLabel]="'SAVE_MAIL_LABEL' | translate | uppercase"
        [type]="'secondary-primary'"
        (onSecondaryClick)="discardMail()"
        (onPrimaryClick)="saveMail()"></vnc-footer>
        <vnc-footer *ngIf="confirmArgs.operationType === mailOperations.FormatPlainText" [secondaryLabel]="'CANCEL' | translate | uppercase"
        [primaryLabel]="'APPLY_LBL' | translate | uppercase"
        [type]="'secondary-primary'"
        (onSecondaryClick)="plainTextAction('cancel')"
        (onPrimaryClick)="plainTextAction('ok')"></vnc-footer>
        <!-- <button mat-stroked-button *ngIf="confirmArgs.operationType !== mailOperations.RemoveAttachment"
        class="cancel_btn" (click)="cancel()" id="cancel-popup-button">{{ "CANCEL_MAIL_LABEL" | translate }}</button> -->
      <button mat-stroked-button *ngIf="confirmArgs.operationType === mailOperations.RemoveAttachment"
        (click)="cancel()">{{ "NO_LABEL" | translate }}</button>
      <button mat-stroked-button color="primary" *ngIf="confirmArgs.operationType === mailOperations.RemoveAttachment"
        (click)="deleteAttachment()">{{ "YES_LABEL" | translate }}</button>
    </div>
  </div>
</vp-common-dialog>
