
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Subject } from "rxjs";
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, NgZone, ChangeDetectorRef, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { takeUntil, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { RootState, getFederatedApps } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { ContactRootState } from "src/app/contacts/store/reducers";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import { CommonUtils } from "src/app/common/utils/common-util";
import { ToastService } from "src/app/common/providers/toast.service";
import { SearchResponse } from "src/app/mail/shared/models/search-item";
import { ContactInfo } from "../../../mail/shared/models/contact-info.model";
import { AvatarCropperDialogComponent } from "src/app/shared/components/avatar-cropper-dialog/avatar-cropper-dialog.component";
import { UserProfile } from "src/app/shared/models/user-profile";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { AppState } from "src/app/reducers/app";
import { SetLastPhotoUpdate, StopProcessing, StartProcessing } from "src/app/actions/app";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { ConfigService } from "src/app/config.service";
import { ElectronService } from "src/app/services/electron.service";
import { TranslateService } from "@ngx-translate/core";
import { RandomColor } from "src/app/common/utils/random-color";

@Component({
    selector: "vp-profile-detail-dialog",
    templateUrl: "./profile-detail-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileDetailDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    contact: ContactInfo = null;
    supportedApps = ["talk", "mail", "task"];
    searchParams: any = {
        q: "*:*",
        sort: "created_dt desc",
        start: 0,
        apps: this.supportedApps.join(","),
        limit: 20
    };
    searchResponse: SearchResponse;
    fedrateApps: any;
    isShowSendEmailOption: boolean = true;
    isShowTalkOptions: boolean = true;
    isShowTaskOption: boolean = true;
    showMoreDetail: boolean = false;
    backgroundImageStyle: any = {};
    isShowTicketOption: boolean = true;
    isCordova: boolean = false;
    isLoggedInUser: boolean = false;
    currentUser: UserProfile;
    canUpdateAvatar: any;
    userEmail: string;
    browserLang: string = "en";
    currentTheme = localStorage.getItem("theme");
    constructor(
        public dialogRef: MatDialogRef<ProfileDetailDialogComponent>,
        private mailBroadcaster: MailBroadcaster,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        private matDialog: MatDialog,
        private toastService: ToastService,
        private store: Store<ContactRootState | RootState>,
        private commonRepository: CommonRepository,
        private mailService: MailService,
        private appStore: Store<AppState>,
        private configService: ConfigService,
        private electronService: ElectronService,
        private translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.canUpdateAvatar = this.configService.get("canUpdateAvatar");
        if (this.data.contact) {
            this.contact = this.data.contact;
            console.log("[contact] data::", this.data);
            this.isLoggedInUser = this.data.isLoggedInUser;
            this.userEmail = this.data.email;
            if (this.data.currentUser && this.data.isLoggedInUser) {
                this.currentUser = this.data.currentUser;
            }
            this.changeDetectorRef.markForCheck();
        }

        this.store.select(getFederatedApps).pipe(takeUntil(this.isAlive$)).subscribe(apps => {
            console.log("[getFederatedApps]", apps);
            this.fedrateApps = apps;
            const isMailAppAvailable = this.fedrateApps.filter(fa => fa.name.toLowerCase() === "vncmail")[0];
            if (!!isMailAppAvailable) {
                this.isShowSendEmailOption = true;
            } else {
                this.isShowSendEmailOption = false;
            }
            const isTalkAppAvailable = this.fedrateApps.filter(fa => fa.name.toLowerCase() === "vnctalk")[0];
            if (!!isTalkAppAvailable) {
                this.isShowTalkOptions = true;
            } else {
                this.isShowTalkOptions = false;
            }
            const isTaskAppAvailable = this.fedrateApps.filter(fa => fa.name.toLowerCase() === "vnctask")[0];
            if (!!isTaskAppAvailable) {
                this.isShowTaskOption = true;
                this.checkVNCtaskRights(this.contact);
            } else {
                this.isShowTaskOption = false;
            }
            const isProjectAppAvailable = this.fedrateApps.filter(fa => fa.name.toLowerCase() === "vncproject")[0];
            if (!!isProjectAppAvailable) {
                this.isShowTicketOption = true;
                this.checkVNCprojectRights(this.contact);
            } else {
                this.isShowTicketOption = false;
            }
            this.changeDetectorRef.markForCheck();
        });
        if (this.contact.avatar) {
            this.setBackground();
        }

        this.mailBroadcaster
        .on<any>("uploadAvatar")
        .pipe(takeUntil(this.isAlive$))
        .subscribe(data => {
            if (data) {
            if (!MailUtils.isIE()) {
                this.avatarUpload(this.blobToFile(data.operation, "avatar.png"));
            } else {
                this.avatarUpload(data.operation);
            }
            }
        });

        this.mailBroadcaster
        .on<any>("REMOVE_PROFILE_AVATAR")
        .pipe(takeUntil(this.isAlive$))
        .subscribe(data => {
            this.avatarRemove();
        });

        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_PROFILE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        this.isCordova = environment.isCordova;
        this.changeDetectorRef.markForCheck();
    }

    ngOnInit() {
        this.callSearchActivity("talk");
        this.browserLang = this.translateService.getBrowserLang();
        const localLang = this.electronService.isElectron
            ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE)
            : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
        if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
          this.browserLang = localLang;
        }
        this.browserLang = this.browserLang.match(/en|de/) ? this.browserLang : "en";
    }

    checkVNCtaskRights(contact: ContactInfo) {
        if (contact && contact.username && contact.products && contact.products.length > 0) {
            const isTaskEnabled = contact.products.filter(fa => fa.name === "vnctask")[0];
            if (isTaskEnabled) {
                this.isShowTaskOption = true;
            } else {
                this.isShowTaskOption = false;
            }
        } else {
            this.isShowTaskOption = false;
        }
    }

    copyEmail(email: string) {
      MailUtils.copyToClipboard([email]);
      this.toastService.show("COPIED_TO_CLIPBOARD");
    }

    openURL(url: string) {
      if (typeof cordova !== "undefined") {
        if (device.platform === "iOS") {
          window.open(url, "_system");
        } else if (device.platform === "Android") {
          navigator.app.loadUrl(url, {
            openExternal: true
          });
        }
      } else if (this.electronService.isElectron) {
        this.electronService.openExternalUrl(url);
      } else {
        window.open(url, "_blank");
      }
    }

    checkVNCprojectRights(contact: ContactInfo) {
        if (contact && contact.username && contact.products && contact.products.length > 0) {
            const isProjectEnabled = contact.products.filter(fa => fa.name === "vncproject")[0];
            if (isProjectEnabled) {
                this.isShowTicketOption = true;
            } else {
                this.isShowTicketOption = false;
            }
        } else {
            this.isShowTicketOption = false;
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    callToNumber(phoneNumber: string): void {
        if (environment.isCordova) {
            window.plugins.CallNumber.callNumber(
                function () { },
                function () { },
                phoneNumber,
                false
            );
        }
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    startAudioCall(): void {
        if (this.isLoggedInUser) {
            this.toastService.show("CAN_NOT_CALL_YOURSELF");
            return;
        }
        this.commonRepository.makeTalkAudioChatVideoOperation(this.contact.jid, "audio-call", "group-audio");
    }

    startVideoCall(): void {
        if (this.isLoggedInUser) {
            this.toastService.show("CAN_NOT_CALL_YOURSELF");
            return;
        }
        this.commonRepository.makeTalkAudioChatVideoOperation(this.contact.jid, "video-call", "group-video");
    }

    startChat(): void {
        if (this.isLoggedInUser) {
            this.toastService.show("CAN_NOT_CHAT_YOURSELF");
            return;
        }
        this.commonRepository.makeTalkAudioChatVideoOperation(this.contact.jid, "start-chat", "group-chat");
    }

    showFeatureUnderDevelopmentToast(): void {
        this.toastService.show("FEATURE_NEXT_VERSION_LBL");
    }

    openAddressLocation(address: any): void {
        let addressData: string[] = [];
        // tslint:disable-next-line:no-unused-expression
        address.street1 ? addressData.push(address.street1) : "";
        // tslint:disable-next-line:no-unused-expression
        address.city ? addressData.push(address.city) : "";
        // tslint:disable-next-line:no-unused-expression
        address.state ? addressData.push(address.state) : "";
        // tslint:disable-next-line:no-unused-expression
        address.country_code ? addressData.push(address.country_code) : "";
        // this.contactService.getlatlng(addressData.toString()).subscribe(res => {
        //     if (res.results.length > 0) {
        //       let loc = res.results[0].geometry.location;
        //       this.openMap(loc, addressData.toString());
        //     } else {
        //         this.toastService.show("NO_LOCATION_FOUND");
        //     }
        // });
    }

    openMap(location: any, address: any): void {
        console.log("Location: ", location);
        let latLon = location.lat + "," + location.lng;
        if (environment.isCordova) {
            if (CommonUtils.isOnAndroid()) {
                window.open("geo:0,0?q=" + latLon + "(" + address + ")", "_system");
            } else if (CommonUtils.isOnIOS()) {
                cordova.InAppBrowser.open("http://maps.apple.com/?q=" + address, "_system");
            }
        } else {
            // window.open(this.contactService.getDesktopMapUrl(latLon));
        }
    }

    callSearchActivity(apps: string): void {
        if (!!this.searchResponse) {
            this.searchResponse.docs = [];
            this.changeDetectorRef.markForCheck();
        }
        console.log("[callSearchActivity]", apps,  this.contact);
        if (this.contact.jid || this.contact.emails && this.contact.emails.length > 0) {
            this.searchParams.from_s = (this.contact.jid) ? this.contact.jid : this.contact.emails[0].email;
            this.searchParams.apps = apps;
            this.commonRepository.searchDocs(this.searchParams).pipe(take(1)).subscribe(docs => {
                console.log("Recent activity for selected contact::", docs);
                this.searchResponse = docs;
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    onSelectedTabChangeActivity(ev: any) {
        if (ev.index === 0) {
            this.callSearchActivity("talk");
        } else if (ev.index === 1) {
            this.callSearchActivity("mail");
        }
    }

    startMail(): void {
        this.commonRepository.sendEmail(this.contact.jid);
    }

    createTask(): void {
        this.commonRepository.createTask(this.contact.username);
    }

    showMoreLess(value: boolean): void {
        this.showMoreDetail = value;
        this.changeDetectorRef.markForCheck();
    }

    exportContact(): void {
    }

    printContact(): void {
    }

    createTicket(): void {
        this.commonRepository.createTicket(this.contact.username);
    }

    getBgAvatarColor(jid) {
        return RandomColor.getCharColor(jid);
    }

    getAvatarUrl(jid) {
        return this.contact.avatar;
    }

    composeEmail(email: string): void {
        console.log("[composeEmail]-email::", email);
        this.commonRepository.sendEmail(email);
        this.mailBroadcaster.broadcast("CLOSE_APPOINTMENT_PREVIEW");
        this.mailBroadcaster.broadcast(MailConstants.BROADCAST_MAIL_SELECTED_TAB);
        this.close();
        // this.contactRepository.composeOnEmail(email);
    }

    callPhoneNumber(phoneNumber: string): void {
        console.log("[callPhoneNumber]-phoneNumber::", phoneNumber);
        // this.contactRepository.callPhoneNumber(phoneNumber);
    }

    parseAddressLocation(address: any): void {
        let addressData: string[] = [];
        // tslint:disable-next-line:no-unused-expression
        address.street1 ? addressData.push(address.street1) : "";
        // tslint:disable-next-line:no-unused-expression
        address.city ? addressData.push(address.city) : "";
        // tslint:disable-next-line:no-unused-expression
        address.state ? addressData.push(address.state) : "";
        // tslint:disable-next-line:no-unused-expression
        address.country ? addressData.push(address.country) : "";
        // tslint:disable-next-line:no-unused-expression
        address.postcode ? addressData.push(address.postcode) : "";
        // tslint:disable-next-line:no-unused-expression
        address.country_code ? addressData.push(address.country_code) : "";
        // tslint:disable-next-line:no-unused-expression
        const addressItem = addressData.toString();
        console.log("[parseAddress]: ", addressItem);
        // this.contactRepository.openAddressOnMap(addressItem);
    }

    openRecentActivity(item) {
        if ( item.type === "talk") {
            if ( item.chatType === "chat") {
                this.commonRepository.jumpToChat(this.contact, item.talkId, item.createdDt);
            } else {
                this.underDevelopment();
            }
        } else if ( item.type === "mail") {
            console.log("openRecentActivity item: ", item);
            this.commonRepository.openMail(item.mailFolderID, item.id);
            this.close();
        } else if (item.type === "task") {
            this.commonRepository.openTask(item.id);
        } else {
            this.underDevelopment();
        }
    }

    underDevelopment() {
        this.toastService.show("COMING_SOON");
    }

    openCropperDialog() {
        if (this.commonRepository.showNoInternetToastIfRequired()) {
          return;
        }
        if (this.data.currentUser && this.data.isLoggedInUser) {
            if (this.canUpdateAvatar) {
                const dialogArgs = {
                autoFocus: false,
                maxWidth: "100%",
                data: { userProfile: this.currentUser },
                panelClass: "mail__dialog"
                };
                this.matDialog.open(AvatarCropperDialogComponent, dialogArgs);
            }
        }
    }

    avatarUpload(file) {
        console.log("avatarUpload", file);
        const formData = new FormData();
        formData.append("file", file);
        console.log("avatarUpload", formData);
        this.appStore.dispatch(new StartProcessing());
        this.mailService
          .uploadAvatar(formData)
          .pipe(take(1))
          .subscribe(res => {
            this.appStore.dispatch(new StopProcessing());
            const avatarURL = this.configService.get("avatarURL");
            const currentTime = new Date().getTime();
            this.store.dispatch(new SetLastPhotoUpdate({email: this.currentUser.email, timestamp: currentTime}));
            const id = res.replace(/"/g, "");
            this.currentUser.imageData = `${avatarURL}/${id}.jpg?v=${currentTime}`;
            this.setBackground();
          }, err => {
            this.toastService.show(MailConstants.FAIL_AVTAR_UPLOADED_MSG);
            this.appStore.dispatch(new StopProcessing());
          });
    }

    setBackground() {
        let avatarId = "";
        if (environment.isElectron) {
        avatarId = this.electronService.md5(this.userEmail);
        } else {
        avatarId = md5(this.userEmail);
        }
        const time = new Date().getTime();
        let backgroundURL = `url(${this.configService.get("avatarURL")}/${avatarId}-420.jpg?v=${time})`;
        this.backgroundImageStyle = {
            "background-image": backgroundURL
        };
        this.changeDetectorRef.markForCheck();
    }

    public blobToFile = (blob: Blob, fileName: string): File => {
        const date = new Date().getTime();
        let file = new File([blob], fileName, {lastModified: date});
        if (environment.isCordova) {
            file = new FileObject([blob], fileName, {lastModified: date});
        }
        return file;
    }

    avatarRemove() {
        this.appStore.dispatch(new StartProcessing());
        this.mailService
          .removeAvatar()
          .pipe(take(1))
          .subscribe(res => {
            this.appStore.dispatch(new StopProcessing());
            const avatarURL = this.configService.get("avatarURL");
            const currentTime = new Date().getTime();
            this.store.dispatch(new SetLastPhotoUpdate({email: this.currentUser.email, timestamp: currentTime}));
            const id = res.replace(/"/g, "");
            this.currentUser.imageData = `${avatarURL}/${id}.jpg?v=${currentTime}`;
            this.setBackground();
            this.toastService.show("IMAGE_REMOVE_MSG");
            this.changeDetectorRef.markForCheck();
          }, err => {
            this.appStore.dispatch(new StopProcessing());
          });
      }

      handleProfileAction(action) {
        console.log("profileDialog handleProfileAction: ", action, this.currentUser, this.contact);
        let targetMail;
        if (!!this.currentUser && !!this.currentUser.email) {
            targetMail = this.currentUser.email;
        }
        if (!targetMail && !!this.contact && !!this.contact.emails[0] && !!this.contact.emails[0].email) {
            targetMail = this.contact.emails[0].email;
        }
        switch (action) {
            case "audio": this.startAudioCall(); break;
            case "video": this.startVideoCall(); break;
            case "chat": this.startChat(); break;
            case "mail": this.composeEmail(targetMail); break;
            case "ticket": this.createTicket(); break;
            case "task": this.createTask(); break;
        }
    }
}
