
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { UserProfile } from "../shared/models";
import { MailUtils } from "../mail/utils/mail-utils";
import { environment } from "src/environments/environment";


export class Utils {
  static escapeRegExpStr(str: string) {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  static async dataUrlToFile(dataUrl: string, fileName: string): Promise<any> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    let file = new File([blob], fileName, { type: "image/png" });
    if (environment.isCordova) {
      file = new FileObject([blob], fileName, { type: "image/png" });
    }
    return file;
  }

  static insertAt(base: string, insert: string, index: number) {
    return base.slice(0, index) + insert + base.slice(index);
  }

  static isAndroid() {
    return /android/i.test(navigator.userAgent || navigator.vendor) ? true : false;
  }

  static isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook/i
      .test(navigator.userAgent);
  }

  static parseUserProfile(contact) {
    const contactUser = typeof contact === "string"
      ? JSON.parse(contact)
      : contact;
    const user: UserProfile = {};
    user.firstName = contactUser.firstName;
    user.lastName = contactUser.lastName;
    user.email = contactUser.email;
    user.avtarUrl = contactUser.avtarUrl;
    user.fullName = contactUser.fullName;
    user.firstLastCharacters = contactUser.firstLastCharacters;
    user.contactId = contactUser.contactId;
    user.app = "mail";
    return user;
  }

  static isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static validateEmail(mail) {
    // tslint:disable-next-line:max-line-length
    // let exp = /^[\u00C0-\u017Fa-zA-Z0-9_]+([\.-][\u00C0-\u017Fa-zA-Z0-9_]+)*@[\u00C0-\u017Fa-zA-Z0-9_]+([\.-][\u00C0-\u017Fa-zA-Z0-9_]+)*(\.[\u00C0-\u017Fa-zA-Z0-9_]{2,20})+$/;
    let exp = /^[!#$%&'*+\-/=?^_`{|}~.0-9A-Za-z]+(\.[!#$%&'*+\-/=?^_`{|}~.0-9A-Za-z]+)*@[A-Za-z0-9]+([-.][A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;

    if (exp.test(mail)) {
      return (true);
    }
    return (false);
  }

  static replacePtoDiv(str: string): string {
    if (str !== undefined && str !== null) {
      // console.log("[replacePtoDiv]", str);
      str = str.replace(/<p>/g, "<div>").replace(/<\/p>/g, "</div>");
      return str;
    }
    return "";
  }

  static quillGetHTML(inputDelta) {
    const tempQuill = new window.sharedQuill(document.createElement("div"));
    // console.log("[quillGetHTML]", inputDelta);
    tempQuill.setContents(inputDelta, "silent");
    return tempQuill.root.innerHTML;
  }

  static processMailContent(quillEditor, originalContent?: string) {
    let topData = [];
    let bottomData = [];
    let signatureData = [];
    if (!quillEditor && !originalContent) {
        return {topData, signatureData, bottomData};
    }
    let content = "";
    if (originalContent) {
      content = originalContent;
    } else if (quillEditor) {
      content = quillEditor.root.innerHTML;
    }
    const originalDelta = quillEditor.clipboard.convert(content.replace(/<p>(\s+)/g, (a, b) => {
      return `${a}${b.replace(/\s/g, "&nbsp;")}`;
    }));
    console.log("[processMailContent] originalDelta", content, originalDelta);
    MailUtils.processList(originalDelta);
    let i = 0;
    let j = 0;
    let startSignatureIndex;
    let endSignatureIndex;
    originalDelta.ops.forEach(op => {
      if (op.attributes && op.attributes.signature) {
        if (startSignatureIndex === undefined) {
          startSignatureIndex = i;
        } else {
          endSignatureIndex = i;
        }
      }
      i++;
    });
    let insertPlaceholder = false;
    let insertEndPlaceholder = false;
    originalDelta.ops.forEach(op => {
      if (op.insert && op.insert.image && op.insert.image.startsWith("cid:")) {
        if (op.attributes) {
          op.attributes["dfsrc"] = op.insert.image;
          op.attributes["alt"] = op.insert.image;
        } else {
          op.attributes = {dfsrc: op.insert.image};
        }
      }
      if (op.attributes && op.attributes.signature) {
        if (startSignatureIndex === j) {
          if (typeof op.insert === "string") {
            op.insert = `START_SIGNATURE${op.insert}`;
          } else {
            insertPlaceholder = true;
          }
        }
        if (endSignatureIndex === j) {
          if (typeof op.insert === "string") {
            op.insert = `${op.insert}END_SIGNATURE`;
          } else {
            insertEndPlaceholder = true;
          }
        }
      }
      j++;
    });
    if (insertPlaceholder) {
      originalDelta.ops.splice(startSignatureIndex, 0,  {insert: "START_SIGNATURE"});
    }
    if (insertEndPlaceholder) {
      originalDelta.ops.splice(endSignatureIndex + 1 + (insertPlaceholder ? 1 : 0), 0,  {insert: "END_SIGNATURE"});
    }
    topData = originalDelta.ops;
    return { topData, signatureData, bottomData };
  }

  static getCharacters(firstName, lastName) {
    if (firstName && firstName !== undefined && lastName && lastName !== undefined) {
      const fName: string = firstName.trim();
      const lName: string = lastName.trim();
      const chr1 = fName.length > 0 ? fName.charAt(0) : "";
      const chr2 = lName.length > 0 ? lName.charAt(0) : "";
      return (chr1 + chr2).trim();
    } else if (firstName && firstName !== undefined) {
      const fName: string = firstName.trim();
      const chr1 = fName.length > 0 ? fName.charAt(0) : "";
      return chr1;
    } else if (lastName && lastName !== undefined) {
      const lName: string = lastName.trim();
      const chr2 = lName.length > 0 ? lName.charAt(0) : "";
      return chr2;
    } else {
      return "NA";
    }
  }

}
