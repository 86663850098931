
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mobile_about_dialog mail-dialog">
  <div class="mail__dialog-header mobile_header">
    <div class="back-header-icon" (click)="close()">
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/arrow-back" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-24px" transform="translate(4.000000, 4.000000)" fill="#fff">
                <polygon id="Shape" points="16 7 3.83 7 9.42 1.41 8 0 0 8 8 16 9.41 14.59 3.83 9 16 9"></polygon>
            </g>
        </g>
      </svg>
    </div>
    <div id="mobile_sidebar_about_header" class="header_lbl disable-select">
      {{ 'HELP_LABEL' | translate }}
    </div>
    <div class="submit"></div>
  </div>
  <div class="mail__dialog-header desktop_view">
    <div>
      <button mat-button>
        <mat-icon class="disable-select">info</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{ 'ABOUT' | translate }}
    </div>
    <div class="submit">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="mail__dialog-body mail__dialog-body--pad">
    <div class="content">
      <mat-list>
        <mat-list-item id="mobile_sidebar_about_version" (click)="aboutDialog()">
          <div class="icon-style-mobile-sidebar">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/info-outline</title>
              <g id="Icon/product/info-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M11,17 L13,17 L13,11 L11,11 L11,17 L11,17 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 L12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 L12,20 Z M11,9 L13,9 L13,7 L11,7 L11,9 L11,9 Z" id="icon" fill="#000"></path>
              </g>
            </svg>
          </div>
          <span class="disable-select">{{ 'APP_INFO' | translate }}</span>
        </mat-list-item>
        <mat-list-item id="mobile_sidebar_about_legal_notice" (click)="openLegaldialog()" *ngIf="!hideLegalNoticeMenu">
          <div class="icon-style-mobile-sidebar">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/info-outline</title>
              <g id="Icon/product/info-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M11,17 L13,17 L13,11 L11,11 L11,17 L11,17 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 L12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 L12,20 Z M11,9 L13,9 L13,7 L11,7 L11,9 L11,9 Z" id="icon" fill="#000"></path>
              </g>
            </svg>
          </div>
          <span class="disable-select">{{ 'LEGAL_NOTICE' | translate }}</span>
          <div class="icon-redirect">
            <svg width="24px" height="25px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Icon/product/keyboard-arrow-right-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M9.29289322,7.29289322 C8.90236893,7.68341751 8.90236893,8.31658249 9.29289322,8.70710678 L13.1213203,12.5355339 L9.29289322,16.363961 C8.90236893,16.7544853 8.90236893,17.3876503 9.29289322,17.7781746 C9.68341751,18.1686989 10.3165825,18.1686989 10.7071068,17.7781746 L15.9497475,12.5355339 L10.7071068,7.29289322 C10.3165825,6.90236893 9.68341751,6.90236893 9.29289322,7.29289322 Z" id="Path" fill="#8B96A0"></path>
              </g>
            </svg>
          </div>
        </mat-list-item>
        <mat-list-item id="mobile_sidebar_about_faq" (click)="helpFaqDialog()" *ngIf="!hideFAQMenu">
          <div class="icon-style-mobile-sidebar">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Icon/product/file-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M13.5857864,2 C13.8510029,2 14.1053568,2.10535684 14.2928932,2.29289322 L19.7071068,7.70710678 C19.8946432,7.89464316 20,8.14899707 20,8.41421356 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.8954305,22 4,21.1045695 4,20 L4,4 C4,2.8954305 4.8954305,2 6,2 L13.5857864,2 Z M10.999,3.999 L6,4 L6,20 L18,20 L18,10 L14,10 C12.4023191,10 11.0963391,8.75108004 11.0050927,7.17627279 L11,7 L10.999,3.999 Z M17.172,8 L13.171,4 L12.999,3.999 L13,7 C13,7.51283584 13.3860402,7.93550716 13.8833789,7.99327227 L14,8 L17.172,8 Z" id="Combined-Shape" fill="#000"></path>
              </g>
            </svg>
          </div>
          <span class="disable-select">{{ 'HELP_MANUAL_FAQ' | translate }}</span>
          <div class="icon-redirect">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/new-tab-redirect-new</title>
              <g id="Icon/product/new-tab-redirect-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M12,3 L12,5 L5,5 L5,19 L19,19 L19,12 L21,12 L21,19 C21,20.1 20.1,21 19,21 L19,21 L5,21 C3.89,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.89,3 5,3 L5,3 L12,3 Z M21,3 L21,10 L19,10 L19,6.41 L9.17,16.24 L7.76,14.83 L17.59,5 L14,5 L14,3 L21,3 Z" id="Shape" fill="#8B96A0"></path>
              </g>
            </svg>
          </div>
        </mat-list-item>
        <mat-list-item id="mobile_sidebar_about_service_desk" (click)="serviceDeskDialog()" *ngIf="!hideServiceDeskMenu">
          <mat-icon class="disable-select">headset_mic</mat-icon>
          <span class="disable-select">{{ serviceDeskMenuItemName }}</span>
          <div class="icon-redirect">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/new-tab-redirect-new</title>
              <g id="Icon/product/new-tab-redirect-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M12,3 L12,5 L5,5 L5,19 L19,19 L19,12 L21,12 L21,19 C21,20.1 20.1,21 19,21 L19,21 L5,21 C3.89,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.89,3 5,3 L5,3 L12,3 Z M21,3 L21,10 L19,10 L19,6.41 L9.17,16.24 L7.76,14.83 L17.59,5 L14,5 L14,3 L21,3 Z" id="Shape" fill="#8B96A0"></path>
              </g>
            </svg>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
