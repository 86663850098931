
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="desktop-header">
        <div class="header-text">{{  'ADD_SHARE_FOLDER' | translate }}</div>
        <div class="header-icon" (click)="close()" matTooltip="{{ 'CLOSE' | translate }}">
            <ng-container [ngTemplateOutlet]="closeIcon"></ng-container>
        </div>
    </div>

    <div class="content">
        <div class="search-from-tree">
            <mat-form-field>
                <input type="text" [formControl]="searchFromTreeInput" matInput
                    placeholder="{{ 'ENTER_TEXT_TO_FILTER_RESULTS' | translate }}">
                <mat-icon matSuffix (click)="searchFromTree()">search</mat-icon>
            </mat-form-field>
        </div>
        <div class="tree">
            <mat-tree [dataSource]="calendarDataSource" [treeControl]="calendarTreeControl"
                class="sidebar-tree-subfolders">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
                    style="white-space:no-wrap;" class="sub_folder_tree_node">
                    <li *ngIf="node" class="sub_folder">
                        <div class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="folder-information">
                                <div class="folder-div disable-select">
                                    <mat-checkbox class="disable-select" (change)="itemSelectionToggle(node, $event)">
                                    </mat-checkbox>
                                    <mat-icon class="mdi-18px disable-select" fontSet="mdi"
                                        fontIcon="mdi-calendar-blank" *ngIf="node.view === 'appointment'">
                                    </mat-icon>
                                    <mat-icon class="mdi-18px disable-select" fontSet="mdi"
                                        fontIcon="mdi-folder" *ngIf="node.view === 'message'">
                                    </mat-icon>
                                    <mat-icon class="mdi-18px disable-select" fontSet="mdi"
                                        fontIcon="mdi-account-box" *ngIf="node.view === 'contact'">
                                    </mat-icon>
                                    <mat-icon class="mdi-18px disable-select" fontSet="mdi"
                                        fontIcon="mdi-briefcase-account" *ngIf="node.view === 'document'">
                                    </mat-icon>
                                    <div vpTooltipDirective>
                                        <span class="sidebar-title disable-select">
                                            {{node.folderPath.substr(1)}}
                                        </span>
                                        <ng-template #tooltipTemplate>
                                            <div class="find-share-tooltip">
                                                <div class="item-name">
                                                    <mat-icon class="mdi-18px disable-select" fontSet="mdi"
                                                        fontIcon="mdi-calendar-blank"></mat-icon>
                                                    {{node.folderPath.substr(1)}}
                                                </div>
                                                <div class="left-right">
                                                    <div class="left">{{ 'OWNER_LBL' | translate }} :</div>
                                                    <div class="right">{{node.ownerName || node.ownerEmail}}</div>
                                                </div>
                                                <div class="left-right">
                                                    <div class="left">{{ 'GRANTEE' | translate }} :</div>
                                                    <div class="right">{{node.granteeName ? node.granteeName : ''}}
                                                    </div>
                                                </div>
                                                <div class="left-right">
                                                    <div class="left">{{ 'ROLE_LBL' | translate }} :</div>
                                                    <div class="right">
                                                        <span
                                                            *ngIf="node.rights === 'r'">{{ "VIEWER_VIEW" | translate }}</span>
                                                        <span
                                                            *ngIf="node.rights === 'rwidx'">{{ "MANAGER_VIEW" | translate }}</span>
                                                        <span
                                                            *ngIf="node.rights === 'rwidxa'">{{ "ADMIN_VIEW" | translate }}</span>
                                                    </div>
                                                </div>
                                                <div class="left-right">
                                                    <div class="left">{{ 'NOTE' | translate }} :</div>
                                                    <div class="right">{{'THE_LINK_NAME_SHARE' | translate }}
                                                        {{node.ownerName || node.ownerEmail.split("@")[0]}}`s
                                                        {{getFolderNameLastIndex(node.folderPath)}}</div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </mat-tree-node>
                <mat-tree-node *matTreeNodeDef="let node; when: hasNestedChild" matTreeNodePadding
                    style="white-space:no-wrap;" class="sub_folder_tree_node">
                    <li *ngIf="node" class="sub_folder">
                        <div class="mat-tree-node">
                            <button *ngIf="calendarTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.folderPath">
                                <mat-icon class="mat-icon-rtl-mirror">expand_more</mat-icon>
                            </button>
                            <button *ngIf="!calendarTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.folderPath">
                                <mat-icon class="mat-icon-rtl-mirror">chevron_right</mat-icon>
                            </button>
                            <div class="folder-information">
                                <div class="folder-div disable-select">
                                    <mat-icon class="mdi-18px disable-select" fontSet="mdi"
                                        fontIcon="mdi-folder-account">
                                    </mat-icon>
                                    <span class="sidebar-title disable-select" title="{{node.folderPath}}">
                                        {{node.folderPath}}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </mat-tree-node>
            </mat-tree>
        </div>
        <div class="search-share-data">
            <mat-form-field>
                <mat-label>{{'FIND_SHARES' | translate }}:</mat-label>
                <input type="text" [formControl]="searchFromEmail" matInput
                    placeholder="{{ 'ENTER_TEXT_TO_FILTER_RESULTS' | translate }}" [matAutocomplete]="auto">
                <mat-icon matSuffix (click)="searchResultRequest()">search</mat-icon>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)"
                    class="vnc_mail_autocomplete_panel" [autoActiveFirstOption]=true>
                    <mat-option *ngFor="let emailItem of filteredFromEmails" [value]="emailItem">
                        <span class="suggestion">
                            <vp-avatar [type]="'mail-detail-avatar'"
                                [user]="{firstLastCharacters: emailItem.email?.charAt(0), color: '#317bbc', email: emailItem.email}">
                            </vp-avatar>
                            <div class="email_name_division">
                                <h3 class="disable-select">{{emailItem.name}}</h3>
                                <p class="disable-select">{{emailItem.email}}</p>
                            </div>
                        </span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="share-type-selection">
            <mat-form-field>
                <mat-label>{{ 'INCLUDE' | translate }}:</mat-label>
                <mat-select [(ngModel)]="searchShareTypeSelection" (selectionChange)="changeSearchType()">
                    <mat-option value='all'>{{ 'ALL_APPLICATIONS' | translate }}</mat-option>
                    <mat-option value='message'>{{ 'MAIL_SHARES_ONLY' | translate }}</mat-option>
                    <mat-option value='contact'>{{ 'CONTACTS_SHARES_ONLY' | translate }}</mat-option>
                    <mat-option value='appointment'>{{ 'CALENDAR_SHARE_ONLY' | translate }}</mat-option>
                    <mat-option value='document'>{{ 'BRIEFCASE_SHARES_ONLY' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="footer">
        <vnc-footer class="calendar-menu-action" [secondaryLabel]="'CANCEL' | translate | uppercase"
        [primaryLabel]="'ADD' | translate | uppercase"
        [ngClass]="{ 'dfb-theme': currentTheme === 'dfb' }"
        [type]="'secondary-primary'"
        [color]="'white'"
        (onSecondaryClick)="close()"
        (onPrimaryClick)="add()"></vnc-footer>
    </div>
</div>

<ng-template  #closeIcon>
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
        </g>
    </svg>
  </ng-template>