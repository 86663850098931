
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ElementRef, ViewChild, NgZone } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs/internal/Subject";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { distinctUntilChanged, take, takeUntil } from "rxjs/operators";
import { ToastService } from "src/app/common/providers/toast.service";
import { ErrorService } from "src/app/common/providers/error-service";
import { ErrorType } from "src/app/common/enums/mail-enum";
import { CommonService } from "src/app/services/ common.service.";
import { SearchRequest } from "src/app/mail/shared/models";
import { Store } from "@ngrx/store";
import { RootState, getZimbraFeatures, getOnlineStatus } from "src/app/reducers";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { ZimbraFeatures } from "src/app/common/utils/zimbra-features";
import { DatabaseService } from "src/app/services/db/database.service";

@Component({
  selector: "vp-calendar-select-addresses",
  templateUrl: "./select-addresses-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class CalendarSelectAddressesDialogComponent implements OnDestroy {
  maxTagNameLength: number = 128;
  private isAlive$ = new Subject<boolean>();
  allEmailAddresses: any[] = [];
  selectedAllEmailAddresses: any[] = [];
  finalEmailAddresses: any[] = [];
  selectedFinalEmailAddresses: any[] = [];
  selectedShowOption: string = "";
  searchText: string = "";
  offset: number = 0;
  limit: number = 50;
  isContact: boolean = false;
  isLoading = false;
  iszimbraFeatureGalEnabled: boolean = false;
  isOnline: boolean = false;
  currentTheme = localStorage.getItem("theme");
  constructor(
    private dialogRef: MatDialogRef<CalendarSelectAddressesDialogComponent>,
    public toastService: ToastService,
    private commonService: CommonService,
    private changeDetectionRef: ChangeDetectorRef,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hotKeyService: NgxHotkeysService,
    private store: Store<RootState>,
    private dbService: DatabaseService
  ) {
    this.finalEmailAddresses = data;
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    this.store.select(getOnlineStatus).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(o => {
      this.isOnline = o;
      if (!this.isOnline) {
        if (this.selectedShowOption !== "contacts") {
          this.selectedShowOption = "contacts";
          this.changeShowNames(this.selectedShowOption);
          this.changeDetectionRef.markForCheck();
        }
      }
    });
    this.store.select(getZimbraFeatures).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.iszimbraFeatureGalEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_GAL_ENABLED);
      if (this.iszimbraFeatureGalEnabled && this.isOnline) {
        this.selectedShowOption = "global";
        this.changeShowNames(this.selectedShowOption);
      } else {
        this.selectedShowOption = "contacts";
        this.changeShowNames(this.selectedShowOption);
      }
    });
    this.changeDetectionRef.markForCheck();
  }

  closeDialog(): void {
    this.close();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  close(): void {
    this.dialogRef.close();
  }

  setSelected(): void {
    this.dialogRef.close({ selectedAddress: this.finalEmailAddresses });
  }

  changeShowNames(value, isSearch?: boolean) {
    if (value === "global") {
      this.isLoading = true;
      const query = {
        name: this.searchText !== "" ? this.searchText : "",
        limit: this.limit,
        offset: this.offset
      };
      this.commonService.searchGalRequest(query).pipe(take(1)).subscribe( res => {
        this.allEmailAddresses = [];
        this.isLoading = false;
        if (res.cn) {
          this.adjustNextAndPrevious(res.cn);
          res.cn.forEach(ele => {
            const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
            const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
            const fullNameUser = !!fullName ? fullName : ele._attrs.email;
            this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
          });
          this.allEmailAddresses = this.allEmailAddresses.filter( e => !!e.email && e.email !== "");
        }
        this.changeDetectionRef.markForCheck();
      }, error => {
        this.isLoading = false;
        this.changeDetectionRef.markForCheck();
        this.errorService.emit({ id: ErrorType.Generic, messages: error });
      });
      this.changeDetectionRef.markForCheck();
    } else if (value === "personalandshared") {
      this.getContactFolders(isSearch);
    } else {
      this.isLoading = true;
      if (this.isOnline) {
        const query: SearchRequest = {
          field: "contact",
          limit: this.limit,
          needExp: 1,
          offset: this.offset,
          query: this.searchText !== "" ? "(\"" + this.searchText + "\") (is:local)" : "(is:local)",
          sortBy: "nameAsc",
          types: "contact"
        };
        this.commonService.searchRequest(query).pipe(take(1)).subscribe(
          res => {
            this.isLoading = false;
            this.allEmailAddresses = [];
            if (res.cn) {
              this.adjustNextAndPrevious(res.cn);
              res.cn.forEach(ele => {
                const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
                const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
                const fullNameUser = !!fullName ? fullName : ele._attrs.email;
                this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
              });
              this.allEmailAddresses = this.allEmailAddresses.filter( e => !!e.email && e.email !== "");
            }
            this.changeDetectionRef.markForCheck();
          }, error => {
            this.isLoading = false;
            this.changeDetectionRef.markForCheck();
            this.errorService.emit({ id: ErrorType.Generic, messages: error });
          });
      } else {
        this.dbService.searchContactsCalendar(this.searchText.trim()).subscribe(res => {
          console.log("[selectEmailDBResults: ", res);
          this.isLoading = false;
          this.allEmailAddresses = [];
            this.adjustNextAndPrevious(res);
            res.forEach(ele => {
              const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
              const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
              const fullNameUser = !!fullName ? fullName : ele._attrs.nickname ? ele._attrs.nickname : ele._attrs.email;
              this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
            });
            this.allEmailAddresses = this.allEmailAddresses.filter(e => !!e.email && e.email !== "");

          this.changeDetectionRef.markForCheck();
        }, error => {
          this.isLoading = false;
          this.changeDetectionRef.markForCheck();
          this.errorService.emit({ id: ErrorType.Generic, messages: error });
        });
      }
      this.changeDetectionRef.markForCheck();
    }
  }

  getContactFolders(isSearch) {
    this.isLoading = true;
    this.changeDetectionRef.markForCheck();
    const body = {
      view: "contact",
    };
    this.commonService.getContactFolders(body).pipe(take(1)).subscribe(
      res => {
        this.isLoading = false;
        this.changeDetectionRef.markForCheck();
        let que = "";
        if (res.folder[0].link) {
          for (let i = 0; i < res.folder[0].link.length; i++) {
            if (i === 0) {
              que += "inid:" + res.folder[0].link[i].id;
            } else {
              que += " OR inid:" + res.folder[0].link[i].id;
            }
          }
        }
        if (que.length > 0) {
          que = "(" + que + " OR is:local)";
        } else {
          que = "(is:local)";
        }
        if (this.searchText !== "") {
          que = "(" + this.searchText + ")" + que;
        }
        const query: SearchRequest = {
          field: "contact",
          limit: this.limit,
          needExp: 1,
          offset: this.offset,
          query: que,
          sortBy: "nameAsc",
          types: "contact"
        };
        this.isLoading = true;
        this.changeDetectionRef.markForCheck();
        this.commonService.searchRequest(query).pipe(take(1)).subscribe(searchRes => {
          this.isLoading = false;
          this.allEmailAddresses = [];
          if (searchRes.cn) {
            this.adjustNextAndPrevious(searchRes.cn);
            searchRes.cn.forEach(ele => {
              const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
              const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
              const fullNameUser = !!fullName ? fullName : ele._attrs.email;
              this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
            });
            this.allEmailAddresses = this.allEmailAddresses.filter( e => !!e.email && e.email !== "");
          }
          this.changeDetectionRef.markForCheck();
        }, error => {
          this.isLoading = false;
          this.changeDetectionRef.markForCheck();
          this.errorService.emit({ id: ErrorType.Generic, messages: error });
        });
      },
      err => {
        this.isLoading = false;
        this.changeDetectionRef.markForCheck();
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    );
  }

  selectEmailAddress(data: any, index: number, $event: MouseEvent): void {
    $event.preventDefault();
    if (this.selectedAllEmailAddresses.indexOf(data) === -1) {
      if ($event.ctrlKey) {
        this.selectedAllEmailAddresses.push(data);
      } else {
        this.selectedAllEmailAddresses = [];
        this.selectedAllEmailAddresses.push(data);
      }
    } else {
      if (this.selectedAllEmailAddresses.length > 1) {
        this.selectedAllEmailAddresses.splice(this.selectedAllEmailAddresses.indexOf(data), 1);
      }
    }
  }

  selectFinalEmailAddresses(data: any, index: number, $event: MouseEvent): void {
    $event.preventDefault();
    if (this.selectedFinalEmailAddresses.indexOf(data) === -1) {
      if ($event.ctrlKey) {
        this.selectedFinalEmailAddresses.push(data);
      } else {
        this.selectedFinalEmailAddresses = [];
        this.selectedFinalEmailAddresses.push(data);
      }
    } else {
      if (this.selectedFinalEmailAddresses.length > 1) {
        this.selectedFinalEmailAddresses.splice(this.selectedFinalEmailAddresses.indexOf(data), 1);
      }
    }
  }

  isSelectedEmailAddress(email: any): boolean {
    return this.selectedAllEmailAddresses.indexOf(email) !== -1;
  }

  isselectFinalEmailAddresses(email: any): boolean {
    return this.selectedFinalEmailAddresses.indexOf(email) !== -1;
  }

  addSelectedinTo() {
    this.selectedAllEmailAddresses.forEach(add => {
      const objCopy = Object.assign({}, add);
      objCopy["t"] = "To";
      if (!this.isAlreadyAdded(objCopy)) {
        this.finalEmailAddresses.push(objCopy);
      }
    });
  }

  addSelectedinCc() {
    this.selectedAllEmailAddresses.forEach(add => {
      const objCopy = Object.assign({}, add);
      objCopy["t"] = "Cc";
      if (!this.isAlreadyAdded(objCopy)) {
        this.finalEmailAddresses.push(objCopy);
      }
    });
  }

  addSelectedinBcc() {
    this.selectedAllEmailAddresses.forEach(add => {
      const objCopy = Object.assign({}, add);
      objCopy["t"] = "Bcc";
      if (!this.isAlreadyAdded(objCopy)) {
        this.finalEmailAddresses.push(objCopy);
      }
    });
  }

  isAlreadyAdded(add) {
    let isAdded = false;
    for (let i = 0; i < this.finalEmailAddresses.length; i++) {
      if (this.finalEmailAddresses[i].email === add.email && this.finalEmailAddresses[i].t === add.t) {
        isAdded = true;
        break;
      }
    }
    return isAdded;
  }

  removeSelectedAddresses() {
    this.selectedFinalEmailAddresses.forEach(add => {
      const addIndex = this.finalEmailAddresses.findIndex((e) => e.email === add.email && e.t === add.t);
      if (addIndex !== -1) {
        this.finalEmailAddresses.splice(addIndex, 1);
      }
    });
  }

  onUserScroll(event): void {
    const allEmailAddressesList = event.target;
  }

  searchEmailAddresses() {
    this.resetOffset();
    this.changeShowNames(this.selectedShowOption, true);
  }

  loadPreviousContact(): void {
    this.offset -= this.limit;
    if (this.searchText !== "") {
      this.changeShowNames(this.selectedShowOption, true);
    } else {
      this.changeShowNames(this.selectedShowOption);
    }
  }

  loadNextContact(): void {
    this.offset += this.limit;
    if (this.searchText !== "") {
      this.changeShowNames(this.selectedShowOption, true);
    } else {
      this.changeShowNames(this.selectedShowOption);
    }
  }

  adjustNextAndPrevious(cn: any): void {
    const totalCount = cn.length;
    if (totalCount < this.limit || totalCount > this.limit) {
      this.isContact = false;
    } else {
      this.isContact = true;
    }
    this.changeDetectionRef.markForCheck();
  }

  resetOffset(): void {
    this.offset = 0;
    this.changeDetectionRef.markForCheck();
  }
}
