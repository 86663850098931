
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="subfolder-header disable-select">
    <span class="subfolder-title">{{ 'YOUR_FOLDERS' | translate }}</span>
    <div class="folder-action" id="user-folder-action">
      <mat-icon *ngIf="isRootUserFolderCollapsed" (click)="expandCollapseUserRootFolder(false)">expand_more
      </mat-icon>
      <mat-icon *ngIf="!isRootUserFolderCollapsed" (click)="expandCollapseUserRootFolder(true)">expand_less
      </mat-icon>
      <mat-icon (click)="createNewFolder(null)" class="create-folder"
        matTooltip="{{'CREATE_NEW_FOLDER' | translate }}">create_new_folder</mat-icon>
      <mat-icon (click)="createNewFolder(null)" class="create-folder">unfold_more</mat-icon>
    </div>
</div>
<div class="mail-user-folder-main-div"
mwlResizable
(resizing)="onResizing($event)"
[resizeEdges]="{ bottom: true }"
[validateResize]="validate">
    <cdk-virtual-scroll-viewport *ngIf="folders && !isRootUserFolderCollapsed" itemSize="20" class="user-folders-viewport">
        <ng-container *cdkVirtualFor="let item of folders"></ng-container>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="sidebar-tree-subfolders">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding style="white-space:no-wrap;"
                [contextMenu]="folderMenu" (contextmenu)="onContextMenu($event, node)"
                class="sub_folder_tree_node">
                <li *ngIf="node" class="sub_folder" (mouseover)="mouseEnter(node, $event)" (mouseout)="mouseOut(node)">
                    <div class="mat-tree-node" cdkDropList
                        [class.isDisabled]="currentUrl.indexOf('/mail/drafts') !== -1 || disableMoveFolder[node.id] || node.name === 'Drafts' || (draggedFolder && draggedFolder.data.id === node.id)"
                        [class.selected-class]="selectedFolder && node.id === selectedFolder.id"
                        (mousedown)="mouseDownAction(node)">
                        <button mat-icon-button disabled></button>
                        <div class="folder-information" (cdkDragStarted)="dragStarted($event)" [cdkDragData]="node"
                            (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="isOnMobileDevice" cdkDrag>
                            <div (click)="routeToFolder(node)" class="folder-div disable-select">
                                <mat-icon *ngIf="emailTemplateFolder && node.id !== emailTemplateFolder.id"
                                    [style.color]="node.rgb">
                                    folder</mat-icon>
                                <mat-icon *ngIf="emailTemplateFolder && node.id == emailTemplateFolder.id" class="mdi-18px"
                                    fontSet="mdi" fontIcon="mdi-email-variant"></mat-icon>
                                <span class="mail-count"
                                    *ngIf="node.name !== 'Drafts' && node.u !== '0' && node.u !== 0 && node.u !== undefined">{{ node.u > 99 ? '99+' : node.u }}</span>
                                <span class="mail-count"
                                    *ngIf="node.name === 'Drafts' && node.n !== '0' && node.n !== 0">{{ node.n > 99 ? '99+' : node.n }}</span>
                                <span class="sidebar-title" title="{{node.name}}"
                                    [ngClass]="{'broken-share-folder': node.owner && !node.oname}">
                                    {{node.name}}</span>
                            </div>
                            <div class="folder-information-preview" *cdkDragPreview>
                                <mat-icon *ngIf="emailTemplateFolder && node.id !== emailTemplateFolder.id"
                                    [style.color]="node.rgb">
                                    folder</mat-icon>
                                <mat-icon *ngIf="emailTemplateFolder && node.id == emailTemplateFolder.id" class="mdi-18px"
                                    fontSet="mdi" fontIcon="mdi-email-variant"></mat-icon>
                                <span class="mail-count"
                                    *ngIf="node.name !== 'Drafts' && node.u !== '0' && node.u !== 0 && node.u !== undefined">{{ node.u > 99 ? '99+' : node.u }}</span>
                                <span class="mail-count"
                                    *ngIf="node.name === 'Drafts' && node.n !== '0' && node.n !== 0">{{ node.n > 99 ? '99+' : node.n }}</span>
                                <span class="sidebar-title" title="{{node.name}}"
                                    [ngClass]="{'broken-share-folder': node.owner && !node.oname}">
                                    {{node.name}}</span>
                            </div>
                        </div>

                        <div class="dropbox_div">
                            <button mat-icon-button class="dropbox" [contextMenu]="folderMenu"
                                (click)="onContextMenu($event, node)">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </div>
                    </div>
                </li>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node; when: hasNestedChild" matTreeNodePadding style="white-space:no-wrap;"
                [contextMenu]="folderMenu" (contextmenu)="onContextMenu($event, node)"
                class="sub_folder_tree_node">
                <li *ngIf="node" class="sub_folder" (mouseover)="mouseEnter(node, $event)" (mouseout)="mouseOut(node)">
                    <div class="mat-tree-node" cdkDropList
                        [class.isDisabled]="currentUrl.indexOf('/mail/drafts') !== -1 || disableMoveFolder[node.id] || node.name === 'Drafts' || (draggedFolder && draggedFolder.data.id === node.id)"
                        [class.selected-class]="selectedFolder && node.id === selectedFolder.id"
                        (mousedown)="mouseDownAction(node)">
                        <button mat-icon-button disabled *ngIf="(node.children && node.children.length === 0)"></button>
                        <button *ngIf="treeControl.isExpanded(node) && (node.children && node.children.length > 0 || node.link && node.link.length > 0)" (click)="removeNodeFromList(node)" mat-icon-button
                            matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">expand_more</mat-icon>
                        </button>

                        <button *ngIf="!treeControl.isExpanded(node) && (node.children && node.children.length > 0 || node.link && node.link.length > 0)" mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.name" (mouseover)="handleMouseOverExpand($event, node)">
                            <mat-icon class="mat-icon-rtl-mirror" (click)="pushUniqueExpandNode(node)">chevron_right</mat-icon>
                        </button>
                        <div class="folder-information" (cdkDragStarted)="dragStarted($event)" [cdkDragData]="node"
                            (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="isOnMobileDevice" cdkDrag>
                            <div (click)="routeToFolder(node)" class="folder-div disable-select">
                                <mat-icon *ngIf="emailTemplateFolder && node.id !== emailTemplateFolder.id"
                                    [style.color]="node.rgb">
                                    folder</mat-icon>
                                <mat-icon *ngIf="emailTemplateFolder && node.id == emailTemplateFolder.id" class="mdi-18px"
                                    fontSet="mdi" fontIcon="mdi-email-variant"></mat-icon>
                                <span class="mail-count"
                                    *ngIf="node.name !== 'Drafts' && node.u !== '0' && node.u !== 0 && node.u !== undefined">{{ node.u > 99 ? '99+' : node.u }}</span>
                                <span class="mail-count"
                                    *ngIf="node.name === 'Drafts' && node.n !== '0' && node.n !== 0">{{ node.n > 99 ? '99+' : node.n }}</span>
                                <span class="sidebar-title" title="{{node.name}}"
                                    [ngClass]="{'broken-share-folder': node.owner && !node.oname}">
                                    {{node.name}}</span>
                            </div>
                            <div class="folder-information-preview" *cdkDragPreview>
                                <mat-icon *ngIf="emailTemplateFolder && node.id !== emailTemplateFolder.id"
                                    [style.color]="node.rgb">
                                    folder</mat-icon>
                                <mat-icon *ngIf="emailTemplateFolder && node.id == emailTemplateFolder.id" class="mdi-18px"
                                    fontSet="mdi" fontIcon="mdi-email-variant"></mat-icon>
                                <span class="mail-count"
                                    *ngIf="node.name !== 'Drafts' && node.u !== '0' && node.u !== 0 && node.u !== undefined">{{ node.u > 99 ? '99+' : node.u }}</span>
                                <span class="mail-count"
                                    *ngIf="node.name === 'Drafts' && node.n !== '0' && node.n !== 0">{{ node.n > 99 ? '99+' : node.n }}</span>
                                <span class="sidebar-title" title="{{node.name}}"
                                    [ngClass]="{'broken-share-folder': node.owner && !node.oname}">
                                    {{node.name}}</span>
                            </div>
                        </div>

                        <div class="dropbox_div">
                            <button mat-icon-button class="dropbox" [contextMenu]="folderMenu"
                                (click)="onContextMenu($event, node)">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </div>
                    </div>
                </li>
            </mat-tree-node>
        </mat-tree>
    </cdk-virtual-scroll-viewport>
</div>
<context-menu #folderMenu id="folderMenu">
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableNewFolder"
        (execute)="createNewFolder(folderInAction)">
        <div class="context-item" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon' }">
            <mat-icon>create_new_folder</mat-icon>
            <span>{{ 'NEW_FOLDER_ACTION' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableMarkRead" (execute)="markAllMailRead()">
        <div class="context-item" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon' }">
            <mat-icon>done_all</mat-icon>
            <span>{{ 'ALL_READ_FODER_ACTION' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableEmpty" (execute)="emptyFolder()">
        <div class="context-item" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon' }">
            <mat-icon>close</mat-icon>
            <span>{{ emptyAction.key | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableShare" (execute)="shareFolder()"
        *ngIf="isSharingFeatureEnabled">
        <div class="context-item" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon' }">
            <mat-icon>share</mat-icon>
            <span>{{ 'SHARE_FOLDER_ACTION' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableMove" (execute)="openMoveFolderDialog()">
        <div class="context-item" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon' }">
            <mat-icon>folder</mat-icon>
            <span>{{ 'MOVE_FOLDER_ACTION' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableDelete" (execute)="deleteFolder()">
        <div class="context-item" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon' }">
            <mat-icon>delete</mat-icon>
            <span>{{ 'DELETE_FOLDER_ACTION' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableRename" (execute)="renameFolder()">
        <div class="context-item" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon' }">
            <mat-icon>edit</mat-icon>
            <span>{{ 'RENAME_FOLDER_ACTION' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [visible]="isVisibleExternalFolder" [enabled]="contextMenuActions.isExternalFolder"
        (execute)="getExternalMail()">
        <div class="context-item" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon' }">
            <mat-icon>sync</mat-icon>
            <span>{{'GET_EXTERNAL_MAIL'| translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEditFolder" (execute)="editFolderProperties()">
        <div class="context-item" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon' }">
            <mat-icon>edit</mat-icon>
            <span>{{'EDIT_PROPERTIES_LBL'| translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableExpandAll"
        (execute)="expandAllFolders(folderInAction)">
        <div class="context-item" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon' }">
            <mat-icon>add</mat-icon>
            <span>{{ 'EXPAND_ALL' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [subMenu]="colorSubMenu" [visible]="contextMenuActions.isEnableColorControl">
        <mat-radio-group class="color-selection-radio" (change)="updateDefaultColor()" [(ngModel)]="folderColor">
            <mat-radio-button class="grey-radio" value="#999" (click)="updateDefaultColor('#999')"></mat-radio-button>
            <mat-radio-button class="red-radio" value="#ea493d" (click)="updateDefaultColor('#ea493d')">
            </mat-radio-button>
            <mat-radio-button class="blue-radio" value="#5899f5" (click)="updateDefaultColor('#5899f5')">
            </mat-radio-button>
            <mat-radio-button class="green-radio" value="#7ed321" (click)="updateDefaultColor('#7ed321')">
            </mat-radio-button>
            <mat-radio-button class="saffroun-radio" value="#ffbd4a" (click)="updateDefaultColor('#ffbd4a')">
            </mat-radio-button>
        </mat-radio-group>
    </ng-template>
    <ng-template contextMenuItem enabled="true" (execute)="searchFolder(folderInAction)">
        <div class="context-item" [ngClass]="{ 'carbon-theme': currentTheme === 'carbon' }">
            <mat-icon>search</mat-icon>
            <span>{{ 'SEARCH_FOLDER' | translate }}</span>
        </div>
    </ng-template>
</context-menu>

<context-menu #colorSubMenu id="colorSubMenu" class="color-contextmenu">
    <ng-template contextMenuItem>
        <color-chrome [color]="folderColor" (onChange)="handleColorChange($event)"></color-chrome>
    </ng-template>
</context-menu>