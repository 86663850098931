
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import * as moment from "moment-timezone";
import { isArray } from "util";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { DateAdapter } from "@angular/material/core";
import { DatePickAdapterService } from "src/app/calendar/service/date-picker-service";
import { ElectronService } from "src/app/services/electron.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { registerLocaleData } from "@angular/common";
import localeDE from "@angular/common/locales/de";
import localEN from "@angular/common/locales/en";

@Component({
    selector: "vp-custom-repeat-dialog",
    templateUrl: "./custom-repeat-dialog.component.html",
    providers: [{
        provide: DateAdapter, useClass: DatePickAdapterService
    }]
})
export class CustomRepeatDialogComponent implements OnInit, OnDestroy {
    repeatType: string = "DAI";
    dayType: string = "everyday";
    everyDayInput: number = 2;
    weekType: string = "everyDayItem";
    everyWeekInput: number = 2;
    weekItems = new FormControl();
    weekList: string[] = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
    sunday: boolean = false;
    monday: boolean = false;
    tuesday: boolean = false;
    wedensDay: boolean = false;
    thursday: boolean = false;
    friday: boolean = false;
    saturday: boolean = false;
    monthlyType: string = "day";
    monthlyDayInput: string = "2";
    monthlyDayMonthInput: number = 1;
    monthlyDayWeek: string = "1";
    monthlyWeekDay: string = "1";
    everyMonthsInput: number = 1;
    yearlyType: string = "every";
    everyYearInput: string = "2";
    everyYearMonth: string = "1";
    yealyDayWeek: string = "1";
    yearlyWeekDay: string = "1";
    theYearMonth: string = "1";
    endAfterInput: string = "1";
    date = new FormControl(new Date());
    repeatWeeklyDays = [];
    repeatYearlyMonthsList = 1;
    _startDate: Date;
    repeatCustomCount = 1;
    repeatCustom = "0";
    repeatCustomMonthDay: any;
    repeatCustomType = "S";
    repeatMonthlyDayList = null;
    repeatWeekday = false;
    repeatCustomDayOfWeek = "SU";
    repeatCustomDays = [];
    repeatCustomOrdinal: any;
    repeatBySetPos: any;
    repeatEndType = "N";
    repeatEndDate = null;
    repeatEndCount = 1;
    showShortcutSelection: string = "";
    browserLang: string = "en";
    @ViewChild("multipleSelectItem", { static: false }) multipleSelectItem: MatSelect;
    currentTheme = localStorage.getItem("theme");
    constructor(
        public dialogRef: MatDialogRef<CustomRepeatDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translateService: TranslateService,
        private datePickerAdapter: DateAdapter<any>,
        private electronService: ElectronService
    ) {
        this.setLocale();
        const rule = this.data.recur;
        this._startDate = this.data.startDate;
        this.repeatCustomMonthDay = this._startDate.getDate();
        if (rule && rule[0] !== "") {
            this.parseData(rule);
        }
        const defaults: string[] = ["monday"];
        this.weekItems.setValue(defaults);

        if (this.repeatEndDate === null) {
            this.repeatEndDate = new Date();
        }
        this.date = new FormControl(this.repeatEndDate);
        this.setFieldsValue();
    }

    ngOnDestroy() { }
    ngOnInit() {
        this.date = new FormControl(this._startDate);
        const selectedDate = new Date(this.date.value);
        this.monthlyDayInput = selectedDate.getDate().toString();
    }

    parseData(recurRules): void {
        this.repeatWeeklyDays = [];

        for (let k = 0; k < recurRules.length; ++k) {
            const adds = isArray(recurRules[k].add) ? recurRules[k].add : [recurRules[k].add];
            if (!adds) {
                continue;
            }

            this.repeatYearlyMonthsList = this._startDate.getMonth() + 1;
            for (let i = 0; i < adds.length; ++i) {
                const rules = isArray(adds[i].rule) ? adds[i].rule : [adds[i].rule];
                if (!rules) {
                    continue;
                }

                for (let j = 0; j < rules.length; ++j) {
                    const rule = rules[j];
                    if (rule.freq) {
                        this.repeatType = rule.freq.substring(0, 3);
                        if (rule.interval) {
                            rule.interval = isArray(rule.interval) ? rule.interval : [rule.interval];
                            this.repeatCustomCount = parseInt(rule.interval[0].ival, 10);
                            this.repeatCustom = "1";
                        }
                    }
                    if (rule.bymonth) {
                        rule.bymonth = isArray(rule.bymonth) ? rule.bymonth : [rule.bymonth];
                        this.repeatYearlyMonthsList = rule.bymonth[0].molist;
                        this.repeatCustom = "1";
                    }
                    if (rule.bymonthday) {
                        if (this.repeatType === "YEA") {
                            this.repeatCustomMonthDay = isArray(rule.bymonthday) ? rule.bymonthday[0].modaylist : rule.bymonthday.modaylist;
                            this.repeatCustomType = "S";
                        } else if (this.repeatType === "MON") {
                            this.repeatMonthlyDayList = isArray(rule.bymonthday) ? rule.bymonthday[0].modaylist.split(",")
                            : rule.bymonthday.modaylist.split(",");
                        }
                        this.repeatCustom = "1";
                    }
                    if (rule.byday) {
                        rule.byday = isArray(rule.byday) ? rule.byday : [rule.byday];
                        if (rule.byday[0] && rule.byday[0].wkday) {
                            this.repeatCustom = "1";
                            const wkday = rule.byday[0].wkday;
                            if (this.repeatType === "WEE" || (this.repeatType === "DAI" && wkday.length === 5)) {
                                this.repeatWeekday = this.repeatType === "DAI";
                                for (let x = 0; x < wkday.length; ++x) {
                                    this.repeatWeeklyDays.push(wkday[x].day);
                                }
                            } else {
                                this.repeatCustomDayOfWeek = wkday[0].day;
                                const days = [];
                                for (let i = 0; i < wkday.length; i++) {
                                    days.push(wkday[i].day);
                                }
                                this.repeatCustomDays = days;
                                this.repeatCustomOrdinal = wkday[0].ordwk;
                                if (rule.bysetpos) {
                                    rule.bysetpos = isArray(rule.bysetpos) ? rule.bysetpos : [rule.bysetpos];
                                }
                                this.repeatBySetPos = (rule.bysetpos && (rule.bysetpos.length > 0)) ? rule.bysetpos[0].poslist : null;
                                if (this.repeatBySetPos === null && this.repeatCustomOrdinal) {
                                    this.repeatBySetPos = this.repeatCustomOrdinal;
                                }
                                this.repeatCustomType = "O";
                            }
                        }
                    }
                    if (rule.until) {
                        this.repeatEndType = "D";
                        const date = isArray(rule.until) ?  rule.until[0].d : rule.until.d;
                        this.repeatEndDate = moment(date).toDate();
                    } else if (rule.count) {
                        this.repeatEndType = "A";
                        const num = isArray(rule.count) ? rule.count[0].num : rule.count.num;
                        this.repeatEndCount = num;
                    }
                }
            }
        }
    }

    setMonthType(weekDays): void {
        weekDays.map(week => {
            if (week === "SU") {
                this.sunday = true;
            } else if (week === "MO") {
                this.monday = true;
            } else if (week === "TU") {
                this.tuesday = true;
            } else if (week === "WE") {
                this.wedensDay = true;
            } else if (week === "TH") {
                this.thursday = true;
            } else if (week === "FR") {
                this.friday = true;
            } else if (week === "SA") {
                this.saturday = true;
            }
        });
    }

    setFieldsValue(): void {
        if (this.repeatType === "DAI") {
            this.dayType = "everyday";
            if (this.repeatWeekday) {
                this.dayType = "everyweekday";
            } else if (this.repeatCustomCount > 1) {
                this.dayType = "every";
                this.everyDayInput = this.repeatCustomCount;
            }
        } else if (this.repeatType === "WEE") {
            if (this.repeatCustomCount === 1 && this.repeatWeeklyDays.length === 1) {
                this.weekType = "everyDayItem";
                for (let j = 0; j < this.weekList.length; j++) {
                    if (this.repeatWeeklyDays[0] === this.weekList[j]) {
                        break;
                    }
                }
            } else {
                this.weekType = "every";
                this.everyWeekInput = this.repeatCustomCount;
                for (let i = 0; i < this.repeatWeeklyDays.length; i++) {
                    for (let j = 0; j < this.weekList.length; j++) {
                        if (this.repeatWeeklyDays[i] === this.weekList[j]) {
                            if (this.repeatWeeklyDays[i] === "SU") {
                                this.sunday = true;
                            } else if (this.repeatWeeklyDays[i] === "SU") {
                                this.sunday = true;
                            } else if (this.repeatWeeklyDays[i] === "MO") {
                                this.monday = true;
                            } else if (this.repeatWeeklyDays[i] === "TU") {
                                this.tuesday = true;
                            } else if (this.repeatWeeklyDays[i] === "WE") {
                                this.wedensDay = true;
                            } else if (this.repeatWeeklyDays[i] === "TH") {
                                this.thursday = true;
                            } else if (this.repeatWeeklyDays[i] === "FR") {
                                this.friday = true;
                            } else if (this.repeatWeeklyDays[i] === "SA") {
                                this.saturday = true;
                            }
                            break;
                        }
                    }
                }
            }
        } else if (this.repeatType === "MON") {
            if (this.repeatCustomType === "S") {
                this.monthlyType = "day";
                if (this.repeatMonthlyDayList !== null) {
                    this.monthlyDayInput = this.repeatMonthlyDayList[0];
                }
                this.monthlyDayMonthInput = this.repeatCustomCount;
            } else {
                this.monthlyType = "the";
                this.monthlyDayWeek = this.repeatBySetPos.toString();
                this.everyMonthsInput = this.repeatCustomCount;
                const weekDaySelect = this.getRecurrenceWeekDaySelection(this.repeatCustomDays);
                this.monthlyWeekDay = weekDaySelect.toString();
            }
        } else if (this.repeatType === "YEA") {
            if (this.repeatCustomType === "S") {
                this.yearlyType = "every";
                this.everyYearMonth = this.repeatYearlyMonthsList.toString();
                this.everyYearInput = this.repeatCustomMonthDay;
            } else {
                this.yearlyType = "the";
                this.yealyDayWeek = this.repeatBySetPos.toString();
                const yearDaySelect = this.getRecurrenceWeekDaySelection(this.repeatCustomDays);
                this.yearlyWeekDay = yearDaySelect.toString();
                this.theYearMonth = this.repeatYearlyMonthsList.toString();
            }
        }
    }

    getWeekdaySelectValue(weekdaySelect): any {
        const day = weekdaySelect;
        if (this.weekList[day]) {
            return [this.weekList[day]];
        }
        if (day === "-2") {
            return this.weekList;
        } else if (day === "-3") {
            return this.weekList.slice(1, 6);
        } else if (day === "-1") {
            return [this.weekList[0], this.weekList[6]];
        }
    }

    getRecurrenceWeekDaySelection(repeatCustomDays): any {
        if (repeatCustomDays instanceof Array) {
            repeatCustomDays = repeatCustomDays.join(",");
        }
        if (repeatCustomDays === this.weekList.join(",")) {
            return -1;
        }
        const weekDays = this.weekList.slice(1, 6);
        if (repeatCustomDays === weekDays.join(",")) {
            return -3;
        }
        const weekEndDays = [this.weekList[0], this.weekList[6]];
        if (repeatCustomDays === weekEndDays.join(",")) {
            return -2;
        }
        for (let i = 0; i < this.weekList.length; i++) {
            if (this.weekList[i] === repeatCustomDays) {
                return i;
                break;
            }
        }
    }

    submit(): void {
        const jsonData = this.setJson();
        if (this.repeatType === "NONE") {
            this.dialogRef.close({ value: "" });
        } else {
            let selectedDate = null;
            if (this.repeatType === "MON") {
                const dateItem = new Date(this.date.value);
                dateItem.setDate(+this.monthlyDayInput);
                if (moment(this._startDate).isAfter(dateItem)) {
                    dateItem.setMonth(dateItem.getMonth() + 1);
                }
                selectedDate = dateItem;
            }
            this.dialogRef.close({ value: jsonData , startDate: selectedDate});
        }
    }

    close() {
        this.dialogRef.close();
    }

    setJson(): any {
        if (this.repeatType === "NONE") {
            return {};
        }
        let recur: any = {};
        let add: any = recur.add = {};
        let rule: any = add.rule = {};
        let interval: any = rule.interval = {};
        let until: any;
        let bwd, bmd, c, i, day, wkDay, bysetpos, bm;
        rule.freq = this.repeatType;
        interval.ival = 1;

        if (this.repeatEndType === "D") {
            until = rule.until = {};
            until.d = moment(this.date.value).format("YYYYMMDD");
        } else if (this.repeatEndType === "A") {
            c = rule.count = {};
            c.num = this.repeatEndCount;
        }
        if (this.repeatType === "DAI") {
            if (this.repeatWeekday) {
                bwd = rule.byday = {};
                wkDay = bwd.wkday = [];
                for (i = 0; i < this.weekList.length; i++) {
                    day = this.weekList[i];
                    if (day === "SA" || day === "SU") {
                        continue;
                    }
                    wkDay.push({
                        day: day
                    });
                }
            }
            if (this.dayType === "every") {
                interval.ival = +this.everyDayInput;
            }
        } else if (this.repeatType === "WEE") {
            bwd = rule.byday = {};
            wkDay = bwd.wkday = [];
            this.repeatWeeklyDays = this.getRepeatWeekDays();
            for (i = 0; i < this.repeatWeeklyDays.length; ++i) {
                wkDay.push({
                    day: this.repeatWeeklyDays[i]
                });
            }
            if (this.weekType === "every") {
                interval.ival = +this.everyWeekInput;
            }
        } else if (this.repeatType === "MON") {
            if (this.monthlyType === "day") {
                bmd = rule.bymonthday = {};
                bmd.modaylist = this.monthlyDayInput;
                interval.ival = +this.monthlyDayMonthInput;
            } else {
                bwd = rule.byday = {};
                bwd.wkday = [];
                const weekCustom = this.getWeekdaySelectValue(this.monthlyWeekDay);
                for (i = 0; i < weekCustom.length; i++) {
                    wkDay = {};
                    wkDay.day = weekCustom[i];
                    if (this.repeatCustomOrdinal) {
                        wkDay.ordwk = this.repeatCustomOrdinal;
                    }
                    bwd.wkday.push(wkDay);
                }
                interval.ival = +this.everyMonthsInput;
                bysetpos = rule.bysetpos = {};
                bysetpos.poslist = this.monthlyDayWeek;
            }
        } else if (this.repeatType === "YEA") {
            bm = rule.bymonth = {};
            interval.ival = this.repeatCustomCount;
            if (this.yearlyType === "the") {
                bwd = rule.byday = {};
                bwd.wkday = [];
                const weekCustom = this.getWeekdaySelectValue(this.yearlyWeekDay);
                for (i = 0; i < weekCustom.length; i++) {
                    wkDay = {};
                    wkDay.day = weekCustom[i];

                    bwd.wkday.push(wkDay);
                }
                bysetpos = rule.bysetpos = {};
                bysetpos.poslist = this.yealyDayWeek;
                bm.molist = this.theYearMonth;
            } else {
                bmd = rule.bymonthday = {};
                bmd.modaylist = this.everyYearInput;
                bm.molist = this.everyYearMonth;
            }
        }
        return recur;
    }

    changeDaily(ev): void {
        if (ev.value === "everyweekday") {
            this.repeatWeekday = true;
        } else if (ev.value === "every") {
            this.repeatWeekday = false;
        } else {
            this.repeatWeekday = false;
        }
    }

    getRepeatWeekDays(): any {
        const weekDays: any[] = [];
        if (this.sunday) {
            weekDays.push("SU");
        }
        if (this.monday) {
            weekDays.push("MO");
        }
        if (this.tuesday) {
            weekDays.push("TU");
        }
        if (this.wedensDay) {
            weekDays.push("WE");
        }
        if (this.thursday) {
            weekDays.push("TH");
        }
        if (this.friday) {
            weekDays.push("FR");
        }
        if (this.saturday) {
            weekDays.push("SA");
        }
        return weekDays;
    }

    dayEveryWeekInputPress(): void {
        this.dayType = "every";
    }

    weekInputEvery(): void {
        this.weekType = "every";
    }

    montlyDayInputClick(): void {
        this.monthlyType = "day";
    }

    evertMonthsInputClick(): void {
        this.monthlyType = "the";
    }

    yearlyInputClick(): void {
        this.yearlyType = "every";
    }

    repeatEndClick(): void {
        this.repeatEndType = "A";
    }

    openDatepicker(ev): void {
        this.repeatEndType = "D";
    }

    pickerInputClick(): void {
        this.repeatEndType = "D";
    }

    isPastOrFutureDate(date: Date): boolean {
        if (moment(this._startDate).isAfter(date) || moment(this._startDate).isBefore(date)) {
            return true;
        }
        return false;
    }

    changeMultipleValue(): void {
        const weekValues = this.weekItems.value;
        this.sunday = false;
        this.monday = false;
        this.tuesday = false;
        this.wedensDay = false;
        this.thursday = false;
        this.friday = false;
        this.saturday = false;
        this.showShortcutSelection = "";
        const weekSelection: any[] = [];
        if (weekValues && weekValues.length > 0) {
            weekValues.map( item => {
                if (item === "SU") {
                    this.sunday = true;
                    this.translateService.get("PREFERENCES.SUN").pipe(take(1)).subscribe(res => {
                        weekSelection.push(res);
                    });
                } else if (item === "MO") {
                    this.monday = true;
                    this.translateService.get("PREFERENCES.MON").pipe(take(1)).subscribe(res => {
                        weekSelection.push(res);
                    });
                } if (item === "TU") {
                    this.tuesday = true;
                    this.translateService.get("PREFERENCES.TUE").pipe(take(1)).subscribe(res => {
                        weekSelection.push(res);
                    });
                } if (item === "WE") {
                    this.wedensDay = true;
                    this.translateService.get("PREFERENCES.WED").pipe(take(1)).subscribe(res => {
                        weekSelection.push(res);
                    });
                } if (item === "TH") {
                    this.thursday = true;
                    this.translateService.get("PREFERENCES.THU").pipe(take(1)).subscribe(res => {
                        weekSelection.push(res);
                    });
                } if (item === "FR") {
                    this.friday = true;
                    this.translateService.get("PREFERENCES.FRI").pipe(take(1)).subscribe(res => {
                        weekSelection.push(res);
                    });
                } if (item === "SA") {
                    this.saturday = true;
                    this.translateService.get("PREFERENCES.SAT").pipe(take(1)).subscribe(res => {
                        weekSelection.push(res);
                    });
                }
            });
        }
        let selected: string = "";
        this.translateService.get("SELECTED").pipe(take(1)).subscribe(res => {
            selected = res;
        });
        if (!!weekSelection && weekSelection.length > 0) {
            this.showShortcutSelection = weekSelection.join(", ").toString() + " " + selected;
        }
        this.multipleSelectItem.close();
    }

    setLocale() {
        this.browserLang = this.translateService.getBrowserLang();
        const localLang = this.electronService.isElectron
            ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE)
            : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
        if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
          this.browserLang = localLang;
        }
        this.browserLang = this.browserLang.match(/en|de/) ? this.browserLang : "en";
        this.datePickerAdapter.setLocale(this.browserLang);
        if (this.browserLang === "de") {
          registerLocaleData(localeDE, this.browserLang);
        } else {
          registerLocaleData(localEN, this.browserLang);
        }
      }
}
