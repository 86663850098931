
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy } from "@angular/core";
import localeDE from "@angular/common/locales/de";
import localEN from "@angular/common/locales/en";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ElectronService } from "src/app/services/electron.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { registerLocaleData, DatePipe } from "@angular/common";
import * as moment from "moment-timezone";
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import jstimezonedetect from "jstimezonedetect";
import TIMEZONE from "src/app/common/utils/timezone";
import { ConfigService } from "src/app/config.service";
import { ToastService } from "src/app/common/providers/toast.service";
import { ConfirmationData, ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: "DD. MMMM YYYY",
    },
    display: {
        dateInput: "DD. MMMM YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY"
    },
};

@Component({
    selector: "vp-send-later-dialog",
    templateUrl: "./send-later-dialog.component.html",
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS
        },
    ]
})
export class SendLaterDialogComponent implements OnInit, OnDestroy {
    startDateFormControl: FormControl = new FormControl(new Date());
    browserLang: string = "en";
    timePoints: any;
    timeInput: string = "";
    startTimeZone: any = {};
    currentTheme = localStorage.getItem("theme");
    timeZone = Object.keys(TIMEZONE).map(key => {
        return { key: key, value: TIMEZONE[key] };
    });
    constructor(
        public dialogRef: MatDialogRef<SendLaterDialogComponent>,
        private datePickerAdapter: DateAdapter<any>,
        private translateService: TranslateService,
        private electronService: ElectronService,
        private datePipe: DatePipe,
        private configService: ConfigService
    ) {
        this.startTimeZone.key = this.configService.prefs.zimbraPrefTimeZoneId;
        this.startTimeZone.value = this.getTimeZoneValue(this.startTimeZone.key);
    }

    ngOnInit() {
        this.setLocale();
        this.timePoints = this.generateTimePointOptions();
        const dateItem = new Date(this.startDateFormControl.value);
        this.setTimeInput(dateItem);
    }

    ngOnDestroy() { }

    close(): void {
        this.dialogRef.close();
    }
    setLocale() {
        this.browserLang = this.translateService.getBrowserLang();
        const localLang = this.electronService.isElectron
            ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE)
            : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
        if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
            this.browserLang = localLang;
        }
        this.browserLang = this.browserLang.match(/en|de/) ? this.browserLang : "en";
        this.datePickerAdapter.setLocale(this.browserLang);
        if (this.browserLang === "de") {
            registerLocaleData(localeDE, this.browserLang);
        } else {
            registerLocaleData(localEN, this.browserLang);
        }
    }

    private generateTimePointOptions(date?: Date): Date[] {
        date = date ? new Date(date) : new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        const timeList: Date[] = [];
        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 4; j++) {
                const newDate = new Date(date);
                newDate.setHours(i);
                newDate.setMinutes(j * 15);
                timeList.push(newDate);
            }
        }
        return timeList;
    }

    okListener(): void {
        const date = moment(this.startDateFormControl.value).format("YYYY-MM-DD");
        let time: any;
        if (this.browserLang !== "de") {
            time = moment(this.timeInput, "hh:mm a").format("HH:mm");
        } else {
            time = moment(this.timeInput, "HH:mm").format("HH:mm");
        }
        const sendDate = moment(date + " " + time, "YYYY-MM-DD HH:mm").toDate();
        const dateOffset = moment(sendDate).utcOffset();
        const utcDate = new Date(sendDate.getTime() - dateOffset * 60 * 1000);

        const now = new Date();
        const nowOffset = moment(now).utcOffset();
        const utcNow = new Date(now.getTime() - nowOffset * 60 * 1000);
        if (utcDate < utcNow) {
            this.dialogRef.close({ value: "pastDate" });
        } else {
            const offset = moment().tz(this.startTimeZone.key).utcOffset();
            const utcSendDate = sendDate.getTime() - ((sendDate.getTimezoneOffset() + offset) * 60 * 1000);
            this.dialogRef.close({ value: "sendDraft", timeStamp: utcSendDate });
        }
    }

    handleStartTimeChanges(date: Date): void {
        this.setTimeInput(date);
    }

    getTimeZoneValue(key: string): string {
        const item = this.timeZone.filter(items => items.key === key);
        if (!!item && item.length > 0) {
            return item[0].value;
        }
        return "";
    }

    handleStartTimeZoneChanges(startTimeZoneKey: any): void {
        this.startTimeZone.key = startTimeZoneKey.key;
        this.startTimeZone.value = startTimeZoneKey.value;
    }

    setTimeInput(dateItem: Date): void {
        if (this.browserLang !== "de") {
            this.timeInput = this.datePipe.transform(dateItem, "hh:mm a", "", this.browserLang);
        } else {
            this.timeInput = this.datePipe.transform(dateItem, "HH:mm", "", this.browserLang);
        }
    }
}
