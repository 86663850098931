
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "vp-save-change-appointment-dialog",
    templateUrl: "./save-change-appointment.component.html"
})
export class SaveChangeAppointmentDialogComponent implements OnInit, OnDestroy {
    currentTheme = localStorage.getItem("theme");
    constructor(
        public dialogRef: MatDialogRef<SaveChangeAppointmentDialogComponent>
    ) { }

    ngOnInit() { }

    ngOnDestroy() { }

    close(value: string): void {
        this.dialogRef.close({ value: value });
    }
}
